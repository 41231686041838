import { useQuery, gql } from '@apollo/client'
import { connectionToCollection, PAGE_INFO_FRAGMENT } from '../../../../util/graphql'

export const QUERY = gql`
    query customerUnresolvedDeliveryQuery(
        $customerId: Int!,
        $after: String,
        $first: Int,
        $where: DeliveryWhereInput
    ) {
        customerDeliveries(
            customerId: $customerId,
            after: $after,
            first: $first,
            where: $where,
        ) {
            edges {
                node {
                    id
                    quantity
                    unresolvedQuantity
                    createdAt
                    customer {
                        id
                        name
                    }
                    asset {
                        id
                        name
                    }
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
    }
    ${PAGE_INFO_FRAGMENT}

`

const useFetchAddDeliveryToOrderModalData = (customerId, assetId, first, after) => {
    const where = {
        assetId,
        unresolvedQuantityIsNot: 0,
    }
    const variables = {
        customerId,
        first,
        after,
        where,
    }
    const {
        loading,
        data = {},
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })
    let { customerDeliveries = [] } = data

    if (!loading && typeof data.customerDeliveries !== 'undefined') {
        customerDeliveries = connectionToCollection(data.customerDeliveries)
    }

    return {
        isFetching: loading,
        customerDeliveries,
    }
}

export default useFetchAddDeliveryToOrderModalData
