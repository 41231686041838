import React from 'react'
import { Form, DatePicker } from 'antd'
import FormItem from '../../../util/form/FormItem'
import CustomerSelect from '../../../util/form/CustomerSelect'

const { RangePicker } = DatePicker

const ExportOrdersForm = ({
    customerId,
    isFetching,
    customers,
    formState,
}) => {
    const {
        antdErrors,
        getInputProps,
    } = formState

    return (
        <Form layout="vertical">
            <FormItem
                label="Customer"
                error={antdErrors.get('customerId')}
            >
                <CustomerSelect
                    {...getInputProps('customerId')}
                    loading={isFetching}
                    customers={customers}
                    disabled={customerId !== null}
                />
            </FormItem>
            <FormItem
                label="Date range"
                error={antdErrors.get('dateRange')}
            >
                <RangePicker
                    className="w-full"
                    {...getInputProps('dateRange')}
                />
            </FormItem>
        </Form>
    )
}

export default ExportOrdersForm
