import React from 'react'
import { Select, Spin } from 'antd'
import { where } from '../../../util'
import CustomerSearch from '../queries/CustomerSearch'

const { Option } = Select
const whereId = where('id')

const CustomerSearchSelect = ({
    value,
    customer,
    onSearch = () => {},
    onChange,
    className,
    disabled = false,
}) => {
    const customerIsDefined = typeof customer !== 'undefined'
    return (
        <CustomerSearch>
            {({
                loading,
                customers,
                handleSearch,
                clearSearch,
            }) => (
                <Select
                    allowClear
                    className={className}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    notFoundContent={loading && <Spin size="small" />}
                    onChange={(id) => {
                        if (id === null) {
                            clearSearch(undefined)
                        }
                        const customer = customers.find(whereId(id))
                        onChange(id, customer)
                    }}
                    onSearch={(search) => {
                        handleSearch(search)
                        onSearch(customers)
                    }}
                    placeholder="Type to search for a customer"
                    showSearch
                    showArrow={false}
                    value={customerIsDefined ? customer.id : value}
                    disabled={disabled}
                >
                    {(() => {
                        const options = customers
                        if (customerIsDefined && typeof customers.find(whereId(customer.id)) === 'undefined') {
                            options.push(customer)
                        }
                        return (
                            options.map((option) => (
                                <Option
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.name}
                                </Option>
                            ))
                        )
                    })()}
                </Select>
            )}
        </CustomerSearch>
    )
}

export default CustomerSearchSelect
