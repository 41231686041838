import React from 'react'
import { Button, Menu, Dropdown } from 'antd'
import HasPermissions from '../../../util/HasPermissions'

const CustomerAssetCreateButton = ({
    disabled,
    assets,
    isCreatingCustomerAsset,
    onCreateCustomerAsset,
}) => (
    <HasPermissions roles={[HasPermissions.ROLES.ADMIN]}>
        <Dropdown
            trigger={['click']}
            disabled={disabled}
            overlay={(
                <Menu>
                    {assets.map((asset) => (
                        <Menu.Item
                            key={asset.id}
                            onClick={() => onCreateCustomerAsset(asset.id)}
                        >
                            {asset.name}
                        </Menu.Item>
                    ))}
                </Menu>
            )}
        >
            <Button
                loading={isCreatingCustomerAsset}
                type="primary"
            >
                Add Asset
            </Button>
        </Dropdown>
    </HasPermissions>
)

export default CustomerAssetCreateButton
