import React from 'react'
import { Card } from 'antd'
import classNames from 'classnames'
import Tag from '../../../util/Tag'

const getCurrencies = (currencies) => (
    currencies.map((obj) => obj.code).join(', ')
)
const getUnits = (units) => (
    units.map((obj) => obj.code).join(', ')
)

const AssetCard = ({
    asset,
    actions,
}) => (
    <Card
        className={classNames({ suspended: asset.suspended })}
        title={asset.name}
        extra={(!asset.available && <Tag>UNAVAILABLE</Tag>) || (asset.suspended && <Tag>SUSPENDED</Tag>)}
        actions={actions}
    >
        <div className="w-full flex justify-between">
            <span>Code:</span>
            <strong>{asset.code}</strong>
        </div>
        <div className="w-full flex justify-between">
            <span>Currencies:</span>
            <strong>{getCurrencies(asset.currencies)}</strong>
        </div>
        <div className="w-full flex justify-between">
            <span>Units:</span>
            <strong>{getUnits(asset.units)}</strong>
        </div>
    </Card>
)

export default AssetCard
