import React from 'react'
import CustomerTopBar from '../../layout/CustomerTopBar'
import InfiniteScroll from '../../util/InfiniteScroll'
import CustomerUsersTable from './table/CustomerUsersTable'
import CreateCustomerUserButton from './table/CreateCustomerUserButton'

const CustomerUsersPage = ({
    hasMore,
    isFetchingMore,
    customer,
    customerUsers,
    handleFetchMore,
    onDeleteComplete,
    onCreateCustomerUser,
    onUpdateCustomerUser,
    onResendEmailInvitation,
}) => (
    <>
        <CustomerTopBar
            customerName={customer.name}
            rightChildren={(
                <CreateCustomerUserButton
                    onCreateCustomerUser={onCreateCustomerUser}
                />
            )}
        />
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            hasMore={hasMore}
            onFetchMore={handleFetchMore}
        >
            <CustomerUsersTable
                users={customerUsers}
                onDeleteComplete={onDeleteComplete}
                onUpdateCustomerUser={onUpdateCustomerUser}
                onResendEmailInvitation={onResendEmailInvitation}
            />
        </InfiniteScroll>
    </>
)

export default CustomerUsersPage
