import React from 'react'
import { Card } from 'antd'
import logo from '../../../static/images/logo.svg'

const LoginFormWrapper = ({ title, button, children }) => (
    <div className="mt-[-75px] min-h-[450px] w-[400px]">
        <div className="flex items-center justify-center mb-8">
            <img className="h-40" src={logo} alt="logo" />
        </div>
        <Card title={title} extra={button}>
            {children}
        </Card>
    </div>
)

export default LoginFormWrapper
