import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { getCurrencySymbol } from '../../../../util/currency'

export const ASSET_CARD_QUERY = gql`
    query assetCardQuery($assetCode: String!, $unitCode: String!, $currencyCode: String!) {
        adjustedMarketData(assetCode: $assetCode, unitCode: $unitCode, currencyCode: $currencyCode) {
            status
            sellPrice
            previousSellPrice
            buyPrice
            previousBuyPrice
        }
    }
`

const useFetchAssetCardData = (assetCode, inputCurrencyCode, inputUnitCode, pollInterval) => {
    const [currencyCode, setCurrencyCode] = useState(inputCurrencyCode)
    const [unitCode, setUnitCode] = useState(inputUnitCode)
    const variables = {
        assetCode,
        unitCode,
        currencyCode,
    }
    const {
        error,
        loading,
        data = {},
    } = useQuery(ASSET_CARD_QUERY, {
        variables,
        pollInterval,
    })
    const { adjustedMarketData = {} } = data

    return {
        error,
        isFetching: loading,
        adjustedMarketData,
        currencyCode,
        currencySymbol: getCurrencySymbol(currencyCode),
        unitCode,
        onChangeCurrency: (newCurrencyCode) => setCurrencyCode(newCurrencyCode),
        onChangeUnit: (newUnitCode) => setUnitCode(newUnitCode),
    }
}

export default useFetchAssetCardData
