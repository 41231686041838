import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Modal, notification } from 'antd'
import createDelivery from '../../../../../api/createDelivery'
import CreateDeliveryForm from '../forms/CreateDeliveryForm'
import useCreateDeliveryForm from '../forms/useCreateDeliveryForm'

const calculateUpdatedOrderDeliveries = (order, deliveries, quantityAvailable) => {
    const remainingQuantity = quantityAvailable - deliveries.reduce((total, delivery) => total + delivery.quantity, 0)
    let deliveryQuantity = remainingQuantity
    if (remainingQuantity >= order.outstandingQuantity) {
        deliveryQuantity = order.outstandingQuantity
    }

    const updatedOrderDeliveries = [...deliveries]
    const existingDeliveryIndex = deliveries.findIndex((delivery) => delivery.orderId === order.id)
    if (existingDeliveryIndex >= 0) {
        const existingDelivery = deliveries[existingDeliveryIndex]
        updatedOrderDeliveries[existingDeliveryIndex] = {
            ...existingDelivery,
            quantity: existingDelivery.quantity + deliveryQuantity,
        }
    } else {
        updatedOrderDeliveries.push({
            orderId: order.id,
            quantity: deliveryQuantity,
        })
    }

    return updatedOrderDeliveries
}

const CreateDeliveryModalWithState = ({
    isVisible,
    onCreateDeliveryComplete,
    closeModal,
    customer = null,
    refetchQuery,
    assets,
}) => {
    const [isCreatingDelivery, setIsCreatingDelivery] = useState(false)
    const apolloClient = useApolloClient()
    const formState = useCreateDeliveryForm(customer)
    const {
        values,
        handleChange,
    } = formState
    const {
        quantity,
        orderDeliveries,
    } = values

    return (
        <Modal
            title="Add delivery"
            visible={isVisible}
            width={750}
            onOk={async () => {
                const isValid = formState.validate()
                if (isValid) {
                    setIsCreatingDelivery(true)
                    try {
                        const input = formState.valuesToInput()
                        const refetchQueries = [{
                            query: refetchQuery,
                            variables: {
                                customerId: input.customerId,
                            },
                        }]
                        const { data } = await createDelivery(apolloClient, input, refetchQueries)
                        notification.success({
                            message: 'Delivery successful!',
                            description: `The delivery for ${data.createDelivery.delivery.customer.name} was successfully registered!`,
                        })
                    } catch (e) {
                        notification.error({
                            message: 'Delivery not created!',
                            description: `Something went wrong. The following error occured: ${e}`,
                        })
                    }
                    setIsCreatingDelivery(false)
                    onCreateDeliveryComplete()
                }
            }}
            closable={!isCreatingDelivery}
            maskClosable={!isCreatingDelivery}
            confirmLoading={isCreatingDelivery}
            onCancel={closeModal}
        >
            <CreateDeliveryForm
                formState={formState}
                assets={assets}
                onFill={(order) => {
                    if (quantity > 0) {
                        const updatedOrderDeliveries = calculateUpdatedOrderDeliveries(order, orderDeliveries, quantity)
                        handleChange('orderDeliveries', updatedOrderDeliveries)
                    }
                }}
                onUnFill={(order) => {
                    const orderDeliveryIndex = orderDeliveries.findIndex((delivery) => delivery.orderId === order.id)
                    orderDeliveries.splice(orderDeliveryIndex, 1)
                    handleChange('orderDeliveries', orderDeliveries)
                }}
            />
        </Modal>
    )
}

export default CreateDeliveryModalWithState
