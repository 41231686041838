import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { notification } from 'antd'
import updateCustomerAsset from '../../../api/updateCustomerAsset'
import * as routes from '../../../routes'
import CustomerPage from '../../layout/CustomerPage'
import CustomerPageError from '../../layout/CustomerPageError'
import useUpdateCustomerAssetForm from './useUpdateCustomerAssetForm'
import UpdateCustomerAssetPage from './UpdateCustomerAssetPage'
import useFetchUpdateCustomerAssetPageData from './useFetchUpdateCustomerAssetPageData'

const UpdateCustomerAssetPageWithState = () => {
    const params = useParams()
    const navigate = useNavigate()
    const customerId = parseInt(params.customerId, 10)
    const customerAssetId = parseInt(params.customerAssetId, 10)
    const [isSavingAsset, setIsSavingAsset] = useState(false)
    const apolloclient = useApolloClient()
    const {
        error,
        isFetching,
        customerAsset,
        currencies,
        units,
    } = useFetchUpdateCustomerAssetPageData(customerAssetId)
    const formState = useUpdateCustomerAssetForm(customerAsset)

    if (error) {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem="assets"
            />
        )
    }

    return (
        <CustomerPage
            customerId={customerId}
            isLoading={isFetching}
            activeMenuItem="assets"
        >
            <UpdateCustomerAssetPage
                customerId={customerId}
                currencies={currencies}
                units={units}
                routes={routes}
                formState={formState}
                isFetching={isFetching}
                isSaving={isSavingAsset}
                onSaveAsset={async () => {
                    const isValid = formState.validate()
                    if (isValid) {
                        setIsSavingAsset(true)
                        try {
                            const input = formState.valuesToInput()
                            const { data } = await updateCustomerAsset(apolloclient, customerAssetId, input)
                            notification.success({
                                message: 'Asset Updated!',
                                description: `Asset ${data.updateCustomerAsset.customerAsset.asset.name} successfully updated`,
                            })
                            navigate(`/customer/${customerId}/settings/assets`)
                        } catch (e) {
                            notification.error({
                                message: 'Customer not updated!',
                                description: `Something went wrong. The following error occured: ${e}`,
                            })
                            setIsSavingAsset(false)
                        }
                    }
                }}
            />
        </CustomerPage>
    )
}

export default UpdateCustomerAssetPageWithState
