import * as validation from '../index'

export const isValidEmail = (email) => (
    validation.isValue(email) &&
    validation.isEmail(email)
)

export const isValidForgotPasswordForm = (values) => (
    isValidEmail(values.email)
)
