import { gql } from '@apollo/client'

export const ADJUSTED_MARKET_DATA_QUERY = gql`
    query adjustedMarketDataQuery(
        $assetCode: String!,
        $unitCode: String!,
        $currencyCode: String!
    ) {
        adjustedMarketData(
            assetCode: $assetCode,
            unitCode: $unitCode,
            currencyCode: $currencyCode
        ) {
            status
            sellPrice
            previousSellPrice
            buyPrice
            previousBuyPrice
        }
    }
`

export default async function getAdjustedMarketData(apolloClient, options) {
    const query = ADJUSTED_MARKET_DATA_QUERY
    const result = await apolloClient.query({
        query,
        ...options,
    })
    return result
}
