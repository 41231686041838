import React from 'react'
import { Select } from 'antd'

const { Option } = Select

const OTHER = 'OTHER'
const HEDGE = 'HEDGE'
const ORDER = 'ORDER'

const options = [{
    value: HEDGE,
    label: 'Hedge',
}, {
    value: ORDER,
    label: 'Order',
}, {
    value: OTHER,
    label: 'Other',
}]

const TypeSelect = ({
    placeholder = 'Choose your type',
    ...props
}) => (
    <Select
        placeholder={placeholder}
        {...props}
    >
        {options.map(({ value, label }) => (
            <Option
                key={value}
                value={value}
            >
                {label}
            </Option>
        ))}
    </Select>
)

TypeSelect.OTHER = OTHER
TypeSelect.HEDGE = HEDGE
TypeSelect.ORDER = ORDER

export default TypeSelect
