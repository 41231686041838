import React from 'react'
import {
    Form,
    Input, Row, Col, Radio, Checkbox,
} from 'antd'
import { ROLES } from '../../../util/roles'
import * as constants from '../../../util/constants'
import InputNumber from '../../util/form/InputNumber'
import FormItem from '../../util/form/FormItem'
import HasPermissions from '../../util/HasPermissions'
import UnitInputWithConverter from '../../util/form/UnitInputWithConverter'
import TradeLimitsToggleButton from './TradeLimitsToggleButton'

const {
    TRADE_LIMIT_TYPE_FIXED: FIXED,
    TRADE_LIMIT_TYPE_MARGIN: MARGIN,
} = constants

const CustomerSettingsForm = ({
    formState,
}) => {
    const {
        getInputProps,
        getNativeInputProps,
        values,
        antdErrors,
        handleEditTradeLimits,
        handleChangeLimitType,
    } = formState
    const {
        hasTradeLimit,
        balanceMargin,
        fixedTradeLimit,
    } = values

    return (
        <HasPermissions roles={[ROLES.ADMIN]}>
            {(hasPermission) => (
                <Form layout="vertical">
                    <form className="form">
                        <Row>
                            <h2 className="text-2xl">Name</h2>
                        </Row>
                        <Row>
                            <Col md={10} sm={24}>
                                <FormItem
                                    error={antdErrors.get('name')}
                                >
                                    <Input
                                        {...getNativeInputProps('name')}
                                        disabled={!hasPermission}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} sm={24}>
                                <h2 className="text-2xl">Trade limit</h2>
                            </Col>
                            <Col md={7} sm={24}>
                                <div className="w-5 h-5 mt-1">
                                    <Checkbox
                                        {...getNativeInputProps('hasTradeLimit')}
                                        checked={hasTradeLimit}
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={10} sm={24}>
                                {balanceMargin !== null && (
                                    <FormItem
                                        label="Balance margin"
                                        error={antdErrors.get('balanceMargin')}
                                    >
                                        <InputNumber
                                            {...getInputProps('balanceMargin')}
                                            disabled={!hasPermission || !hasTradeLimit}
                                            className="w-full"
                                            addonAfter="%"
                                        />
                                    </FormItem>
                                )}
                                {fixedTradeLimit !== null && (
                                    <FormItem
                                        label="Fixed limit"
                                        error={antdErrors.get('fixedTradeLimit')}
                                    >
                                        <InputNumber
                                            {...getInputProps('fixedTradeLimit')}
                                            disabled={!hasPermission || !hasTradeLimit}
                                            className="w-full"
                                            addonAfter="€"
                                        />
                                    </FormItem>
                                )}
                            </Col>
                            <Col md={10} sm={24}>
                                <FormItem
                                    label="Limit type:"
                                >
                                    <Radio.Group
                                        {...getInputProps('limitType')}
                                        onChange={handleChangeLimitType}
                                        buttonStyle="solid"
                                        disabled={!hasTradeLimit}
                                    >
                                        <Radio.Button value={MARGIN}>
                                            Margin
                                        </Radio.Button>
                                        <Radio.Button value={FIXED}>
                                            Fixed
                                        </Radio.Button>
                                    </Radio.Group>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <h2 className="text-2xl">Quantity limits</h2>
                        </Row>
                        <Row gutter={16}>
                            <Col md={10} sm={24}>
                                <FormItem
                                    label="Minimum quantity limit"
                                    style={{ width: '100%' }}
                                >
                                    <UnitInputWithConverter
                                        {...getInputProps('minTradeLimit')}
                                        error={antdErrors.get('minTradeLimit')}
                                        code="settings"
                                        isRounded={false}
                                        disabled={!formState.values.areTradeLimitsSet}
                                    />
                                </FormItem>
                            </Col>
                            <Col md={10} sm={24}>
                                <FormItem
                                    label="Maximum quantity limit"
                                    style={{ width: '100%' }}
                                >
                                    <UnitInputWithConverter
                                        {...getInputProps('maxTradeLimit')}
                                        error={antdErrors.get('maxTradeLimit')}
                                        code="settings"
                                        isRounded={false}
                                        disabled={!formState.values.areTradeLimitsSet}
                                    />
                                </FormItem>
                            </Col>
                            <Col md={4} sm={24}>
                                <div className="mt-7">
                                    <TradeLimitsToggleButton
                                        areTradeLimitsSet={formState.values.areTradeLimitsSet}
                                        onToggleEditTradeLimits={handleEditTradeLimits}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Form>
            )}
        </HasPermissions>
    )
}

export default CustomerSettingsForm
