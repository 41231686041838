import React from 'react'
import { InputNumber } from 'antd'
import { getInputStep, getPrecision } from '../../../../util/units'
import { getDecimalSeparator } from '../../../../util/currency'
import { PRICE_ADJUSTMENT_ACCURACY } from '../../../../util/constants'

const CustomerPriceAdjustmentsTableInput = ({
    formState,
    name,
    index,
    formattingStyle = 'european',
}) => {
    const {
        values,
        getInputProps,
    } = formState
    const customerPriceAdjustment = values.priceAdjustments[index]
    const decimalSeparator = getDecimalSeparator(formattingStyle)
    const { unitCode } = customerPriceAdjustment

    let finalValue = customerPriceAdjustment[name]
    if (finalValue === null) {
        finalValue = customerPriceAdjustment.priceAdjustment[name] / PRICE_ADJUSTMENT_ACCURACY
    }

    return (
        <InputNumber
            {...getInputProps(`priceAdjustments.${index}.${name}`)}
            min={0}
            step={getInputStep(unitCode)}
            precision={getPrecision(unitCode)}
            decimalSeparator={decimalSeparator}
            value={finalValue}
            disabled={customerPriceAdjustment[name] === null}
            className="w-full"
        />
    )
}

export default CustomerPriceAdjustmentsTableInput
