import Pusher from 'pusher-js'

let pusher = null
export const getPusherConnection = (accessToken) => {
    if (pusher === null) {
        const {
            REACT_APP_SERVER_URI: URI,
            REACT_APP_PUSHER_KEY: KEY,
        } = window.env
        pusher = new Pusher(KEY, {
            authEndpoint: `${URI}/pusher/auth`,
            auth: {
                headers: {
                    authorization: accessToken,
                },
            },
            cluster: 'eu',
            forceTLS: true,
        })
    }
    return pusher
}

export const subscribeToEvent = (channelName, eventName, callback) => {
    const channel = pusher.subscribe(channelName)
    channel.bind(eventName, callback)
    return () => pusher.unsubscribe(channelName)
}
