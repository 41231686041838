import React from 'react'
import AssetsList from './list/AssetsList'

const AssetsPage = ({
    assets,
    onEditAsset,
    onSuspendAsset,
    onToggleAvailableAsset,
}) => (
    <AssetsList
        assets={assets}
        onEditAsset={onEditAsset}
        onSuspendAsset={onSuspendAsset}
        onToggleAvailableAsset={onToggleAvailableAsset}
    />
)

export default AssetsPage
