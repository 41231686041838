import { createFormValidation } from 'use-form-state'
import useFormState from '../../hooks/useFormState'

const validation = createFormValidation([{
    path: 'dateRange',
    validate: (dateRange) => dateRange !== null && dateRange.length > 0,
    message: 'Please pick a date range',
}])

const useUpdateAssetForm = (initialValues, options) => (
    useFormState(initialValues, {
        validation,
        ...options,
    })
)

export default useUpdateAssetForm
