import React from 'react'
import {
    Form,
    Modal,
    DatePicker,
} from 'antd'
import FormItem from '../../util/form/FormItem'

const { RangePicker } = DatePicker

const ExportTradesModal = ({
    isOpen,
    isLoading,
    formState,
    onCancel,
    onExportTrades,
}) => {
    const {
        antdErrors,
        getInputProps,
    } = formState
    return (
        <Modal
            title="Export trades"
            visible={isOpen}
            width={400}
            closable={!isLoading}
            maskClosable={!isLoading}
            confirmLoading={isLoading}
            onOk={onExportTrades}
            onCancel={onCancel}
            okText={isLoading ? 'Exporting...' : 'Export'}
        >
            <Form layout="vertical">
                <FormItem
                    label="Date range"
                    error={antdErrors.get('dateRange')}
                >
                    <RangePicker
                        className="w-full"
                        {...getInputProps('dateRange')}
                    />
                </FormItem>
            </Form>
        </Modal>
    )
}

export default ExportTradesModal
