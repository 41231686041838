import React from 'react'
import { Input, Button } from 'antd'
import FormItem from '../../util/form/FormItem'
import OrderSummary from './OrderSummary'

const CreateOrderAuthenticateForm = ({
    formState,
    isLoading,
    assetName,
    onCancel,
    onConfirm,
}) => {
    const {
        values,
        errors,
        getNativeInputProps,
    } = formState
    const customerName = typeof values.customer !== 'undefined' ? values.customer.name : ''
    return (
        <>
            <OrderSummary
                isHedgeOrder={values.isHedgeOrder}
                side={values.side}
                assetName={assetName}
                customerName={customerName}
                quantity={values.quantity}
                unitCode={values.unitCode}
                currencyCode={values.currencyCode}
                spotPrice={values.spotPrice}
            />
            <p>Confirm the order by entering your password.</p>
            <FormItem error={errors.password}>
                <Input
                    type="password"
                    placeholder="Fill in your password"
                    disabled={isLoading}
                    {...getNativeInputProps('password')}
                />
            </FormItem>
            <div className="flex align-center justify-between">
                <Button
                    type="secondary"
                    disabled={isLoading}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    disabled={!values.password || isLoading}
                    onClick={onConfirm}
                >
                    Confirm
                </Button>
            </div>
        </>
    )
}

export default CreateOrderAuthenticateForm
