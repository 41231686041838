import React from 'react'
import SettingsPageError from '../../layout/SettingsPageError'
import LogsPage from './LogsPage'
import useLogsPageQuery from './useLogsPageQuery'

const PAGE_SIZE = 25
const activeMenuItem = 'logs'

const LogsPageWithState = () => {
    const {
        assets,
        appEvents,
        loading,
        error,
        hasMore,
        isFetchingMore,
        handleFetchMore,
    } = useLogsPageQuery({ first: PAGE_SIZE })

    if (typeof error !== 'undefined') {
        return (
            <SettingsPageError activeMenuItem={activeMenuItem} />
        )
    }
    return (
        <LogsPage
            activeMenuItem={activeMenuItem}
            hasMore={hasMore}
            loading={loading}
            isFetchingMore={isFetchingMore}
            assets={assets}
            appEvents={appEvents}
            handleFetchMore={handleFetchMore}
        />
    )
}

export default LogsPageWithState
