import React from 'react'
import { notification } from 'antd'
import { useAsyncEffect } from '@wappla/react-hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { parseQueryString } from '../../util'
import PageLoading from '../../layout/PageLoading'
import useProcessHedgeAuthCodeMutation from './useProcessHedgeAuthCodeMutation'

const AuthorizeHedgePageWithState = () => {
    const { search } = useLocation()
    const navigate = useNavigate()
    const [processHedgeAuthCode] = useProcessHedgeAuthCodeMutation()
    useAsyncEffect(async () => {
        try {
            const { code } = parseQueryString(search)
            await processHedgeAuthCode({
                variables: { code },
            })
            navigate('/settings/fix')
        } catch (error) {
            notification.error({
                message: 'Could not process code!',
                description: `Something went wrong. The following error occured: ${error}`,
            })
        }
    }, [])
    return (
        <PageLoading
            activeMenuItem="settings"
        />
    )
}

export default AuthorizeHedgePageWithState
