import { gql } from '@apollo/client'

const CREATE_DELIVERY_MUTATION = gql`
    mutation createDelivery($input: DeliveryInput!) {
        createDelivery(input: $input) {
            delivery {
                id
                customer {
                    name
                }
            }
        }
    }
`

export default async function createDelivery(apolloClient, input, refetchQueries) {
    const mutation = CREATE_DELIVERY_MUTATION
    const variables = {
        input,
    }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        refetchQueries,
    })
    return response
}
