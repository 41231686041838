import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
    PAGE_INFO_FRAGMENT,
    mergeQueryResults,
    createQueryUpdater,
    connectionToCollection,
} from '../../../util/graphql'
import CUSTOMER_FRAGMENT from '../../fragments/CustomerFragment'
import CustomerPaymentsTable from './CustomerPaymentsTable'

const QUERY = gql`
    query customerPayments($after: String, $first: Int, $customerId: Int!) {
        customer(id: $customerId) {
            ...Customer
        }
        customerPayments(customerId: $customerId, after: $after, first: $first) {
            edges {
                node {
                    ...CustomerPaymentsTable
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
    }
    ${CUSTOMER_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
    ${CustomerPaymentsTable.FRAGMENT}
`

export const updateCustomerPaymentsQuery = createQueryUpdater(QUERY)

const useCustomerPaymentsPageQuery = (options) => {
    const [isFetchingMore, setIsFetchingMore] = useState(false)
    const {
        data = {},
        error,
        loading,
        fetchMore,
        ...result
    } = useQuery(QUERY, options)

    if (error) {
        return {
            error,
            loading,
        }
    }

    const { customer = {} } = data
    let customerPayments = []
    let hasMore = false
    if (!loading && typeof data.customerPayments !== 'undefined') {
        customerPayments = connectionToCollection(data.customerPayments)
        hasMore = data.customerPayments.pageInfo.hasNextPage
    }

    const handleFetchMore = () => {
        setIsFetchingMore(true)
        const refetchAfter = data.customerPayments.pageInfo.endCursor
        fetchMore({
            variables: { after: refetchAfter },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                setIsFetchingMore(false)
                return mergeQueryResults(previousResult, fetchMoreResult, 'customerPayments')
            },
        })
    }

    return {
        error,
        loading,
        queryToRefetch: QUERY,
        hasMore,
        isFetchingMore,
        handleFetchMore,
        customer,
        customerPayments,
        ...result,
    }
}

export default useCustomerPaymentsPageQuery
