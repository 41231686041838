import React, { useState } from 'react'
import { notification } from 'antd'
import { useAsyncEffect } from '@wappla/react-hooks'
import { useApolloClient } from '@apollo/client'
import getAdjustedMarketData from '../../../api/getAdjustedMarketData'
import { whereId } from '../../util/index'
import UpdateTradeModal from './UpdateCustomerTradeModal'
import useUpdateTradeQuery from './useCreateCustomerTradeQuery'
import useUpdateTradeMutation from './useUpdateCustomerTradeMutation'
import useUpdateTradeFormState from './useUpdateCustomerTradeFormState'

const UpdateTradeModalWithState = ({
    trade,
    isOpen,
    closeModal,
    onUpdateTradeCompleted = () => {},
}) => {
    const apolloClient = useApolloClient()
    const [pricing, setPricing] = useState({ buyPrice: 0, sellPrice: 0 })
    const {
        units,
        assets,
        currencies,
    } = useUpdateTradeQuery()
    const [updateTrade, { loading: isUpdating }] = useUpdateTradeMutation({
        onCompleted: onUpdateTradeCompleted,
    })
    const formState = useUpdateTradeFormState(trade)

    const { values } = formState
    useAsyncEffect(async () => {
        const {
            currencyCode,
            priceUnitCode,
        } = values
        try {
            const { data } = await getAdjustedMarketData(apolloClient, {
                variables: {
                    currencyCode,
                    unitCode: priceUnitCode,
                    assetCode: assets.find(whereId(values.assetId)).code,
                },
            })
            const { buyPrice, sellPrice } = data.adjustedMarketData
            setPricing({ buyPrice, sellPrice })
        } catch (e) {
            notification.error({
                message: 'Could not fetch pricing',
                description: `Something went wrong. The following error occurred: ${e}`,
            })
        }
    }, [values.currencyCode, values.priceUnitCode, values.assetId])

    const handleUpdateTrade = async () => {
        if (formState.validate()) {
            try {
                await updateTrade({
                    variables: {
                        id: trade.id,
                        input: formState.valuesToInput(),
                    },
                })
                formState.resetForm()
                notification.success({
                    message: 'Trade updated!',
                    description: 'Trade was successfully updated!',
                })
            } catch (e) {
                notification.error({
                    message: 'Trade not updated!',
                    description: `Something went wrong. The following error occurred: ${e}`,
                })
            }
        }
    }

    const handleCancelTrade = () => {
        formState.resetForm()
        closeModal()
    }

    return (
        <UpdateTradeModal
            pricing={pricing}
            units={units}
            assets={assets}
            currencies={currencies}
            formState={formState}
            isOpen={isOpen}
            isUpdating={isUpdating}
            onCancel={handleCancelTrade}
            onConfirm={handleUpdateTrade}
        />
    )
}

export default UpdateTradeModalWithState
