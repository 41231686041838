import React from 'react'
import { Link } from 'react-router-dom'
import * as routes from '../../../routes'
import MainCentered from '../../layout/MainCentered'
import LoginFormWrapper from '../login/LoginFormWrapper'
import ForgotPasswordWithState from './ForgotPasswordWithState'

const LoginPage = () => (
    <MainCentered>
        <LoginFormWrapper
            title="Reset Password"
            button={<Link to={routes.login()}>Back</Link>}
        >
            <ForgotPasswordWithState />
        </LoginFormWrapper>
    </MainCentered>
)

export default LoginPage
