import { createFormValidation } from 'use-form-state'
import useFormState from '../../hooks/useFormState'

const validation = createFormValidation([{
    path: 'fixPrice',
    validate: (value) => value > 0,
    message: 'Price must be greater than 0',
}, {
    path: 'fixedQuantity',
    validate: (value) => value > 0,
    message: 'Quantity must be greater than 0.',
}])

const valuesToInput = (values) => ({
    ...values,
    fixPrice: parseInt(values.fixPrice * 100, 10),
})

const useConfirmOrderFormState = () => (
    useFormState(
        {
            fixPrice: 0,
            fixedQuantity: 0,
        },
        {
            validation,
            valuesToInput,
        }
    )
)

export default useConfirmOrderFormState
