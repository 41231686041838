import React from 'react'
import { Form, Button } from 'antd'
import { ROLES } from '../../../util/roles'
import FormItem from '../../util/form/FormItem'
import HasPermissions from '../../util/HasPermissions'
import UnitInputWithConverter from '../../util/form/UnitInputWithConverter'

const AppSettingsForm = ({
    formState,
    isSaving,
    onSubmit,
}) => {
    const {
        antdErrors,
        getInputProps,
    } = formState
    return (
        <HasPermissions roles={[ROLES.ADMIN]}>
            {(hasPermission) => (
                <Form layout="vertical">
                    <form className="max-w- max-w-[200px]">
                        <h2 className="text-2xl">Trade Limits</h2>
                        <FormItem
                            label="Minimum"
                            style={{ width: '100%' }}
                        >
                            <UnitInputWithConverter
                                {...getInputProps('minTradeLimit')}
                                error={antdErrors.get('minTradeLimit')}
                                code="settings"
                                isRounded={false}
                                disabled={!hasPermission}
                            />
                        </FormItem>
                        <FormItem
                            label="Maximum"
                            style={{ width: '100%' }}
                        >
                            <UnitInputWithConverter
                                {...getInputProps('maxTradeLimit')}
                                error={antdErrors.get('maxTradeLimit')}
                                code="settings"
                                isRounded={false}
                                disabled={!hasPermission}
                            />
                        </FormItem>
                    </form>
                    {hasPermission && (
                        <div className="mt-6">
                            <Button
                                className="button"
                                onClick={onSubmit}
                                type="primary"
                                loading={isSaving}
                            >
                                Save
                            </Button>
                        </div>
                    )}
                </Form>
            )}
        </HasPermissions>
    )
}

export default AppSettingsForm
