import { gql, useMutation } from '@apollo/client'

export const TRADE_FRAGMENT = gql`
    fragment Trade on Trade {
        id
        createdAt
        updatedAt
        date
        side
        currencyCode
        quantity
        quantityUnitCode
        asset {
            id
        }
        customer {
            id
            name
        }
        assetCode
        type
        counterParty
        price
        priceUnitCode
        conversionRate
        totalPrice
        notes
    }
`

const UPDATE_TRADE_MUTATION = gql`
    mutation updateTradeMutation($id: Int!, $input: UpdateTradeInput!) {
        updateTrade(id: $id, input: $input) {
            trade {
                ...Trade
            }
        }
    }
    ${TRADE_FRAGMENT}
`

const useUpdateTradeMutation = (options) => (
    useMutation(UPDATE_TRADE_MUTATION, options)
)

export default useUpdateTradeMutation
