import React from 'react'
import { useParams } from 'react-router-dom'
import CustomerPageError from '../../layout/CustomerPageError'
import CustomerPage from '../../layout/CustomerPage'
import useModalState from '../../hooks/useModalState'
import useFetchCustomerTransfersPageData, { CUSTOMER_TRANSFERS_PAGE_QUERY } from './useFetchCustomerTransfersPageData'
import CreatePickupModalWithState from '../transfers/CreatePickupModalWithState'
import CreateDeliveryModalWithState from '../transfers/deliveries/modals/CreateDeliveryModalWithState'
import CustomerTransfersPage from './CustomerTransfersPage'

const CustomerTransfersPageWithState = () => {
    const params = useParams()
    const createPickupModalState = useModalState()
    const createDeliveryModalState = useModalState()
    const customerId = parseInt(params.customerId, 10)
    const {
        error,
        isFetching,
        customer,
        customerPickups,
        customerDeliveries,
        assets,
        isFetchingMorePickups,
        isFetchingMoreDeliveries,
        hasMorePickups,
        hasMoreDeliveries,
        handleFetchMorePickups,
        handleFetchMoreDeliveries,
    } = useFetchCustomerTransfersPageData(customerId)

    if (typeof error !== 'undefined') {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem="transfers"
            />
        )
    }

    return (
        <CustomerPage
            customerId={customerId}
            activeMenuItem="transfers"
            isLoading={isFetching}
        >
            <CustomerTransfersPage
                customer={customer}
                pickups={customerPickups}
                deliveries={customerDeliveries}
                isFetchingMorePickups={isFetchingMorePickups}
                isFetchingMoreDeliveries={isFetchingMoreDeliveries}
                hasMorePickups={hasMorePickups}
                hasMoreDeliveries={hasMoreDeliveries}
                handleFetchMorePickups={handleFetchMorePickups}
                handleFetchMoreDeliveries={handleFetchMoreDeliveries}
                onCreatePickup={() => {
                    createPickupModalState.open({ customer, refetchQuery: CUSTOMER_TRANSFERS_PAGE_QUERY })
                }}
                onCreateDelivery={() => {
                    createDeliveryModalState.open({ customer, refetchQuery: CUSTOMER_TRANSFERS_PAGE_QUERY })
                }}
            />
            {createPickupModalState.isOpen && (
                <CreatePickupModalWithState
                    onCreatePickupComplete={() => createPickupModalState.close()}
                    closeModal={() => createPickupModalState.close()}
                    assets={assets}
                    {...createPickupModalState.initialState}
                />
            )}
            {createDeliveryModalState.isOpen && (
                <CreateDeliveryModalWithState
                    onCreateDeliveryComplete={() => createDeliveryModalState.close()}
                    closeModal={() => createDeliveryModalState.close()}
                    assets={assets}
                    {...createDeliveryModalState.initialState}
                />
            )}
        </CustomerPage>
    )
}

export default CustomerTransfersPageWithState
