import React from 'react'
import clsx from 'clsx'

const MainCentered = ({
    className,
    children,
}) => (
    <main
        className={
            clsx(
                'flex items-center justify-center flex-col w-full h-screen',
                className,
            )
        }
    >
        {children}
    </main>
)

export default MainCentered
