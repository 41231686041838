import roundTo from 'round-to'
import * as constants from './constants'

const {
    UNIT_CODE_MILLIGRAM: MG,
    UNIT_CODE_GRAM: G,
    UNIT_CODE_OUNCE: OZ,
    UNIT_CODE_KILOGRAM: KG,
    GRAM_ROUND,
    OUNCE_ROUND,
    KILOGRAM_ROUND,
} = constants

export const OUNCE_TO_GRAM_RATIO = 31.1034768

// Fixes floating point bug. For example: 20.08 * 100 = 2007.999999
export function fixFloatingPointError(num) {
    return Math.round(num * 10000) / 10000
}

export const ounceToGram = (value) => (
    fixFloatingPointError(value * OUNCE_TO_GRAM_RATIO)
)

export const gramToOunce = (value) => (
    fixFloatingPointError(value / OUNCE_TO_GRAM_RATIO)
)

export const gramToMilligram = (value) => (
    fixFloatingPointError(value * 1000)
)

export const kilogramToMilligram = (value) => (
    fixFloatingPointError(value * 1000 * 1000)
)

export const gramToKilogram = (value) => (
    fixFloatingPointError(value / 1000)
)

export const milligramToGram = (value) => (
    fixFloatingPointError(value / 1000)
)

export const roundToMilligramToGram = (value, rounding = 0) => (
    roundTo(milligramToGram(value), rounding)
)

export const milligramToKilogram = (value) => (
    fixFloatingPointError(value / 1000 / 1000)
)

export const ounceToMilligram = (value) => (
    ounceToGram(value) * 1000
)

export const milligramToOunce = (value) => (
    gramToOunce(value / 1000)
)

export const ounceToKilogram = (value) => (
    ounceToGram(value) / 1000
)

export const kilogramToOunce = (value) => (
    gramToOunce(value * 1000)
)

export const kilogramToGram = (value) => (
    value * 1000
)

export const unitToMilligram = (value, unitCode) => {
    let result = value
    if (unitCode === G) {
        result = gramToMilligram(value)
    }
    if (unitCode === KG) {
        result = kilogramToMilligram(value)
    }
    if (unitCode === OZ) {
        result = ounceToMilligram(value)
    }
    return roundTo(result, 0)
}

export const unitToGram = (value, unitCode) => {
    let result = value
    if (unitCode === MG) {
        result = milligramToGram(value)
    }
    if (unitCode === KG) {
        result = kilogramToGram(value)
    }
    if (unitCode === OZ) {
        result = ounceToGram(value)
    }
    return roundTo(result, 3)
}

export const unitToKilogram = (value, unitCode) => {
    let result = value
    if (unitCode === MG) {
        result = milligramToKilogram(value)
    }
    if (unitCode === G) {
        result = gramToKilogram(value)
    }
    if (unitCode === OZ) {
        result = ounceToKilogram(value)
    }
    return roundTo(result, 6)
}

export const unitToOunce = (value, unitCode) => {
    let result = value
    if (unitCode === MG) {
        result = milligramToOunce(value)
    }
    if (unitCode === G) {
        result = gramToOunce(value)
    }
    if (unitCode === KG) {
        result = kilogramToOunce(value)
    }
    return roundTo(result, 7)
}

export const unitTo = (value, unitCodeFrom, unitCodeTo) => {
    if (unitCodeTo === MG) {
        return unitToMilligram(value, unitCodeFrom)
    }
    if (unitCodeTo === G) {
        return unitToGram(value, unitCodeFrom)
    }
    if (unitCodeTo === KG) {
        return unitToKilogram(value, unitCodeFrom)
    }
    if (unitCodeTo === OZ) {
        return unitToOunce(value, unitCodeFrom)
    }
    throw new Error(`Unknown unit code '${unitCodeTo}'.`)
}

export const roundToUnitToOunce = (value, unitCode, rounding = 0) => (
    roundTo(unitToOunce(value, unitCode), rounding)
)

export const ounceToUnit = (value, unitCode) => {
    if (unitCode === MG) {
        return ounceToMilligram(value)
    }
    if (unitCode === G) {
        return ounceToGram(value)
    }
    if (unitCode === KG) {
        return ounceToKilogram(value)
    }
    return value
}

export const milligramToUnit = (value, unitCode) => {
    switch (unitCode) {
        case G:
            return milligramToGram(value)
        case OZ:
            return milligramToOunce(value)
        case KG:
            return milligramToKilogram(value)
        default:
            return value
    }
}

export const milligramToUnitUsingOrderRounding = (value, unitCode) => {
    let rounding = 0 // For MG -> MG
    if (unitCode === G) {
        rounding = 2
    }
    if (unitCode === OZ) {
        rounding = 0
    }
    if (unitCode === KG) {
        rounding = 5
    }
    return roundTo(milligramToUnit(value, unitCode), rounding)
}

export const calculateQuantityToFix = (quantity) => {
    const quantityInOunce = unitToOunce(quantity, MG)
    const quantityInOunceRounded = roundTo(quantityInOunce, 3)
    return roundTo.down(quantityInOunceRounded, 0)
}

export const calculateUnfixedQuantityInMilligram = (quantity, fixedQuantityInOunce) => {
    const fixedQuantityInMilligram = unitToMilligram(fixedQuantityInOunce, OZ)
    return quantity - fixedQuantityInMilligram
}

export const getRoundingForUnit = (unitCode) => {
    switch (unitCode) {
        case G:
            return GRAM_ROUND
        case OZ:
            return OUNCE_ROUND
        case KG:
            return KILOGRAM_ROUND
        default:
            return 0
    }
}

export const getPreferredUnits = (code) => {
    let units = []
    switch (code) {
        case 'settings':
            units = ['g', 'kg', 'oz']
            break
        case 'oz':
            units = ['oz', 'lb']
            break
        case 'g':
        default:
            units = ['g', 'kg']
    }
    return units
}

export const getInputStep = (unitCode) => {
    let inputStep = 0.01
    if (unitCode === 'g') {
        inputStep = 0.00001
    }
    return inputStep
}

export const getPrecision = (unitCode) => {
    let precision = 2
    if (unitCode === 'g') {
        precision = 5
    }
    return precision
}

export const calculateStep = (isRounded, currentUnit) => {
    if (!isRounded) {
        return 1 / 10 ** getRoundingForUnit(currentUnit)
    }
    return 1
}

export const convertAndRoundQuantity = (quantity, currentUnit, newUnit) => {
    const convertedQuantity = unitTo(quantity, currentUnit, newUnit)
    const roundedQuantity = roundTo(convertedQuantity, getRoundingForUnit(newUnit))
    return roundedQuantity
}
