import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import MarketDataPlaceholder from '../order-modal/MarketDataPlaceholder'
import MarketDataWithQuery from './MarketDataWithQuery'

const MarketDataContainer = ({
    side,
    assetCode,
    unitCode,
    currencyCode,
    onCompletedSpotPrice,
}) => {
    const hasSelectedAllRequiredFields = (
        typeof side !== 'undefined'
        && typeof assetCode !== 'undefined'
        && typeof unitCode !== 'undefined'
        && typeof currencyCode !== 'undefined'
    )
    return (
        <div className="flex align-center justify-center h-24">
            {hasSelectedAllRequiredFields
                ? (
                    <MarketDataWithQuery
                        side={side}
                        assetCode={assetCode}
                        unitCode={unitCode}
                        currencyCode={currencyCode}
                        onCompletedSpotPrice={onCompletedSpotPrice}
                    />
                ) : (
                    <MarketDataPlaceholder
                        text="Please choose a currency code."
                        icon={(
                            <SearchOutlined
                                className="text-3xl mr-4"
                            />
                        )}
                    />
                )}
        </div>
    )
}

export default MarketDataContainer
