import { gql } from '@apollo/client'

const CUSTOMER_USER_FRAGMENT = gql`
    fragment CustomerUser on User {
        id
        createdAt
        firstName
        lastName
        email
        phone
        preferredUnitCode
        preferredCurrencyCode
        role {
            name
            id
        }
        customer {
            id
            name
        }
    }
`

export default CUSTOMER_USER_FRAGMENT
