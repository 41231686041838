import React from 'react'
import { notification } from 'antd'
import ConfirmOrderModal from './ConfirmOrderModal'
import useConfirmOrderMutation from './useConfirmOrderMutation'
import useConfirmOrderFormState from './useConfirmOrderFormState'

export default function ConfirmOrderModalWithState({
    order,
    isOpen,
    onClose,
    onSubmit,
}) {
    const formState = useConfirmOrderFormState()
    const [confirmOrder, { loading }] = useConfirmOrderMutation()

    const handleSubmitForm = async () => {
        if (formState.validate()) {
            try {
                const variables = {
                    id: order.id,
                    input: formState.valuesToInput()
                }
                await confirmOrder({ variables })
                notification.success({
                    message: 'Success',
                    description: 'The order was successfully confirmed.',
                })
                onSubmit()
            } catch (e) {
                notification.error({
                    message: 'Error',
                    description: `Something went wrong. The following error occured: ${e}`,
                })
            }
        }
    }
    return (
        <ConfirmOrderModal
            formState={formState}
            isOpen={isOpen}
            isSubmitting={loading}
            onCancel={onClose}
            onSubmit={handleSubmitForm}
        />
    )
}
