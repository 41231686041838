import { gql, useMutation } from '@apollo/client'

const STOP_CONNECTIONS_MUTATION = gql`
    mutation stopMarketConnections {
        stopMarketConnections
    }
`

const useStopConnectionsMutation = (options) => (
    useMutation(STOP_CONNECTIONS_MUTATION, options)
)

export default useStopConnectionsMutation
