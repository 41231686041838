import React from 'react'
import { Button } from 'antd'
import { ROLES } from '../../../../util/roles'
import TopBar from '../../../util/TopBar'
import HasPermissions from '../../../util/HasPermissions'

const CustomersTableTopBar = ({ onCreateCustomerClick }) => (
    <TopBar
        rightChildren={(
            <HasPermissions roles={[ROLES.ADMIN]}>
                <Button
                    type="primary"
                    onClick={onCreateCustomerClick}
                >
                    Add customer
                </Button>
            </HasPermissions>
        )}
    />
)

export default CustomersTableTopBar
