import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { notification, Popconfirm } from 'antd'
import { EuroOutlined } from '@ant-design/icons'
import markOrderPaid from '../../../api/markOrderPaid'
import markOrderUnpaid from '../../../api/markOrderUnpaid'

const PaymentButtonWithState = ({
    orderId,
    totalOutstandingPrice,
    customerId,
}) => {
    const [isSavingPayment, setIsSavingPayment] = useState(false)
    const apolloClient = useApolloClient()
    const isPaid = totalOutstandingPrice === 0
    const onClick = async () => {
        setIsSavingPayment(true)
        try {
            if (totalOutstandingPrice === 0) {
                await markOrderUnpaid(apolloClient, orderId, customerId)
            } else {
                await markOrderPaid(apolloClient, orderId, customerId)
            }
        } catch (e) {
            notification.error({
                message: 'Payment not updated!',
                description: `Something went wrong. The following error occured: ${e}`,
            })
        }
        setIsSavingPayment(false)
    }

    return (
        <Popconfirm
            title={`Are you sure you want to mark this order as ${isPaid ? 'unpaid?' : 'paid?'}`}
            onConfirm={onClick}
            okText="Yes"
            cancelText="No"
            placement="left"
            disabled={isSavingPayment}
        >
            <EuroOutlined size="small" />
            {isPaid ? 'Unresolve payment' : 'Resolve payment'}
        </Popconfirm>
    )
}

export default PaymentButtonWithState
