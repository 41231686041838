import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import {
    GoldOutlined,
    TeamOutlined,
    EuroOutlined,
    SwapOutlined,
    LeftOutlined,
    StockOutlined,
    DollarOutlined,
    SettingOutlined,
    FileDoneOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons'
import * as routes from '../../routes'

const CustomerSidebarMenu = ({
    customerId,
    activeMenuItem,
}) => {
    const navigate = useNavigate()
    return (
        <>
            <div className="p-4">
                <Link to={routes.customers()}>
                    <LeftOutlined className="mr-4" />Back to overview
                </Link>
            </div>
            <Menu
                mode="vertical"
                selectedKeys={[activeMenuItem]}
                style={{ borderRight: 0 }}
            >
                <Menu.Item key="balance" icon={<InfoCircleOutlined />} onClick={() => navigate(`/customer/${customerId}/balance`)}>
                    Balance
                </Menu.Item>
                <Menu.Item key="trades" icon={<StockOutlined />} onClick={() => navigate(`/customer/${customerId}/trades`)}>
                    Trades
                </Menu.Item>
                <Menu.Item key="orders" icon={<FileDoneOutlined />} onClick={() =>  navigate(`/customer/${customerId}/orders`)}>
                    Orders
                </Menu.Item>
                <Menu.Item key="prices" icon={<DollarOutlined />} onClick={() =>  navigate(`/customer/${customerId}/prices`)}>
                    Prices
                </Menu.Item>
                <Menu.Item key="transfers" icon={<SwapOutlined />} onClick={() =>  navigate(`/customer/${customerId}/transfers`)}>
                    Pickups &amp; Deliveries
                </Menu.Item>
                <Menu.Item key="payments" icon={<EuroOutlined />} onClick={() =>  navigate(`/customer/${customerId}/payments`)}>
                    Payments
                </Menu.Item>
                <Menu.Item key="users" icon={<TeamOutlined />} onClick={() =>  navigate(`/customer/${customerId}/users`)}>
                    Users
                </Menu.Item>
                <Menu.Item key="settings" icon={<SettingOutlined />} onClick={() =>  navigate(`/customer/${customerId}/settings`)}>
                    Settings
                </Menu.Item>
                <Menu.Item key="assets" icon={<GoldOutlined />} onClick={() =>  navigate(`/customer/${customerId}//settings/assets`)}>
                    Assets
                </Menu.Item>
            </Menu>
        </>
    )
}

export default CustomerSidebarMenu
