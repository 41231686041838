import { gql } from '@apollo/client'
import { removeEdgeFromCollectionWhereNodeId } from '../util/index'
import { updateAdminUsersQuery } from '../components/pages/users/useFetchUsersPageData'

const DELETE_ADMIN_USER_MUTATION = gql`
    mutation deleteAdminUser($id: Int!) {
        deleteAdminUser(id: $id)
    }
`

export default async function deleteAdminUser(apolloclient, adminUserId) {
    const mutation = DELETE_ADMIN_USER_MUTATION
    const variables = { id: adminUserId }
    const update = () => {
        updateAdminUsersQuery({
            path: 'adminUsers.edges',
            value: removeEdgeFromCollectionWhereNodeId(adminUserId),
        })
    }
    const response = await apolloclient.mutate({
        mutation,
        variables,
        update,
    })
    return response
}
