import React from 'react'
import Loading from '../util/Loading'
import Navbar from './Navbar'
import Main from './Main'
import ContainerWithSidebar from './ContainerWithSidebar'
import CustomerSidebarMenu from './CustomerSidebarMenu'
import CustomerPageContentPlaceholder from './CustomerPageContentPlaceholder'

const CustomersPage = ({
    customerId,
    activeMenuItem,
    isLoading,
    children,
}) => (
    <>
        <Navbar activeMenuItem="customers" />
        <Main>
            <ContainerWithSidebar
                sidebar={(
                    <CustomerSidebarMenu
                        customerId={customerId}
                        activeMenuItem={activeMenuItem}
                    />
                )}
            >
                {(() => {
                    if (isLoading) {
                        return (
                            <CustomerPageContentPlaceholder>
                                <Loading size={50} />
                            </CustomerPageContentPlaceholder>
                        )
                    }
                    return children
                })()}
            </ContainerWithSidebar>
        </Main>
    </>
)

export default CustomersPage
