import React from 'react'
import { Form, Modal } from 'antd'
import FormItem from '../../util/form/FormItem'
import InputNumber from '../../util/form/InputNumber'

export default function ConfirmOrderModal({
    formState,
    isOpen,
    isSubmitting,
    onSubmit,
    onCancel,
}) {
    const {
        antdErrors,
        getInputProps,
    } = formState
    return (
        <Modal
            title="Confirm order"
            visible={isOpen}
            onOk={onSubmit}
            onCancel={onCancel}
            closable={!isSubmitting}
            maskClosable={!isSubmitting}
            confirmLoading={isSubmitting}
        >
            <Form layout="vertical">
                <FormItem
                    label="Quantity fixed (OZ)"
                    error={antdErrors.get('fixedQuantity')}
                >
                    <InputNumber
                        {...getInputProps('fixedQuantity')}
                        min={0}
                        precision={2}
                    />
                </FormItem>
                <FormItem
                    label="Fix price (€)"
                    error={antdErrors.get('fixPrice')}
                >
                    <InputNumber
                        {...getInputProps('fixPrice')}
                        min={0}
                        precision={2}
                    />
                </FormItem>
            </Form>
        </Modal>
    )
}
