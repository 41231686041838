import React from 'react'
import { formatCurrencyNotation } from '../../../../../util/currency'
import PaymentLogItem from './PaymentLogItem'

const PaymentCreatedItem = ({
    appEvent,
}) => {
    const {
        customer, user, createdAt, payload,
    } = appEvent
    const { type, amount, currencyCode } = payload
    return (
        <PaymentLogItem
            isCreated
            createdAt={createdAt}
            user={user}
        >
            <div>
                {type} of <strong>{formatCurrencyNotation(amount, currencyCode)} {currencyCode}</strong>
                {' '}for{' '}
                <strong>{customer.name}</strong> created.
            </div>
        </PaymentLogItem>
    )
}

export default PaymentCreatedItem
