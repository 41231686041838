import React from 'react'
import clsx from 'clsx'
import { milligramToUnitUsingOrderRounding } from '../../util/units'

const UnitValue = ({
    formatValue = true,
    isBold,
    isNegative,
    isPositive,
    value,
    unitCode,
    displayUnitCode = unitCode,
    className,
}) => (
    <span
        className={
            clsx(isBold && 'font-bold', className)
        }
    >
        {(isPositive || isNegative) && (
            <span
                className={clsx(
                    'mr-1',
                    isPositive && 'text-green-500',
                    isNegative && 'text-red-500',
                )}
            >
                {isPositive ? '+' : '-'}
            </span>
        )}
        <span>
            {formatValue
                ? milligramToUnitUsingOrderRounding(Math.abs(value), unitCode)
                : Math.abs(value)}
        </span>
        &nbsp;
        {displayUnitCode && (
            <span>
                {displayUnitCode}
            </span>
        )}
    </span>
)

export default UnitValue
