import React from 'react'
import { Form } from 'antd'

const FormItem = ({
    isRequired,
    label,
    error,
    extra = null,
    style,
    children,
    ...props
}) => (
    <Form.Item
        style={style}
        hasFeedback
        required={isRequired}
        label={label}
        validateStatus={error ? 'error' : ''}
        extra={extra}
        help={error}
        {...props}
    >
        {children}
    </Form.Item>
)

export default FormItem
