import { gql } from '@apollo/client'

const UPDATE_APP_SETTINGS_MUTATION = gql`
    mutation updateAppSettings($input: AppSettingsInput!) {
        updateAppSettings(input: $input) {
            minTradeLimit
            maxTradeLimit
        }
    }
`

export default async function updateAppSettings(apolloClient, input, options) {
    const mutation = UPDATE_APP_SETTINGS_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
