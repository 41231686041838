import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { notification } from 'antd'
import updateAppSettings from '../../../api/updateAppSettings'
import useFetchAppSettingsPageData from './useFetchAppSettings'
import useUpdateAppSettingsForm from './useUpdateAppSettingsForm'
import AppSettingsPage from './AppSettingsPage'
import SettingsPage from '../SettingsPage'
import SettingsPageError from '../../layout/SettingsPageError'

const AppSettingsPageWithState = () => {
    const [isSavingAppSettings, setIsSavingAppSettings] = useState(false)
    const apolloClient = useApolloClient()
    const {
        error,
        isFetching,
        appSettings,
    } = useFetchAppSettingsPageData()
    const formState = useUpdateAppSettingsForm(appSettings)

    if (error) {
        return (
            <SettingsPageError
                activeMenuItem="settings"
            />
        )
    }

    return (
        <SettingsPage
            activeMenuItem="appSettings"
            isLoading={isFetching}
        >
            <AppSettingsPage
                formState={formState}
                isSavingAppSettings={isSavingAppSettings}
                onSaveLimits={async () => {
                    const isValid = formState.validate()
                    if (isValid) {
                        try {
                            setIsSavingAppSettings(true)
                            await updateAppSettings(apolloClient, formState.values)
                            notification.success({
                                message: 'Successfully updated!',
                                description: 'The trade limits were successfully updated!',
                            })
                            setIsSavingAppSettings(false)
                        } catch (e) {
                            notification.error({
                                message: 'Appsettings not updated!',
                                description: `Something went wrong. The following error occured: ${e}`,
                            })
                        }
                    }
                }}
            />
        </SettingsPage>
    )
}

export default AppSettingsPageWithState
