import React from 'react'
import { Form, Col, Row } from 'antd'
import FormItem from '../../util/form/FormItem'
import AssetSelect from '../../util/form/AssetSelect'
import ExtraInfoLabel from '../../util/form/ExtraInfoLabel'
import CustomerSearchSelect from '../../util/form/CustomerSearchSelect'
import UnitInputWithConverter from '../../util/form/UnitInputWithConverter'
import UnfilledBuyCustomerOrdersTable from './UnfilledBuyCustomerOrdersTable'
import UnfilledBuyCustomerOrdersTableWithData from './UnfilledBuyCustomerOrdersTableWithData'

const CreatePickupForm = ({
    formState,
    assets,
}) => {
    const {
        values,
        setValues,
        antdErrors,
        getInputProps,
    } = formState
    const {
        customer,
        assetId,
        quantity,
        orderPickups,
    } = values

    return (
        <>
            <Form layout="vertical">
                <FormItem
                    label="Customer"
                    error={antdErrors.get('customer')}
                >
                    <CustomerSearchSelect
                        className="w-full"
                        customer={customer !== null ? customer : undefined}
                        value={customer !== null ? customer.id : undefined}
                        onChange={(customerId, newCustomer) => {
                            let inputCustomer = null
                            if (typeof newCustomer !== 'undefined') {
                                inputCustomer = newCustomer
                            }
                            setValues({
                                customer: inputCustomer,
                                orderPickups: [],
                                quantity: 0,
                            })
                        }}
                    />
                </FormItem>
                <Row gutter={16}>
                    <Col span={12}>
                        <AssetSelect
                            {...getInputProps('assetId')}
                            className="w-full"
                            error={antdErrors.get('assetId')}
                            label="Asset"
                            assets={assets}
                            onChange={(newAssetId) => setValues({
                                assetId: newAssetId,
                                orderPickups: [],
                                quantity: 0,
                            })}
                        />
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label="Quantity"
                            style={{ width: '100%' }}
                        >
                            <UnitInputWithConverter
                                {...getInputProps('quantity')}
                                error={antdErrors.get('quantity')}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            {(() => {
                if (customer !== null && assetId !== null) {
                    return (
                        <>
                            <UnfilledBuyCustomerOrdersTableWithData
                                customerId={customer.id}
                                assetId={assetId}
                                formState={formState}
                            />
                            <ExtraInfoLabel message="Changes made in this form will not be saved until the form is submitted." />
                        </>
                    )
                }
                return (
                    <>
                        <UnfilledBuyCustomerOrdersTable
                            orders={[]}
                        />
                        <ExtraInfoLabel message="Search for a customer and select an asset first to view the orders." />
                    </>
                )
            })()}

        </>
    )
}

export default CreatePickupForm
