import React from 'react'
import UserLogItem from './UserLogItem'

const UserCreatedItem = ({
    appEvent
}) => (
    <UserLogItem
        isCreated
        createdAt={appEvent.createdAt}
        user={appEvent.user}
    >
        User <strong>{appEvent.payload.email}</strong> created.
    </UserLogItem>
)

export default UserCreatedItem
