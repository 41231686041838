import React, { useState, useEffect } from 'react'
import { Button, Checkbox } from 'antd'

const CheckboxGroup = Checkbox.Group

const FilterCheckboxGroup = ({
    value,
    options,
    onApplyFilter,
}) => {
    const [checkedValues, setCheckedValues] = useState(value)
    const allIsChecked = checkedValues.length === options.length

    useEffect(() => {
        setCheckedValues(value)
    }, [value])

    return (
        <div className="flex flex-col p-4">
            <div className="flex flex-col mb-2">
                <Checkbox
                    onChange={() => {
                        if (allIsChecked === true) {
                            setCheckedValues([])
                        } else {
                            setCheckedValues(options)
                        }
                    }}
                    checked={allIsChecked}
                >
                    All
                </Checkbox>
                <CheckboxGroup
                    options={options}
                    value={checkedValues}
                    onChange={setCheckedValues}
                    disabled={allIsChecked}
                />
            </div>
            <div className="self-end">
                <Button
                    size="small"
                    onClick={() => onApplyFilter(checkedValues)}
                >
                    Apply
                </Button>
            </div>
        </div>
    )
}

export default FilterCheckboxGroup
