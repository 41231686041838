import React from 'react'
import { Table, Select, InputNumber } from 'antd'
import { getDecimalSeparator } from '../../../../util/currency'
import { getInputStep, getPrecision } from '../../../../util/units'
import PriceAdjustmentExample from './PriceAdjustmentExample'

export const INITIAL_ADJUSTMENT_VALUES = {
    discount: 0,
    premium: 0,
    step: 0,
    rounding: 0,
}

export const ROUNDINGS = [{
    label: '100',
    value: '-4',
}, {
    label: '10',
    value: '-3',
}, {
    label: '0',
    value: '-2',
}, {
    label: '0.1',
    value: '-1',
}, {
    label: '0.01',
    value: '0',
}]

const PriceAdjustmentsTable = ({
    formState,
    formattingStyle = 'european',
}) => {
    const {
        getInputProps,
        values,
    } = formState
    const { priceAdjustments } = values
    const decimalSeparator = getDecimalSeparator(formattingStyle)

    if (!priceAdjustments.length) {
        return null
    }

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            width: '10%',
        }, {
            title: 'Example',
            dataIndex: 'id',
            width: '18%',
            render: (id, priceAdjustment) => (
                <PriceAdjustmentExample
                    priceAdjustment={priceAdjustment}
                />
            ),
        }, {
            title: 'Discount',
            dataIndex: 'discount',
            render: (discount, priceAdjustment, index) => {
                const { unitCode } = priceAdjustment
                return (
                    <InputNumber
                        {...getInputProps(`priceAdjustments.${index}.discount`)}
                        min={0}
                        step={getInputStep(unitCode)}
                        precision={getPrecision(unitCode)}
                        decimalSeparator={decimalSeparator}
                        className="w-full"
                    />
                )
            },
        }, {
            title: 'Premium',
            dataIndex: 'premium',
            render: (premium, priceAdjustment, index) => {
                const { unitCode } = priceAdjustment
                return (
                    <InputNumber
                        {...getInputProps(`priceAdjustments.${index}.premium`)}
                        min={0}
                        step={getInputStep(unitCode)}
                        precision={getPrecision(unitCode)}
                        decimalSeparator={decimalSeparator}
                        className="w-full"
                    />
                )
            },
        }, {
            title: 'Adjustment',
            dataIndex: 'step',
            render: (step, priceAdjustment, index) => {
                const { unitCode } = priceAdjustment
                return (
                    <InputNumber
                        {...getInputProps(`priceAdjustments.${index}.step`)}
                        min={0}
                        step={getInputStep(unitCode)}
                        precision={getPrecision(unitCode)}
                        decimalSeparator={decimalSeparator}
                        className="w-full"
                    />
                )
            },

        }, {
            title: 'Rounding',
            dataIndex: 'rounding',
            render: (rounding, priceAdjustment, index) => (
                <Select
                    {...getInputProps(`priceAdjustments.${index}.rounding`)}
                    className="w-full"
                >
                    {ROUNDINGS.map(({ label, value }) => {
                        const parsedValue = parseInt(value, 10)

                        return (
                            <Select.Option key={parsedValue} value={parsedValue}>
                                {label}
                            </Select.Option>
                        )
                    })}
                </Select>
            ),

        },
    ]

    return (
        <Table
            rowKey="code"
            columns={columns}
            pagination={false}
            dataSource={priceAdjustments}
        />
    )
}

export default PriceAdjustmentsTable
