import React from 'react'
import SettingsPage from '../pages/SettingsPage'

const SettingsPageError = ({
    activeMenuItem,
}) => (
    <SettingsPage
        activeMenuItem={activeMenuItem}
    >
        <h3 className="text-xl">Failed to load page</h3>
    </SettingsPage>
)

export default SettingsPageError
