import React, { useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { whereId } from '../../util/index'
import useModalState from '../../hooks/useModalState'
import CustomerPageError from '../../layout/CustomerPageError'
import useTradeDateState from '../trades/useTradeDateState'
import CustomerTradesPage from './CustomerTradesPage'
import useCustomerTradesPageQuery from './useCustomerTradesPageQuery'
import CreateCustomerTradeModalWithState from './CreateCustomerTradeModalWithState'
import UpdateCustomerTradeModalWithState from './UpdateCustomerTradeModalWithState'

const activeMenuItem = 'trades'

const CustomerTradesPageWithState = () => {
    const params = useParams()
    const [selectedAssetId, setSelectedAssetId] = useState(null)
    const customerId = parseInt(params.customerId, 10)
    const createModalState = useModalState()
    const updateModalState = useModalState()

    const {
        fromDate,
        untilDate,
        selectedMonth,
        selectedYear,
        setSelectedMonth,
        setSelectedYear,
    } = useTradeDateState()

    const {
        error,
        loading,
        assets,
        customer,
        customerTrades,
        isFetchingMore,
        hasMore,
        handleFetchMore,
        refetch,
    } = useCustomerTradesPageQuery({
        variables: {
            assetId: selectedAssetId,
            customerId,
            from: fromDate,
            until: untilDate,
            orderBy: { date: 'DESC' },
        },
    })

    if (typeof error !== 'undefined') {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem={activeMenuItem}
            />
        )
    }

    return (
        <>
            <CustomerTradesPage
                loading={loading}
                assets={assets}
                customer={customer}
                customerTrades={customerTrades}
                activeMenuItem={activeMenuItem}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                selectedAssetId={selectedAssetId}
                onChangeMonth={setSelectedMonth}
                onChangeYear={setSelectedYear}
                onChangeAsset={setSelectedAssetId}
                isFetchingMore={isFetchingMore}
                hasMore={hasMore}
                handleFetchMore={handleFetchMore}
                onUpdateTrade={(trade) => (
                    updateModalState.open({ trade: customerTrades.find(whereId(trade.id)) })
                )}
                onCreateTrade={() => createModalState.open()}
            />
            {updateModalState.isOpen && (
                <UpdateCustomerTradeModalWithState
                    isOpen={updateModalState.isOpen}
                    onUpdateTradeCompleted={() => {
                        updateModalState.close()
                        refetch()
                    }}
                    closeModal={updateModalState.close}
                    {...updateModalState.initialState}
                />
            )}
            {createModalState.isOpen && (
                <CreateCustomerTradeModalWithState
                    date={moment().seconds(0)}
                    assetId={selectedAssetId}
                    customer={customer}
                    isOpen={createModalState.isOpen}
                    onCompleted={() => {
                        createModalState.close()
                        refetch()
                    }}
                    closeModal={createModalState.close}
                    {...createModalState.initialState}
                />
            )}
        </>
    )
}

export default CustomerTradesPageWithState
