import React from 'react'
import CustomerLogItem from './CustomerLogItem'

const CustomerUpdatedItem = ({
    appEvent
}) => (
    <CustomerLogItem
        createdAt={appEvent.createdAt}
        user={appEvent.user}
    >
        Customer <strong>{appEvent.payload.name}</strong> updated.
    </CustomerLogItem>
)

export default CustomerUpdatedItem
