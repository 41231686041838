import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
    PAGE_INFO_FRAGMENT,
    mergeQueryResults,
    createQueryUpdater,
    connectionToCollection,
} from '../../../util/graphql'
import CUSTOMER_USER_FRAGMENT from '../../fragments/CustomerUserFragment'
import CUSTOMER_FRAGMENT from '../../fragments/CustomerFragment'

export const CUSTOMER_USERS_QUERY = gql`
    query customerUsers($customerId: Int!, $after: String, $first: Int) {
        customerUsers(customerId: $customerId, after: $after, first: $first) {
            edges {
                node {
                    ...CustomerUser
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
        customer(id: $customerId) {
            ...Customer
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${CUSTOMER_USER_FRAGMENT}
    ${CUSTOMER_FRAGMENT}
`

export const updateCustomerUsersQuery = createQueryUpdater(CUSTOMER_USERS_QUERY)

const useFetchCustomerUsersTableData = (customerId) => {
    const [isFetchingMore, setIsFetchingMore] = useState(false)
    const {
        loading,
        data = {},
        fetchMore,
        ...result
    } = useQuery(CUSTOMER_USERS_QUERY, {
        variables: { customerId },
    })
    const { customer = {} } = data

    let customerUsers = []
    let hasMore = false
    if (!loading && typeof data.customerUsers !== 'undefined') {
        customerUsers = connectionToCollection(data.customerUsers)
        hasMore = data.customerUsers.pageInfo.hasNextPage
    }

    return {
        hasMore,
        isFetching: loading,
        isFetchingMore,
        customer,
        customerUsers,
        handleFetchMore: () => {
            setIsFetchingMore(true)
            const refetchAfter = data.customerUsers.pageInfo.endCursor
            fetchMore({
                variables: { after: refetchAfter },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    setIsFetchingMore(false)
                    return mergeQueryResults(previousResult, fetchMoreResult, 'customerUsers')
                },
            })
        },
        ...result,
    }
}

export default useFetchCustomerUsersTableData
