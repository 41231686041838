import { useState, useEffect } from 'react'
import moment from 'moment'
import { NEXT_MONTH, LAST_MONTH, THIS_MONTH } from './TradePageDateSelect'

const useTradeDateState = () => {
    const [fromDate, setFromDate] = useState(moment.utc().clone().startOf('month').toISOString())
    const [untilDate, setUntilDate] = useState(moment.utc().clone().endOf('month').toISOString())
    const [selectedMonth, setSelectedMonth] = useState(THIS_MONTH)
    const [selectedYear, setSelectedYear] = useState(moment.utc())

    useEffect(() => {
        let mDate = moment.utc()
        if (selectedMonth === LAST_MONTH) {
            mDate = moment.utc().clone().subtract(1, 'months')
        }
        if (selectedMonth === NEXT_MONTH) {
            mDate = moment.utc().clone().add(1, 'months')
        }
        if (selectedMonth !== THIS_MONTH && selectedMonth !== LAST_MONTH && selectedMonth !== NEXT_MONTH) {
            mDate = moment.utc().clone().month(selectedMonth).year(selectedYear.year())
        }
        setFromDate(mDate.clone().startOf('month').toISOString())
        setUntilDate(mDate.clone().endOf('month').toISOString())
    }, [selectedMonth, selectedYear])

    return {
        fromDate,
        untilDate,
        selectedMonth,
        selectedYear,
        setSelectedMonth,
        setSelectedYear,
    }
}

export default useTradeDateState
