import React from 'react'
import { notification } from 'antd'
import CreatePickupModal from './CreatePickupModal'
import useCreatePickupForm from './useCreatePickupForm'
import useCreatePickupMutation from './useCreatePickupMutation'

const CreatePickupModalWithState = ({
    isVisible,
    onCreatePickupComplete,
    closeModal,
    customer = null,
    refetchQuery,
    assets,
}) => {
    const formState = useCreatePickupForm(customer)
    const [createPickup, { loading: isCreatingPickup }] = useCreatePickupMutation()

    const handleCreatePickup = async () => {
        const isValid = formState.validate()
        if (isValid) {
            try {
                const input = formState.valuesToInput()
                const refetchQueries = [{
                    query: refetchQuery,
                    variables: {
                        customerId: input.customerId,
                    },
                }]
                const { data } = await createPickup({
                    variables: { input },
                    refetchQueries,
                })
                notification.success({
                    message: 'Pickup successful!',
                    description: `The pickup for ${data.createPickup.pickup.customer.name} was successfully registered!`,
                })
            } catch (e) {
                notification.error({
                    message: 'Pickup not created!',
                    description: `Something went wrong. The following error occured: ${e}`,
                })
            }
            onCreatePickupComplete()
        }
    }

    return (
        <CreatePickupModal
            assets={assets}
            formState={formState}
            onSubmit={handleCreatePickup}
            onCancel={closeModal}
            isVisible={isVisible}
            isCreating={isCreatingPickup}
        />
    )
}

export default CreatePickupModalWithState
