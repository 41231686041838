import './environment'
import React from 'react'
import ReactDOM from 'react-dom'
import AppWithState from './components/AppWithState'
import initializeSentry from './services/sentry/initSentry'
import getApolloClient from './apollo/getApolloClient'

initializeSentry()

const apolloClient = getApolloClient()

ReactDOM.render(
    <AppWithState
        apolloClient={apolloClient}
    />,
    document.getElementById('root'),
)
