import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
    PAGE_INFO_FRAGMENT,
    mergeQueryResults,
    createQueryUpdater,
    connectionToCollection,
} from '../../../util/graphql'
import TradePageBlotter from './TradePageBlotter'
import { TRADE_FRAGMENT } from './useUpdateTradeMutation'

export const TRADE_PAGE_QUERY = gql`
    query tradePageQuery(
        $assetId: Int!,
        $from: DateTime,
        $until: DateTime,
        $after: String,
        $first: Int,
        $orderBy: TradeOrderByInput
    ) {
        trades(
            assetId: $assetId,
            from: $from,
            until: $until,
            after: $after,
            first: $first,
            orderBy: $orderBy
        ) {
            edges {
                node {
                    ...Trade
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
        }
        tradeSummary(
            assetId: $assetId,
            from: $from,
            until: $until
        ) {
            ...TradePageBlotter
        }
    }
    ${TRADE_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
    ${TradePageBlotter.FRAGMENT}
`

const useTradePageQuery = (options = {}) => {
    const [isFetchingMore, setIsFetchingMore] = useState(false)
    const {
        data = {},
        loading,
        fetchMore,
        error,
        ...result
    } = useQuery(TRADE_PAGE_QUERY, options)
    const {
        tradeSummary = { currentProfit: {}, profit: {} },
    } = data

    let trades = []
    let hasMore = false
    if (!loading && typeof data.trades !== 'undefined') {
        trades = connectionToCollection(data.trades)
        hasMore = data.trades.pageInfo.hasNextPage
    }

    return {
        trades,
        tradeSummary,
        loading,
        error,
        hasMore,
        isFetchingMore,
        handleFetchMore: () => {
            setIsFetchingMore(true)
            const refetchAfter = data.trades.pageInfo.endCursor
            fetchMore({
                variables: { after: refetchAfter },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    setIsFetchingMore(false)
                    return mergeQueryResults(previousResult, fetchMoreResult, 'trades')
                },
            })
        },
        ...result,
    }
}

export const updateCustomersQuery = createQueryUpdater(TRADE_PAGE_QUERY)

export default useTradePageQuery
