import React from 'react'
import { notification } from 'antd'
import EditOrderModal from './EditOrderModal'
import useEditOrderFormState from './useEditOrderFormState'
import useUpdateOrderMutation from './useUpdateOrderMutation'

const EditOrderModalWithState = ({
    onUpdateOrderCompleted,
    order,
    ...props
}) => {
    const [updateOrder, { loading }] = useUpdateOrderMutation({
        onCompleted: onUpdateOrderCompleted,
    })
    const formState = useEditOrderFormState({
        notes: order.notes,
    })

    const handleUpdateOrder = async () => {
        try {
            if (formState.validate()) {
                await updateOrder({
                    variables: {
                        id: order.id,
                        input: formState.values,
                    },
                })
            }
            notification.success({
                message: 'Success!',
                description: 'The order was successfully updated.',
            })
        } catch (e) {
            notification.error({
                message: 'Something went wrong!',
                description: `Could not update order. The following error occured: ${e}`,
            })
        }
    }

    return (
        <EditOrderModal
            isUpdating={loading}
            onUpdateOrder={handleUpdateOrder}
            formState={formState}
            {...props}
        />
    )
}

export default EditOrderModalWithState
