import React from 'react'
import { Select } from 'antd'
import { useQuery } from '@apollo/client'
import { gql } from '@apollo/client'

export const ADMIN_USERS_QUERY = gql`
    query adminRoles {
        adminRoles {
            id
            slug
            name
        }
    }
`

const RolesSelectWithData = ({
    name,
    value,
    onChange,
}) => {
    const {
        data = {},
        loading,
    } = useQuery(ADMIN_USERS_QUERY)
    const { adminRoles = [] } = data
    return (
        <Select
            value={(() => {
                if (value) {
                    if (adminRoles.length > 0) {
                        return adminRoles.find((role) => role.id.toString() === value.toString()).name
                    }
                    return ''
                }
                return value
            })()}
            name={name}
            onChange={onChange}
            disabled={loading}
            style={{ width: '100%' }}
        >
            <Select.Option key="default" value={null}>Select role</Select.Option>
            {adminRoles.map((role) => (
                <Select.Option key={role.id}>{role.name}</Select.Option>
            ))}
        </Select>
    )
}

export default RolesSelectWithData
