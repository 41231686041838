import React from 'react'
import { Row, Col, Button } from 'antd'
import PickupsTable from './TransfersPickupsTable'
import DeliveriesTable from './TransfersDeliveriesTable'

const TransfersPage = ({
    pickups,
    deliveries,
    isFetchingMorePickups,
    isFetchingMoreDeliveries,
    hasMorePickups,
    hasMoreDeliveries,
    handleFetchMorePickups,
    handleFetchMoreDeliveries,
    onCreatePickup,
    onCreateDelivery,
}) => (
    <Row gutter={16}>
        <Col md={12} sm={24}>
            <div className="flex justify-between mb-4">
                <h2 className="text-2xl">Deliveries</h2>
                <Button
                    type="primary"
                    onClick={() => onCreateDelivery()}
                >
                    New Delivery
                </Button>
            </div>
            <DeliveriesTable
                deliveries={deliveries}
                isFetchingMore={isFetchingMoreDeliveries}
                hasMore={hasMoreDeliveries}
                onFetchMore={handleFetchMoreDeliveries}
            />
        </Col>
        <Col md={12} sm={24}>
            <div className="flex justify-between mb-4">
                <h2 className="text-2xl">Pickups</h2>
                <Button
                    type="primary"
                    onClick={() => onCreatePickup()}
                >
                    New Pickup
                </Button>
            </div>
            <PickupsTable
                pickups={pickups}
                isFetchingMore={isFetchingMorePickups}
                hasMore={hasMorePickups}
                onFetchMore={handleFetchMorePickups}
            />
        </Col>
    </Row>
)

export default TransfersPage
