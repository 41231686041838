import { gql, useMutation } from '@apollo/client'

const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input)
    }
`

const useResetPasswordMutation = (options) => (
    useMutation(RESET_PASSWORD_MUTATION, options)
)

export default useResetPasswordMutation
