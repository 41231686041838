import { useQuery, gql } from '@apollo/client'

export const ASSETS_PAGE_FRAGMENT = gql`
    fragment AssetPageAsset on Asset {
        id
        name
        suspended
        code
        available
        currencies {
            code
            name
            symbol
        }
        units {
            code
            name
            milligram
        }
        priceAdjustments {
            id
            code
            currencyCode
            unitCode
            discount
            premium
            step
            rounding
        }
        impurities {
            id
            code
            currencyCode
            unitCode
            name
            discount
            premium
            percentage
        }
    }
`

export const ASSETS_PAGE_QUERY = gql`
    query assetsPageQuery {
        assets {
            ...AssetPageAsset
        }
    }
    ${ASSETS_PAGE_FRAGMENT}
`

const useFetchAssetsPageData = () => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(ASSETS_PAGE_QUERY)

    const { assets = {} } = data

    return ({
        error,
        isFetching: loading,
        assets,
    })
}

export default useFetchAssetsPageData
