import { gql } from '@apollo/client'

const ME_FRAGMENT = gql`
  fragment Me on User {
      id
      firstName
      lastName
      email
      phone
      preferredUnitCode
      preferredCurrencyCode
      role {
          slug
          name
      }
  }
`
export default ME_FRAGMENT
