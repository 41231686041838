import React from 'react'
import { Form, Input, Button } from 'antd'
import FormItem from '../../util/form/FormItem'

const ChoosePasswordForm = ({
    isLoading,
    values,
    errors,
    onChange,
    onSubmit,
}) => (
    <Form layout="vertical">
        <FormItem
            label="Password"
            error={errors.password}
        >
            <Input
                name="password"
                type="password"
                value={values.password}
                onChange={onChange}
            />
        </FormItem>
        <FormItem
            label="Confirm Password"
            error={errors.confirmPassword}
        >
            <Input
                name="confirmPassword"
                type="password"
                value={values.confirmPassword}
                onChange={onChange}
            />
        </FormItem>
        <Button
            disabled={isLoading}
            type="primary"
            onClick={onSubmit}
        >
            Reset Password
        </Button>
    </Form>
)

export default ChoosePasswordForm
