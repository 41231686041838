import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { connectionToCollection, PAGE_INFO_FRAGMENT, mergeQueryResults } from '../../../util/graphql'
import CUSTOMER_FRAGMENT from '../../fragments/CustomerFragment'

export const STATUS_FILTER_OPTIONS = ['Delivered', 'Awaiting delivery', 'Partially delivered']

export const PAYMENT_FILTER_OPTIONS = ['Resolved', 'Unresolved']

export const CUSTOMER_TRADES_PAGE_QUERY = gql`
    query customerTradesPageQuery($customerId: Int!, $after: String, $first: Int, $where: OrderWhereInput) {
        customer(id: $customerId) {
            ...Customer
        }
        customerOrders(customerId: $customerId, after: $after, first: $first, where: $where ) {
            edges {
                node {
                    id
                    reference
                    uid
                    createdAt
                    side
                    quantity
                    quantityUnitCode
                    quantityInUnit
                    outstandingQuantity
                    outstandingQuantityInUnit
                    status
                    fixStatus
                    notes
                    price
                    totalPrice
                    priceUnitCode
                    totalOutstandingPrice
                    currencyCode
                    unitCode
                    asset {
                        id
                        name
                        code
                    }
                    user {
                        id
                        email
                        phone
                        fullName
                    }
                    customer {
                        id
                    }
                    receitUrl
                    impurity {
                        id
                        name
                    }
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
    }
    ${CUSTOMER_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

const FIRST = 15
const useFetchCustomersPageData = (customerId) => {
    const [isFetchingMore, setisFetchingMore] = useState(false)
    const variables = {
        customerId,
        first: FIRST,
    }
    const {
        error,
        loading,
        data = {},
        fetchMore,
        refetch,
    } = useQuery(CUSTOMER_TRADES_PAGE_QUERY, {
        variables,
    })

    const { customer = {} } = data

    let customerOrders = []
    let hasMoreCustomerOrders = false
    if (!loading && typeof data.customerOrders !== 'undefined') {
        customerOrders = connectionToCollection(data.customerOrders)
        hasMoreCustomerOrders = data.customerOrders.pageInfo.hasNextPage
    }

    return ({
        error,
        isFetching: loading,
        customerOrders,
        customer,
        isFetchingMore,
        hasMoreCustomerOrders,
        handleFetchMoreCustomerOrders: () => {
            setisFetchingMore(true)
            const after = data.customerOrders.pageInfo.endCursor
            fetchMore({
                variables: {
                    ...variables,
                    after,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    setisFetchingMore(false)
                    return mergeQueryResults(previousResult, fetchMoreResult, 'customerOrders')
                },
            })
        },
        refetch,
    })
}

export default useFetchCustomersPageData
