import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { notification, Modal } from 'antd'
import { graphqlErrorsToFormErrors } from '../../../util/graphql'
import createCustomerUser from '../../../api/createCustomerUser'
import CustomerUserForm from './form/CustomerUserForm'
import useCreateCustomerUserForm from './form/useCreateCustomerUserForm'
import { CUSTOMER_USERS_QUERY } from './useFetchCustomerUsersTableData'

const CreateCustomerUserModalWithState = ({
    customerId,
    isVisible,
    closeModal,
}) => {
    const [isSavingCustomerUser, setIsSavingCustomerUser] = useState(false)
    const apolloClient = useApolloClient()
    const formstate = useCreateCustomerUserForm()
    return (
        <Modal
            visible={isVisible}
            title="Create user"
            onOk={async () => {
                if (formstate.validate()) {
                    setIsSavingCustomerUser(true)
                    try {
                        const { data } = await createCustomerUser(
                            apolloClient,
                            customerId,
                            formstate.values,
                            [{ query: CUSTOMER_USERS_QUERY, variables: { customerId } }],
                        )
                        notification.success({
                            message: 'Customer Created!',
                            description: `${data.createCustomerUser.user.firstName} was successfully created!`,
                        })
                        closeModal()
                    } catch (e) {
                        const formErrors = graphqlErrorsToFormErrors(e.graphQLErrors)
                        if (formErrors.length !== 0) {
                            formstate.updateErrors(formErrors)
                        } else {
                            notification.error({
                                message: 'Customer not created!',
                                description: `Something went wrong. The following error occured: ${e}`,
                            })
                            closeModal()
                        }
                    }
                    setIsSavingCustomerUser(false)
                }
            }}
            closable={!isSavingCustomerUser}
            maskClosable={!isSavingCustomerUser}
            confirmLoading={isSavingCustomerUser}
            onCancel={closeModal}
        >
            <CustomerUserForm
                formstate={formstate}
                isCreating
            />
        </Modal>
    )
}

export default CreateCustomerUserModalWithState
