import { gql, useQuery } from '@apollo/client'

const MARKET_DATA_QUERY = gql`
    query marketData(
        $assetCode: String!,
        $currencyCode: String!,
        $unitCode: String!
    ) {
        marketData(
            assetCode: $assetCode,
            currencyCode: $currencyCode,
            unitCode: $unitCode,
        ) {
            status
            sellPrice
            buyPrice
        }
  }
`

const useMarketDataQuery = (options) => (
    useQuery(MARKET_DATA_QUERY, options)
)

export default useMarketDataQuery
