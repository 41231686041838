import React from 'react'
import { Popconfirm } from 'antd'
import {
    DeleteOutlined,
    LoadingOutlined,
} from '@ant-design/icons'
import HasPermissions from '../../../util/HasPermissions'

const CustomerAssetDeleteButton = ({
    asset,
    isDeletingCustomerAsset,
    onDeleteCustomerAsset,
}) => (
    <HasPermissions roles={[HasPermissions.ROLES.ADMIN]}>
        {(hasPermission) => {
            if (!hasPermission) {
                return (
                    <DeleteOutlined style={{ cursor: 'not-allowed' }} />
                )
            }
            return (
                <Popconfirm
                    title={(
                        <>
                            Are you sure you want to delete this asset?<br />
                            All custom added price adjustments will also be removed.
                        </>
                    )}
                    onConfirm={() => onDeleteCustomerAsset(asset)}
                    okText="Yes"
                    cancelText="No"
                >
                    {(() => {
                        if (isDeletingCustomerAsset) {
                            return <LoadingOutlined style={{ cursor: !hasPermission && 'not-allowed' }} />
                        }
                        return (
                            <DeleteOutlined style={{ cursor: !hasPermission && 'not-allowed' }} />
                        )
                    })()}
                </Popconfirm>
            )
        }}
    </HasPermissions>
)

export default CustomerAssetDeleteButton
