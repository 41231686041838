import React from 'react'
import {
    Col,
    Row,
    Form,
    Input,
    InputNumber,
    DatePicker,
} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import {
    calculateStep,
    getRoundingForUnit,
} from '../../../util/units'
import FormItem from '../../util/form/FormItem'
import UnitSelect from '../../util/form/UnitSelect'
import AssetSelect from '../../util/form/AssetSelect'
import CurrencySelect from '../../util/form/CurrencySelect'
import OrderSideButtons from '../orders/order-modal/OrderSideButtons'
import { pricingIsWithinValidRange } from '../trades/CreateTradeForm'

const { TextArea } = Input

const CreateCustomerTradeForm = ({
    pricing,
    units = [],
    assets = [],
    currencies = [],
    formState,
}) => {
    const {
        values,
        getInputProps,
        getNativeInputProps,
        antdErrors,
    } = formState
    return (
        <Form layout="vertical">
            <FormItem>
                <OrderSideButtons
                    {...getNativeInputProps('side')}
                />
            </FormItem>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Date"
                        error={antdErrors.get('date')}
                    >
                        <DatePicker
                            format="DD/MM/YYYY HH:mm"
                            showTime={{ format: 'HH:mm' }}
                            className="w-full"
                            {...getInputProps('date')}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <AssetSelect
                        isRequired
                        {...getInputProps('assetId')}
                        label="Asset"
                        error={antdErrors.get('assetId')}
                        assets={assets}
                        className="w-full"
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Quantity"
                        error={antdErrors.get('quantity')}
                    >
                        <InputNumber
                            {...getInputProps('quantity')}
                            decimalSeparator=","
                            min={0}
                            step={calculateStep(true, values.quantityUnitCode)}
                            precision={getRoundingForUnit(values.quantityUnitCode)}
                            addonAfter={(
                                <UnitSelect
                                    units={units}
                                    {...getInputProps('quantityUnitCode')}
                                />
                            )}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Price"
                        error={antdErrors.get('price')}
                        extra={values.price !== 0 && !pricingIsWithinValidRange(values.price * 100, pricing, values.side) && (
                            <div className="flex items-baseline text-yellow-400">
                                <InfoCircleOutlined className="text-sm mr-2" />
                                <span>Based on the current pricing, this value does not look correct</span>
                            </div>
                        )}
                    >
                        <InputNumber
                            {...getInputProps('price')}
                            min={0}
                            decimalSeparator=","
                            precision={2}
                            addonAfter={(
                                <>
                                    <CurrencySelect
                                        style={{ margin: '-5px 0' }}
                                        currencies={currencies}
                                        {...getInputProps('currencyCode')}
                                    />
                                    /
                                    <UnitSelect
                                        style={{ margin: '-5px 0' }}
                                        units={units}
                                        {...getInputProps('priceUnitCode')}
                                    />
                                </>
                            )}
                        />
                    </FormItem>
                </Col>

            </Row>

            <FormItem
                label="Notes"
                error={antdErrors.get('notes')}
            >
                <TextArea
                    rows={4}
                    {...getNativeInputProps('notes')}
                />
            </FormItem>
        </Form>
    )
}

export default CreateCustomerTradeForm
