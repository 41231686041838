import { gql } from '@apollo/client'
import CUSTOMER_USER_FRAGMENT from '../components/fragments/CustomerUserFragment'

const CREATE_CUSTOMER_USER = gql`
    mutation createCustomerUser($customerId: Int!, $input: CustomerUserInput!) {
        createCustomerUser(customerId: $customerId, input: $input) {
            user {
                ...CustomerUser
            }
        }
    }
    ${CUSTOMER_USER_FRAGMENT}
`

export default async function createCustomerUser(apolloClient, customerId, input, refetchQueries) {
    const mutation = CREATE_CUSTOMER_USER
    const variables = {
        customerId,
        input,
    }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        refetchQueries,
    })
    return response
}
