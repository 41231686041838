import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import {
    GoldOutlined,
    TeamOutlined,
    StockOutlined,
    SettingOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons'

function SettingsSidebarMenu({
    activeMenuItem,
}) {
    const navigate = useNavigate()
    return (
        <Menu
            mode="vertical"
            selectedKeys={[activeMenuItem]}
            style={{ borderRight: 0 }}
        >
            <Menu.Item key="users" icon={<TeamOutlined />} onClick={() => navigate('/settings/users')}>
                Users
            </Menu.Item>
            <Menu.Item key="assets" icon={<GoldOutlined />} onClick={() => navigate('/settings/assets')}>
                Assets
            </Menu.Item>
            <Menu.Item key="logs" icon={<ClockCircleOutlined />} onClick={() => navigate('/settings/logs')} >
                 Logs
            </Menu.Item>
            <Menu.Item key="fix" icon={<StockOutlined />} onClick={() => navigate('/settings/fix')}>
                Hedge
            </Menu.Item>
            <Menu.Item key="appSettings" icon={<SettingOutlined />}  onClick={() => navigate('/settings/appSettings')}>
                Settings
            </Menu.Item>
        </Menu>
    )
}

export default SettingsSidebarMenu
