import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
    PAGE_INFO_FRAGMENT,
    mergeQueryResults,
    connectionToCollection,
} from '../../../util/graphql'
import AssetSelect from '../../util/form/AssetSelect'
import CustomerTopBar from '../../layout/CustomerTopBar'
import CustomerTradesPageTable from './CustomerTradesPageTable'

const QUERY = gql`
    query customerTradesPageQuery(
        $customerId: Int!,
        $assetId: Int,
        $from: DateTime,
        $until: DateTime,
        $after: String,
        $first: Int,
        $orderBy: TradeOrderByInput
    ) {
        assets {
            ...AssetSelect
        }
        customer(id: $customerId) {
            id
            ...CustomerTopBar
        }
        customerTrades(
            customerId: $customerId,
            assetId: $assetId,
            from: $from,
            until: $until,
            after: $after,
            first: $first,
            orderBy: $orderBy
        ) {
            edges {
                node {
                    ...CustomerTradesPageTable
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${AssetSelect.FRAGMENT}
    ${CustomerTopBar.FRAGMENT}
    ${CustomerTradesPageTable.FRAGMENT}
`

const useCustomerTradesPageQuery = (options) => {
    const [isFetchingMore, setIsFetchingMore] = useState()
    const {
        data = {},
        error,
        loading,
        fetchMore,
        ...result
    } = useQuery(QUERY, options)

    let hasMore = false
    let customerTrades = []
    if (!loading && typeof data.customerTrades !== 'undefined') {
        customerTrades = connectionToCollection(data.customerTrades)
        hasMore = data.customerTrades.pageInfo.hasNextPage
    }

    const handleFetchMore = () => {
        setIsFetchingMore(true)
        const refetchAfter = data.customerTrades.pageInfo.endCursor
        fetchMore({
            variables: { after: refetchAfter },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                setIsFetchingMore(false)
                return mergeQueryResults(previousResult, fetchMoreResult, 'customerTrades')
            },
        })
    }

    const { customer = {}, assets = [] } = data

    return {
        error,
        loading,
        assets,
        customer,
        customerTrades,
        hasMore,
        handleFetchMore,
        isFetchingMore,
        ...result,
    }
}

export default useCustomerTradesPageQuery
