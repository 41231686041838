import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
    PAGE_INFO_FRAGMENT,
    mergeQueryResults,
    connectionToCollection,
} from '../../../util/graphql'
import { LOG_APP_EVENT_FRAGMENT } from './LogTimelineItem'

export const LOGS_PAGE_QUERY = gql`
    query logsPageQuery(
        $after: String,
        $first: Int
    ) {
        appEvents(
            after: $after,
            first: $first,
            orderBy: {
                createdAt: DESC
            }
        ) {
            edges {
                node {
                    ...LogAppEvent
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
        assets {
            id
            name
            code
        }
    }
    ${LOG_APP_EVENT_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

const useLogsPageQuery = ({ after, first }) => {
    const [isFetchingMore, setIsFetchingMore] = useState(false)
    const {
        data = {},
        loading,
        fetchMore,
        error,
        ...result
    } = useQuery(LOGS_PAGE_QUERY, {
        variables: {
            after,
            first,
        },
    })

    const {
        assets = [],
    } = data

    let appEvents = []
    let hasMore = false
    if (!loading && typeof data.appEvents !== 'undefined') {
        appEvents = connectionToCollection(data.appEvents)
        hasMore = data.appEvents.pageInfo.hasNextPage
    }

    return {
        assets,
        appEvents,
        loading,
        error,
        hasMore,
        isFetchingMore,
        handleFetchMore: () => {
            setIsFetchingMore(true)
            const refetchAfter = data.appEvents.pageInfo.endCursor
            fetchMore({
                variables: { after: refetchAfter },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    setIsFetchingMore(false)
                    return mergeQueryResults(previousResult, fetchMoreResult, 'appEvents')
                },
            })
        },
        ...result,
    }
}

export default useLogsPageQuery
