import React from 'react'
import moment from 'moment'
import { Table, Tag } from 'antd'
import { gql } from '@apollo/client'
import { DEPOSIT } from '../../../util/constants'
import { formatCurrencyNotation } from '../../../util/currency'
import HasPermissions from '../../util/HasPermissions'
import InfiniteScroll from '../../util/InfiniteScroll'
import CustomerPaymentsTableDeleteButton from './CustomerPaymentsTableDeleteButton'

const FRAGMENT = gql`
    fragment CustomerPaymentsTable on Payment {
        id
        type
        amount
        currencyCode
        customer {
            id
            name
        }
        orders {
            id
        }
        createdAt
    }
`

const CustomerPaymentsTable = ({
    customerPayments,
    hasMore,
    onFetchMore,
    isLoading,
    isFetchingMore,
    onDeleteComplete,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        hasMore={hasMore}
        onFetchMore={onFetchMore}
    >
        <Table
            rowKey="id"
            columns={[
                {
                    title: 'Customer',
                    dataIndex: ['customer', 'name'],
                    width: 200,
                },
                {
                    title: 'Type',
                    dataIndex: 'type',
                    render: (type) => {
                        const color = type === DEPOSIT ? 'green' : 'blue'
                        return <Tag color={color}>{type}</Tag>
                    },
                    width: 150,
                    align: 'center',
                },
                {
                    title: 'Amount',
                    render: (payment) => `${formatCurrencyNotation(payment.amount, payment.currencyCode)} ${payment.currencyCode}`,
                    width: 150,
                }, {
                    title: 'Date',
                    dataIndex: 'createdAt',
                    render: (createdAt) => moment(createdAt).format('DD/MM/YY'),
                    width: 150,
                }, {
                    title: '',
                    align: 'right',
                    width: 50,
                    render: (payment) => (
                        <HasPermissions roles={[HasPermissions.ROLES.ADMIN]}>
                            {(hasPermission) => (
                                <CustomerPaymentsTableDeleteButton
                                    disabled={!hasPermission || payment.orders.length}
                                    paymentId={payment.id}
                                    onDeleteComplete={onDeleteComplete}
                                />
                            )}
                        </HasPermissions>
                    ),
                },
            ]}
            loading={isLoading}
            dataSource={customerPayments}
            pagination={false}
        />
    </InfiniteScroll>
)

CustomerPaymentsTable.FRAGMENT = FRAGMENT

export default CustomerPaymentsTable
