import { gql } from '@apollo/client'

const RESEND_CUSTOMER_USER_MAIL = gql`
    mutation resendCustomerUserMail($id: Int!) {
        resendCustomerUserMail(id: $id)
    }
`

export default async function resendCustomerUserMail(apolloClient, id) {
    const mutation = RESEND_CUSTOMER_USER_MAIL
    const variables = { id }
    const response = await apolloClient.mutate({
        mutation,
        variables,
    })
    return response
}
