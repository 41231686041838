import React from 'react'
import { Radio } from 'antd'
import { SIDE_BUY, SIDE_SELL } from '../../../../util/constants'

const OrderSideButtons = ({
    value,
    onChange,
}) => (
    <Radio.Group
        name="side"
        value={value}
        onChange={onChange}
        buttonStyle="solid"
        style={{ width: '100%' }}
    >
        <Radio.Button
            style={{ width: '50%', textAlign: 'center' }}
            value={SIDE_BUY}
        >
            {SIDE_BUY}
        </Radio.Button>
        <Radio.Button
            style={{ width: '50%', textAlign: 'center' }}
            value={SIDE_SELL}
        >
            {SIDE_SELL}
        </Radio.Button>
    </Radio.Group>
)

export default OrderSideButtons
