import { createFormValidation } from 'use-form-state'
import useFormState from '../../hooks/useFormState'

const appSettingsToFormValues = (appSettings) => ({
    minTradeLimit: appSettings.minTradeLimit || 0,
    maxTradeLimit: appSettings.maxTradeLimit || 0,
})

const validation = createFormValidation([{
    path: 'minTradeLimit',
    validate: (minTradeLimit) => typeof minTradeLimit !== 'undefined' && minTradeLimit > 0,
    message: 'Minimum trade limit must be larger than 0',
}, {
    path: 'maxTradeLimit',
    validate: (maxTradeLimit) => typeof maxTradeLimit !== 'undefined' && maxTradeLimit > 0,
    message: 'Maximum trade limit must be larger than 0',
}])

const useUpdateAppSettingsForm = (values, options) => {
    const formState = useFormState(
        appSettingsToFormValues(values),
        {
            validation,
            ...options,
        },
    )

    return {
        ...formState,
    }
}

export default useUpdateAppSettingsForm
