import React, { useState } from 'react'
import clsx from 'clsx'
import { Select, InputNumber } from 'antd'
import roundTo from 'round-to'
import { getRoundingForUnit, unitTo, getPreferredUnits } from '../../../util/units'
import * as constants from '../../../util/constants'
import PreferredUnits from '../PreferredUnits'

const { Option } = Select
const {
    UNIT_CODE_MILLIGRAM: MG,
} = constants

const calculateStep = (isRounded, currentUnit) => {
    if (!isRounded) {
        return 1 / 10 ** getRoundingForUnit(currentUnit)
    }
    return 1
}

const convertAndRoundQuantity = (quantity, currentUnit, newUnit) => {
    const convertedQuantity = unitTo(quantity, currentUnit, newUnit)
    const roundedQuantity = roundTo(convertedQuantity, getRoundingForUnit(newUnit))
    return roundedQuantity
}

const UnitInputWithConverter = ({
    error,
    value,
    onChange,
    code,
    isRounded = true,
    disabled,
}) => {
    const units = getPreferredUnits(code)
    const [currentUnit, setCurrentUnit] = useState(units[0])

    return (
        <PreferredUnits
            code={code}
        >
            {() => (
                <div>
                    <div className="flex">
                        <div className="w-full">
                            <InputNumber
                                className="w-full"
                                value={convertAndRoundQuantity(value, MG, currentUnit)}
                                decimalSeparator=","
                                onChange={(input) => {
                                    const convertedInput = unitTo(input, currentUnit, MG)
                                    if (convertedInput) {
                                        onChange(convertedInput)
                                    }
                                }}
                                precision={getRoundingForUnit(currentUnit)}
                                disabled={disabled}
                                min={0}
                                step={calculateStep(isRounded, currentUnit)}
                            />
                        </div>
                        <div className="w-16">
                            <Select
                                className={
                                    clsx(
                                        'border-gray-200',
                                        typeof error !== 'undefined' && 'border-red-500',
                                    )
                                }
                                defaultValue={currentUnit}
                                onChange={(newUnit) => {
                                    setCurrentUnit(newUnit)
                                }}
                            >
                                {units.map((unit) => (
                                    <Option
                                        key={unit}
                                        value={unit}
                                    >
                                        {unit}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <p className="text-sm text-red-500">
                        {error}
                    </p>
                </div>
            )}
        </PreferredUnits>
    )
}

export default UnitInputWithConverter
