import React, { useState } from 'react'
import { Avatar, Popover, Button } from 'antd'
import {
    UserOutlined,
    PoweroffOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import useAppState from '../hooks/useAppState'

const NavbarUserDropdownMenu = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState()
    const { currentUser, logout } = useAppState()
    return (
        <Popover
            title={`${currentUser.firstName} ${currentUser.lastName} (${currentUser.role.name})`}
            trigger="click"
            placement="bottom"
            content={(
                <div>
                    <div className="flex flex-col">
                        <span className="mb-4">
                            <strong>Tel: </strong>{currentUser.phone}
                        </span>
                        <span className="mb-4">
                            <strong>Email: </strong>{currentUser.email}
                        </span>
                    </div>
                    <Button
                        icon={<PoweroffOutlined />}
                        loading={isLoading}
                        onClick={async () => {
                            setIsLoading(true)
                            await logout()
                            navigate('/login')
                        }}
                        className="w-full"
                    >
                        Logout
                    </Button>
                </div>
            )}
        >
            <Avatar icon={<UserOutlined />} className="cursor-pointer" />
        </Popover>
    )
}
export default NavbarUserDropdownMenu
