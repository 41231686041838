import { gql, useMutation } from '@apollo/client'
import CustomerTradesPageTable from './CustomerTradesPageTable'

const MUTATION = gql`
    mutation updateCustomerTradeMutation(
        $id: Int!,
        $input: UpdateCustomerTradeInput!
    ) {
        updateCustomerTrade(
            id: $id,
            input: $input
        ) {
            trade {
                ...CustomerTradesPageTable
            }
        }
    }
    ${CustomerTradesPageTable.FRAGMENT}
`

const useUpdateCustomerTradeMutation = (options) => (
    useMutation(MUTATION, options)
)

export default useUpdateCustomerTradeMutation
