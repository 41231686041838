import React from 'react'
import { Modal } from 'antd'
import CreateTradeForm from './CreateTradeForm'

const CreateTradeModal = ({
    pricing,
    units,
    assets,
    currencies,
    formState,
    isOpen,
    isCreating,
    onCancel,
    onConfirm,
}) => (
    <Modal
        title="Create new Trade"
        width={750}
        visible={isOpen}
        closable={!isCreating}
        maskClosable={!isCreating}
        confirmLoading={isCreating}
        onCancel={onCancel}
        onOk={onConfirm}
    >
        <CreateTradeForm
            pricing={pricing}
            units={units}
            assets={assets}
            currencies={currencies}
            formState={formState}
        />
    </Modal>
)

export default CreateTradeModal
