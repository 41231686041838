import React from 'react'
import { formatCurrency } from '../../../../../util/currency'
import PaymentLogItem from './PaymentLogItem'

const PaymentDeletedItem = ({
    appEvent,
}) => {
    const {
        customer, user, createdAt, payload,
    } = appEvent
    const { type, amount, currencyCode } = payload
    return (
        <PaymentLogItem
            isDeleted
            createdAt={createdAt}
            user={user}
        >
            <div>
                {type} of <strong>{formatCurrency(amount)} {currencyCode}</strong>
                {' '}for{' '}
                <strong>{customer.name}</strong> deleted.
            </div>
        </PaymentLogItem>
    )
}

export default PaymentDeletedItem
