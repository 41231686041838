import React from 'react'
import Logo from './util/Logo'
import Loading from './util/Loading'
import MainCentered from './layout/MainCentered'

const AppLoading = () => (
    <MainCentered>
        <div className="mb-6">
            <Logo height={40} />
        </div>
        <Loading size={40} />
    </MainCentered>
)

export default AppLoading
