import React from 'react'
import { Tag } from 'antd'
import UnitValue from '../../util/UnitValue'
import CurrencyValue from '../../util/CurrencyValue'

const TradeSummary = ({
    isMarketorder,
    isFixed,
    side,
    assetName,
    customerName,
    unitCode,
    currencyCode,
    spotPrice,
    quantity,
}) => {
    const totalPrice = spotPrice * quantity
    return (
        <div className="calculationInfo">
            <h3 className="text-xl">Trade Summary</h3>
            <ul>
                <li>
                    Side:
                    {' '}
                    <strong>{side}</strong>
                </li>
                <li>
                    Metal:
                    {' '}
                    <strong>{assetName}</strong>
                </li>
                {!isMarketorder && (
                    <li>
                        Customer:
                        {' '}
                        <strong>{customerName}</strong>
                    </li>
                )}
                <li>
                    Quantity:{' '}
                    <UnitValue
                        isBold
                        formatValue={false}
                        value={quantity}
                        unitCode={unitCode}
                    />
                </li>
                <li>
                    Spot Price:
                    {' '}
                    <CurrencyValue
                        isBold
                        formatValue={false}
                        value={spotPrice}
                        currencyCode={currencyCode}
                    />
                </li>
                <li>
                    Total Price:
                    {' '}
                    <CurrencyValue
                        isBold
                        formatValue={false}
                        value={totalPrice}
                        currencyCode={currencyCode}
                    />
                </li>
                {!isMarketorder && (
                    <li>
                        Fix Hedge:
                        {' '}
                        <Tag
                            color={isFixed ? 'green' : 'red'}
                        >
                            {isFixed ? 'YES' : 'NO'}
                        </Tag>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default TradeSummary
