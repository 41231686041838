import React, { useState } from 'react'
import { notification } from 'antd'
import { useAsyncEffect } from '@wappla/react-hooks'
import { useApolloClient } from '@apollo/client'
import getAdjustedMarketData from '../../../api/getAdjustedMarketData'
import { whereId } from '../../util/index'
import * as constants from '../../../util/constants'
import CreateTradeModal from './CreateTradeModal'
import useCreateTradeQuery from './useCreateTradeQuery'
import useCreateTradeMutation from './useCreateTradeMutation'
import useCreateTradeFormState from './useCreateTradeFormState'

const {
    UNIT_CODE_KILOGRAM: KG,
} = constants

const CreateTradeModalWithState = ({
    date,
    assetId,
    isOpen,
    closeModal,
    onCreateTradeCompleted = () => {},
}) => {
    const [pricing, setPricing] = useState({ buyPrice: 0, sellPrice: 0 })
    const apolloClient = useApolloClient()
    const {
        units,
        assets,
        currencies,
        isFetching,
    } = useCreateTradeQuery()
    const [createTrade, { loading: isCreating }] = useCreateTradeMutation({
        onCompleted: onCreateTradeCompleted,
    })
    const formState = useCreateTradeFormState({
        date,
        assetId,
        currencyCode: isFetching ? '' : currencies[0].code,
        quantityUnitCode: KG,
        priceUnitCode: KG,
    })

    const { values } = formState
    useAsyncEffect(async () => {
        const {
            currencyCode,
            priceUnitCode,
        } = values
        try {
            const { data } = await getAdjustedMarketData(apolloClient, {
                variables: {
                    currencyCode,
                    unitCode: priceUnitCode,
                    assetCode: assets.find(whereId(assetId)).code,
                },
            })
            const { buyPrice, sellPrice } = data.adjustedMarketData
            setPricing({ buyPrice, sellPrice })
        } catch (e) {
            notification.error({
                message: 'Could not fetch pricing',
                description: `Something went wrong. The following error occurred: ${e}`,
            })
        }
    }, [values.currencyCode, values.priceUnitCode, values.assetId])

    const handleCreateTrade = async () => {
        if (formState.validate()) {
            try {
                const input = formState.valuesToInput()
                await createTrade({
                    variables: {
                        input,
                    },
                })
                formState.resetForm()
                notification.success({
                    message: 'Trade created!',
                    description: 'Trade was successfully created!',
                })
            } catch (e) {
                notification.error({
                    message: 'Trade not created!',
                    description: `Something went wrong. The following error occurred: ${e}`,
                })
            }
        }
    }

    const handleCancelTrade = () => {
        formState.resetForm()
        closeModal()
    }

    return (
        <CreateTradeModal
            pricing={pricing}
            units={units}
            assets={assets}
            currencies={currencies}
            formState={formState}
            isOpen={isOpen}
            isCreating={isCreating}
            onCancel={handleCancelTrade}
            onConfirm={handleCreateTrade}
        />
    )
}

export default CreateTradeModalWithState
