import React from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import MarketDataPlaceholder from './MarketDataPlaceholder'

const MarketDataError = ({
    error,
}) => (
    <MarketDataPlaceholder
        title="Error"
        text={!error
            ? 'Something went wrong, please try again later.'
            : error}
        icon={(
            <CloseCircleOutlined
                className="text-3xl mr-4"
            />
        )}
    />
)

export default MarketDataError
