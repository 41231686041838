import { createFormValidation } from 'use-form-state'
import useFormState from '../../hooks/useFormState'
import * as constants from '../../../util/constants'
import { fixFloatingPointError } from '../../../util/units'

const {
    MIN_TRADE_LIMIT_MARGIN: MIN_MARGIN,
    MAX_TRADE_LIMIT_MARGIN: MAX_MARGIN,
    TRADE_LIMIT_TYPE_FIXED: FIXED,
    TRADE_LIMIT_TYPE_MARGIN: MARGIN,
} = constants

const customerToFormValues = ({
    name,
    balanceMargin,
    hasTradeLimit,
    minTradeLimit: customerMinTradeLimit,
    maxTradeLimit: customerMaxTradeLimit,
    fixedTradeLimit,
    tradeLimit = {},
}) => {
    const areTradeLimitsSet = customerMinTradeLimit !== null && typeof customerMinTradeLimit !== 'undefined'
        && customerMaxTradeLimit !== null && typeof customerMaxTradeLimit !== 'undefined'
    const minTradeLimit = customerMinTradeLimit || tradeLimit.minTradeLimit || 0
    const maxTradeLimit = customerMaxTradeLimit || tradeLimit.maxTradeLimit || 0
    let limitType = MARGIN
    let inputFixedTradeLimit = null
    if (typeof fixedTradeLimit !== 'undefined' && fixedTradeLimit !== null) {
        limitType = FIXED
        inputFixedTradeLimit = fixedTradeLimit / 100
    }

    return {
        name,
        balanceMargin,
        hasTradeLimit,
        areTradeLimitsSet,
        minTradeLimit,
        maxTradeLimit,
        fixedTradeLimit: inputFixedTradeLimit,
        limitType,
    }
}

const validation = createFormValidation([{
    path: 'name',
    validate: (name) => typeof name !== 'undefined' && name !== null && name !== '',
    message: 'Name is required',
}, {
    path: 'balanceMargin',
    validate: (balanceMargin) => balanceMargin === null || (typeof balanceMargin === 'number'
        && balanceMargin >= MIN_MARGIN
        && balanceMargin <= MAX_MARGIN),
    message: 'Please provide a margin between 0 and 100%',
}, {
    path: 'minTradeLimit',
    validate: (minTradeLimit) => typeof minTradeLimit !== 'undefined' && minTradeLimit > 0,
    message: 'Minimum trade limit is required and must be larger than 0',
}, {
    path: 'maxTradeLimit',
    validate: (maxTradeLimit) => typeof maxTradeLimit !== 'undefined' && maxTradeLimit > 0,
    message: 'Maximum trade limit is required and must be larger than 0',
}, {
    path: 'fixedTradeLimit',
    validate: (fixedTradeLimit) => fixedTradeLimit === null || fixedTradeLimit >= 0,
    message: 'Fixed trade limit is must be larger or equal to than 0',
}])

const toCustomerInput = ({
    name,
    balanceMargin,
    hasTradeLimit,
    areTradeLimitsSet,
    minTradeLimit,
    maxTradeLimit,
    fixedTradeLimit,
}) => {
    const inputMinTradeLimit = areTradeLimitsSet ? minTradeLimit : null
    const inputMaxTradeLimit = areTradeLimitsSet ? maxTradeLimit : null
    const inputFixedTradeLimit = fixedTradeLimit === null
        ? null : fixFloatingPointError(fixedTradeLimit * 100)
    return {
        name,
        balanceMargin,
        hasTradeLimit,
        minTradeLimit: inputMinTradeLimit,
        maxTradeLimit: inputMaxTradeLimit,
        fixedTradeLimit: inputFixedTradeLimit,
    }
}

const useUpdateCustomerSettingsForm = (customer, options) => {
    const formState = useFormState(
        customerToFormValues(customer),
        {
            validation,
            valuesToInput: toCustomerInput,
            ...options,
        },
    )

    const { areTradeLimitsSet } = formState.values
    const handleEditTradeLimits = () => {
        if (areTradeLimitsSet === true) {
            return formState.setValues({
                areTradeLimitsSet: !areTradeLimitsSet,
                minTradeLimit: customer.tradeLimit.minTradeLimit,
                maxTradeLimit: customer.tradeLimit.maxTradeLimit,
            })
        }
        return formState.handleChange('areTradeLimitsSet', !areTradeLimitsSet)
    }

    const handleChangeLimitType = (e) => {
        const limitType = e.target.value
        if (limitType === MARGIN) {
            return formState.setValues({
                limitType,
                balanceMargin: 0,
                fixedTradeLimit: null,
            })
        }
        return formState.setValues({
            limitType,
            balanceMargin: null,
            fixedTradeLimit: 0,
        })
    }

    return {
        ...formState,
        handleEditTradeLimits,
        handleChangeLimitType,
    }
}

export default useUpdateCustomerSettingsForm
