import dotProp from 'dot-prop-immutable'
import { gql } from '@apollo/client'
import getApolloClient from '../apollo/getApolloClient'

export const connectionToCollection = (connection = { edges: [] }) => connection.edges.map((edge) => edge.node)

export const nestedConnectionToCollection = (key) => (entity) => ({
    ...entity,
    [key]: connectionToCollection(entity[key]),
})

export const createQueryUpdater = (query) => ({ variables = {}, path, value }) => {
    const apolloClient = getApolloClient()
    const data = apolloClient.readQuery({
        query,
        variables,
    })

    const mutatedData = dotProp.set(data, path, value)

    apolloClient.writeQuery({
        query,
        variables,
        data: mutatedData,
    })
}

export const mergeQueryResults = (previousResult, fetchMoreResult, path) => {
    const newEdges = fetchMoreResult[path].edges
    const { totalCount } = fetchMoreResult[path]
    const { pageInfo } = fetchMoreResult[path]
    if (newEdges.length === 0) {
        return previousResult
    }
    return {
        [path]: {
            __typename: previousResult[path].__typename,
            edges: [...previousResult[path].edges, ...newEdges],
            pageInfo,
            totalCount,
        },
    }
}

export const graphqlErrorsToFormErrorsDEPRECATED = (errors) => errors.reduce((invalidFields, error) => ({
    ...invalidFields, ...error.extensions.exception.invalidFields,
}), {})

export const graphqlErrorsToFormErrors = (errors) => {
    if (typeof errors === 'undefined') {
        return []
    }
    return errors.reduce((_, error) => {
        const { invalidFields } = error.extensions.exception
        return Object.keys(invalidFields).map((key) => ({
            path: key,
            message: invalidFields[key],
        }))
    }, [])
}

export const PAGE_INFO_FRAGMENT = gql`
    fragment PageInfo on PageInfo {
        hasNextPage
        startCursor
        endCursor
    }
`
