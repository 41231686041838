import * as Sentry from '@sentry/react'

const DSN = 'https://c083f94d71514fcdb7b26eba1df1ebaf@o77097.ingest.sentry.io/1366526'

export default function initializeSentry() {
    const { REACT_APP_ENV } = window.env
    if (REACT_APP_ENV !== 'local') {
        Sentry.init({
            dsn: DSN,
            environment: REACT_APP_ENV,
            integrations: [
                Sentry.browserTracingIntegration()
            ],
        })
    }
}
