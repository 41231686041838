import React from 'react'
import { LogoutOutlined } from '@ant-design/icons'
import TimelineItem from '../timeline/TimelineItem'

const HedgeLoggedOutItem = ({
    appEvent,
}) => {
    const {
        user,
        createdAt,
    } = appEvent
    return (
        <TimelineItem
            dot={(
                <LogoutOutlined
                    style={{
                        fontSize: '18px',
                    }}
                />
            )}
            createdAt={createdAt}
            user={user}
        >
            <div>
                Logged out from Hedge provider
            </div>
        </TimelineItem>
    )
}

export default HedgeLoggedOutItem
