import React from 'react'
import { Affix } from 'antd'

const SIDEBAR_WIDTH = 200
const SIDEBAR_HEIGHT = 500

const ContainerWithSidebar = ({
    topbar,
    sidebar,
    children,
}) => (
    <div className="flex">
        <Affix offsetTop={10}>
            <div className="w-[200px] h-[500px] mr-4 border-r-1 border-gray-100">
                {sidebar}
            </div>
        </Affix>
        <div className="flex flex-1 flex-col">
            {topbar}
            {children}
        </div>
    </div>
)

ContainerWithSidebar.SIDEBAR_WIDTH = SIDEBAR_WIDTH
ContainerWithSidebar.SIDEBAR_HEIGHT = SIDEBAR_HEIGHT

export default ContainerWithSidebar
