import React from 'react'
import { Alert } from 'antd'

const PriceAdjustmentInfoAlert = () => (
    <Alert
        showIcon
        className="my-4 bg-blue-50 border-blue-300"
        message="Customer price calculation"
        description={(
            <>
                <div>
                    Customer <strong>sells</strong> to VT: Hedge bid price - step - discount rounded down = Sell price
                </div>
                <div>
                    Customer <strong>buys</strong> from VT: Hedge offer price + step + premium rounded up = Buy price
                </div>
            </>
        )}
    />
)

export default PriceAdjustmentInfoAlert
