import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { getCurrencySymbol } from '../../../util/currency'

export const CUSTOMER_ASSET_CARD_QUERY = gql`
    query customerAssetCardQuery($customerId: Int!, $assetCode: String!, $unitCode: String!, $currencyCode: String!) {
        adjustedCustomerMarketData(customerId: $customerId, assetCode: $assetCode, unitCode: $unitCode, currencyCode: $currencyCode) {
            status
            sellPrice
            previousSellPrice
            buyPrice
            previousBuyPrice
        }
    }
`

const useFetchCustomerAssetCardData = (customerId, assetCode, inputCurrencyCode, inputUnitCode, pollInterval) => {
    const [currencyCode, setCurrencyCode] = useState(inputCurrencyCode)
    const [unitCode, setUnitCode] = useState(inputUnitCode)
    const variables = {
        customerId,
        assetCode,
        unitCode,
        currencyCode,
    }
    const {
        error,
        loading,
        data = {},
    } = useQuery(CUSTOMER_ASSET_CARD_QUERY, {
        variables,
        pollInterval,
    })
    const { adjustedCustomerMarketData = {} } = data

    return {
        error,
        isFetching: loading,
        adjustedCustomerMarketData,
        currencyCode,
        currencySymbol: getCurrencySymbol(currencyCode),
        unitCode,
        onChangeCurrency: (newCurrencyCode) => setCurrencyCode(newCurrencyCode),
        onChangeUnit: (newUnitCode) => setUnitCode(newUnitCode),
    }
}

export default useFetchCustomerAssetCardData
