import React from 'react'
import { Menu } from 'antd'
import {
    StockOutlined,
    TeamOutlined,
    SwapOutlined,
    DollarOutlined,
    SettingOutlined,
    FileDoneOutlined,
} from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import * as routes from '../../routes'

const { SubMenu, Item } = Menu

const NavbarMenu = ({
    assets,
    activeMenuItem,
}) => {
    const navigate = useNavigate()
    const [firstAsset] = assets
    return (
        <Menu
            mode="horizontal"
            selectedKeys={[activeMenuItem]}
            style={{ borderBottom: 'none' }}
        >
            <SubMenu
                key="trades"
                onTitleClick={() => {
                    navigate(`/trades/${firstAsset.id}`)
                }}
                icon={<StockOutlined />}
                title={"Trades"}
            >
                {assets.map(({ id, name }) => (
                    <Item key={`trades:${id}`}>
                        <Link to={`/trades/${id}`}>
                            {name}
                        </Link>
                    </Item>
                ))}
            </SubMenu>
            <SubMenu
                key="orders"
                onTitleClick={() => {
                    navigate(`/orders/${firstAsset.id}`)
                }}
                icon={<FileDoneOutlined />}
                title="Orders"
            >
                {assets.map(({ id, name }) => (
                    <Item key={`orders:${id}`}>
                        <Link to={routes.assetOrders(id)}>
                            {name}
                        </Link>
                    </Item>
                ))}
            </SubMenu>
            <Item key="prices" icon={<DollarOutlined />} onClick={() => {
                navigate('/prices')
            }}>
                Prices
            </Item>
            <Item key="deliveries" icon={<SwapOutlined />} onClick={() => navigate('/transfers')} >
                Pickups &amp; Deliveries
            </Item>
            <Item key="customers" icon={<TeamOutlined />} onClick={() => navigate('/customers')}>
                Customers
            </Item>
            <Item key="settings" icon={<SettingOutlined />} onClick={() => navigate('/settings/users')}>
                Settings
            </Item>
        </Menu>
    )
}

export default NavbarMenu
