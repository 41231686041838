import React from 'react'
import { gql } from '@apollo/client'
import { Button } from 'antd'
import UnitValue from '../../util/UnitValue'
import CurrencyValue from '../../util/CurrencyValue'

const FRAGMENT = gql`
    fragment TradePageBlotter on TradeSummary {
        open
        currentProfit {
            eur
            usd
        }
        profit {
            eur
            usd
        }
        brokerPosition {
            eur
            usd
        }
    }
`

const TradePageBlotter = ({
    loading,
    tradeSummary,
    onFixOpenQuantity,
}) => {
    const {
        open,
        profit,
        currentProfit,
        brokerPosition,
    } = tradeSummary
    if (loading) {
        return (
            <table>
                <tbody>
                    <tr className="border-b">
                        <td className="text-gray-500 px-4 py-2 border-r">
                            Open
                        </td>
                        <td />
                        <td className="text-center">
                            <Button
                                disabled
                                onClick={onFixOpenQuantity}
                                size="small"
                                className="w-20"
                            >
                                Fix
                            </Button>
                        </td>
                    </tr>
                    <tr className="border-b ">
                        <td className="text-gray-500 px-4 py-2 border-r">
                            Current Profit
                        </td>
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td className="text-sm text-gray-500 px-4 py-2 border-r">
                            Profit
                        </td>
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td className="text-sm text-gray-500 px-4 py-2 border-r">
                            Broker position
                        </td>
                        <td />
                        <td />
                    </tr>
                </tbody>
            </table>
        )
    }
    return (
        <table>
            <tbody>
                <tr className="border-b">
                    <td className="text-gray-500 px-4 py-2 border-r">
                        Open
                    </td>
                    <td className="px-4 py-2">
                        <UnitValue
                            isBold
                            value={open}
                            unitCode="kg"
                            isPositive={open > 0}
                            isNegative={open < 0}
                        />
                        <span className="ml-2 text-sm text-gray-300">
                            (<UnitValue
                                isBold
                                value={open}
                                unitCode="oz"
                                isPositive={open > 0}
                                isNegative={open < 0}
                            />)
                        </span>
                    </td>
                    <td className="text-center">
                        <Button
                            disabled={open === 0}
                            size="small"
                            className="w-20"
                            onClick={onFixOpenQuantity}
                        >
                            Fix
                        </Button>
                    </td>
                </tr>
                <tr className="border-b ">
                    <td className="text-gray-500 px-4 py-2 border-r">
                        Current Profit
                    </td>
                    <td className=" px-4 py-2">
                        <CurrencyValue
                            isBold
                            value={currentProfit.eur}
                            currencyCode="EUR"
                            displayCurrencyCode="EUR"
                            isPositive={currentProfit.eur > 0}
                            isNegative={currentProfit.eur < 0}
                        />
                    </td>
                    <td className=" px-4 py-2">
                        <CurrencyValue
                            isBold
                            value={currentProfit.usd}
                            currencyCode="USD"
                            displayCurrencyCode="USD"
                            isPositive={currentProfit.usd > 0}
                            isNegative={currentProfit.usd < 0}
                        />
                    </td>
                </tr>
                <tr className="border-b">
                    <td className="text-sm text-gray-500 px-4 py-2 border-r">
                        Profit
                    </td>
                    <td className=" px-4 py-2">
                        <CurrencyValue
                            isBold
                            value={profit.eur}
                            currencyCode="EUR"
                            displayCurrencyCode="EUR"
                            isPositive={profit.eur > 0}
                            isNegative={profit.eur < 0}
                        />
                    </td>
                    <td className=" px-4 py-2">
                        <CurrencyValue
                            isBold
                            value={profit.usd}
                            currencyCode="USD"
                            displayCurrencyCode="USD"
                            isPositive={profit.usd > 0}
                            isNegative={profit.usd < 0}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="text-sm text-gray-500 px-4 py-2 border-r">
                        Broker position
                    </td>
                    <td className="px-4 py-2">
                        <UnitValue
                            isBold
                            value={brokerPosition.eur}
                            unitCode="oz"
                            displayUnitCode="oz (EUR)"
                            isPositive={brokerPosition.eur > 0}
                            isNegative={brokerPosition.eur < 0}
                        />
                    </td>
                    <td className="px-4 py-2">
                        <UnitValue
                            isBold
                            value={brokerPosition.usd}
                            unitCode="oz"
                            displayUnitCode="oz (USD)"
                            isPositive={brokerPosition.usd > 0}
                            isNegative={brokerPosition.usd < 0}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

TradePageBlotter.FRAGMENT = FRAGMENT

export default TradePageBlotter
