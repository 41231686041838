import { gql, useMutation } from '@apollo/client'

const PROCESS_HEDGE_AUTH_CODE_MUTATION = gql`
    mutation processHedgeAuthCode($code: String!) {
        processHedgeAuthCode(code: $code)
    }
`

const useProcessHedgeAuthCodeMutation = (options) => (
    useMutation(PROCESS_HEDGE_AUTH_CODE_MUTATION, options)
)

export default useProcessHedgeAuthCodeMutation
