import React from 'react'
import PageError from '../../layout/PageError'
import PageLoading from '../../layout/PageLoading'
import PricesPage from './PricesPage'
import useFetchPricesPageData from './useFetchPricesPageData'

const activeMenuItem = 'prices'

const PricesPageWithState = () => {
    const {
        error,
        isFetching,
        assets,
    } = useFetchPricesPageData()

    if (isFetching) {
        return <PageLoading activeMenuItem={activeMenuItem} />
    }
    if (typeof error !== 'undefined') {
        return <PageError activeMenuItem={activeMenuItem} />
    }

    return (
        <PricesPage
            assets={assets}
            activeMenuItem={activeMenuItem}
        />
    )
}

export default PricesPageWithState
