import React from 'react'
import { Select, DatePicker } from 'antd'

const { Option, OptGroup } = Select

export const THIS_MONTH = 'THIS_MONTH'
export const LAST_MONTH = 'LAST_MONTH'
export const NEXT_MONTH = 'NEXT_MONTH'
export const JANUARY = 0
export const FEBRUARY = 1
export const MARCH = 2
export const APRIL = 3
export const MAY = 4
export const JUNE = 5
export const JULY = 6
export const AUGUST = 7
export const SEPTEMBER = 8
export const OCTOBER = 9
export const NOVEMBER = 10
export const DECEMBER = 11

const TradePageDateSelect = ({
    loading,
    selectedMonth,
    selectedYear,
    onChangeMonth,
    onChangeYear,
    ...props
}) => (
    <div {...props}>
        <Select
            disabled={loading}
            style={{ width: 350 }}
            placeholder="Month"
            onChange={onChangeMonth}
            value={selectedMonth}
        >
            <OptGroup label="Quick links">
                <Option value={LAST_MONTH}>Last Month</Option>
                <Option value={THIS_MONTH}>This Month</Option>
                <Option value={NEXT_MONTH}>Next Month</Option>
            </OptGroup>
            <OptGroup label="All months">
                <Option value={JANUARY}>January</Option>
                <Option value={FEBRUARY}>February</Option>
                <Option value={MARCH}>March</Option>
                <Option value={APRIL}>April</Option>
                <Option value={MAY}>May</Option>
                <Option value={JUNE}>June</Option>
                <Option value={JULY}>July</Option>
                <Option value={AUGUST}>August</Option>
                <Option value={SEPTEMBER}>September</Option>
                <Option value={OCTOBER}>October</Option>
                <Option value={NOVEMBER}>November</Option>
                <Option value={DECEMBER}>December</Option>
            </OptGroup>
        </Select>
        <DatePicker
            value={selectedYear}
            disabled={loading || selectedMonth === THIS_MONTH || selectedMonth === LAST_MONTH}
            onChange={onChangeYear}
            picker="year"
            allowClear={false}
        />
    </div>
)

export default TradePageDateSelect
