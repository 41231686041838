import React from 'react'
import moment from 'moment'
import { Table, Button } from 'antd'
import { convertQuantity } from '../../../../../util/converters'
import TableActions from '../../../../util/TableActions'
import PreferredUnits from '../../../../util/PreferredUnits'
import CurrencyValue from '../../../../util/CurrencyValue'

const tableHeight = 240

const CustomerOrdersTable = ({
    isLoading,
    orders,
    quantityAvailable,
    onFill,
    onUnFill,
    emptyText,
}) => (
    <PreferredUnits>
        {({ preferredUnitCode }) => (
            <Table
                className="h-60"
                rowKey="id"
                size="small"
                scroll={{ y: tableHeight }}
                loading={isLoading}
                pagination={false}
                columns={[{
                    key: 'createdAt',
                    title: 'Date',
                    dataIndex: 'createdAt',
                    render: (createdAt) => moment(createdAt).format('DD/MM HH:mm'),
                    width: 110,
                }, {
                    key: 'reference',
                    title: 'Ref.',
                    dataIndex: 'reference',
                    width: 50,
                }, {
                    key: 'outstandingQuantity',
                    title: 'Outstanding',
                    dataIndex: 'outstandingQuantity',
                    width: 250,
                    render: (outstandingQuantity, order) => (
                        <>
                            <strong>{convertQuantity(outstandingQuantity, preferredUnitCode)}</strong>
                            {' / '}
                            {convertQuantity(order.quantity, preferredUnitCode)}
                            {preferredUnitCode}
                        </>
                    ),
                }, {
                    key: 'price',
                    title: 'Price',
                    dataIndex: 'price',
                    render: (price, record) => (
                        <CurrencyValue
                            value={price}
                            currencyCode={record.currencyCode}
                        />
                    ),
                }, {
                    key: 'action',
                    align: 'right',
                    width: 110,
                    render: (record) => {
                        const recordIsFilled = record.outstandingQuantity === record.quantity
                        const recordIsPartiallyDeliveredAndFilled = record.originalOutstandingQuantity !== null && record.originalOutstandingQuantity === record.outstandingQuantity

                        return (
                            <TableActions>
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => onFill(record)}
                                    disabled={record.outstandingQuantity === 0 || quantityAvailable === 0}
                                >
                                    Fill
                                </Button>
                                <Button
                                    type="secondary"
                                    size="small"
                                    onClick={() => onUnFill(record)}
                                    disabled={recordIsFilled || recordIsPartiallyDeliveredAndFilled}
                                >
                                    Unfill
                                </Button>
                            </TableActions>
                        )
                    },
                }]}
                dataSource={orders}
                locale={{ emptyText }}
                rowClassName={({ quantity, outstandingQuantity }) => {
                    if (outstandingQuantity === 0) {
                        return 'text-green-400'
                    } if (outstandingQuantity < quantity && outstandingQuantity !== 0) {
                        return 'text-yellow-400'
                    }
                    return ''
                }}
            />
        )}
    </PreferredUnits>
)

export default CustomerOrdersTable
