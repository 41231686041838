import { useReducer } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import clonedeep from 'lodash.clonedeep'

const CLEAR_FILTERS = 'CLEAR_FILTERS'
const UPDATE_FILTERS = 'UPDATE_FILTERS'

const INITIAL_STATE = {
    orderId: null,
    dateFilter: null,
    rangeFilter: null,
    customer: null,
}

const reducer = (state, action) => {
    const { type } = action
    switch (type) {
        case CLEAR_FILTERS: {
            const { initialState } = action
            return ({
                ...INITIAL_STATE,
                ...initialState,
            })
        }
        case UPDATE_FILTERS: {
            const { filter } = action
            return ({
                ...state,
                ...filter,
            })
        }
        default:
            throw new Error(`Unknown action ${action.type}`)
    }
}

const useFilterState = (initialState = {}) => {
    const [state, dispatch] = useReducer(reducer, {
        ...INITIAL_STATE,
        ...initialState,
    })

    useDeepCompareEffect(() => {
        const initialStateClone = clonedeep(initialState)
        dispatch({
            type: UPDATE_FILTERS,
            filter: {
                ...INITIAL_STATE,
                ...initialStateClone,
            },
        })
    }, [initialState])

    const handleClearFilters = () => {
        dispatch({
            type: CLEAR_FILTERS,
            initialState,
        })
    }

    const handleChangeFilter = (filter) => {
        dispatch({
            type: UPDATE_FILTERS,
            filter,
        })
    }

    return {
        filters: state,
        handleClearFilters,
        handleChangeFilter,
    }
}

export default useFilterState
