import { gql } from '@apollo/client'

export const HEALTH_QUERY = gql`
    query health {
        health {
            adminAppIsAvailable
        },
    }
`

export default async function getAppHealth(apolloClient) {
    const query = HEALTH_QUERY
    const { data } = await apolloClient.query({
        query,
    })
    return data
}
