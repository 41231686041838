import { gql, useMutation } from '@apollo/client'

const HEDGE_LOGOUT_MUTATION = gql`
    mutation hedgeLogout {
        hedgeLogout
    }
`

const useHedgeLogoutMutation = (options) => (
    useMutation(HEDGE_LOGOUT_MUTATION, options)
)

export default useHedgeLogoutMutation
