import React from 'react'
import Main from '../layout/Main'
import Navbar from '../layout/Navbar'
import Loading from '../util/Loading'
import SettingsSidebarMenu from '../layout/SettingsSidebarMenu'
import ContainerWithSidebar from '../layout/ContainerWithSidebar'

const CustomersPage = ({
    activeMenuItem,
    isLoading,
    children,
}) => (
    <>
        <Navbar activeMenuItem="settings" />
        <Main>
            <ContainerWithSidebar
                sidebar={(
                    <SettingsSidebarMenu
                        activeMenuItem={activeMenuItem}
                    />
                )}
            >
                {(() => {
                    if (isLoading) {
                        return (
                            <div>
                                <Loading size={50} />
                            </div>
                        )
                    }
                    return children
                })()}
            </ContainerWithSidebar>
        </Main>
    </>
)

export default CustomersPage
