import { gql, useMutation } from '@apollo/client'

const DELETE_TRADE_MUTATION = gql`
    mutation deleteTradeMutation($id: Int!) {
        deleteTrade(id: $id)
    }
`

const useDeleteTradeMutation = (options) => (
    useMutation(DELETE_TRADE_MUTATION, options)
)

export default useDeleteTradeMutation
