import { gql } from '@apollo/client'
import CUSTOMER_USER_FRAGMENT from '../components/fragments/CustomerUserFragment'

const UPDATE_CUSTOMER_USER = gql`
    mutation updateCustomerUser($id: Int!, $input: CustomerUserInput!) {
        updateCustomerUser(id: $id, input: $input) {
            user {
                ...CustomerUser
            }
        }
    }
    ${CUSTOMER_USER_FRAGMENT}
`

export default async function updateCustomerUser(apolloClient, id, input) {
    const mutation = UPDATE_CUSTOMER_USER
    const variables = { id, input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
    })
    return response
}
