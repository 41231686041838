import { gql, useMutation } from '@apollo/client'

const CREATE_HEDGE_TRADE_MUTATION = gql`
    mutation createMarketOrder($input: MarketOrderInput!) {
        createMarketOrder(input: $input) {
            marketOrder {
                id
                spotPrice
                quantity
                currencyCode
                createdAt
            }
        }
    }
`

const useCreateHedgeTradeMutation = (options) => (
    useMutation(CREATE_HEDGE_TRADE_MUTATION, options)
)

export default useCreateHedgeTradeMutation
