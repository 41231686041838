import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

const Loading = ({
    size = 32,
}) => (
    <div className="flex flex-col justify-center items-center">
        <LoadingOutlined
            className="text-primary"
            style={{
                fontSize: `${size}px`,
            }}
        />
    </div>
)

export default Loading
