import React from 'react'
import moment from 'moment'
import { Table } from 'antd'
import { convertQuantity } from '../../../../../util/converters'
import PreferredUnits from '../../../../util/PreferredUnits'
import InfiniteScroll from '../../../../util/InfiniteScroll'

const columns = [{
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'center',
    render: (quantity) => (
        <PreferredUnits>
            {({ preferredUnitCode }) => (
                `${convertQuantity(quantity, preferredUnitCode)}${preferredUnitCode}`
            )}
        </PreferredUnits>
    ),
    width: 90,
}, {
    title: 'Asset',
    dataIndex: 'asset',
    align: 'center',
    render: (asset) => asset.name,
    width: 90,
}, {
    title: 'Date',
    dataIndex: 'createdAt',
    align: 'center',
    render: (createdAt) => moment(createdAt).format('DD/MM/YY'),
    width: 90,
}]

const PickupsTable = ({
    pickups,
    hasMore,
    onFetchMore,
    isLoading,
    isFetchingMore,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        hasMore={hasMore}
        onFetchMore={onFetchMore}
    >
        <Table
            rowKey="id"
            columns={columns}
            loading={isLoading}
            dataSource={pickups}
            pagination={false}
        />
    </InfiniteScroll>
)

export default PickupsTable
