import React from 'react'
import { Input, Form } from 'antd'
import FormItem from '../../util/form/FormItem'

const CustomerForm = ({ formstate }) => {
    const {
        getNativeInputProps,
        antdErrors,
    } = formstate
    return (
        <Form layout="vertical">
            <FormItem
                label="Name"
                error={antdErrors.get('name')}
            >
                <Input
                    {...getNativeInputProps('name')}
                />
            </FormItem>
        </Form>
    )
}

export default CustomerForm
