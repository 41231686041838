import React from 'react'
import { Modal } from 'antd'
import { whereId } from '../../util'
import CreateOrderForm from './CreateOrderForm'
import CreateOrderAuthenticateForm from './CreateOrderAuthenticateForm'

const CreateOrderModal = ({
    units,
    assets,
    currencies,
    formState,
    isOpen,
    isFetching,
    isCreating,
    isAuthenticating,
    onClose,
    onCancel,
    onConfirm,
    onSubmitOrder,
}) => {
    const asset = assets.find(whereId(formState.values.assetId))
    return (
        <Modal
            title="Create new order"
            visible={isOpen}
            width={750}
            closable={!isCreating}
            maskClosable={!isCreating}
            confirmLoading={isCreating}
            footer={null}
            onCancel={onClose}
        >
            {isAuthenticating ? (
                <CreateOrderAuthenticateForm
                    formState={formState}
                    isLoading={isCreating}
                    assetName={asset.name}
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                />
            ) : (
                <CreateOrderForm
                    units={units}
                    asset={asset}
                    assets={assets}
                    isFetching={isFetching}
                    currencies={currencies}
                    formState={formState}
                    onSubmit={onSubmitOrder}
                />
            )}
        </Modal>
    )
}

export default CreateOrderModal
