import React from 'react'
import {
    Table, InputNumber, Input,
} from 'antd'
import { getDecimalSeparator } from '../../../../util/currency'
import { getInputStep } from '../../../../util/units'

const ImpuritiesTable = ({
    formState,
    formattingStyle = 'european',
}) => {
    const {
        getNativeInputProps,
        getInputProps,
        values,
    } = formState
    const { impurities } = values
    const decimalSeparator = getDecimalSeparator(formattingStyle)

    if (!impurities.length) {
        return null
    }

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            width: '10%',
        }, {
            title: 'Name',
            dataIndex: 'name',
            render: (name, impurity, index) => (
                <Input
                    {...getNativeInputProps(`impurities.${index}.name`)}
                />
            ),
        }, {
            title: 'Discount',
            dataIndex: 'discount',
            render: (discount, impurity, index) => {
                const { unitCode } = impurity
                return (
                    <InputNumber
                        {...getInputProps(`impurities.${index}.discount`)}
                        min={0}
                        step={getInputStep(unitCode)}
                        precision={5}
                        decimalSeparator={decimalSeparator}
                        className="w-full"
                    />
                )
            },
        }, {
            title: 'Percentage',
            dataIndex: 'percentage',
            render: (percentage, priceAdjustment, index) => (
                <InputNumber
                    {...getInputProps(`impurities.${index}.percentage`)}
                    min={0}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    decimalSeparator={decimalSeparator}
                    className="w-full"
                />
            ),

        },
    ]

    return (
        <Table
            rowKey="id"
            columns={columns}
            pagination={false}
            dataSource={impurities}
        />
    )
}

export default ImpuritiesTable
