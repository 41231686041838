import useFormState from '../../hooks/useFormState'
import { PRICE_ADJUSTMENT_ACCURACY } from '../../../util/constants'
import {
    toCode, priceAdjustmentsToForm, priceAdjustmentsToDb, computePriceAdjustmentsFromValues, assetFormvalidation,
} from '../update-asset/useUpdateAssetForm'

const customerAssetToFormValues = (customerAsset) => {
    const {
        asset = {}, currencies = [], units = [], priceAdjustments = [],
    } = customerAsset
    return {
        name: asset.name,
        code: asset.code,
        canBuy: customerAsset.canBuy,
        canSell: customerAsset.canSell,
        currencies: currencies.map(toCode),
        units: units.map(toCode),
        priceAdjustments: priceAdjustmentsToForm(priceAdjustments),
    }
}

const formValuesToInput = ({
    canSell,
    canBuy,
    priceAdjustments,
}) => ({
    canSell,
    canBuy,
    priceAdjustments: priceAdjustmentsToDb(priceAdjustments),
})

const useUpdateCustomerAssetForm = (customerAsset, options) => {
    const formState = useFormState(
        customerAssetToFormValues(customerAsset),
        {
            validation: assetFormvalidation,
            valuesToInput: formValuesToInput,
            ...options,
        },
    )

    const handleCurrencyChange = (newCurrencies) => {
        const newPriceAdjustments = computePriceAdjustmentsFromValues({
            units: formState.values.units,
            currencies: newCurrencies,
            priceAdjustments: formState.values.priceAdjustments,
        })
        formState.handleChange('currencies', newCurrencies)
        formState.handleChange('priceAdjustments', newPriceAdjustments)
    }

    const handleUnitChange = (newUnits) => {
        const newPriceAdjustments = computePriceAdjustmentsFromValues({
            units: newUnits,
            currencies: formState.values.currencies,
            priceAdjustments: formState.values.priceAdjustments,
        })
        formState.handleChange('units', newUnits)
        formState.handleChange('priceAdjustments', newPriceAdjustments)
    }

    const handleAddDiscountPremium = (index, customerPriceAdjustment) => {
        const { priceAdjustments } = formState.values
        priceAdjustments[index] = {
            ...customerPriceAdjustment,
            discount: customerPriceAdjustment.priceAdjustment.discount / PRICE_ADJUSTMENT_ACCURACY,
            premium: customerPriceAdjustment.priceAdjustment.premium / PRICE_ADJUSTMENT_ACCURACY,
        }
        formState.handleChange('priceAdjustments', priceAdjustments)
    }

    const handleRemoveDiscountPremium = (index, customerPriceAdjustment) => {
        const { priceAdjustments } = formState.values
        priceAdjustments[index] = {
            ...customerPriceAdjustment,
            discount: null,
            premium: null,
        }
        formState.handleChange('priceAdjustments', priceAdjustments)
    }

    const handleAddStepRounding = (index, customerPriceAdjustment) => {
        const { priceAdjustments } = formState.values
        priceAdjustments[index] = {
            ...customerPriceAdjustment,
            step: customerPriceAdjustment.priceAdjustment.step / PRICE_ADJUSTMENT_ACCURACY,
            rounding: customerPriceAdjustment.priceAdjustment.rounding,
        }
        formState.handleChange('priceAdjustments', priceAdjustments)
    }

    const handleRemoveStepRounding = (index, customerPriceAdjustment) => {
        const { priceAdjustments } = formState.values
        priceAdjustments[index] = {
            ...customerPriceAdjustment,
            step: null,
            rounding: null,
        }
        formState.handleChange('priceAdjustments', priceAdjustments)
    }

    return {
        ...formState,
        handleCurrencyChange,
        handleUnitChange,
        handleAddDiscountPremium,
        handleRemoveDiscountPremium,
        handleAddStepRounding,
        handleRemoveStepRounding,
    }
}

export default useUpdateCustomerAssetForm
