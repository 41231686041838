import React from 'react'
import clsx from 'clsx'
import { WalletOutlined } from '@ant-design/icons'
import CurrencyValue from '../../util/CurrencyValue'

const MoneyWalletCard = ({
    wallet,
    className,
}) => {
    const {
        value,
        currencyCode,
    } = wallet
    return (
        <div
            className={
                clsx(
                    'py-4 px-6 mr-4 border-1 rounded-lg',
                    className,
                )
            }
        >
            <div className="flex items-center mb-4">
                <WalletOutlined
                    className="mr-2 text-3xl"
                />
                <h5 className="font-black text-2xl">{currencyCode}</h5>
            </div>
            <div>
                <h3 className="font-black text-3xl">
                    <CurrencyValue
                        isNegative={value < 0}
                        isPositive={value > 0}
                        currencyCode={currencyCode}
                        value={Math.abs(value)}
                    />
                </h3>
            </div>
        </div>
    )
}

export default MoneyWalletCard
