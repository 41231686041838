import { createFormValidation } from 'use-form-state'
import useFormState from '../../../hooks/useFormState'
import * as validation from '../../../../validation'

const INITIAL_VALUES = {
    orderDeliveries: [],
}

export const orderDeliveriesValidation = createFormValidation([{
    path: 'orderDeliveries',
    validate: (orderDeliveries) => validation.isLongerThan(0)(orderDeliveries),
    message: 'Please select at least one delivery',
}])

const useCreateOrderDeliveriesForm = (values, options) => useFormState(
    {
        ...INITIAL_VALUES,
        ...values,
    },
    {
        validation: orderDeliveriesValidation,
        ...options,
    },
)

export default useCreateOrderDeliveriesForm
