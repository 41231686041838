import React from 'react'
import Navbar from '../../layout/Navbar'
import Main from '../../layout/Main'
import InfiniteScroll from '../../util/InfiniteScroll'
import CustomersTable from './table/CustomersTable'
import CustomersTableTopBar from './table/CustomersTableTopBar'

const CustomersPage = ({
    hasMore,
    customers,
    loading,
    isFetchingMore,
    handleFetchMore,
    onCreateCustomerClick,
}) => (
    <>
        <Navbar activeMenuItem="customers" />
        <Main>
            <CustomersTableTopBar
                onCreateCustomerClick={onCreateCustomerClick}
            />
            <InfiniteScroll
                isFetchingMore={isFetchingMore}
                hasMore={hasMore}
                onFetchMore={handleFetchMore}
            >
                <CustomersTable
                    isLoading={loading}
                    customers={customers}
                />
            </InfiniteScroll>
        </Main>
    </>
)

export default CustomersPage
