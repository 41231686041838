import { gql } from '@apollo/client'
import CUSTOMER_ASSET_FRAGMENT from '../components/fragments/CustomerAssetFragment'

const SUSPEND_CUSTOMER_ASSET = gql`
    mutation toggleSuspendCustomerAsset($id: Int!) {
        toggleSuspendCustomerAsset(id: $id) {
            customerAsset {
                ...CustomerAsset
            }
        }
    }
    ${CUSTOMER_ASSET_FRAGMENT}
`

export default async function toggleSuspendCustomerAsset(apolloClient, id, options) {
    const mutation = SUSPEND_CUSTOMER_ASSET
    const variables = { id }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
