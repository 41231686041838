import React from 'react'
import Main from '../../layout/Main'
import Navbar from '../../layout/Navbar'
import InfiniteScroll from '../../util/InfiniteScroll'
import TradePageTable from './TradePageTable'
import TradePageTopBar from './TradePageTopBar'

const TradePage = ({
    hasMore,
    activeMenuItem,
    assetId,
    selectedMonth,
    selectedYear,
    trades,
    loading,
    handleFetchMore,
    tradeSummary,
    isFetchingMore,
    isTableExpanded,
    onExportTrades,
    onCreateTrade,
    onCreateHedgeTrade,
    onChangeMonth,
    onChangeYear,
    onCloseMonth,
    onDeleteTrade,
    onUpdateTrade,
    onFixOpenQuantity,
    onToggleTableExpand,
}) => (
    <>
        <Navbar activeMenuItem={activeMenuItem} />
        <TradePageTopBar
            trades={trades}
            assetId={assetId}
            loading={loading}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            isTableExpanded={isTableExpanded}
            tradeSummary={tradeSummary}
            onExportTrades={onExportTrades}
            onToggleTableExpand={onToggleTableExpand}
            onCreateTrade={onCreateTrade}
            onCreateHedgeTrade={onCreateHedgeTrade}
            onChangeMonth={onChangeMonth}
            onChangeYear={onChangeYear}
            onCloseMonth={onCloseMonth}
            onFixOpenQuantity={onFixOpenQuantity}
        />
        <Main maxWidth={isTableExpanded ? 'max-w-container-lg' : 'max-w-container'}>
            <InfiniteScroll
                isFetchingMore={isFetchingMore}
                hasMore={hasMore}
                onFetchMore={handleFetchMore}
            >
                <TradePageTable
                    loading={loading}
                    trades={trades}
                    onDeleteTrade={onDeleteTrade}
                    onUpdateTrade={onUpdateTrade}
                />
            </InfiniteScroll>
        </Main>
    </>
)

export default TradePage
