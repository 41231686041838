import React from 'react'
import { Row, Col } from 'antd'
import CustomerTopBar from '../../layout/CustomerTopBar'
import BalanceCard from './BalanceCard'
import AssetWalletCard from './AssetWalletCard'
import MoneyWalletCard from './MoneyWalletCard'

const CustomerBalancePage = ({
    customer,
}) => {
    const {
        balance,
        hasTradeLimit,
        fixedTradeLimit,
    } = customer

    return (
        <>
            <CustomerTopBar customerName={customer.name} />
            <div className="flex justify-center mb-6">
                <BalanceCard
                    balance={balance}
                    hasTradeLimit={hasTradeLimit}
                    fixedTradeLimit={fixedTradeLimit}
                />
            </div>
            <div className="mb-6">
                <h2 className="text-center mb-4">Metal Wallets</h2>
                <Row gutter={16}>
                    {balance.assetWallets.map((assetWallet) => (
                        <Col
                            md={12}
                            key={assetWallet.asset.id}
                        >
                            <AssetWalletCard
                                wallet={assetWallet}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
            <div className="mb-6">
                <h2 className="text-center mb-4">Money Wallets</h2>
                <Row gutter={16}>
                    {balance.moneyWallets.map((moneyWallet) => (
                        <Col
                            md={12}
                            key={moneyWallet.currencyCode}
                        >
                            <MoneyWalletCard
                                wallet={moneyWallet}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    )
}

export default CustomerBalancePage
