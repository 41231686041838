import React from 'react'
import { Drawer, Button } from 'antd'

const DrawerWithActions = ({
    onSubmit,
    onCancel,
    actions,
    isLoading,
    children,
    ...drawerProps
}) => (
    <Drawer
        {...drawerProps}
    >
        <div className="mb-16">
            { children }
        </div>
        <div className="absolute w-full bottom-0 left-0 py-3 px-4 border-t-1 border-gray-200 rounded-sm bg-white flex flex-wrap justify-end -mb-2">
            <div className="ml-2 mb-2">
                <Button className="button" onClick={onCancel}>
                    Cancel
                </Button>
            </div>
            <div className="action">
                <Button className="button" onClick={onSubmit} type="primary" loading={isLoading}>
                    Submit
                </Button>
            </div>
        </div>
    </Drawer>
)

export default DrawerWithActions
