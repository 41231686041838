import React from 'react'
import { Popconfirm } from 'antd'
import {
    StopOutlined,
    StockOutlined,
} from '@ant-design/icons'
import HasPermissions from '../../../util/HasPermissions'

const getPopconfirmMessage = (suspended) => {
    if (suspended) {
        return 'Are you sure you want to allow this asset for trading?'
    }
    return 'Are you sure you want to suspend this asset from trading?'
}

const AssetSuspendButton = ({
    suspended,
    onClick,
}) => (
    <HasPermissions roles={[HasPermissions.ROLES.ADMIN]}>
        {(hasPermission) => {
            if (!hasPermission) {
                if (suspended) {
                    return <StockOutlined style={{ cursor: 'not-allowed' }} />
                }
                return (
                    <StopOutlined style={{ cursor: 'not-allowed' }} />
                )
            }
            return (
                <Popconfirm
                    title={getPopconfirmMessage(suspended)}
                    onConfirm={onClick}
                    okText="Yes"
                    cancelText="No"
                >
                    {(() => {
                        if (suspended) {
                            return <StockOutlined />
                        }
                        return (
                            <StopOutlined />
                        )
                    })()}
                </Popconfirm>
            )
        }}
    </HasPermissions>
)

export default AssetSuspendButton
