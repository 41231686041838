import { gql } from '@apollo/client'

const DELETE_CUSTOMER_ASSET = gql`
    mutation deleteCustomerAsset($id: Int!) {
        deleteCustomerAsset(id: $id)
    }
`

export default async function deleteCustomerAsset(apolloclient, asset) {
    const mutation = DELETE_CUSTOMER_ASSET
    const variables = { id: asset.id }
    const response = await apolloclient.mutate({
        mutation,
        variables,
    })
    return response
}
