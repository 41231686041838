import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
    PAGE_INFO_FRAGMENT,
    mergeQueryResults,
    createQueryUpdater,
    connectionToCollection,
} from '../../../util/graphql'
import CUSTOMER_FRAGMENT from '../../fragments/CustomerFragment'

export const CUSTOMERS_PAGE_QUERY = gql`
    query customersPageQuery(
        $after: String,
        $first: Int,
        $orderBy: CustomerOrderByInput
    ) {
        customersPaginated(after: $after, first: $first, orderBy: $orderBy) {
            edges {
                node {
                    ...Customer
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${CUSTOMER_FRAGMENT}
`

const useFetchCustomersPageData = () => {
    const [isFetchingMore, setIsFetchingMore] = useState(false)
    const {
        data = {},
        loading,
        fetchMore,
        error,
        ...result
    } = useQuery(CUSTOMERS_PAGE_QUERY, {
        variables: {
            orderBy: { name: 'ASC' },
        },
    })

    let customers = []
    let hasMore = false
    if (!loading && typeof data.customersPaginated !== 'undefined') {
        customers = connectionToCollection(data.customersPaginated)
        hasMore = data.customersPaginated.pageInfo.hasNextPage
    }

    const handleFetchMore = () => {
        setIsFetchingMore(true)
        const refetchAfter = data.customersPaginated.pageInfo.endCursor
        fetchMore({
            variables: { after: refetchAfter },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                setIsFetchingMore(false)
                return mergeQueryResults(previousResult, fetchMoreResult, 'customersPaginated')
            },
        })
    }

    return {
        hasMore,
        customers,
        loading,
        handleFetchMore,
        isFetchingMore,
        error,
        ...result,
    }
}

export const updateCustomersQuery = createQueryUpdater(CUSTOMERS_PAGE_QUERY)

export default useFetchCustomersPageData
