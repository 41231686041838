import { connect } from 'unistore/react'

export default function createModalState(modalName, initialValues = {}) {
    const actions = (store) => ({
        open: (state, updatedValues) => ({
            openModals: {
                ...state.openModals,
                [modalName]: updatedValues || initialValues,
            },
        }),
        close: (state) => {
            const { [modalName]: modalToRemove, ...remainingModals } = state.openModals
            return { openModals: remainingModals }
        },
    })
    return connect('openModals', actions)(
        ({
            openModals, open, close, children,
        }) => (
            children({
                isOpen: !!openModals[modalName],
                open,
                close,
                initialValues: openModals[modalName],
            })
        ),
    )
}
