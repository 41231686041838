import React from 'react'
import { Modal, Button } from 'antd'

const ConfirmModal = ({
    title,
    onConfirm,
    onCancel,
    confirmText = "Yes",
    cancelText = "No",
    loading,
    children,
    cancelDisabled,
    confirmDisabled,
    isOpen,
    close,
}) => (
    <Modal
        title={title}
        visible={isOpen}
        closable={!loading && !cancelDisabled}
        maskClosable={!loading}
        confirmLoading={loading}
        onCancel={close}
        footer={[
            <Button
                key="back"
                loading={loading}
                onClick={() => {
                    onCancel && onCancel()
                    close()
                }}
                disabled={cancelDisabled}
            >
                {cancelText}
            </Button>,
            <Button
                key="submit"
                type="primary"
                onClick={() => {
                    onConfirm()
                    close()
                }}
                disabled={confirmDisabled}
            >
                {confirmText}
            </Button>,
        ]}
    >
        { children }
    </Modal>
)

export default ConfirmModal
