import React from 'react'
import { List } from 'antd'
import HasPermissions from '../../../util/HasPermissions'
import AssetCard from './AssetCard'
import AssetEditButton from './AssetEditButton'
import AssetSuspendButton from './AssetSuspendButton'
import AssetAvailabilityButton from './AssetAvailabilityButton'

const AssetsList = ({
    assets,
    onEditAsset,
    onSuspendAsset,
    onToggleAvailableAsset,
}) => (
    <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={assets}
        renderItem={(item) => (
            <List.Item>
                <HasPermissions roles={[HasPermissions.ROLES.ADMIN]}>
                    {(hasPermission) => {
                        const actions = hasPermission ? [
                            <AssetEditButton
                                asset={item}
                                onClick={onEditAsset}
                            />,
                            <AssetSuspendButton
                                suspended={item.suspended}
                                onClick={() => onSuspendAsset(item)}
                            />,
                            <AssetAvailabilityButton
                                available={item.available}
                                onClick={() => onToggleAvailableAsset(item)}
                            />,
                        ] : []
                        return (
                            <AssetCard
                                asset={item}
                                actions={actions}
                            />
                        )
                    }}
                </HasPermissions>
            </List.Item>
        )}
    />
)

export default AssetsList
