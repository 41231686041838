import React from 'react'
import UserLogItem from './UserLogItem'

const UserUpdatedItem = ({
    appEvent
}) => (
    <UserLogItem
        createdAt={appEvent.createdAt}
        user={appEvent.user}
    >
        User <strong>{appEvent.payload.email}</strong> updated.
    </UserLogItem>
)

export default UserUpdatedItem
