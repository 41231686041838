import React from 'react'
import { List } from 'antd'
import AssetCard from '../../assets/list/AssetCard'
import AssetEditButton from '../../assets/list/AssetEditButton'
import AssetSuspendButton from '../../assets/list/AssetSuspendButton'
import HasPermissions from '../../../util/HasPermissions'
import CustomerAssetDeleteButton from './CustomerAssetDeleteButton'

const toAsset = (customerAsset) => ({
    ...customerAsset,
    name: customerAsset.asset.name,
    code: customerAsset.asset.code,
})

const CustomerAssetsList = ({
    customerAssets,
    onToggleSuspendCustomerAsset,
    onEditCustomerAsset,
    isDeletingCustomerAsset,
    onDeleteCustomerAsset,
}) => (
    <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={customerAssets.map(toAsset)}
        renderItem={(asset) => (
            <List.Item>
                <HasPermissions roles={[HasPermissions.ROLES.ADMIN]}>
                    {(hasPermission) => {
                        const actions = hasPermission ? [
                            <AssetEditButton
                                asset={asset}
                                onClick={onEditCustomerAsset}
                            />,
                            <AssetSuspendButton
                                suspended={asset.suspended}
                                onClick={() => onToggleSuspendCustomerAsset(asset)}
                            />,
                            <CustomerAssetDeleteButton
                                asset={asset}
                                isDeletingCustomerAsset={isDeletingCustomerAsset}
                                onDeleteCustomerAsset={onDeleteCustomerAsset}
                            />,
                        ] : []
                        return (
                            <AssetCard
                                asset={asset}
                                actions={actions}
                            />
                        )
                    }}
                </HasPermissions>
            </List.Item>
        )}
    />
)

export default CustomerAssetsList
