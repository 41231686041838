import React from 'react'

const CustomersPageContentPlaceholder = ({
    children,
}) => (
    <div className="h-[500px] w-full flex items-center justify-center flex-col">
        {children}
    </div>
)

export default CustomersPageContentPlaceholder
