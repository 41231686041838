import React from 'react'
import { gql } from '@apollo/client'
import * as appEventsTypes from '../../../util/appEvents'
import UserCreatedLogItem from './items/user/UserCreatedItem'
import UserUpdatedItem from './items/user/UserUpdatedItem'
import UserDeletedItem from './items/user/UserDeletedItem'
import TimelineItem from './items/timeline/TimelineItem'
import AssetSuspendedItem from './items/asset/AssetSuspendedItem'
import AssetUpdatedItem from './items/asset/AssetUpdatedItem'
import CustomerCreatedItem from './items/customer/CustomerCreatedItem'
import CustomerUpdatedItem from './items/customer/CustomerUpdatedItem'
import CustomerDeletedItem from './items/customer/CustomerDeletedItem'
import DeliveryCreatedItem from './items/transfer/DeliveryCreatedItem'
import PickupCreatedItem from './items/transfer/PickupCreatedItem'
import PaymentCreatedItem from './items/payment/PaymentCreatedItem'
import PaymentDeletedItem from './items/payment/PaymentDeletedItem'
import HedgeCreatedItem from './items/trade/HedgeCreatedItem'
import OrderCreatedItem from './items/trade/OrderCreatedItem'
import CustomerAssetCreatedItem from './items/customer-asset/CustomerAssetCreatedItem'
import CustomerAssetUpdatedItem from './items/customer-asset/CustomerAssetUpdatedItem'
import CustomerAssetDeletedItem from './items/customer-asset/CustomerAssetDeletedItem'
import CustomerAssetSuspendedItem from './items/customer-asset/CustomerAssetSuspendedItem'
import AppSettingsUpdatedItem from './items/appSettings/AppSettingsUpdatedItem'
import HedgeLoggedInItem from './items/hedge/HedgeLoggedInItem'
import HedgeLoggedOutItem from './items/hedge/HedgeLoggedOutItem'
import HedgeConnectionsStoppedItem from './items/hedge/HedgeConnectionsStopped'
import HedgeConnectionsStartedItem from './items/hedge/HedgeConnectionsStartedItem'
import LogTradeCreated from './LogTradeCreated'

const {
    USER_CREATED,
    USER_UPDATED,
    USER_DELETED,
    ASSET_UPDATED,
    ASSET_SUSPENDED,
    CUSTOMER_CREATED,
    CUSTOMER_UPDATED,
    CUSTOMER_DELETED,
    DELIVERY_CREATED,
    PICKUP_CREATED,
    PAYMENT_CREATED,
    PAYMENT_DELETED,
    TRADE_CREATED,
    MARKET_TRADE_CREATED,
    CUSTOMER_TRADE_CREATED,
    CUSTOMER_ASSET_CREATED,
    CUSTOMER_ASSET_UPDATED,
    CUSTOMER_ASSET_DELETED,
    CUSTOMER_ASSET_SUSPENDED,
    APP_SETTINGS_UPDATED,
    HEDGE_AUTHORIZED,
    HEDGE_LOGGED_OUT,
    HEDGE_CONNECTIONS_STARTED,
    HEDGE_CONNECTIONS_STOPPED,
} = appEventsTypes

export const LOG_APP_EVENT_FRAGMENT = gql`
    fragment LogAppEvent on AppEvent {
        id
        createdAt
        type
        payload
        user {
            id
            fullName
        }
        customer {
            id
            name
        }
    }
`

const LogTimelineItem = ({
    assets,
    appEvent,
}) => {
    const { type } = appEvent
    const { payload, ...withoutPayload } = appEvent
    const formattedAppEvent = {
        ...withoutPayload,
        payload: JSON.parse(payload),
    }
    if (type === USER_CREATED) {
        return <UserCreatedLogItem appEvent={formattedAppEvent} />
    }
    if (type === USER_UPDATED) {
        return <UserUpdatedItem appEvent={formattedAppEvent} />
    }
    if (type === USER_DELETED) {
        return <UserDeletedItem appEvent={formattedAppEvent} />
    }
    if (type === ASSET_SUSPENDED) {
        return <AssetSuspendedItem appEvent={formattedAppEvent} />
    }
    if (type === ASSET_UPDATED) {
        return <AssetUpdatedItem appEvent={formattedAppEvent} />
    }
    if (type === CUSTOMER_CREATED) {
        return <CustomerCreatedItem appEvent={formattedAppEvent} />
    }
    if (type === CUSTOMER_UPDATED) {
        return <CustomerUpdatedItem appEvent={formattedAppEvent} />
    }
    if (type === CUSTOMER_DELETED) {
        return <CustomerDeletedItem appEvent={formattedAppEvent} />
    }
    if (type === DELIVERY_CREATED) {
        return (
            <DeliveryCreatedItem
                assets={assets}
                appEvent={formattedAppEvent}
            />
        )
    }
    if (type === PICKUP_CREATED) {
        return (
            <PickupCreatedItem
                assets={assets}
                appEvent={formattedAppEvent}
            />
        )
    }
    if (type === PAYMENT_CREATED) {
        return <PaymentCreatedItem appEvent={formattedAppEvent} />
    }
    if (type === PAYMENT_DELETED) {
        return <PaymentDeletedItem appEvent={formattedAppEvent} />
    }
    if (type === CUSTOMER_TRADE_CREATED) {
        return (
            <OrderCreatedItem
                assets={assets}
                appEvent={formattedAppEvent}
            />
        )
    }
    if (type === MARKET_TRADE_CREATED) {
        return (
            <HedgeCreatedItem
                assets={assets}
                appEvent={formattedAppEvent}
            />
        )
    }
    if (type === TRADE_CREATED) {
        return <LogTradeCreated appEvent={formattedAppEvent} assets={assets} />
    }
    if (type === CUSTOMER_ASSET_CREATED) {
        return <CustomerAssetCreatedItem appEvent={formattedAppEvent} />
    }
    if (type === CUSTOMER_ASSET_DELETED) {
        return <CustomerAssetDeletedItem appEvent={formattedAppEvent} />
    }
    if (type === CUSTOMER_ASSET_UPDATED) {
        return <CustomerAssetUpdatedItem appEvent={formattedAppEvent} />
    }
    if (type === CUSTOMER_ASSET_SUSPENDED) {
        return <CustomerAssetSuspendedItem appEvent={formattedAppEvent} />
    }
    if (type === APP_SETTINGS_UPDATED) {
        return <AppSettingsUpdatedItem appEvent={formattedAppEvent} />
    }
    if (type === HEDGE_AUTHORIZED) {
        return <HedgeLoggedInItem appEvent={formattedAppEvent} />
    }
    if (type === HEDGE_LOGGED_OUT) {
        return <HedgeLoggedOutItem appEvent={formattedAppEvent} />
    }
    if (type === HEDGE_CONNECTIONS_STARTED) {
        return <HedgeConnectionsStartedItem appEvent={formattedAppEvent} />
    }
    if (type === HEDGE_CONNECTIONS_STOPPED) {
        return <HedgeConnectionsStoppedItem appEvent={formattedAppEvent} />
    }
    return (
        <TimelineItem
            createdAt={appEvent.createdAt}
            user={appEvent.user}
        />
    )
}

export default LogTimelineItem
