import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { notification } from 'antd'
import toggleSuspendAsset from '../../../api/toggleSuspendAsset'
import toggleAvailableAsset from '../../../api/toggleAvailableAsset'
import useFetchAssetsPageData from './useFetchAssetsPageData'
import AssetsPage from './AssetsPage'
import SettingsPage from '../SettingsPage'
import SettingsPageError from '../../layout/SettingsPageError'

const AssetsPageWithState = () => {
    const navigate = useNavigate()
    const apolloclient = useApolloClient()
    const {
        error,
        isFetching,
        assets,
    } = useFetchAssetsPageData()

    if (typeof error !== 'undefined') {
        return (
            <SettingsPageError
                activeMenuItem="assets"
            />
        )
    }

    return (
        <SettingsPage
            activeMenuItem="assets"
            isLoading={isFetching}
        >
            <AssetsPage
                assets={assets}
                onEditAsset={(asset) => {
                    navigate(`/settings/assets/${asset.id}`)
                }}
                onSuspendAsset={async (asset) => {
                    try {
                        const { data } = await toggleSuspendAsset(apolloclient, asset.id, {
                            optimisticResponse: {
                                __typename: 'Mutation',
                                toggleSuspendAsset: {
                                    __typename: 'AssetPayload',
                                    asset: {
                                        __typename: 'Asset',
                                        ...asset,
                                        suspended: !asset.suspended,
                                    },
                                },
                            },
                        })
                        let successMessage = 'Asset was successfully suspended'
                        if (data.toggleSuspendAsset.asset.suspended === false) {
                            successMessage = 'Asset was successfully allowed for trading'
                        }
                        notification.success({
                            message: 'Success',
                            description: successMessage,
                        })
                    } catch (e) {
                        notification.error({
                            message: 'Something went wrong',
                            description: `The following error occurred: ${e}`,
                        })
                    }
                }}
                onToggleAvailableAsset={async (asset) => {
                    try {
                        const { data } = await toggleAvailableAsset(apolloclient, asset.id, {
                            optimisticResponse: {
                                __typename: 'Mutation',
                                toggleAvailableAsset: {
                                    __typename: 'AssetPayload',
                                    asset: {
                                        __typename: 'Asset',
                                        ...asset,
                                        available: !asset.available,
                                    },
                                },
                            },
                        })
                        let successMessage = 'Asset was successfully made available'
                        if (data.toggleAvailableAsset.asset.available === false) {
                            successMessage = 'Asset was successfully made unavailable'
                        }
                        notification.success({
                            message: 'Success',
                            description: successMessage,
                        })
                    } catch (e) {
                        notification.error({
                            message: 'Something went wrong',
                            description: `The following error occurred: ${e}`,
                        })
                    }
                }}
            />
        </SettingsPage>
    )
}

export default AssetsPageWithState
