import { createFormValidation } from 'use-form-state'
import * as validation from '../../../validation'
import useFormState from '../../hooks/useFormState'

const setInitialFormValues = () => ({
    name: '',
})

export const customerUserValidation = createFormValidation([{
    path: 'name',
    validate: (name) => validation.isValue(name),
    message: 'Please fill in a name.',
}])

const useCreateCustomerForm = (options) => (
    useFormState(
        setInitialFormValues(),
        {
            customerUserValidation,
            ...options,
        },
    )
)

export default useCreateCustomerForm
