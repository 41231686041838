import React from 'react'
import { Button } from 'antd'
import { ROLES } from '../../../../util/roles'
import HasPermissions from '../../../util/HasPermissions'

const CreateCustomerUserButton = ({
    onCreateCustomerUser,
}) => (
    <HasPermissions roles={[ROLES.ADMIN]}>
        <Button
            type="primary"
            onClick={onCreateCustomerUser}
        >
            Invite user
        </Button>
    </HasPermissions>
)

export default CreateCustomerUserButton
