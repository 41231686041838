import React from 'react'
import { Form, InputNumber, Select } from 'antd'
import FormItem from '../../util/form/FormItem'
import CurrencySelectWithCache from '../../util/form/CurrencySelectWithCache'
import { DEPOSIT, WITHDRAW } from '../../../util/constants'

export const INITIAL_VALUES = {
    type: '',
    amount: '',
    currencyCode: '',
}

const TYPE_OPTIONS = [
    { key: DEPOSIT, option: 'Deposit' },
    { key: WITHDRAW, option: 'Withdraw' },
]

const PaymentForm = ({
    isLoading,
    values,
    errors,
    onChange,
    onChangeAsValue,
    isCreating,
}) => (
    <Form layout="vertical">
        <FormItem
            label="Deposit or withdraw?"
            error={errors.type}
        >
            <Select
                className="w-full"
                placeholder="Please select"
                onChange={(value) => onChangeAsValue('type', value)}
            >
                {TYPE_OPTIONS.map(({ key, option }) => (
                    <Select.Option
                        key={key}
                        value={key}
                    >
                        {option}
                    </Select.Option>
                ))}
            </Select>
        </FormItem>

        <FormItem
            label="Amount"
            error={errors.amount}
        >
            <InputNumber
                min={0}
                defaultValue={0}
                type="number"
                name="amount"
                value={values.amount}
                onChange={(value) => onChangeAsValue('amount', value)}
                className="w-full"
            />
        </FormItem>

        <FormItem
            label="Currency"
            error={errors.currencyCode}
        >
            <CurrencySelectWithCache
                name="currencyCode"
                value={values.currencyCode}
                onChange={(value) => onChangeAsValue('currencyCode', value)}
            />
        </FormItem>
    </Form>
)

export default PaymentForm
