import { createFormValidation } from 'use-form-state'
import * as formValidation from '../../../validation'
import useFormState from '../../hooks/useFormState'
import { fixFloatingPointError } from '../../../util/units'

const validation = createFormValidation([{
    path: 'assetId',
    validate: formValidation.isValue,
    message: 'Please select a asset.',
}, {
    path: 'quantity',
    validate: (value) => formValidation.isValue(value) && formValidation.isGreaterThan(0)(value),
    message: 'Quantity must be greater than 0.',
}])

const valuesToInput = (values) => ({
    ...values,
    spotPrice: fixFloatingPointError(values.spotPrice * 100),
})

const useCreateHedgeTradeForm = (initialValues, options) => (
    useFormState(
        {
            spotPrice: 0,
            unitCode: '',
            currencyCode: '',
            ...initialValues,
        },
        {
            validation,
            valuesToInput,
            ...options,
        },
    )
)

export default useCreateHedgeTradeForm
