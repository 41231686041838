import React from 'react'
import { Input } from 'antd'
import ConfirmModal from './ConfirmModal'
import FormState from './form/FormState'

const INITIAL_VALUES = {
    safeWord: '',
}

const ConfirmInputModal = ({
    children,
    safeWord,
    ...props
}) => (
    <FormState
        initialValues={INITIAL_VALUES}
    >
        {({
            values,
            handleChange,
        }) => (
            <ConfirmModal
                confirmDisabled={values.safeWord !== safeWord}
                {...props}
            >
                {children}

                <Input
                    name="safeWord"
                    value={values.safeWord}
                    onChange={handleChange}
                />
            </ConfirmModal>
        )}
    </FormState>
)

export default ConfirmInputModal
