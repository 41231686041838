import React from 'react'
import { Tag, Popover } from 'antd'
import { ROUNDINGS } from '../../../update-asset/table/PriceAdjustmentsTable'
import CurrencyValue from '../../../../util/CurrencyValue'
import CustomerAssetLogItem from './CustomerAssetLogItem'

const CustomerAssetUpdatedItem = ({
    appEvent,
}) => {
    const {
        customer, createdAt, user, payload,
    } = appEvent
    const { name, code, priceAdjustments } = payload
    return (
        <CustomerAssetLogItem
            createdAt={createdAt}
            user={user}
        >
            <div>
                <strong>{name} ({code})</strong>
                {' '}
                for
                {' '}
                <strong>{customer.name}</strong>
                {' '}
                updated.
                {' '}
                <Popover
                    trigger="click"
                    content={(
                        <ul>
                            <li>Name: <strong>{name}</strong></li>
                            <li>Code: <strong>{code}</strong></li>
                            {priceAdjustments.map(({
                                unitCode,
                                currencyCode,
                                discount,
                                premium,
                                step,
                                rounding,
                            }) => (
                                <li key={`${currencyCode}/${unitCode}`}>
                                    {currencyCode}/{unitCode}
                                    <ul>
                                        <li>
                                            Discount:
                                            {' '}
                                            <strong>
                                                <CurrencyValue value={discount} />
                                            </strong>
                                        </li>
                                        <li>
                                            Premium:
                                            {' '}
                                            <strong>
                                                <CurrencyValue value={premium} />
                                            </strong>
                                        </li>
                                        <li>
                                            Step:
                                            {' '}
                                            <strong>
                                                <CurrencyValue value={step} />
                                            </strong>
                                        </li>
                                        <li>
                                            Rounding:
                                            {' '}
                                            <strong>
                                                {(() => {
                                                    const round = ROUNDINGS.find(({ value }) => value === `${rounding}`)
                                                    if (typeof round !== 'undefined') {
                                                        return round.label
                                                    }
                                                    return 0.00
                                                })()}
                                            </strong>
                                        </li>
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    )}
                >
                    <Tag>Data</Tag>
                </Popover>
            </div>
        </CustomerAssetLogItem>
    )
}

export default CustomerAssetUpdatedItem
