import React from 'react'

const MarketDataPlaceholderContainer = ({
    children,
}) => (
    <div className="w-full flex items-center justify-center text-center mx-auto">
        {children}
    </div>
)

export default MarketDataPlaceholderContainer
