import React from 'react'
import { Input, Checkbox, Form } from 'antd'
import FormItem from '../../../util/form/FormItem'
import PhoneInput from '../../../util/form/PhoneInput'
import UnitsSelectWithCache from '../../../util/form/UnitSelectWithCache'
import CurrencySelectWithCache from '../../../util/form/CurrencySelectWithCache'
import RolesSelectWithData from '../roles/RolesSelectWithData'

const UserForm = ({
    formState,
    isCreating,
}) => {
    const {
        getInputProps,
        getNativeInputProps,
        antdErrors,
    } = formState

    return (
        <Form layout="vertical">
            <FormItem
                label="Email"
                error={antdErrors.get('email')}
            >
                <Input
                    {...getNativeInputProps('email')}
                />
            </FormItem>
            <FormItem
                label="Phone"
                error={antdErrors.get('phone')}
            >
                <PhoneInput
                    {...getInputProps('phone')}
                />
            </FormItem>
            <FormItem
                label="First Name"
                error={antdErrors.get('firstName')}
            >
                <Input
                    {...getNativeInputProps('firstName')}
                />
            </FormItem>
            <FormItem
                label="Last Name"
                error={antdErrors.get('lastName')}
            >
                <Input
                    {...getNativeInputProps('lastName')}
                />
            </FormItem>
            <FormItem
                label="Role"
                error={antdErrors.get('roleId')}
            >
                <RolesSelectWithData
                    {...getInputProps('roleId')}
                />
            </FormItem>
            <FormItem
                label="Preferred unit"
                error={antdErrors.get('preferredUnitCode')}
            >
                <UnitsSelectWithCache
                    {...getInputProps('preferredUnitCode')}
                />
            </FormItem>
            <FormItem
                label="Preferred currency"
                error={antdErrors.get('preferredCurrencyCode')}
            >
                <CurrencySelectWithCache
                    {...getInputProps('preferredCurrencyCode')}
                />
            </FormItem>
            { isCreating && (
                <Checkbox
                    {...getNativeInputProps('sendEmail')}
                >
                    Send an invite link via email
                </Checkbox>
            )}
        </Form>
    )
}

export default UserForm
