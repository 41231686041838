import React from 'react'
import CalculationInfo from '../forms/CalculationInfo'
import CustomerOrdersTable from './CustomerOrdersTable'
import useFetchCustomerOrders from './useFetchCustomerOrders'

const calculateOrderQuantities = (orders, deliveries) => orders.map((order) => {
    const updatedOrder = { ...order }
    deliveries.some((delivery) => {
        if (order.id === delivery.orderId) {
            updatedOrder.outstandingQuantity -= delivery.quantity
            return true
        }
        return false
    })
    let originalOutstandingQuantity = null
    if (order.outstandingQuantity < order.quantity) {
        originalOutstandingQuantity = order.outstandingQuantity
    }
    return {
        ...updatedOrder,
        originalOutstandingQuantity,
    }
})

const CustomerOrdersTableWithData = ({
    formValues,
    onFill,
    onUnFill,
    error,
}) => {
    const {
        customer,
        assetId,
        quantity: quantityAvailable,
        orderDeliveries,
    } = formValues
    const {
        loading,
        customerOrders,
    } = useFetchCustomerOrders(500, undefined, customer.id, assetId)
    const updatedCustomerOrders = calculateOrderQuantities(customerOrders, orderDeliveries)
    const deliveryQuantity = orderDeliveries.reduce((total, delivery) => total + delivery.quantity, 0)

    return (
        <>
            <CustomerOrdersTable
                isLoading={loading}
                orders={updatedCustomerOrders}
                quantityAvailable={quantityAvailable}
                onFill={onFill}
                onUnFill={onUnFill}
            />
            <CalculationInfo
                quantityAvailable={quantityAvailable - deliveryQuantity}
                totalQuantity={quantityAvailable}
                error={error}
            />
        </>
    )
}

export default CustomerOrdersTableWithData
