import React from 'react'
import { Input, Checkbox, Form } from 'antd'
import FormItem from '../../../util/form/FormItem'
import PhoneInput from '../../../util/form/PhoneInput'
import UnitSelectWithCache from '../../../util/form/UnitSelectWithCache'
import CurrencySelectWithCache from '../../../util/form/CurrencySelectWithCache'

export const INITIAL_VALUES = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '+32',
    preferredCurrencyCode: '',
    preferredUnitCode: '',
}

const UserForm = ({
    formstate,
    isCreating,
}) => {
    const {
        getInputProps,
        getNativeInputProps,
        antdErrors,
    } = formstate
    return (
        <Form layout="vertical">
            <FormItem
                label="Email"
                error={antdErrors.get('email')}
            >
                <Input
                    {...getNativeInputProps('email')}
                />
            </FormItem>
            <FormItem
                label="Phone"
                error={antdErrors.get('phone')}
            >
                <PhoneInput
                    {...getInputProps('phone')}
                />
            </FormItem>
            <FormItem
                label="First Name"
                error={antdErrors.get('firstName')}
            >
                <Input
                    {...getNativeInputProps('firstName')}
                />
            </FormItem>
            <FormItem
                label="Last Name"
                error={antdErrors.get('lastName')}
            >
                <Input
                    {...getNativeInputProps('lastName')}
                />
            </FormItem>
            <FormItem
                label="Preferred unit"
                error={antdErrors.get('preferredUnitCode')}
            >
                <UnitSelectWithCache
                    {...getInputProps('preferredUnitCode')}
                />
            </FormItem>
            <FormItem
                label="Preferred currency"
                error={antdErrors.get('preferredCurrencyCode')}
            >
                <CurrencySelectWithCache
                    {...getInputProps('preferredCurrencyCode')}
                />
            </FormItem>
            { isCreating
            && (
                <Checkbox
                    {...getNativeInputProps('sendEmail')}
                >
                    Send an invite link via email
                </Checkbox>
            )}
        </Form>
    )
}

export default UserForm
