import React from 'react'
import { useParams, Link } from 'react-router-dom'
import MainCentered from '../../layout/MainCentered'
import * as routes from '../../../routes'
import LoginFormWrapper from '../login/LoginFormWrapper'
import ChoosePasswordWithState from './ChoosePasswordWithState'

const ChoosePasswordPage = () => {
    const params = useParams()
    return (
        <MainCentered>
            <LoginFormWrapper
                title="Choose Password"
                button={<Link to={routes.login()}>Back</Link>}
            >
                <ChoosePasswordWithState
                    resetToken={params.resetToken}
                    email={params.email}
                />
            </LoginFormWrapper>
        </MainCentered>
    )
}

export default ChoosePasswordPage
