import React from 'react'
import CustomerTopBar from '../../layout/CustomerTopBar'
import CustomerOrdersTableWithState from './CustomerOrdersTableWithState'

const CustomerOrdersPage = ({
    customerId,
    customerName,
    isFetchingMore,
    hasMore,
    customerOrders,
    onFetchMore,
    onEditOrder,
    onAddPickup,
    onAddDelivery,
    onExportOrders,
    refetch,
}) => (
    <>
        <CustomerTopBar customerName={customerName} />
        <CustomerOrdersTableWithState
            customerId={customerId}
            isFetchingMore={isFetchingMore}
            hasMore={hasMore}
            customerOrders={customerOrders}
            onEditOrder={onEditOrder}
            onAddPickup={onAddPickup}
            onAddDelivery={onAddDelivery}
            onFetchMore={onFetchMore}
            onExportOrders={onExportOrders}
            refetch={refetch}
        />
    </>
)

export default CustomerOrdersPage
