import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { notification } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import * as routes from '../../../routes'
import updateAsset from '../../../api/updateAsset'
import useUpdateAssetForm from './useUpdateAssetForm'
import UpdateAssetPage from './UpdateAssetPage'
import SettingsPage from '../SettingsPage'
import useFetchUpdateAssetPageData from './useFetchUpdateAssetPageData'
import SettingsPageError from '../../layout/SettingsPageError'

const UpdateAssetPageWithState = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { assetId } = params
    const [isSavingAsset, setIsSavingAsset] = useState(false)
    const apolloclient = useApolloClient()
    const {
        error,
        isFetching,
        asset,
    } = useFetchUpdateAssetPageData(parseInt(assetId, 10))
    const formState = useUpdateAssetForm(asset)

    if (error) {
        return (
            <SettingsPageError
                activeMenuItem="assets"
            />
        )
    }

    return (
        <SettingsPage
            activeMenuItem="assets"
            isLoading={isFetching}
        >
            <UpdateAssetPage
                routes={routes}
                isSaving={isSavingAsset}
                formState={formState}
                onSubmit={async () => {
                    const isValid = formState.validate()
                    if (isValid) {
                        setIsSavingAsset(true)
                        try {
                            const input = formState.valuesToInput()
                            const { data } = await updateAsset(apolloclient, asset.id, input)
                            notification.success({
                                message: 'Asset successfully updated!',
                                description: `Asset ${data.updateAsset.asset.name} was successfully updated!`,
                            })
                            navigate('/settings/assets')
                        } catch (e) {
                            notification.error({
                                message: 'Asset not updated!',
                                description: `Something went wrong. The following error occured: ${e}`,
                            })
                            setIsSavingAsset(false)
                        }
                    }
                }}
            />
        </SettingsPage>
    )
}

export default UpdateAssetPageWithState
