import React from 'react'
import { useParams } from 'react-router-dom'
import CustomerPage from '../../layout/CustomerPage'
import CustomerPageError from '../../layout/CustomerPageError'
import CustomerAssetCardWithState from './CustomerAssetCardWithState'
import useFetchCustomerPricesPageData from './useFetchCustomerPricesPageData'

const CustomerPricesPageWithState = () => {
    const params = useParams()
    const customerId = parseInt(params.customerId, 10)
    const {
        error,
        isFetching,
        customerAssets,
    } = useFetchCustomerPricesPageData(customerId)

    if (typeof error !== 'undefined') {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem="prices"
            />
        )
    }

    return (
        <CustomerPage
            customerId={customerId}
            isLoading={isFetching}
            activeMenuItem="prices"
        >
            <div className="flex flex-col items-center">
                {customerAssets.map((asset) => (
                    <CustomerAssetCardWithState
                        key={asset.id}
                        asset={asset}
                        customerId={customerId}
                    />
                ))}
            </div>
        </CustomerPage>
    )
}

export default CustomerPricesPageWithState
