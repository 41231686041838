import React from 'react'
import { Row, Col, Select } from 'antd'
import * as constants from '../../../../util/constants'
import Loading from '../../../util/Loading'
import Tag from '../../../util/Tag'
import AssetCardPrice from './AssetCardPrice'

const { Option } = Select
const { SIDE_SELL, SIDE_BUY } = constants
const STATUS_UNAVAILABLE = 'UNAVAILABLE'

const AssetCard = ({
    isFetching,
    asset,
    marketData,
    currencyCode,
    currencySymbol,
    unitCode,
    onChangeCurrency,
    onChangeUnit,
}) => {
    const {
        code,
        name,
        suspended: isSuspended,
        available: isAvailable,
        currencies,
        units,
    } = asset
    const {
        status,
        previousSellPrice,
        sellPrice,
        previousBuyPrice,
        buyPrice,
    } = marketData

    let isTrading = true
    let headerClass = 'flex justify-between mb-4'
    if (status === STATUS_UNAVAILABLE) {
        isTrading = false
        headerClass = 'flex justify-between'
    }

    return (
        <div className="w-full md:w-2/3 lg:w-1/2 mb-8 border-2 border-gray-200 p-4">
            {isFetching
                ? (
                    <div className="flex justify-center items-center">
                        <Loading />
                    </div>
                )
                : (
                    <>
                        <div className={headerClass}>
                            <h1 className="text-3xl mb-0">{name}</h1>
                            {isTrading
                                ? (
                                    <div className="flex">
                                        <div className="mt-1">
                                            {(() => {
                                                if (!isAvailable) {
                                                    return <Tag>UNAVAILABLE</Tag>
                                                }
                                                if (isSuspended) {
                                                    return <Tag>SUSPENDED</Tag>
                                                }
                                            })()}
                                        </div>
                                        <div className="ml-4">
                                            <Select
                                                value={`${code} / ${currencyCode}`}
                                                onChange={(selectedCurrency) => onChangeCurrency(selectedCurrency)}
                                            >
                                                {currencies.map((currency) => <Option key={currency.code} value={currency.code}>{`${code} / ${currency.code}`}</Option>)}
                                            </Select>
                                        </div>
                                        <div className="ml-4">
                                            <Select
                                                value={unitCode}
                                                onChange={(selectedUnit) => onChangeUnit(selectedUnit)}
                                            >
                                                {units.map((unit) => <Option key={unit.code} value={unit.code}>{unit.code}</Option>)}
                                            </Select>
                                        </div>
                                    </div>
                                )
                                : (
                                    <div className="mt-4">
                                        <Tag>MARKET CLOSED</Tag>
                                    </div>
                                )}
                        </div>
                        {isTrading && (
                            <Row>
                                <Col md={12} sm={24}>
                                    <AssetCardPrice
                                        side={SIDE_SELL}
                                        currencyCode={currencyCode}
                                        currencySymbol={currencySymbol}
                                        unitCode={unitCode}
                                        previousPrice={previousSellPrice}
                                        price={sellPrice}
                                    />
                                </Col>
                                <Col md={12} sm={24}>
                                    <AssetCardPrice
                                        side={SIDE_BUY}
                                        currencyCode={currencyCode}
                                        currencySymbol={currencySymbol}
                                        unitCode={unitCode}
                                        previousPrice={previousBuyPrice}
                                        price={buyPrice}
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
        </div>
    )
}

export default AssetCard
