import { useQuery, gql } from '@apollo/client'
import { connectionToCollection, PAGE_INFO_FRAGMENT } from '../../../../../util/graphql'

export const CUSTOMER_ORDERS_QUERY = gql`
    query customerOrdersQuery($first: Int, $customerId: Int!, $where: OrderWhereInput) {
        customerOrders(first: $first, customerId: $customerId, where: $where) {
            edges {
                node {
                    id
                    createdAt,
                    reference
                    quantity
                    outstandingQuantity
                    price
                    currencyCode
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const useFetchCustomerOrders = (first, after, customerId, assetId) => {
    const variables = {
        first,
        after,
        customerId,
        where: {
            assetId,
            side: 'SELL',
            deliveryStatus: ['awaitingDelivery'],
        },
    }
    const {
        loading,
        data = {},
    } = useQuery(CUSTOMER_ORDERS_QUERY, {
        variables,
    })

    let customerOrders = []
    if (!loading && typeof data.customerOrders !== 'undefined') {
        customerOrders = connectionToCollection(data.customerOrders)
    }

    return {
        loading,
        customerOrders,
    }
}

export default useFetchCustomerOrders
