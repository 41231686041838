import { createFormValidation } from 'use-form-state'
import * as validation from '../../../validation'
import useFormState from '../../hooks/useFormState'

const setInitialFormValues = (customer) => ({
    customer,
    assetId: null,
    quantity: 0,
    orderPickups: [],
})

export const pickupValidation = createFormValidation([{
    path: 'customer',
    validate: (customer) => customer !== null && validation.isValue(customer.id),
    message: 'Please choose a valid customer.',
}, {
    path: 'assetId',
    validate: (assetId) => validation.isValue(assetId),
    message: 'Please choose a valid asset.',
}, {
    path: 'quantity',
    validate: (quantity) => validation.isNumber(quantity) && validation.isGreaterThan(0)(quantity),
    message: 'Please select at least one order.',
}, {
    path: 'quantity',
    validate: (quantity, { orderPickups }) => {
        if (orderPickups.length > 0) {
            return true
        }
        return quantity > 0
    },
    message: 'Please provide a quantity higher than 0.',
}])

const toCreatePickupInput = ({
    customer,
    assetId,
    quantity,
    orderPickups,
}) => ({
    customerId: customer.id,
    assetId,
    quantity,
    orderPickups,
})

const useCreatePickupForm = (customer, options) => {
    const formState = useFormState(
        setInitialFormValues(customer),
        {
            validation: pickupValidation,
            valuesToInput: toCreatePickupInput,
            ...options,
        },
    )

    const handleFill = (order) => {
        const { quantity, orderPickups } = formState.values
        if (quantity > 0) {
            const totalPickedUpQuantity = orderPickups.reduce((total, pickup) => total + pickup.quantity, 0)
            const remainingQuantity = quantity - totalPickedUpQuantity
            let pickupQuantity = remainingQuantity
            if (remainingQuantity >= order.outstandingQuantity) {
                pickupQuantity = order.outstandingQuantity
            }

            const updatedOrderPickups = [...orderPickups]
            const existingPickupIndex = orderPickups.findIndex(({ orderId }) => orderId === order.id)
            if (existingPickupIndex >= 0) {
                const existingPickup = orderPickups[existingPickupIndex]
                updatedOrderPickups[existingPickupIndex] = {
                    ...existingPickup,
                    quantity: existingPickup.quantity + pickupQuantity,
                }
            } else {
                updatedOrderPickups.push({
                    orderId: order.id,
                    quantity: pickupQuantity,
                })
            }

            formState.handleChange('orderPickups', updatedOrderPickups)
        }
    }

    const handleUnFill = (order) => {
        const { orderPickups } = formState.values
        const orderPickupIndex = orderPickups.findIndex(({ orderId }) => orderId === order.id)
        orderPickups.splice(orderPickupIndex, 1)
        formState.handleChange('orderDeliveries', orderPickups)
    }

    return {
        ...formState,
        handleFill,
        handleUnFill,
    }
}

export default useCreatePickupForm
