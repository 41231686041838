import React from 'react';
import { Route, Routes } from 'react-router-dom';
import * as routes from '../routes';
import LoginPage from './pages/login/LoginPage';
import LogsPageWithState from './pages/logs/LogsPageWithState';
import HedgePageWithState from './pages/hedge/HedgePageWithState';
import UsersPageWithState from './pages/users/UsersPageWithState';
import TradePageWithState from './pages/trades/TradePageWithState';
import AssetsPageWithState from './pages/assets/AssetsPageWithState';
import OrdersPageWithState from './pages/orders/OrdersPageWithState';
import PricesPageWithState from './pages/prices/PricesPageWithState';
import ForgotPasswordPage from './pages/forgot-password/ForgotPasswordPage';
import ChoosePasswordPage from './pages/choose-password/ChoosePasswordPage';
import TransfersPageWithState from './pages/transfers/TransfersPageWithState';
import CustomersPageWithState from './pages/customers/CustomersPageWithState';
import AppSettingsPageWithState from './pages/app-settings/AppSettingsPageWithState';
import UpdateAssetPageWithState from './pages/update-asset/UpdateAssetPageWithState';
import CustomerUsersPageWithState from './pages/customer-users/CustomerUsersPageWithState';
import AuthorizeHedgePageWithState from './pages/authorize-hedge/AuthorizeHedgePageWithState';
import CustomerAssetsPageWithState from './pages/customer-assets/CustomerAssetsPageWithState';
import CustomerPricesPageWithState from './pages/customer-prices/CustomerPricesPageWithState';
import CustomerTradesPageWithState from './pages/customer-trades/CustomerTradesPageWithState';
import CustomerOrdersPageWithState from './pages/customer-orders/CustomerOrdersPageWithState';
import CustomerBalancePageWithState from './pages/customer-balance/CustomerBalancePageWithState';
import CustomerPaymentsPageWithState from './pages/customer-payments/CustomerPaymentsPageWithState';
import CustomerSettingsPageWithState from './pages/customer-settings/CustomerSettingsPageWithState';
import CustomerTransfersPageWithState from './pages/customer-transfers/CustomerTransfersPageWithState';
import UpdateCustomerAssetPageWithState from './pages/update-customer-asset/UpdateCustomerAssetPageWithState';

function AppRouter({ isAuthenticated, ...props }) {
    if (!isAuthenticated) {
        return (
            <Routes>
                <Route path={routes.forgotPassword()} element={<ForgotPasswordPage {...props} />} />
                <Route path={routes.choosePassword()} element={<ChoosePasswordPage {...props} />} />
                <Route path={routes.login()} element={<LoginPage {...props} />} />
                <Route path="/" element={<LoginPage {...props} />} />
            </Routes>
        );
    }
    return (
        <Routes>
            <Route path={routes.assetOrders()} element={<OrdersPageWithState {...props} />} />
            <Route path={routes.transfers()} element={<TransfersPageWithState {...props} />} />
            <Route path={routes.customers()} element={<CustomersPageWithState {...props} />} />
            <Route path={routes.customer()} element={<CustomerBalancePageWithState {...props} />} />
            <Route path={routes.customerBalance()} element={<CustomerBalancePageWithState {...props} />} />
            <Route path={routes.customerTrades()} element={<CustomerTradesPageWithState {...props} />} />
            <Route path={routes.customerOrders()} element={<CustomerOrdersPageWithState {...props} />} />
            <Route path={routes.customerPrices()} element={<CustomerPricesPageWithState {...props} />} />
            <Route path={routes.customerTransfers()} element={<CustomerTransfersPageWithState {...props} />} />
            <Route path={routes.customerPayments()} element={<CustomerPaymentsPageWithState {...props} />} />
            <Route path={routes.customerUsers()} element={<CustomerUsersPageWithState {...props} />} />
            <Route path={routes.customerSettings()} element={<CustomerSettingsPageWithState {...props} />} />
            <Route path={routes.customerAssetSettings()} element={<CustomerAssetsPageWithState {...props} />} />
            <Route path={routes.users()} element={<UsersPageWithState {...props} />} />
            <Route path={routes.assets()} element={<AssetsPageWithState {...props} />} />
            <Route path={routes.logs()} element={<LogsPageWithState {...props} />} />
            <Route path={routes.hedge()} element={<HedgePageWithState {...props} />} />
            <Route path={routes.appSettings()} element={<AppSettingsPageWithState {...props} />} />
            <Route path={routes.updateAsset()} element={<UpdateAssetPageWithState {...props} />} />
            <Route path={routes.updateCustomerAsset()} element={<UpdateCustomerAssetPageWithState {...props} />} />
            <Route path={routes.prices()} element={<PricesPageWithState {...props} />} />
            <Route path={routes.authorizeHedge()} element={<AuthorizeHedgePageWithState {...props} />} />
            <Route path="/trades/:assetId" element={<TradePageWithState {...props} />} />
            <Route path="/" element={<TradePageWithState {...props} />} />
        </Routes>
    );
}

export default AppRouter;
