import React, { useState } from 'react'
import useModalState from '../../hooks/useModalState'
import CustomerOrdersTable from './CustomerOrdersTable'
import useConfirmOrderMutation from './useConfirmOrderMutation'
import CustomerOrdersTableHeader from './CustomerOrdersTableHeader'
import ConfirmOrderModalWithState from './ConfirmOrderModalWithState'
import { STATUS_FILTER_OPTIONS, PAYMENT_FILTER_OPTIONS } from './useFetchCustomerOrdersPageData'

const transformStatusFilter = (statusFilter) => {
    const filterLength = statusFilter.length
    if (filterLength === STATUS_FILTER_OPTIONS.length || filterLength === 0) {
        return []
    }
    return statusFilter.map((status) => {
        switch (status) {
            case 'Delivered':
                return 'delivered'
            case 'Awaiting delivery':
                return 'awaitingDelivery'
            case 'Partially delivered':
                return 'partiallyDelivered'
            default:
                return null
        }
    })
}

const transformPaymentFilter = (paymentFilter) => {
    const filterLength = paymentFilter.length
    if (filterLength === PAYMENT_FILTER_OPTIONS.length || filterLength === 0) {
        return []
    }
    return paymentFilter.map((payment) => {
        switch (payment) {
            case 'Resolved':
                return 'resolvedPayments'
            case 'Unresolved':
                return 'unResolvedPayments'
            default:
                return null
        }
    })
}

const transformFiltersToWhere = (filters) => {
    const {
        orderId,
        statusFilter,
        paymentFilter,
    } = filters

    if (orderId !== null) {
        return { id: parseInt(orderId, 10) }
    }

    let where = {}
    if (statusFilter !== null) {
        where = {
            ...where,
            deliveryStatus: transformStatusFilter(statusFilter),
        }
    }
    if (paymentFilter !== null) {
        where = {
            ...where,
            paymentStatus: transformPaymentFilter(paymentFilter),
        }
    }

    return where
}

const INITIAL_FILTERS = {
    orderId: null,
    statusFilter: STATUS_FILTER_OPTIONS,
    paymentFilter: PAYMENT_FILTER_OPTIONS,
}

const CustomerOrdersTableWithState = ({
    customerId,
    refetch,
    customerOrders,
    isFetchingMore,
    hasMore,
    onFetchMore,
    onEditOrder,
    onExportOrders,
    onAddPickup,
    onAddDelivery,
}) => {
    const confirmOrderModalState = useModalState()
    const [isRefetching, setIsRefetching] = useState(false)
    const [filters, setFilters] = useState(INITIAL_FILTERS)

    const handleFilterOrdersTable = async (where) => {
        setIsRefetching(true)
        await refetch({
            customerId,
            where,
        })
        setIsRefetching(false)
    }

    const resetTableFilters = async () => {
        setFilters(INITIAL_FILTERS)
        await handleFilterOrdersTable(transformFiltersToWhere(INITIAL_FILTERS))
    }

    const handleConfirmOrder = (order) => {
        confirmOrderModalState.open({ order })
    }

    return (
        <>
            <CustomerOrdersTableHeader
                filters={filters}
                onSearchOrder={async (orderId) => {
                    const newFilters = {
                        ...INITIAL_FILTERS,
                        orderId,
                    }
                    setFilters(newFilters)
                    await handleFilterOrdersTable(transformFiltersToWhere(newFilters))
                }}
                onResetOrderSearch={resetTableFilters}
                onResetTableFilter={resetTableFilters}
            />
            <CustomerOrdersTable
                loading={isRefetching}
                filters={filters}
                isFetchingMore={isFetchingMore}
                hasMore={hasMore}
                customerOrders={customerOrders}
                onFetchMore={onFetchMore}
                onEditOrder={onEditOrder}
                onExportOrders={onExportOrders}
                onConfirmOrder={handleConfirmOrder}
                onApplyStatusFilter={async (statusFilter) => {
                    const newFilters = {
                        ...filters,
                        statusFilter,
                    }
                    setFilters(newFilters)
                    await handleFilterOrdersTable(transformFiltersToWhere(newFilters))
                }}
                onApplyPaymentFilter={async (paymentFilter) => {
                    const newFilters = {
                        ...filters,
                        paymentFilter,
                    }
                    setFilters(newFilters)
                    await handleFilterOrdersTable(transformFiltersToWhere(newFilters))
                }}
                onAddPickup={onAddPickup}
                onAddDelivery={onAddDelivery}
            />
            {confirmOrderModalState.isOpen ? (
                <ConfirmOrderModalWithState
                    isOpen={confirmOrderModalState.isOpen}
                    onClose={confirmOrderModalState.close}
                    onSubmit={confirmOrderModalState.close}
                    {...confirmOrderModalState.initialState}
                />
            ) : null}
        </>
    )
}

export default CustomerOrdersTableWithState
