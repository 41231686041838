import { ROLES } from '../../util/roles'
import useAppState from '../hooks/useAppState'

const HasPermissions = ({
    roles = [],
    children,
}) => {
    const { currentUser, isLoading } = useAppState()
    let hasPermission = false

    if (isLoading) {
        hasPermission = false
    }

    if (currentUser === null) {
        hasPermission = false
    }

    if (roles.includes(currentUser.role.slug)) {
        hasPermission = true
    }

    if (typeof children === 'function') {
        return children(hasPermission)
    }

    return hasPermission ? children : null
}
HasPermissions.ROLES = ROLES

export default HasPermissions
