import { gql } from '@apollo/client'
import CUSTOMER_ASSET_FRAGMENT from '../components/fragments/CustomerAssetFragment'

const UPDATE_CUSTOMER_ASSET_MUTATION = gql`
    mutation updateCustomerAsset($id: Int!, $input: CustomerAssetInput!) {
        updateCustomerAsset(id: $id, input: $input) {
            customerAsset {
                ...CustomerAsset
            }
        }
    }
    ${CUSTOMER_ASSET_FRAGMENT}
`

export default async function updateCustomerAsset(apolloClient, id, input, options) {
    const mutation = UPDATE_CUSTOMER_ASSET_MUTATION
    const variables = { id, input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
