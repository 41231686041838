import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { notification } from 'antd'
import updateCustomer from '../../../api/updateCustomer'
import deleteCustomer from '../../../api/deleteCustomer'
import CustomerSettingsPage from './CustomerSettingsPage'
import CustomerPage from '../../layout/CustomerPage'
import CustomerPageError from '../../layout/CustomerPageError'
import useFetchCustomerSettingsPageData from './useFetchCustomerSettingsPageData'
import useUpdateCustomerSettingsForm from './useUpdateCustomerSettingsForm'
import { CUSTOMERS_PAGE_QUERY } from '../customers/useFetchCustomersPageData'

const CustomerSettingsPageWithStage = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [isDeletingCustomer, setIsDeletingCustomer] = useState(false)
    const customerId = parseInt(params.customerId, 10)
    const apolloClient = useApolloClient()
    const {
        error,
        isFetching,
        customer,
    } = useFetchCustomerSettingsPageData(customerId)
    const formState = useUpdateCustomerSettingsForm(customer)

    if (error) {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem="settings"
            />
        )
    }

    return (
        <CustomerPage
            customerId={customer.id}
            isLoading={isFetching}
            activeMenuItem="settings"
        >
            <CustomerSettingsPage
                formState={formState}
                customer={customer}
                onCustomerSettingsFormSubmit={
                    async () => {
                        const isValid = formState.validate()
                        if (isValid) {
                            try {
                                const { data } = await updateCustomer(
                                    apolloClient,
                                    customerId,
                                    formState.valuesToInput(),
                                )
                                notification.success({
                                    message: 'Successfully updated!',
                                    description: `Customer ${data.updateCustomer.customer.name} was successfully edited!`,
                                })
                            } catch (e) {
                                notification.error({
                                    message: 'Customer not updated!',
                                    description: `Something went wrong. The following error occured: ${e}`,
                                })
                            }
                        }
                    }
                }
                onCustomerDeleteConfirm={
                    async () => {
                        try {
                            setIsDeletingCustomer(true)
                            await deleteCustomer(
                                apolloClient,
                                customer,
                                [{ query: CUSTOMERS_PAGE_QUERY, variables: { orderBy: { name: 'ASC' } } }],
                            )
                            setIsDeletingCustomer(false)
                            navigate('/customers')
                        } catch (e) {
                            notification.error({
                                message: 'Customer not deleted!',
                                description: `Something went wrong. The following error occured: ${e}`,
                            })
                        }
                    }
                }
                isDeletingCustomer={isDeletingCustomer}
            />
        </CustomerPage>
    )
}

export default CustomerSettingsPageWithStage
