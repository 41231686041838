import { createFormValidation } from 'use-form-state'
import * as constants from '../../../util/constants'
import { convertAndRoundQuantity, fixFloatingPointError } from '../../../util/units'
import * as formValidation from '../../../validation'
import useFormState from '../../hooks/useFormState'
import TypeSelect from './TypeSelect'

const {
    SIDE_BUY,
    UNIT_CODE_MILLIGRAM: MG,
} = constants

const validation = createFormValidation([{
    path: 'date',
    validate: formValidation.isValue,
    message: 'Please fill in a date.',
}, {
    path: 'type',
    validate: formValidation.isValue,
    message: 'Please select a type.',
}, {
    path: 'customer',
    validate: (customer, { type }) => type !== TypeSelect.ORDER || formValidation.isValue(customer),
    message: 'Please select a customer.',
}, {
    path: 'assetId',
    validate: formValidation.isValue,
    message: 'Please select a asset.',
}, {
    path: 'price',
    validate: (value) => formValidation.isValue(value) && formValidation.isGreaterThan(0)(value),
    message: 'Price must be greater than 0.',
}, {
    path: 'quantity',
    validate: (value) => formValidation.isValue(value) && formValidation.isGreaterThan(0)(value),
    message: 'Quantity must be greater than 0.',
}])

const valuesToInput = ({
    quantity,
    customer,
    quantityUnitCode,
    ...values
}) => ({
    ...values,
    price: fixFloatingPointError(values.price * 100),
    quantity: convertAndRoundQuantity(quantity, quantityUnitCode, MG),
    quantityUnitCode,
    ...customer !== null && { customerId: customer.id },
})

const useCreateTradeForm = (initialValues, options) => (
    useFormState(
        {
            side: SIDE_BUY,
            currencyCode: '',
            quantity: 0,
            quantityUnitCode: '',
            type: null,
            counterParty: null,
            notes: null,
            price: 0,
            priceUnitCode: '',
            assetId: null,
            customer: null,
            ...initialValues,
        },
        {
            validation,
            valuesToInput,
            ...options,
        },
    )
)

export default useCreateTradeForm
