import { gql } from '@apollo/client'

const RESEND_ADMIN_USER_MAIL = gql`
mutation resendAdminUserMail($id: Int!) {
    resendAdminUserMail(id: $id)
}
`

export default async function resendAdminUserMail(apolloClient, id) {
    const mutation = RESEND_ADMIN_USER_MAIL
    const variables = { id }
    const response = await apolloClient.mutate({
        mutation,
        variables,
    })
    return response
}
