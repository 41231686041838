import { gql, useMutation } from '@apollo/client'

const CREATE_TRADE_MUTATION = gql`
    mutation createTradeMutation($input: CreateTradeInput!) {
        createTrade(input: $input) {
            trade {
                id
            }
        }
    }
`

const useCreateTradeMutation = (options) => (
    useMutation(CREATE_TRADE_MUTATION, options)
)

export default useCreateTradeMutation
