import useFormState from '../../../hooks/useFormState'
import { customerUserValidation } from './useCreateCustomerUserForm'

const setInitialFormValues = (customerUser) => ({
    email: customerUser.email,
    firstName: customerUser.firstName,
    lastName: customerUser.lastName,
    phone: customerUser.phone,
    sendEmail: false,
    preferredUnitCode: customerUser.preferredUnitCode,
    preferredCurrencyCode: customerUser.preferredCurrencyCode,
})

const useUpdateCustomerUserForm = (customerUser, options) => (
    useFormState(
        setInitialFormValues(customerUser),
        {
            customerUserValidation,
            ...options,
        },
    )
)

export default useUpdateCustomerUserForm
