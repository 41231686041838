import { gql } from '@apollo/client'

const DELETE_CUSTOMER_MUTATION = gql`
    mutation deleteCustomer($id: Int!) {
        deleteCustomer(id: $id)
    }
`

export default async function deleteCustomer(apolloclient, customer, refetchQueries) {
    const mutation = DELETE_CUSTOMER_MUTATION
    const variables = { id: customer.id }
    const response = await apolloclient.mutate({
        mutation,
        variables,
        refetchQueries,
    })
    return response
}
