import React from 'react'
import classNames from 'classnames'

const Main = ({
    className,
    maxWidth = 'max-w-container',
    children,
}) => (
    <main
        className={classNames(
            'mt-0 m-48 mx-auto px-6',
            maxWidth,
            className,
        )}
    >
        {children}
    </main>
)

export default Main
