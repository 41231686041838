import React from 'react'
import { GoldOutlined } from '@ant-design/icons'
import TimelineItem from '../timeline/TimelineItem'

const CustomerAssetLogItem = (props) => (
    <TimelineItem
        {...props}
        dot={(
            <GoldOutlined
                style={{
                    fontSize: '18px',
                }}
            />
        )}
    />
)

export default CustomerAssetLogItem
