import React, { useState } from 'react'
import { notification } from 'antd'
import * as constants from '../../../util/constants'
import CreateHedgeTradeModal from './CreateHedgeTradeModal'
import useCreateHedgeTradeQuery from './useCreateTradeQuery'
import useCreateHedgeTradeMutation from './useCreateHedgeTradeMutation'
import useCreateHedgeTradeFormState from './useCreateHedgeTradeFormState'

const {
    SIDE_BUY,
    UNIT_CODE_OUNCE,
} = constants

const CreateHedgeTradeModalWithState = ({
    side = SIDE_BUY,
    quantity = 0,
    assetId,
    isOpen,
    closeModal,
    onCreateTradeCompleted = () => {},
}) => {
    const [isAuthenticating, setIsAuthenticating] = useState(false)
    const {
        units,
        assets,
        currencies,
    } = useCreateHedgeTradeQuery()
    const [createHedgeTrade, { loading: isCreating }] = useCreateHedgeTradeMutation({
        onCompleted: onCreateTradeCompleted,
    })
    const formState = useCreateHedgeTradeFormState({
        side,
        quantity,
        assetId,
        unitCode: UNIT_CODE_OUNCE,
        currencyCode: currencies[0].code,
    })

    const handleCreateTrade = async () => {
        if (formState.validate()) {
            try {
                await createHedgeTrade({
                    variables: {
                        input: formState.valuesToInput(),
                    },
                })
                formState.resetForm()
                notification.success({
                    message: 'Hedge trade created!',
                    description: 'Trade was successfully created!',
                })
            } catch (e) {
                notification.error({
                    message: 'Hedge trade not created!',
                    description: `Something went wrong. The following error occurred: ${e}`,
                })
            }
        }
    }

    const handleClose = () => {
        formState.resetForm()
        closeModal()
    }

    return (
        <CreateHedgeTradeModal
            units={units}
            assets={assets}
            currencies={currencies}
            formState={formState}
            isOpen={isOpen}
            isCreating={isCreating}
            isAuthenticating={isAuthenticating}
            onClose={handleClose}
            onCancel={() => setIsAuthenticating(false)}
            onConfirm={handleCreateTrade}
            onSubmitTrade={() => setIsAuthenticating(true)}
        />
    )
}

export default CreateHedgeTradeModalWithState
