import React from 'react'
import Navbar from './Navbar'
import MainCentered from './MainCentered'

const PageError = ({
    activeMenuItem,
    error,
}) => (
    <>
        <Navbar activeMenuItem={activeMenuItem} />
        <MainCentered className="h-[75vh]">
            <h3 className="text-xl">Failed to load page. The following error occured:</h3>
            <p>{error}</p>
        </MainCentered>
    </>
)

export default PageError
