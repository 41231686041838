import React from 'react'
import { Modal } from 'antd'
import createModalState from '../../hoc/createModalState'
import { isValidCreatePaymentForm } from '../../../validation/create-payment/createPayment'
import { getCreatePaymentFormErrors } from '../../../validation/create-payment/createPaymentErrors'
import { graphqlErrorsToFormErrorsDEPRECATED } from '../../../util/graphql'
import FormState from '../../util/form/FormState'
import PaymentForm, { INITIAL_VALUES } from './PaymentForm'
import useCreatePaymentMutation from './useCreatePaymentMutation'

export const CreatePaymentModalState = createModalState('createPaymentModal', INITIAL_VALUES)

const valuesToInput = (values = {}) => ({
    ...values,
    amount: values.amount * 100,
})

const CreatePaymentModalWithState = ({
    queryToRefetchAfterMutation,
    customerId,
}) => {
    const [createPayment, { loading }] = useCreatePaymentMutation()
    return (
        <CreatePaymentModalState>
            {({ isOpen, close, initialValues }) => (
                <FormState
                    key={isOpen}
                    isValidForm={isValidCreatePaymentForm}
                    getFormErrors={getCreatePaymentFormErrors}
                    initialValues={initialValues}
                >
                    {({
                        values,
                        errors,
                        checkValidation,
                        handleChange,
                        handleChangeAsValue,
                        resetForm,
                        updateErrors,
                    }) => (
                        <Modal
                            title="Create Payment"
                            visible={isOpen}
                            onOk={async () => {
                                if (checkValidation()) {
                                    try {
                                        await createPayment({
                                            variables: { input: valuesToInput(values), customerId },
                                            query: queryToRefetchAfterMutation,
                                        })
                                        resetForm()
                                        close()
                                    } catch (e) {
                                        updateErrors(graphqlErrorsToFormErrorsDEPRECATED(e.graphQLErrors))
                                    }
                                }
                            }}
                            closable={!loading}
                            maskClosable={!loading}
                            confirmLoading={loading}
                            onCancel={close}
                        >
                            <PaymentForm
                                key={isOpen}
                                isLoading={loading}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                                onChangeAsValue={handleChangeAsValue}
                                isCreating
                            />
                        </Modal>
                    )}
                </FormState>
            )}

        </CreatePaymentModalState>
    )
}

export default CreatePaymentModalWithState
