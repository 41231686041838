import React from 'react'
import { Timeline } from 'antd'
import { formatDateTime } from '../../../../../util/dates'

const TimelineItem = ({
    dot,
    createdAt,
    user,
    isCreated,
    isDeleted,
    children,
}) => {
    let color = 'blue'
    if (isCreated) {
        color = 'green'
    }
    if (isDeleted) {
        color = 'red'
    }
    return (
        <Timeline.Item
            dot={dot}
            color={color}
        >
            <div className="flex items-baseline pl-4">
                <div className="pr-4 border-r-1 border-gray-200">
                    {formatDateTime(createdAt)}
                </div>
                <div className="px-4 min-w-[375px]">
                    {(() => {
                        if (typeof children === 'undefined') {
                            if (isCreated) {
                                return <>Unknown <strong>create</strong> event</>
                            }
                            if (isDeleted) {
                                return <>Unknown <strong>delete</strong> event</>
                            }
                            return <>Unknown event</>
                        }
                        return children
                    })()}
                </div>
                {user !== null && (
                    <div className="pl-4 border-l-1 border-gray-200">
                        {user.fullName}
                    </div>
                )}
            </div>
        </Timeline.Item>
    )
}

export default TimelineItem
