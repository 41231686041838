import React from 'react'
import { Table } from 'antd'
import PriceAdjustmentExample from '../../update-asset/table/PriceAdjustmentExample'
import CustomerPriceAdjustmentsTableInput from './CustomerPriceAdjustmentsTableInput'
import CustomerPriceAdjustmentsTableButton from './CustomerPriceAdjustmentsTableButton'
import CustomerPriceAdjustmentTableSelect from './CustomerPriceAdjustmentTableSelect'

const CustomerPriceAdjustmentsTable = ({
    formState,
}) => {
    const {
        values,
        handleAddDiscountPremium,
        handleRemoveDiscountPremium,
        handleAddStepRounding,
        handleRemoveStepRounding,
    } = formState
    const { priceAdjustments } = values

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            width: '10%',
        }, {
            title: 'Example',
            dataIndex: 'id',
            key: 'example',
            width: '18%',
            render: (_, priceAdjustment) => (
                <PriceAdjustmentExample
                    priceAdjustment={priceAdjustment}
                />
            ),
        }, {
            title: 'Discount',
            dataIndex: 'discount',
            render: (_, customerPriceAdjustment, index) => (
                <CustomerPriceAdjustmentsTableInput
                    formState={formState}
                    name="discount"
                    index={index}
                />
            ),
        }, {
            title: 'Premium',
            dataIndex: 'premium',
            className: 'px-0',
            render: (_, customerPriceAdjustment, index) => (
                <CustomerPriceAdjustmentsTableInput
                    formState={formState}
                    name="premium"
                    index={index}
                />
            ),
        }, {
            key: 'discountPremiumButton',
            render: (_, customerPriceAdjustment, index) => {
                const { discount, premium } = customerPriceAdjustment
                return (
                    <CustomerPriceAdjustmentsTableButton
                        isSet={discount !== null || premium !== null}
                        index={index}
                        customerPriceAdjustment={customerPriceAdjustment}
                        onAdd={handleAddDiscountPremium}
                        onRemove={handleRemoveDiscountPremium}
                    />
                )
            },
        }, {
            title: 'Adjustment',
            dataIndex: 'step',
            render: (_, customerPriceAdjustment, index) => (
                <CustomerPriceAdjustmentsTableInput
                    formState={formState}
                    name="step"
                    index={index}
                />
            ),
        }, {
            title: 'Rounding',
            dataIndex: 'rounding',
            className: 'pl-0',
            render: (_, customerPriceAdjustment, index) => (
                <CustomerPriceAdjustmentTableSelect
                    formState={formState}
                    name="rounding"
                    index={index}
                />
            )
            ,
        }, {
            key: 'stepRoundingButton',
            className: 'px-0',
            render: (_, customerPriceAdjustment, index) => {
                const { step, rounding } = customerPriceAdjustment
                return (
                    <CustomerPriceAdjustmentsTableButton
                        isSet={step !== null || rounding !== null}
                        index={index}
                        customerPriceAdjustment={customerPriceAdjustment}
                        onAdd={handleAddStepRounding}
                        onRemove={handleRemoveStepRounding}
                    />
                )
            },
        },
    ]

    return (
        <Table
            rowKey="code"
            columns={columns}
            pagination={false}
            dataSource={priceAdjustments}
        />
    )
}

export default CustomerPriceAdjustmentsTable
