import React from 'react'
import AssetCard from '../prices/assetcard/AssetCard'
import useFetchCustomerAssetCardData from './useFetchCustomerAssetCardData'

const POLL_FREQUENCY = 1000 * 3 // 3 sec

const CustomerAssetCardWithState = ({
    customerId,
    asset,
}) => {
    const { code, currencies, units } = asset
    const {
        error,
        isFetching,
        adjustedCustomerMarketData: initialMarketData,
        currencyCode,
        currencySymbol,
        unitCode,
        onChangeCurrency,
        onChangeUnit,
    } = useFetchCustomerAssetCardData(customerId, code, currencies[0].code, units[0].code, POLL_FREQUENCY)

    if (typeof error !== 'undefined') {
        return (
            <>
                <h4 className="text-lg">Something went wrong loading the marketdata for asset {asset.name}:</h4>
                <p>{error}</p>
            </>
        )
    }

    return (
        <AssetCard
            isFetching={isFetching}
            asset={asset}
            marketData={initialMarketData}
            currencyCode={currencyCode}
            currencySymbol={currencySymbol}
            unitCode={unitCode}
            onChangeCurrency={onChangeCurrency}
            onChangeUnit={onChangeUnit}
        />
    )
}

export default CustomerAssetCardWithState
