import React from 'react'
import { notification } from 'antd'
import HedgePage from './HedgePage'
import useFetchHedgePageData from './useFetchHedgePageData'
import useHedgeLogoutMutation from './useHedgeLogoutMutation'
import useStopConnectionsMutation from './useStopConnectionsMutation'
import useStartConnectionsMutation from './useStartConnectionsMutation'

const HedgePageWithStata = () => {
    const {
        refetch,
        appSettings,
        requestHedgeLogin,
    } = useFetchHedgePageData()
    const [
        startConnectionsMutation,
        { loading: isStartingConnections },
    ] = useStartConnectionsMutation()
    const [
        stopConnectionsMutation,
        { loading: isStoppingConnections },
    ] = useStopConnectionsMutation()
    const [
        hedgeLogoutMutation,
        { loading: isLoggingOut },
    ] = useHedgeLogoutMutation()

    const handleStartConnections = async () => {
        try {
            await startConnectionsMutation()
            refetch()
            notification.success({
                message: 'Success',
                description: 'The market connections were successfully started.',
            })
        } catch (error) {
            notification.error({
                message: 'Could not start connections!',
                description: `Something went wrong. The following error occured: ${error}`,
            })
        }
    }

    const handleStopConnections = async () => {
        try {
            await stopConnectionsMutation()
            refetch()
            notification.success({
                message: 'Success',
                description: 'The market connections were successfully stopped.',
            })
        } catch (error) {
            notification.error({
                message: 'Could not stop connections!',
                description: `Something went wrong. The following error occured: ${error}`,
            })
        }
    }

    const handleAuthorizeSaxoBank = async () => {
        try {
            const { authorizationUri } = requestHedgeLogin
            window.location.href = authorizationUri
        } catch (error) {
            notification.error({
                message: 'Could not request login!',
                description: `Something went wrong. The following error occured: ${error}`,
            })
        }
    }

    const handleHedgeLogout = async () => {
        try {
            await hedgeLogoutMutation()
            refetch()
            notification.success({
                message: 'Success',
                description: 'You were successfully logged out from the Hedge driver.',
            })
        } catch (error) {
            notification.error({
                message: 'Could not logout!',
                description: `Something went wrong. The following error occured: ${error}`,
            })
        }
    }

    return (
        <HedgePage
            isLoggingOut={isLoggingOut}
            isStoppingConnections={isStoppingConnections}
            isStartingConnections={isStartingConnections}
            hedgeIsConnected={appSettings.hedgeIsConnected}
            hedgeIsAuthorized={requestHedgeLogin.isAuthorized}
            onStopConnections={handleStopConnections}
            onStartConnections={handleStartConnections}
            onHedgeLogout={handleHedgeLogout}
            onAuthorizeSaxoBank={handleAuthorizeSaxoBank}
        />
    )
}

export default HedgePageWithStata
