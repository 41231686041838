import { useQuery, gql } from '@apollo/client'
import CUSTOMER_FRAGMENT from '../../fragments/CustomerFragment'

export const CUSTOMER_SETTINGS_PAGE_QUERY = gql`
    query customerSettingsPageQuery($customerId: Int!) {
        customer(id: $customerId) {
            ...Customer
        }
    }
    ${CUSTOMER_FRAGMENT}
`

const useFetchCustomerSettingsPageData = (customerId) => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(CUSTOMER_SETTINGS_PAGE_QUERY, {
        variables: {
            customerId,
        },
    })
    const { customer = {} } = data

    return {
        error,
        isFetching: loading,
        customer,
    }
}

export default useFetchCustomerSettingsPageData
