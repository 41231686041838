import React from 'react'
import { Select } from 'antd'

const CurrencySelect = ({
    name,
    value,
    onChange,
    disabled,
    multiple,
    currencies,
    placeholder = 'Select a currency',
    ...props
}) => {
    let selectProps = { value, ...props }
    if (multiple) {
        selectProps = {
            mode: 'multiple',
            filterOption: (input, option) => (
                option.props.children.toString().toLowerCase().includes(input.toLowerCase())
            ),
            ...selectProps,
        }
    }
    return (
        <Select
            name={name}
            placeholder={placeholder}
            onChange={(currentSelection) => onChange(currentSelection)}
            disabled={disabled}
            {...selectProps}
        >
            {currencies.map((currency) => (
                <Select.Option
                    key={currency.code}
                    value={currency.code}
                >
                    {currency.realSymbol} {currency.code}
                </Select.Option>
            ))}
        </Select>
    )
}

export default CurrencySelect
