import React from 'react'
import { Button } from 'antd'
import {
    EditOutlined,
    CloseOutlined,
} from '@ant-design/icons'

const CustomerPriceAdjustmentsTableButton = ({
    isSet = [],
    index,
    customerPriceAdjustment,
    onRemove,
    onAdd,
}) => {
    if (isSet) {
        return (
            <Button
                type="danger"
                icon={<CloseOutlined />}
                size="small"
                onClick={() => onRemove(index, customerPriceAdjustment)}
            />
        )
    }
    return (
        <Button
            type="primary"
            icon={<EditOutlined />}
            size="small"
            onClick={() => onAdd(index, customerPriceAdjustment)}
        />
    )
}

export default CustomerPriceAdjustmentsTableButton
