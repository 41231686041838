import React from 'react'
import AppSettingsForm from './AppSettingsForm'

const AppSettingsPage = ({
    formState,
    isSavingAppSettings,
    onSaveLimits,
}) => (
    <AppSettingsForm
        formState={formState}
        isSaving={isSavingAppSettings}
        onSubmit={onSaveLimits}
    />
)

export default AppSettingsPage
