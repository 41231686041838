import { useQuery, gql } from '@apollo/client'
import { createQueryUpdater } from '../../../util/graphql'
import CUSTOMER_ASSET_FRAGMENT from '../../fragments/CustomerAssetFragment'
import CUSTOMER_FRAGMENT from '../../fragments/CustomerFragment'

export const CUSTOMER_ASSETS_PAGE_QUERY = gql`
    query customerAssetsPageQuery($customerId: Int!) {
        customer(id: $customerId) {
            ...Customer
        }
        assets {
            id
            name
            code
        }
        customerAssets(customerId: $customerId) {
            ...CustomerAsset
        }
    }
    ${CUSTOMER_FRAGMENT}
    ${CUSTOMER_ASSET_FRAGMENT}
`

export const updateCustomerAssetsPageData = createQueryUpdater(CUSTOMER_ASSETS_PAGE_QUERY)

const useFetchCustomerAssetsPageData = (customerId) => {
    const variables = { customerId }
    const {
        error,
        loading,
        data = {},
    } = useQuery(CUSTOMER_ASSETS_PAGE_QUERY, {
        variables,
    })

    const {
        assets = [],
        customer = {},
        customerAssets = [],
    } = data

    const refetchQueries = [{
        query: CUSTOMER_ASSETS_PAGE_QUERY,
        variables,
    }]

    return ({
        error,
        isFetching: loading,
        assets,
        customer,
        customerAssets,
        refetchQueries,
    })
}

export default useFetchCustomerAssetsPageData
