import React from 'react'
import moment from 'moment'
import { Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import { formatCurrencyNotation } from '../../../../util/currency'

const columns = [{
    title: 'Name',
    key: 'name',
    render: (text, record) => record.name,
    width: 200,
}, {
    title: 'Trade limit',
    key: 'hasTradeLimit',
    dataIndex: 'hasTradeLimit',
    align: 'center',
    render: (hasTradeLimit, record) => {
        if (!hasTradeLimit) {
            return 'none'
        }
        if (record.balanceMargin !== null) {
            return `${record.balanceMargin}%`
        }
        return `${formatCurrencyNotation(record.fixedTradeLimit)}€`
    },
    width: 200,
}, {
    title: 'Added',
    dataIndex: 'createdAt',
    render: (createdAt) => moment(createdAt)
        .format('DD/MM/YY'),
    width: 90,
}]

const CustomersTable = ({
    customers,
    isLoading,
}) => {
    const navigate = useNavigate()
    return (
        <Table
            className="cursor-pointer"
            useFixedHeader
            rowKey="id"
            columns={columns}
            loading={isLoading}
            dataSource={customers}
            pagination={false}
            onRow={(record) => ({
                onClick: () => navigate(`/customer/${record.id}/balance`)
            })}
        />
    )
}

export default CustomersTable
