import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { notification } from 'antd'
import updateCustomerUser from '../../../api/updateCustomerUser'
import { graphqlErrorsToFormErrors } from '../../../util/graphql'
import DrawerWithActions from '../../util/DrawerWithActions'
import useUpdateCustomerUserForm from './form/useUpdateCustomerUserForm'
import CustomerUserForm from './form/CustomerUserForm'

const UpdateAdminUserDrawerWithState = ({
    isVisible,
    user,
    closeDrawer,
}) => {
    const [isSavingCustomerUser, setIsSavingCustomerUser] = useState(false)
    const apolloClient = useApolloClient()
    const formstate = useUpdateCustomerUserForm(user)

    return (
        <DrawerWithActions
            visible={isVisible}
            title="Update User"
            onSubmit={async () => {
                if (formstate.validate()) {
                    setIsSavingCustomerUser(true)
                    try {
                        await updateCustomerUser(
                            apolloClient,
                            user.id,
                            formstate.values,
                        )
                        notification.success({
                            message: 'Customer updated!',
                            description: `${user.firstName} was successfully updated!`,
                        })
                    } catch (e) {
                        const formErrors = graphqlErrorsToFormErrors(e.graphQLErrors)
                        if (formErrors.length !== 0) {
                            formstate.updateErrors(formErrors)
                        } else {
                            notification.error({
                                message: 'Customer not updated!',
                                description: `Something went wrong. The following error occured: ${e}`,
                            })
                            closeDrawer()
                        }
                    }
                    setIsSavingCustomerUser(false)
                }
            }}
            onSubmitLoading={isSavingCustomerUser}
            onCancel={closeDrawer}
            width={520}
            onClose={closeDrawer}
        >
            <CustomerUserForm
                formstate={formstate}
            />
        </DrawerWithActions>
    )
}

export default UpdateAdminUserDrawerWithState
