import React from 'react'
import { Select } from 'antd'
import { ROUNDINGS } from '../../update-asset/table/PriceAdjustmentsTable'

const CustomerPriceAdjustmentTableSelect = ({
    formState,
    name,
    index,
}) => {
    const {
        values,
        getInputProps,
    } = formState
    const customerPriceAdjustment = values.priceAdjustments[index]
    let finalRounding = customerPriceAdjustment.rounding
    if (finalRounding === null) {
        finalRounding = customerPriceAdjustment.priceAdjustment.rounding
    }

    return (
        <Select
            {...getInputProps(`priceAdjustments.${index}.${name}`)}
            name={name}
            className="w-full"
            value={finalRounding}
            disabled={customerPriceAdjustment.rounding === null}
        >
            {ROUNDINGS.map(({ label, value }) => {
                const parsedValue = parseInt(value, 10)
                return (
                    <Select.Option key={parsedValue} value={parsedValue}>
                        {label}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

export default CustomerPriceAdjustmentTableSelect
