import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { notification, Modal } from 'antd'
import createCustomer from '../../../api/createCustomer'
import CustomerForm from './CustomerForm'
import { CUSTOMERS_PAGE_QUERY } from './useFetchCustomersPageData'
import useCreateCustomerForm from './useCreateCustomerForm'

const CreateCustomerModalWithState = ({
    isVisible,
    onCreateCustomerComplete,
    closeModal,
}) => {
    const navigate = useNavigate()
    const [isCreatingCustomer, setIsCreatingCustomer] = useState(false)
    const apolloClient = useApolloClient()
    const formstate = useCreateCustomerForm()

    return (
        <Modal
            title="Create Customer"
            visible={isVisible}
            onOk={async () => {
                const isValid = formstate.validate()
                if (isValid) {
                    setIsCreatingCustomer(true)
                    try {
                        const { data } = await createCustomer(
                            apolloClient,
                            formstate.values,
                            [{ query: CUSTOMERS_PAGE_QUERY, variables: { orderBy: { name: 'ASC' } } }],
                        )
                        notification.success({
                            message: 'Customer Created!',
                            description: `${data.createCustomer.customer.name} was successfully created!`,
                        })
                        navigate(`/customer/${data.createCustomer.customer.id}/settings`)
                    } catch (e) {
                        notification.error({
                            message: 'Customer not created!',
                            description: `Something went wrong. The following error occured: ${e}`,
                        })
                    }
                    setIsCreatingCustomer(false)
                    onCreateCustomerComplete()
                }
            }}
            closable={!isCreatingCustomer}
            maskClosable={!isCreatingCustomer}
            confirmLoading={isCreatingCustomer}
            onCancel={closeModal}
        >
            <CustomerForm
                formstate={formstate}
            />
        </Modal>
    )
}

export default CreateCustomerModalWithState
