import React from 'react'
import clsx from 'clsx'
import { Button } from 'antd'
import State from '../../../util/State'
import { formatCurrency, calculateSellPrice, calculateBuyPrice } from '../../../../util/currency'

const CODE_EXAMPLE_MAP = {
    'EUR / g': 3476,
    'EUR / oz': 108126,
    'EUR / kg': 3476812,
    'USD / g': 3957,
    'USD / oz': 123087,
    'USD / kg': 3956857,
}

const PriceAdjustmentExample = ({
    priceAdjustment,
    className,
}) => (
    <State>
        {({ state, setState }) => {
            if (!state.isShown) {
                return (
                    <Button onClick={() => {
                        setState({ isShown: true })
                    }}
                    >
                        Show
                    </Button>
                )
            }
            const basePrice = CODE_EXAMPLE_MAP[priceAdjustment.code]
            const vtBuysAt = calculateSellPrice(basePrice, priceAdjustment)
            const vtSellsAt = calculateBuyPrice(basePrice, priceAdjustment)
            const invalidBuyPrice = vtBuysAt > basePrice
            const invalidSellPrice = vtSellsAt < basePrice
            return (
                <div className={clsx('bg-transparent items-center text-xs', className)}>
                    <div>
                        <strong>Mid price: </strong>
                        {formatCurrency(basePrice)}
                    </div>
                    <div className={clsx(invalidBuyPrice && 'text-red-500')}>
                        <strong>VT Buys at: </strong>
                        {formatCurrency(vtBuysAt)}
                    </div>
                    <div className={clsx(invalidSellPrice && 'text-red-500')}>
                        <strong>VT Sells at: </strong>
                        {formatCurrency(vtSellsAt)}
                    </div>
                </div>
            )
        }}
    </State>
)

export default PriceAdjustmentExample
