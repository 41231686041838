import React, { useState } from 'react'
import { Modal, notification } from 'antd'
import getExportedOrders from '../../../../services/fetch/getExportedOrders'
import useExportOrdersForm from './useExportOrdersForm'
import ExportOrdersForm from './ExportOrdersForm'
import useFetchExportOrdersModalData from './useFetchExportOrdersModalData'

const ExportOrdersModalWithState = ({
    isVisible,
    onExportOrdersToFileComplete,
    closeModal,
    assetId,
    customerId = null,
    dateRange,
}) => {
    const [isExportingOrders, setIsExportingOrders] = useState(false)
    const formState = useExportOrdersForm(customerId, dateRange)
    const { isFetching, customers } = useFetchExportOrdersModalData()

    return (
        <Modal
            title="Export orders"
            visible={isVisible}
            width={400}
            onOk={async () => {
                const isValid = formState.validate()
                if (isValid) {
                    setIsExportingOrders(true)
                    try {
                        const {
                            customerId: inputCustomerId,
                            dateRange: inputDateRange,
                        } = formState.values
                        const [from, till] = inputDateRange
                        await getExportedOrders(from, till, inputCustomerId, assetId)
                    } catch (e) {
                        notification.error({
                            message: 'Orders not exported!',
                            description: `Something went wrong. The following error occured: ${e}`,
                        })
                    }
                    setIsExportingOrders(false)
                    onExportOrdersToFileComplete()
                }
            }}
            closable={!isExportingOrders}
            maskClosable={!isExportingOrders}
            confirmLoading={isExportingOrders}
            onCancel={closeModal}
            okText={isExportingOrders ? 'Exporting...' : 'Export'}
        >
            <ExportOrdersForm
                customerId={customerId}
                isFetching={isFetching}
                customers={customers}
                formState={formState}
            />
        </Modal>
    )
}

export default ExportOrdersModalWithState
