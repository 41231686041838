import React from 'react'
import { Tag, Popover } from 'antd'
import { SIDE_SELL, UNIT_CODE_OUNCE } from '../../../../../util/constants'
import PreferredCurrencyValue from '../../../../util/PreferredCurrencyValue'
import TradeLogItem from './TradeLogItem'

const HedgeCreatedItem = ({
    assets,
    appEvent,
}) => {
    const { user, createdAt, payload } = appEvent
    const {
        side, assetId, totalPrice,
        quantity, quantityFilled,
    } = payload
    const isSell = side === SIDE_SELL
    const color = isSell ? 'green' : 'blue'
    return (
        <TradeLogItem
            isCreated
            createdAt={createdAt}
            user={user}
        >
            <div>
                Hedge created
                {' '}
                <Popover
                    placement="bottom"
                    content={(
                        <div>
                            {side}
                            <ul>
                                <li>Asset: <strong>{assets.find(({ id }) => id === assetId).name}</strong></li>
                                <li>Quantity:
                                    <ul>
                                        <li>
                                            Total:
                                            {' '}
                                            <strong>
                                                {quantity}{UNIT_CODE_OUNCE}
                                            </strong>
                                        </li>
                                        <li>
                                            Filled:
                                            {' '}
                                            <strong>
                                                {quantityFilled}{UNIT_CODE_OUNCE}
                                            </strong>
                                        </li>
                                    </ul>
                                </li>
                                <li>Price:
                                    <ul>
                                        <li>
                                            Total:
                                            {' '}
                                            <strong>
                                                <PreferredCurrencyValue
                                                    value={Math.abs(totalPrice)}
                                                />
                                            </strong>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    )}
                >
                    <Tag color={color}>Data</Tag>
                </Popover>
            </div>
        </TradeLogItem>
    )
}

export default HedgeCreatedItem
