import React from 'react'
import { unitTo } from '../../../util/units'
import { UNIT_CODE_MILLIGRAM } from '../../../util/constants'
import OrderInfo from './add-delivery-to-order/OrderInfo'
import PickupsTable from './PickupsTable'

const AddPickupToOrderForm = ({
    formState,
    order,
    pickups,
    remainingQuantityToFill,
    onAdd,
    onRemove,
}) => {
    const { antdErrors } = formState
    const error = antdErrors.get('orderPickups')
    const remainingQuantity = unitTo(remainingQuantityToFill, UNIT_CODE_MILLIGRAM, order.quantityUnitCode)
    return (
        <>
            <OrderInfo order={order} />
            <PickupsTable
                order={order}
                pickups={pickups}
                remainingQuantityToFill={remainingQuantityToFill}
                onAdd={onAdd}
                onRemove={onRemove}
            />
            <h2 className="text-2xl">
                Remaining quantity to fill: {remainingQuantity} {order.quantityUnitCode}
            </h2>
            {error && (
                <span className="text-red-500">{error}</span>
            )}
        </>
    )
}

export default AddPickupToOrderForm
