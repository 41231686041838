import localforage from 'localforage'

const ACCESS_TOKEN_KEY = 'jwtTokenAdmin'
const REFRESH_TOKEN_KEY = 'refreshToken'
const IS_TABLE_EXPANDED_KEY = 'isTradeTableExpanded'

// In memory cache
/* eslint-disable import/no-mutable-exports */
export let accessToken = null
export let refreshToken = null

export const ready = (...args) => localforage.ready(...args)

export const loadAccessToken = async () => {
    accessToken = await localforage.getItem(ACCESS_TOKEN_KEY)
}

export const getAccessToken = async () => {
    if (accessToken === null) {
        accessToken = await localforage.getItem(ACCESS_TOKEN_KEY)
    }
    return accessToken
}

export const setAccessToken = async (newJwtToken) => {
    accessToken = newJwtToken
    await localforage.setItem(ACCESS_TOKEN_KEY, newJwtToken)
}

export const removeAccessToken = () => {
    accessToken = null
    return localforage.removeItem(ACCESS_TOKEN_KEY)
}

export const getRefreshToken = async () => {
    if (refreshToken === null) {
        refreshToken = await localforage.getItem(REFRESH_TOKEN_KEY)
    }
    return refreshToken
}

export const setRefreshToken = (newRefreshToken) => {
    refreshToken = newRefreshToken
    return localforage.setItem(REFRESH_TOKEN_KEY, newRefreshToken)
}

export const removeRefreshToken = () => {
    refreshToken = null
    return localforage.removeItem(REFRESH_TOKEN_KEY)
}

export const isTableExpanded = async () => localforage.getItem(IS_TABLE_EXPANDED_KEY)
export const toggleIsTableExpanded = async () => {
    const isExpanded = await isTableExpanded()
    await localforage.setItem(IS_TABLE_EXPANDED_KEY, !isExpanded)
}
