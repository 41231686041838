import { getPreferredUnits } from '../../util/units'
import useAppState from '../hooks/useAppState'

const PreferredUnits = ({
    code,
    children,
}) => {
    const { currentUser } = useAppState()
    return children({
        units: getPreferredUnits(code || currentUser.preferredUnitCode),
        preferredUnitCode: code || currentUser.preferredUnitCode,
    })
}

export default PreferredUnits
