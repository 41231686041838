import { gql, useMutation } from '@apollo/client'

const mutation = gql`
    mutation createOrderPickups($input: OrderPickupsInput!) {
        createOrderPickups(input: $input) {
            orderPickups {
                id
            }
        }
    }
`

const useCreateOrderPickupsMutation = (options) => (
    useMutation(mutation, options)
)

export default useCreateOrderPickupsMutation
