import { gql } from '@apollo/client'

const CUSTOMER_ASSET_FRAGMENT = gql`
    fragment CustomerAsset on CustomerAsset {
        id
        asset {
            id
            name
            code
            currencies {
                code
                name
                symbol
            }
            units {
                code
                name
                milligram
            }
            priceAdjustments {
                unitCode
                currencyCode
                discount
                premium
                step
                rounding
            }
        }
        suspended
        available
        canBuy
        canSell
        currencies {
            code
            name
            symbol
        }
        units {
            code
            name
            milligram
        }
        priceAdjustments {
            id
            code
            currencyCode
            unitCode
            discount
            premium
            step
            rounding
            priceAdjustment {
                discount
                premium
                step
                rounding
            }
        }
    }
`

export default CUSTOMER_ASSET_FRAGMENT
