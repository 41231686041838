import React, { useState, useEffect } from 'react'
import usePolling from '../../../hooks/usePolling'
import { getSidePricing } from '../market-order-modal/MarketDataWithQuery'
import MarketDataError from './MarketDataError'
import MarketDataLoading from './MarketDataLoading'
import CustomerMarketData from './CustomerMarketData'
import MarketDataUnavailable from './MarketDataUnavailable'
import useCustomerMarketDataQuery from './useCustomerMarketDataQuery'

const STATUS_UNAVAILABLE = 'UNAVAILABLE'

const CustomerMarketDataWithQuery = ({
    customerId,
    side,
    assetCode,
    unitCode,
    currencyCode,
    onCompletedSpotPrice,
}) => {
    const [pricingIsFetched, setPricingIsFetched] = useState(false)
    const {
        data = {},
        loading,
        stopPolling,
        startPolling,
    } = useCustomerMarketDataQuery({
        variables: {
            customerId,
            assetCode,
            currencyCode,
            unitCode,
        },
        errorPolicy: 'ignore',
    })

    usePolling(startPolling, stopPolling)

    useEffect(() => {
        if (!pricingIsFetched && data.marketData) {
            const spotPrice = getSidePricing(side, data.adjustedMarketData)
            onCompletedSpotPrice(spotPrice)
            setPricingIsFetched(true)
        }
    }, [data.marketData])

    if (loading) {
        return <MarketDataLoading />
    }
    const { adjustedMarketData } = data
    if (adjustedMarketData === null) {
        return <MarketDataError />
    }
    if (adjustedMarketData.status === STATUS_UNAVAILABLE) {
        return <MarketDataUnavailable assetName={assetCode} />
    }
    return (
        <CustomerMarketData
            unitCode={unitCode}
            side={side}
            currencyCode={currencyCode}
            spotPrice={getSidePricing(side, data.adjustedMarketData)}
            marketData={data.marketData}
            adjustedMarketData={data.adjustedMarketData}
            adjustedCustomerMarketData={data.adjustedCustomerMarketData}
        />
    )
}

export default CustomerMarketDataWithQuery
