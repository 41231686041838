import React, { useState } from 'react'
import { notification } from 'antd'
import CreateOrderModal from './CreateOrderModal'
import useCreateOrderQuery from './useCreateOrderQuery'
import useCreateOrderWithHedgeOrderMutation from './useCreateOrderWithHedgeOrderMutation'
import useCreateOrderWithoutHedgeOrderMutation from './useCreateOrderWithoutHedgeOrderMutation'
import useCreateOrderFormState from './useCreateOrderFormState'

const CreateOrderModalWithState = ({
    assetId,
    isOpen,
    closeModal,
    onCreateOrderCompleted = () => {},
}) => {
    const [isAuthenticating, setIsAuthenticating] = useState(false)
    const {
        units,
        assets,
        currencies,
        isFetching,
    } = useCreateOrderQuery()
    const formState = useCreateOrderFormState({
        isHedgeOrder: true,
        assetId,
        unitCode: isFetching ? '' : units[0].code,
        currencyCode: isFetching ? '' : currencies[0].code,
    })
    const [
        createOrder,
        { loading: isCreatingOrder },
    ] = useCreateOrderWithoutHedgeOrderMutation({
        onCompleted: onCreateOrderCompleted,
    })
    const [
        createOrderWithHedgeOrder,
        { loading: isCreatingOrderWithHedgeOrder },
    ] = useCreateOrderWithHedgeOrderMutation({
        onCompleted: onCreateOrderCompleted,
    })

    const handleCreateOrder = async () => {
        if (formState.validate()) {
            try {
                const variables = {
                    customerId: formState.values.customerId,
                    input: formState.valuesToInput(),
                }
                if (formState.values.isHedgeOrder) {
                    await createOrderWithHedgeOrder({ variables })
                } else {
                    await createOrder({ variables })
                }
                formState.resetForm()
                notification.success({
                    message: 'Order Created!',
                    description: 'Order was successfully created!',
                })
            } catch (e) {
                notification.error({
                    message: 'Order not created!',
                    description: `Something went wrong. The following error occurred: ${e}`,
                })
            }
        }
    }

    const handleClose = () => {
        formState.resetForm()
        closeModal()
    }

    return (
        <CreateOrderModal
            units={units}
            assets={assets}
            currencies={currencies}
            formState={formState}
            isOpen={isOpen}
            isFetching={isFetching}
            isCreating={isCreatingOrder || isCreatingOrderWithHedgeOrder}
            isAuthenticating={isAuthenticating}
            onClose={handleClose}
            onCancel={() => setIsAuthenticating(false)}
            onConfirm={handleCreateOrder}
            onSubmitOrder={() => setIsAuthenticating(true)}
        />
    )
}

export default CreateOrderModalWithState
