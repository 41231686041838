import { gql, useQuery } from '@apollo/client'

const QUERY = gql`
    query customerMarketData(
        $customerId: Int!,
        $assetCode: String!,
        $currencyCode: String!,
        $unitCode: String!
    ) {
        marketData(
            assetCode: $assetCode,
            currencyCode: $currencyCode,
            unitCode: $unitCode
        ) {
            status
            sellPrice
            buyPrice
        }
        adjustedCustomerMarketData(
            customerId: $customerId,
            assetCode: $assetCode,
            currencyCode: $currencyCode,
            unitCode: $unitCode
        ) {
            status
            sellPrice
            buyPrice
        }
        adjustedMarketData(
            assetCode: $assetCode,
            currencyCode: $currencyCode,
            unitCode: $unitCode,
        ) {
            status
            sellPrice
            buyPrice
        }
        currencies {
            code
        }
    }
`

const useCustomerMarketDataQuery = (options) => (
    useQuery(QUERY, options)
)

export default useCustomerMarketDataQuery
