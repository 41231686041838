import React from 'react'
import { notification } from 'antd'
import CloseTradeMonthModal from './CloseTradeMonthModal'
import useCloseTradeMonthMutation from './useCloseTradeMonthMutation'

const CloseTradeMonthModalWithState = ({
    assetId,
    open,
    date,
    closeModal,
    onCompleted,
    ...props
}) => {
    const [closeTradeMonth, { loading }] = useCloseTradeMonthMutation({
        onCompleted,
    })

    const handleCloseTradeMonth = async () => {
        try {
            await closeTradeMonth({
                variables: {
                    input: {
                        assetId,
                        date,
                    },
                },
            })
            notification.success({
                message: 'Month closed!',
                description: 'The open quantity was successfully transferred to the next month.',
            })
        } catch (e) {
            notification.error({
                message: 'Month not closed!',
                description: `Something went wrong. The following error occurred: ${e}`,
            })
        }
    }

    return (
        <CloseTradeMonthModal
            {...props}
            isTranferring={loading}
            open={open}
            onCancel={closeModal}
            onConfirm={handleCloseTradeMonth}
        />
    )
}

export default CloseTradeMonthModalWithState
