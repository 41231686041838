import React, { useEffect } from 'react'
import MarketDataError from '../order-modal/MarketDataError'
import MarketDataLoading from '../order-modal/MarketDataLoading'
import MarketDataUnavailable from '../order-modal/MarketDataUnavailable'
import usePolling from '../../../hooks/usePolling'
import MarketData from './MarketData'
import useMarketDataQuery from './useMarketDataQuery'

const STATUS_UNAVAILABLE = 'UNAVAILABLE'
const SELL = 'SELL'

export const getSidePricing = (side, marketData) => (
    side === SELL ? marketData.sellPrice : marketData.buyPrice
)

const MarketDataWithQuery = ({
    side,
    assetCode,
    unitCode,
    currencyCode,
    onCompletedSpotPrice,
}) => {
    const {
        data = {},
        loading,
        startPolling,
        stopPolling,
    } = useMarketDataQuery({
        variables: {
            assetCode,
            currencyCode,
            unitCode,
        },
    })

    usePolling(startPolling, stopPolling)

    useEffect(() => {
        if (data.marketData) {
            const spotPrice = getSidePricing(side, data.marketData)
            onCompletedSpotPrice(spotPrice)
        }
    }, [data.marketData])

    if (loading) {
        return <MarketDataLoading />
    }
    const { marketData } = data
    if (marketData === null) {
        return <MarketDataError />
    }
    if (marketData.status === STATUS_UNAVAILABLE) {
        return <MarketDataUnavailable assetName={assetCode} />
    }
    return (
        <MarketData
            spotPrice={getSidePricing(side, data.marketData)}
            unitCode={unitCode}
            currencyCode={currencyCode}
        />
    )
}

export default MarketDataWithQuery
