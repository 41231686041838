import { gql } from '@apollo/client'
import ADMIN_USER_FRAGMENT from '../components/pages/users/AdminUserFragment'

const UPDATE_ADMIN_USER = gql`
mutation updateAdminUser($id: Int!, $input: AdminUserInput!) {
    updateAdminUser(id: $id, input: $input) {
        user {
            ...User
        }
    }
}
${ADMIN_USER_FRAGMENT}
`

export default async function updateAdminUser(apolloClient, id, input) {
    const mutation = UPDATE_ADMIN_USER
    const variables = { id, input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
    })
    return response
}
