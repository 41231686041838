import React from 'react'
import moment from 'moment'
import { Table } from 'antd'
import { convertQuantity } from '../../../util/converters'
import PreferredUnits from '../../util/PreferredUnits'
import InfiniteScroll from '../../util/InfiniteScroll'

const columns = [{
    title: 'Date',
    dataIndex: 'createdAt',
    render: (createdAt) => moment(createdAt).format('DD/MM HH:mm'),
    width: 90,
}, {
    title: 'Customer',
    dataIndex: ['customer', 'name'],
    render: (customerName) => customerName,
    width: 150,
}, {
    title: 'Quantity',
    dataIndex: 'quantity',
    render: (quantity) => (
        <PreferredUnits>
            {({ preferredUnitCode }) => (
                `${convertQuantity(quantity, preferredUnitCode)}${preferredUnitCode}`
            )}
        </PreferredUnits>
    ),
    width: 90,
}, {
    title: 'Asset',
    dataIndex: 'asset',
    render: (asset) => asset.name,
    width: 90,
}]

const TransfersPickupsTable = ({
    pickups,
    isFetchingMore,
    hasMore,
    onFetchMore,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        hasMore={hasMore}
        onFetchMore={onFetchMore}
    >
        <Table
            rowKey="id"
            columns={columns}
            dataSource={pickups}
            pagination={false}
        />
    </InfiniteScroll>
)

export default TransfersPickupsTable
