import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Modal, notification, Button } from 'antd'
import createOrderDeliveries from '../../../../api/createOrderDeliveries'
import Loading from '../../../util/Loading'
import useFetchAddDeliveryToOrderModalData from './useFetchAddDeliveryToOrderModalData'
import useCreateOrderDeliveriesForm from './useCreateOrderDeliveriesForm'
import OrderDeliveriesForm from './OrderDeliveriesForm'

const AddDeliveryToOrderModalWithState = ({
    order,
    isVisible,
    closeModal,
    onCreateOrderDeliveriesComplete,
    refetchQuery,
}) => {
    const [isLinkingDeliveries, setIsLinkingDeliveries] = useState(false)
    const apolloClient = useApolloClient()
    const {
        isFetching,
        customerDeliveries,
    } = useFetchAddDeliveryToOrderModalData(order.customer.id, order.asset.id)
    const formState = useCreateOrderDeliveriesForm()

    const { orderDeliveries } = formState.values
    const updatedCustomerDeliveries = customerDeliveries.map((customerDelivery) => {
        const updatedCustomerDelivery = {
            ...customerDelivery,
            originalUnresolvedQuantity: customerDelivery.unresolvedQuantity,
        }
        const orderDelivery = orderDeliveries.find(({ deliveryId }) => deliveryId === customerDelivery.id)
        if (typeof orderDelivery !== 'undefined') {
            const unresolvedQuantity = customerDelivery.unresolvedQuantity - orderDelivery.quantity

            return {
                ...updatedCustomerDelivery,
                unresolvedQuantity,
            }
        }

        return updatedCustomerDelivery
    })
    const remainingQuantityToFill = order.outstandingQuantity - orderDeliveries.reduce((filledQuantity, orderDelivery) => filledQuantity + orderDelivery.quantity, 0)

    if (isFetching) {
        return (
            <Modal
                visible
                closable={false}
                footer={null}
            >
                <Loading />
            </Modal>
        )
    }

    if (customerDeliveries.length > 0) {
        return (
            <Modal
                title="Add delivery to order"
                visible={isVisible}
                width={750}
                onOk={async () => {
                    const isValid = formState.validate()
                    if (isValid) {
                        setIsLinkingDeliveries(true)
                        try {
                            const input = {
                                ...formState.values,
                                orderId: order.id,
                            }
                            const refetchQueries = [{
                                query: refetchQuery,
                                variables: {
                                    customerId: order.customer.id,
                                },
                            }]
                            await createOrderDeliveries(apolloClient, input, { refetchQueries })
                            notification.success({
                                message: 'Order updated successfully!',
                                description: `Order ${order.id} was successfully updated`,
                            })
                        } catch (e) {
                            notification.error({
                                message: 'Order not updated!',
                                description: `Something went wrong. The following error occured: ${e}`,
                            })
                        }
                        setIsLinkingDeliveries(false)
                        onCreateOrderDeliveriesComplete()
                    }
                }}
                closable={!isLinkingDeliveries}
                maskClosable={!isLinkingDeliveries}
                confirmLoading={isLinkingDeliveries}
                onCancel={closeModal}
            >
                <OrderDeliveriesForm
                    formState={formState}
                    order={order}
                    deliveries={updatedCustomerDeliveries}
                    remainingQuantityToFill={remainingQuantityToFill}
                    onAdd={(delivery, orderId) => {
                        let orderDeliveryQuantity = delivery.unresolvedQuantity
                        if (delivery.unresolvedQuantity > remainingQuantityToFill) {
                            orderDeliveryQuantity = remainingQuantityToFill
                        }
                        const newOrderDeliveries = orderDeliveries.concat([{
                            quantity: orderDeliveryQuantity,
                            orderId,
                            deliveryId: delivery.id,
                        }])
                        formState.handleChange('orderDeliveries', newOrderDeliveries)
                    }}
                    onRemove={(delivery) => {
                        formState.handleChange('orderDeliveries', orderDeliveries.filter(({ deliveryId }) => deliveryId !== delivery.id))
                    }}
                />
            </Modal>
        )
    }
    return (
        <Modal
            visible
            centered
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={closeModal}
                >
                    Close
                </Button>,
            ]}
            onCancel={closeModal}
        >
            There are no unresolved deliveries to fill this order
        </Modal>
    )
}

export default AddDeliveryToOrderModalWithState
