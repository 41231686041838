import React from 'react'
import Logo from './util/Logo'
import MainCentered from './layout/MainCentered'

const AppFailure = () => (
    <MainCentered>
        <div className="mb-6">
            <Logo height={40} />
        </div>
        <h3 className="text-xl">Something went wrong</h3>
        <p className="text-center">An unexpected error occurred while trying to load Value Trading Admin.<br />Please try again later.</p>
    </MainCentered>
)

export default AppFailure
