import { gql, useMutation } from '@apollo/client'

const CREATE_PAYMENT = gql`
    mutation createPayment($input: PaymentInput!, $customerId: Int!) {
        createPayment(input: $input, customerId: $customerId) {
            payment {
                id
                createdAt
                type
                currencyCode
                customer {
                    id
                }
            }
        }
    }
`

const useCreatePaymentMutation = (options) => (
    useMutation(CREATE_PAYMENT, options)
)

export default useCreatePaymentMutation
