import { gql, useQuery } from '@apollo/client'

export const ORDERS_PAGE_QUERY = gql`
    query ordersPageQuery{
        customers {
            id
            name
        }
    }
`

const useFetchExportOrdersModalData = () => {
    const {
        loading,
        data = {},
    } = useQuery(ORDERS_PAGE_QUERY)
    const { customers = [] } = data

    return {
        isFetching: loading,
        customers,
    }
}

export default useFetchExportOrdersModalData
