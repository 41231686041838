import React from 'react'
import { Popconfirm, Dropdown, Menu } from 'antd'
import {
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons'
import TypeSelect from './TypeSelect'

export const ACTION_EDIT = 'edit'
export const ACTION_DELETE = 'delete'

function TradePageTableActionDropdown({
    trade,
    onEdit,
    onDelete,
    children,
}) {
    const handleSelect = (e) => {
        if (e.key === ACTION_EDIT) {
            onEdit(trade)
        }
    }
    return (
        <>
            <Dropdown
                overlay={(
                    <div>
                        <Menu
                            onClick={handleSelect}
                            className="border-1 border-gray-200"
                        >
                            <Menu.Item key={ACTION_EDIT} icon={<EditOutlined size="small" />}>
                                Edit
                            </Menu.Item>
                            {trade.type === TypeSelect.OTHER && (
                                <Menu.Item icon={<DeleteOutlined size="small" />}>
                                    <Popconfirm
                                        title="Are you sure you want to delete this trade?"
                                        onConfirm={onDelete}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        Delete
                                    </Popconfirm>
                                </Menu.Item>
                            )}
                        </Menu>
                    </div>
                )}
                placement="bottomRight"
                trigger={['click']}
            >
                {children}
            </Dropdown>
        </>
    )
}

export default TradePageTableActionDropdown
