import React from 'react'
import Date from '../../util/Date'
import UnitValue from '../../util/UnitValue'
import CurrencyValue from '../../util/CurrencyValue'
import * as constants from '../../../util/constants'
import { getCurrencySymbol } from '../../../util/currency'
import { ListItem } from '../customer-orders/CustomerOrdersTableDetails'

const {
    UNIT_CODE_OUNCE: OZ,
    UNIT_CODE_KILOGRAM: KG,
} = constants

const CustomerTradesTableDetails = ({
    price,
    notes,
    user,
    createdAt,
    totalPrice,
    currencyCode,
    priceUnitCode,
    quantity,
    quantityUnitCode,
}) => (
    <div className="flex">
        <div className="flex-col flex-1">
            <h3 className="text-xl mb-4">Pricing</h3>
            <ul className="mb-4">
                <ListItem label="Amount">
                    {(() => {
                        if (quantityUnitCode !== OZ) {
                            return (
                                <UnitValue
                                    value={quantity}
                                    unitCode={KG}
                                />
                            )
                        }
                        return (
                            <UnitValue
                                value={quantity}
                                unitCode={quantityUnitCode}
                            />
                        )
                    })()}
                </ListItem>
                <ListItem label="Price per unit">
                    <CurrencyValue
                        value={price}
                        currencyCode={currencyCode}
                        displayCurrencyCode={getCurrencySymbol(currencyCode)}
                    />
                    /
                    {priceUnitCode}
                </ListItem>
                <ListItem label="Total price">
                    <CurrencyValue
                        isBold
                        value={totalPrice}
                        currencyCode={currencyCode}
                        displayCurrencyCode={getCurrencySymbol(currencyCode)}
                    />
                </ListItem>
            </ul>
        </div>
        <div className="flex-col flex-1">
            <h3 className="text-xl mb-4">Client</h3>
            <ul className="mb-4">
                <ListItem label="Name">
                    {user.fullName}
                </ListItem>
                <ListItem label="Email">
                    {user.email}
                </ListItem>
                <ListItem label="Phone">
                    {user.phone}
                </ListItem>
            </ul>
            <h3 className="text-xl mb-4">Other details</h3>
            <ul className="mb-4">
                <ListItem label="Traded on">
                    <Date value={createdAt} />
                </ListItem>
                <ListItem label="Notes">
                    {notes}
                </ListItem>
            </ul>
        </div>
    </div>
)

export default CustomerTradesTableDetails
