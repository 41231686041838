import { gql, useMutation } from '@apollo/client'
import { CREATE_ORDER_FRAGMENT } from './useCreateOrderWithoutHedgeOrderMutation'

const CREATE_ORDER_MUTATION = gql`
   mutation createCustomerOrderWithMarketOrder($input: CustomerOrderInput!, $customerId: Int!) {
        createCustomerOrderWithMarketOrder(input: $input, customerId: $customerId) {
            order {
                ...OrderPageOrder
            }
        }
    }
    ${CREATE_ORDER_FRAGMENT}
`

const useCreateOrderWithHedgeOrderMutation = (options) => (
    useMutation(CREATE_ORDER_MUTATION, options)
)

export default useCreateOrderWithHedgeOrderMutation
