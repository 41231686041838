import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { notification, Modal } from 'antd'
import createAdminUser from '../../../api/createAdminUser'
import { graphqlErrorsToFormErrors } from '../../../util/graphql'
import useCreateAdminUserForm from './form/useCreateAdminUserForm'
import { ADMIN_USERS_QUERY } from './useFetchUsersPageData'
import UserForm from './form/UserForm'

const CreateAdminUserModalWithState = ({
    isVisible,
    closeModal,
}) => {
    const [isSavingAdminUser, setIsSavingAdminUser] = useState(false)
    const apolloClient = useApolloClient()
    const formstate = useCreateAdminUserForm()

    return (
        <Modal
            visible={isVisible}
            title="Create user"
            onOk={async () => {
                if (formstate.validate()) {
                    setIsSavingAdminUser(true)
                    try {
                        const { data } = await createAdminUser(
                            apolloClient,
                            formstate.valuesToInput(),
                            [{ query: ADMIN_USERS_QUERY }],
                        )
                        notification.success({
                            message: 'Customer Created!',
                            description: `${data.createAdminUser.user.firstName} was successfully created!`,
                        })
                        closeModal()
                    } catch (e) {
                        const formErrors = graphqlErrorsToFormErrors(e.graphQLErrors)
                        if (formErrors.length !== 0) {
                            formstate.updateErrors(formErrors)
                        } else {
                            notification.error({
                                message: 'Customer not created!',
                                description: `Something went wrong. The following error occured: ${e}`,
                            })
                            closeModal()
                        }
                    }
                    setIsSavingAdminUser(false)
                }
            }}
            closable={!isSavingAdminUser}
            maskClosable={!isSavingAdminUser}
            confirmLoading={isSavingAdminUser}
            onCancel={closeModal}
        >
            <UserForm
                formState={formstate}
                isCreating
            />
        </Modal>
    )
}

export default CreateAdminUserModalWithState
