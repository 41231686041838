import React from 'react'
import { Modal } from 'antd'
import CreatePickupForm from './CreatePickupForm'

const CreatePickupModal = ({
    assets,
    formState,
    onSubmit,
    onCancel,
    isVisible,
    isCreating,
}) => (
    <Modal
        title="Add pickup"
        visible={isVisible}
        width={750}
        onOk={onSubmit}
        closable={!isCreating}
        maskClosable={!isCreating}
        confirmLoading={isCreating}
        onCancel={onCancel}
    >
        <CreatePickupForm
            formState={formState}
            assets={assets}
        />
    </Modal>
)

export default CreatePickupModal
