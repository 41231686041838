import React from 'react'
import { convertQuantity } from '../../../util/converters'
import PreferredUnits from '../../util/PreferredUnits'

const UnfilledBuyCustomerOrdersCalculationInfo = ({
    quantityAvailable,
    totalQuantity,
    error,
}) => (
    <div className="mt-3">
        <PreferredUnits>
            {({ preferredUnitCode }) => (
                <>
                    <h2 className="text-2xl">
                        Remaining quantity to use for order fill: {convertQuantity(quantityAvailable, preferredUnitCode)} {preferredUnitCode}
                    </h2>
                    <h2 className="text-2xl">
                        Total quantity that will be picked up: {convertQuantity(totalQuantity, preferredUnitCode)} {preferredUnitCode}
                    </h2>
                    {error && (
                        <span className="text-red-500">{error}</span>
                    )}
                </>
            )}
        </PreferredUnits>
    </div>
)

export default UnfilledBuyCustomerOrdersCalculationInfo
