import { gql } from '@apollo/client'
import { ASSETS_PAGE_FRAGMENT } from '../components/pages/assets/useFetchAssetsPageData'

const UPDATE_ASSET_MUTATION = gql`
    mutation updateAsset($id: Int!, $input: AssetInput!) {
        updateAsset(id: $id, input: $input) {
            asset {
                ...AssetPageAsset
            }
        }
    }
    ${ASSETS_PAGE_FRAGMENT}
`
export default async function updateAsset(apolloClient, id, input, options) {
    const mutation = UPDATE_ASSET_MUTATION
    const variables = { id, input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
