import { gql, useMutation } from '@apollo/client'

const mutation = gql`
    mutation confirmOrder($id: Int!,  $input: ConfirmOrderInput!) {
        confirmOrder(id: $id, input: $input) {
            order {
                id
                fixStatus
            }
        }
    }
`

export default function useConfirmOrderMutation(options) {
    return useMutation(mutation, options)
}
