import { gql, useMutation } from '@apollo/client'

const mutation = gql`
    mutation createPickup($input: PickupInput!) {
        createPickup(input: $input) {
            pickup {
                id
                customer {
                    name
                }
            }
        }
    }
`

const useCreatePickupMutation = (options) => (
    useMutation(mutation, options)
)

export default useCreatePickupMutation
