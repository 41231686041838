import React from 'react'
import InfiniteScroll from '../../util/InfiniteScroll'
import UsersTableTopBar from './table/UsersTableTopBar'
import UsersTable from './table/UsersTable'
import SettingsPage from '../SettingsPage'

const UsersPage = ({
    isFetching,
    isFetchingMore,
    hasMore,
    adminUsers,
    handleFetchMore,
    onCreateAdminUser,
    onUpdateAdminUser,
    onResendEmailInvitation,
}) => (
    <SettingsPage
        activeMenuItem="users"
        isLoading={isFetching}
    >
        <UsersTableTopBar onCreateAdminUser={onCreateAdminUser} />
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            hasMore={hasMore}
            onFetchMore={handleFetchMore}
        >
            <UsersTable
                users={adminUsers}
                onUpdateAdminUser={onUpdateAdminUser}
                onResendEmailInvitation={onResendEmailInvitation}
            />
        </InfiniteScroll>
    </SettingsPage>
)

export default UsersPage
