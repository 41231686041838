import React from 'react'
import Main from '../../layout/Main'
import Navbar from '../../layout/Navbar'
import InfiniteScroll from '../../util/InfiniteScroll'
import OrdersTable from './OrdersTable'
import OrdersPageTopBar from './OrdersPageTopBar'

const OrdersPage = ({
    hasMore,
    orders,
    isFetchingMore,
    activeMenuItem,
    fetchMore,
    filters,
    onExportOrders,
    onCreateCustomerOrder,
    onChangeDateFilter,
    onChangeRangeFilter,
    onChangeSearchFilter,
    onChangeCustomerFilter,
}) => (
    <>
        <Navbar activeMenuItem={activeMenuItem} />
        <Main>
            <OrdersPageTopBar
                orders={orders}
                filters={filters}
                onExportOrders={onExportOrders}
                onCreateCustomerOrder={onCreateCustomerOrder}
                onChangeDateFilter={onChangeDateFilter}
                onChangeRangeFilter={onChangeRangeFilter}
                onChangeSearchFilter={onChangeSearchFilter}
                onChangeCustomerFilter={onChangeCustomerFilter}
            />
            <InfiniteScroll
                isFetchingMore={isFetchingMore}
                hasMore={hasMore}
                onFetchMore={fetchMore}
            >
                <OrdersTable
                    orders={orders}
                />
            </InfiniteScroll>
        </Main>
    </>
)

export default OrdersPage
