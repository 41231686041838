import { gql, useQuery } from '@apollo/client'
import { connectionToCollection, PAGE_INFO_FRAGMENT } from '../../../util/graphql'
import UnfilledBuyCustomerOrdersTable from './UnfilledBuyCustomerOrdersTable'

const QUERY = gql`
    query customerOrdersQuery($first: Int, $customerId: Int!, $where: OrderWhereInput) {
        customerOrders(first: $first, customerId: $customerId, where: $where) {
            edges {
                node {
                    ...UnfilledBuyCustomerOrdersTable
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${UnfilledBuyCustomerOrdersTable.FRAGMENT}
`

const useUnfilledBuyOrdersQuery = (options) => {
    const {
        data = {},
        loading,
        ...result
    } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        ...options,
    })

    if (loading) {
        return {
            loading,
            customerOrders: []
        }
    }

    const { customerOrders } = data

    return {
        loading,
        customerOrders: typeof customerOrders === 'undefined' ? [] : connectionToCollection(customerOrders),
        ...result,
    }
}

export default useUnfilledBuyOrdersQuery
