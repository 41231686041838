import useFormState from '../../../hooks/useFormState'
import { adminUserValidation } from './useCreateAdminUserForm'

const adminUserToFormValues = (user) => ({
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    roleId: user.role.id,
    phone: user.phone,
    sendEmail: false,
    preferredUnitCode: user.preferredUnitCode,
    preferredCurrencyCode: user.preferredCurrencyCode,
})

const toUpdateAdminUserInput = ({
    email,
    firstName,
    lastName,
    roleId,
    phone,
    sendEmail,
    preferredUnitCode,
    preferredCurrencyCode,
}) => ({
    email,
    firstName,
    lastName,
    roleId: parseInt(roleId, 10),
    phone,
    sendEmail,
    preferredUnitCode,
    preferredCurrencyCode,
})

const useUpdateAdminUserForm = (user, options) => (
    useFormState(
        adminUserToFormValues(user),
        {
            adminUserValidation,
            valuesToInput: toUpdateAdminUserInput,
            ...options,
        },
    )
)

export default useUpdateAdminUserForm
