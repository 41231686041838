import moment from 'moment'
import { createFormValidation } from 'use-form-state'
import * as constants from '../../../util/constants'
import { milligramToUnit, convertAndRoundQuantity, fixFloatingPointError } from '../../../util/units'
import * as formValidation from '../../../validation'
import useFormState from '../../hooks/useFormState'
import TypeSelect from './TypeSelect'

const { UNIT_CODE_MILLIGRAM: MG, } = constants

const validation = createFormValidation([{
    path: 'date',
    validate: formValidation.isValue,
    message: 'Please fill in a date.',
}, {
    path: 'type',
    validate: formValidation.isValue,
    message: 'Please select a type.',
}, {
    path: 'customer',
    validate: (customer, { type }) => type !== TypeSelect.ORDER || formValidation.isValue(customer),
    message: 'Please select a customer.',
}, {
    path: 'assetId',
    validate: formValidation.isValue,
    message: 'Please select a asset.',
}, {
    path: 'price',
    validate: (value) => formValidation.isValue(value) && formValidation.isGreaterThan(0)(value),
    message: 'Price must be greater than 0.',
}, {
    path: 'quantity',
    validate: (value) => formValidation.isValue(value) && formValidation.isGreaterThan(0)(value),
    message: 'Quantity must be greater than 0.',
}])

const valuesToInput = ({
    date,
    side,
    currencyCode,
    quantity,
    quantityUnitCode,
    type,
    counterParty,
    notes,
    price,
    priceUnitCode,
    assetId,
    customer,
}) => ({
    date,
    side,
    currencyCode,
    quantityUnitCode,
    type,
    counterParty,
    notes,
    price: fixFloatingPointError(price * 100),
    priceUnitCode,
    assetId,
    quantity: convertAndRoundQuantity(quantity, quantityUnitCode, MG),
    ...customer !== null && { customerId: customer.id },
})

const useUpdateTradeForm = (initialValues, options) => (
    useFormState(
        {
            ...initialValues,
            quantity: milligramToUnit(initialValues.quantity, initialValues.quantityUnitCode),
            notes: null,
            assetId: initialValues.asset.id,
            customerId: initialValues.customer !== null ? initialValues.customer.id : null,
            customer: initialValues.customer,
            date: moment(initialValues.date),
            price: fixFloatingPointError(initialValues.price / 100),
        },
        {
            validation,
            valuesToInput,
            ...options,
        }
    )
)

export default useUpdateTradeForm
