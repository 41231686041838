import React from 'react'
import { TeamOutlined } from '@ant-design/icons'
import TimelineItem from '../timeline/TimelineItem'

const CustomerLogItem = (props) => (
    <TimelineItem
        {...props}
        dot={(
            <TeamOutlined
                style={{
                    fontSize: '18px',
                }}
            />
        )}
    />
)

export default CustomerLogItem
