import React from 'react'
import { Table, Button } from 'antd'
import moment from 'moment'
import { unitTo } from '../../../util/units'
import { UNIT_CODE_MILLIGRAM } from '../../../util/constants'
import TableActions from '../../util/TableActions'

const PickupsTable = ({
    order,
    pickups,
    remainingQuantityToFill,
    onAdd,
    onRemove,
}) => (
    <div className="mb-4">
        <h4 className="text-lg mb-2">Pickups: </h4>
        <Table
            rowKey="id"
            size="small"
            pagination={false}
            columns={[{
                key: 'createdAt',
                title: 'Date',
                dataIndex: 'createdAt',
                render: (createdAt) => moment(createdAt).format('DD/MM HH:mm'),
                width: 100,
            }, {
                key: 'asset',
                title: 'Asset',
                dataIndex: 'asset',
                render: (asset) => (order.impurity === null ? asset.name : `${asset.name} ${order.impurity.name}`),
                align: 'center',
                width: 150,
            }, {
                key: 'quantity',
                title: 'Quantity',
                dataIndex: 'quantity',
                render: (quantity) => (`${unitTo(quantity, UNIT_CODE_MILLIGRAM, order.unitCode)} ${order.unitCode}`),
                align: 'center',
            }, {
                key: 'unresolvedQuantity',
                title: 'Quantity available for pickup',
                dataIndex: 'unresolvedQuantity',
                render: (unresolvedQuantity) => (`${unitTo(unresolvedQuantity, UNIT_CODE_MILLIGRAM, order.unitCode)} ${order.unitCode}`),
                align: 'center',
            }, {
                key: 'action',
                align: 'right',
                width: 110,
                render: (pickup) => (
                    <TableActions>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => onAdd(pickup, order.id)}
                            disabled={pickup.unresolvedQuantity === 0 || remainingQuantityToFill === 0}
                        >
                            Add
                        </Button>
                        <Button
                            type="secondary"
                            size="small"
                            onClick={() => onRemove(pickup)}
                            disabled={pickup.unresolvedQuantity === pickup.originalUnresolvedQuantity}
                        >
                            Remove
                        </Button>
                    </TableActions>
                ),
            }]}
            dataSource={pickups}
            rowClassName={({ unresolvedQuantity, originalUnresolvedQuantity }) => {
                if (unresolvedQuantity === 0) {
                    return 'text-green-400'
                } if (unresolvedQuantity < originalUnresolvedQuantity && unresolvedQuantity !== 0) {
                    return 'text-yellow-400'
                }
                return ''
            }}
        />
    </div>
)

export default PickupsTable
