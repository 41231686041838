import React from 'react'
import { StockOutlined } from '@ant-design/icons'
import TimelineItem from '../timeline/TimelineItem'

const TradeLogItem = (props) => (
    <TimelineItem
        {...props}
        dot={(
            <StockOutlined
                style={{
                    fontSize: '18px',
                }}
            />
        )}
    />
)

export default TradeLogItem
