import { gql, useMutation } from '@apollo/client'

const CLOSE_TRADE_MONTH_MUTATION = gql`
    mutation closeTradeMonthMutation($input: CloseTradeMonthInput!) {
        closeTradeMonth(input: $input)
    }
`

const useCloseTradeMonthMutation = (options) => (
    useMutation(CLOSE_TRADE_MONTH_MUTATION, options)
)

export default useCloseTradeMonthMutation
