import { createValidationErrorObject } from '../index'
import * as validation from './forgotPassword'

const getEmailError = createValidationErrorObject(
    validation.isValidEmail,
    'email',
    'Please fill in a valid email.'
)

export const getForgotPasswordFormErrors = ({
    email,
}) => ({
    ...getEmailError(email),
})
