import { createFormValidation } from 'use-form-state'
import useFormState from '../../../hooks/useFormState'

export const exportFormvalidation = createFormValidation([{
    path: 'dateRange',
    validate: (dateRange) => dateRange.length > 0,
    message: 'Please pick a date range',
}])

const useUpdateAssetForm = (customerId, dateRange, options) => useFormState(
    {
        customerId,
        dateRange,
    },
    {
        validation: exportFormvalidation,
        ...options,
    },
)

export default useUpdateAssetForm
