import React from 'react'
import { Modal, notification, Button } from 'antd'
import Loading from '../../util/Loading'
import AddPickupToOrderModal from './AddPickupToOrderModal'
import useAddPickupToOrderQuery from './useAddPickupToOrderQuery'
import useCreateOrderPickupsMutation from './useCreateOrderPickupsMutation'
import useCreateOrderPickupsFormState from './useCreateOrderPickupsFormState'

const AddPickupToOrderModalWithState = ({
    order,
    isVisible,
    closeModal,
    onComplete,
    refetchQuery,
}) => {
    const {
        isFetching,
        customerPickups,
    } = useAddPickupToOrderQuery(order.customer.id, order.asset.id)
    const [createOrderPickups, { loading: isSubmitting }] = useCreateOrderPickupsMutation()
    const formState = useCreateOrderPickupsFormState()

    const { orderPickups } = formState.values
    const updatedCustomerPickups = customerPickups.map((customerPickup) => {
        const updatedCustomerPickup = {
            ...customerPickup,
            originalUnresolvedQuantity: customerPickup.unresolvedQuantity,
        }
        const orderPickup = orderPickups.find(({ pickupId }) => pickupId === customerPickup.id)
        if (typeof orderPickup !== 'undefined') {
            const unresolvedQuantity = customerPickup.unresolvedQuantity - orderPickup.quantity

            return {
                ...updatedCustomerPickup,
                unresolvedQuantity,
            }
        }

        return updatedCustomerPickup
    })
    const pickedUpQuantity = orderPickups.reduce((filledQuantity, orderPickup) => filledQuantity + orderPickup.quantity, 0)
    const remainingQuantityToFill = order.outstandingQuantity - pickedUpQuantity

    const handleSubmit = async () => {
        if (formState.validate()) {
            try {
                const input = {
                    ...formState.values,
                    orderId: order.id,
                }
                const refetchQueries = [{
                    query: refetchQuery,
                    variables: {
                        customerId: order.customer.id,
                    },
                }]
                await createOrderPickups({ variables: { input }, refetchQueries })
                notification.success({
                    message: 'Order updated successfully!',
                    description: `Order ${order.id} was successfully updated`,
                })
                onComplete()
            } catch (e) {
                notification.error({
                    message: 'Order not updated!',
                    description: `Something went wrong. The following error occured: ${e}`,
                })
            }
        }
    }

    const handleAdd = (pickup, orderId) => {
        let orderPickupQuantity = pickup.unresolvedQuantity
        if (pickup.unresolvedQuantity > remainingQuantityToFill) {
            orderPickupQuantity = remainingQuantityToFill
        }
        const newOrderPickups = orderPickups.concat([{
            quantity: orderPickupQuantity,
            orderId,
            pickupId: pickup.id,
        }])
        formState.handleChange('orderPickups', newOrderPickups)
    }
    
    const handleRemove = (pickup) => {
        formState.handleChange('orderPickups', orderPickups.filter(({ pickupId }) => pickupId !== pickup.id))
    }

    if (isFetching) {
        return (
            <Modal
                visible
                closable={false}
                footer={null}
            >
                <Loading />
            </Modal>
        )
    }

    if (customerPickups.length > 0) {
        return (
            <AddPickupToOrderModal
                formState={formState}
                order={order}
                pickups={updatedCustomerPickups}
                remainingQuantityToFill={remainingQuantityToFill}
                isVisible={isVisible}
                isSubmitting={isSubmitting}
                onSubmit={handleSubmit}
                onCancel={closeModal}
                onAdd={handleAdd}
                onRemove={handleRemove}
            />
        )
    }

    return (
        <Modal
            visible={isVisible}
            centered
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={closeModal}
                >
                    Close
                </Button>,
            ]}
            onCancel={closeModal}
        >
            There are no unresolved pickups to fill this order
        </Modal>
    )
}

export default AddPickupToOrderModalWithState
