import { gql } from '@apollo/client'
import CUSTOMER_FRAGMENT from '../components/fragments/CustomerFragment'

const CREATE_CUSTOMER_MUTATION = gql`
    mutation createCustomer($input: CustomerInput!) {
        createCustomer(input: $input) {
            customer {
               ...Customer
            }
        }
    }
    ${CUSTOMER_FRAGMENT}
`

export default async function createCustomer(apolloClient, input, refetchQueries) {
    const mutation = CREATE_CUSTOMER_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        refetchQueries,
    })
    return response
}
