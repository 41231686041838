import React from 'react'
import moment from 'moment'
import { Button, Table, Tag } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { ROLES } from '../../../../util/roles'
import deleteAdminUser from '../../../../api/deleteAdminUser'
import HasPermissions from '../../../util/HasPermissions'
import TableActions from '../../../util/TableActions'
import UsersTableDeleteButtonWithState from './UsersTableDeleteButtonWithState'
import UsersTableResendMailButton from './UsersTableResendMailButton'

const UsersTable = ({
    users,
    onUpdateAdminUser,
    onResendEmailInvitation,
}) => {
    const columns = [{
        title: 'Name',
        key: 'name',
        render: (text, record) => `${record.firstName} ${record.lastName}`,
        width: 150,
    }, {
        title: 'Email',
        dataIndex: 'email',
        width: 220,
    }, {
        title: 'Role',
        dataIndex: 'role',
        width: 150,
        render: (role) => <Tag>{role.name}</Tag>,
    }, {
        title: 'Phone',
        dataIndex: 'phone',
        width: 150,
    }, {
        title: 'Invited',
        dataIndex: 'createdAt',
        render: (createdAt) => moment(createdAt)
            .format('DD/MM/YY'),
        width: 95,
    }, {
        title: 'Action',
        dataIndex: 'id',
        align: 'center',
        render: (id) => {
            const updateUser = users.find((user) => user.id === id)
            return (
                <HasPermissions roles={[ROLES.ADMIN]}>
                    <TableActions>
                        <Button
                            ghost
                            icon={<EditOutlined />}
                            type="primary"
                            shape="circle"
                            size="small"
                            className="border-none"
                            onClick={() => onUpdateAdminUser(updateUser)}
                        />
                        <UsersTableDeleteButtonWithState
                            userId={id}
                            deleteUserMutation={deleteAdminUser}
                        />
                        <UsersTableResendMailButton
                            onResendEmailInvitation={() => onResendEmailInvitation(updateUser)}
                        />
                    </TableActions>
                </HasPermissions>
            )
        },
    }]

    return (
        <Table
            useFixedHeader
            rowKey="id"
            columns={columns}
            dataSource={users}
            pagination={false}
        />
    )
}

export default UsersTable
