import React from 'react'
import logout from '../api/logout'
import { loadAndValidateTokens } from '../util/auth'
import getAppHealth from '../apollo/queries/getAppHealth'
import getInitialData from '../apollo/queries/getInitialData'
import { getPusherConnection } from '../services/pusher/pusher'
import { scopeSentryUser, removeScopedSentryUser } from '../services/sentry/scopeUser'
import * as storage from '../util/cache'
import App from './App'
import AppProviders from './AppProviders'
import AppStateProvider from './providers/AppStateProvider'

class AppWithState extends React.Component {
    constructor(props) {
        super(props)

        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.refresh = this.refresh.bind(this)

        this.state = {
            units: [],
            assets: [],
            currencies: [],
            isLoading: true,
            isAvailable: false,
            isAuthenticated: false,
            login: this.login,
            logout: this.logout,
            refresh: this.refresh,
            currentUser: null,
        }
    }

    async componentDidMount() {
        try {
            await storage.ready()
            await this.checkAppHealth()
            await this.checkAuthorizationState()
            await this.fetchInitialData()
            await this.initServices()
        } catch (error) {
            throw new Error(error.message)
        } finally {
            this.setState({
                isLoading: false,
            })
        }
    }

    async checkAppHealth() {
        const { apolloClient } = this.props
        try {
            const { health } = await getAppHealth(apolloClient)
            this.setState({
                isAvailable: health.adminAppIsAvailable,
            })
        } catch (error) {
            throw new Error(`Failed to check app health. ${error}`)
        }
    }

    async checkAuthorizationState() {
        const { isAvailable } = this.state
        if (isAvailable) {
            const { accessToken } = await loadAndValidateTokens()
            const isAuthenticated = accessToken !== null
            this.setState({
                isAuthenticated,
            })
        }
    }

    async fetchInitialData() {
        const { apolloClient } = this.props
        const { isAvailable, isAuthenticated } = this.state
        if (isAvailable && isAuthenticated) {
            const {
                units,
                assets,
                currencies,
                me: currentUser,
            } = await getInitialData(apolloClient)
            if (currentUser === null) {
                await this.logout()
                return
            }
            this.setState({
                units,
                assets,
                currencies,
                currentUser,
            })
        }
    }

    async initServices() {
        const { isAvailable, isAuthenticated, currentUser } = this.state
        if (isAvailable && isAuthenticated) {
            const { accessToken } = await loadAndValidateTokens()
            getPusherConnection(accessToken)
            scopeSentryUser(currentUser)
        }
    }

    async login(accessToken, refreshToken, currentUser) {
        this.setState({ isLoading: true })
        await Promise.all([
            storage.setAccessToken(accessToken),
            storage.setRefreshToken(refreshToken)
        ])
        this.setState({
            isAuthenticated: accessToken !== null,
            currentUser,
        })
        await this.initServices()
        this.setState({ isLoading: false })
    }

    async logout() {
        const { apolloClient } = this.props
        if (storage.refreshToken) {
            await logout(storage.refreshToken)
        }
        await Promise.all([
            storage.removeAccessToken(),
            storage.removeRefreshToken()
        ])
        await apolloClient.clearStore()
        this.removeUserContext()
        this.setState({ isAuthenticated: false })
    }

    removeUserContext() {
        removeScopedSentryUser()
    }

    async refresh() {
        this.setState({
            isLoading: true,
            isAuthenticated: false,
            currentUser: null,
        })
        await this.checkAppHealth()
        await this.checkAuthorizationState()
        await this.fetchInitialData()
        await this.initServices()
        this.setState({ isLoading: false })
    }

    render() {
        const { apolloClient } = this.props
        const {
            isLoading,
            isAvailable,
            isAuthenticated,
        } = this.state
        return (
            <AppStateProvider value={this.state}>
                <AppProviders
                    apolloClient={apolloClient}
                >
                    <App
                        isLoading={isLoading}
                        isAvailable={isAvailable}
                        isAuthenticated={isAuthenticated}
                    />
                </AppProviders>
            </AppStateProvider>
        )
    }
}

export default AppWithState
