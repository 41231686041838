import React from 'react'
import CustomerLogItem from './CustomerLogItem'

const CustomerCreatedItem = ({
    appEvent
}) => (
    <CustomerLogItem
        isCreated
        createdAt={appEvent.createdAt}
        user={appEvent.user}
    >
        Customer <strong>{appEvent.payload.name}</strong> created.
    </CustomerLogItem>
)

export default CustomerCreatedItem
