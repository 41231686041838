import { gql } from '@apollo/client'

const CUSTOMER_FRAGMENT = gql`
    fragment Customer on Customer {
        id
        createdAt
        name
        balanceMargin
        hasTradeLimit
        minTradeLimit
        maxTradeLimit
        fixedTradeLimit
        tradeLimit {
            minTradeLimit
            maxTradeLimit
        }
    }
`

export default CUSTOMER_FRAGMENT
