/* eslint-disable no-param-reassign */
export const containsErrors = (errors) => errors.length > 0

export const containsNoErrors = (errors) => errors.length === 0

export const getErrorsPerStep = (keysPerStep, errors) => (
    keysPerStep.map((stepKeys) => stepKeys.reduce((stepErrors, key) => {
        const error = errors.find(({ path }) => path === key)
        if (typeof error !== 'undefined') {
            return [
                ...stepErrors,
                error,
            ]
        }
        return stepErrors
    }, []))
)

export const getNextStep = (steps, currentStep) => {
    const currentStepIndex = steps.indexOf(currentStep)
    return steps[currentStepIndex + 1] || null
}

export const getPreviousStep = (steps, currentStep) => {
    const currentStepIndex = steps.indexOf(currentStep)
    return steps[currentStepIndex - 1] || null
}

export const mapInitialFormValues = (initialValues) => (
    Object
        .keys(initialValues)
        .reduce((acc, curr) => {
            if (initialValues[curr] === null) {
                acc[curr] = ''
            } else {
                acc[curr] = initialValues[curr]
            }
            return acc
        }, {})
)
