import React from 'react'
import { Alert } from 'antd'
import { useNavigate } from 'react-router-dom'
import { delay } from '../../util'
import { isValidLoginForm } from '../../../validation/login/login'
import { getLoginFormErrors } from '../../../validation/login/loginErrors'
import FormState from '../../util/form/FormState'
import useAppState from '../../hooks/useAppState'
import LoginForm from './LoginForm'
import useLoginMutation from './useLoginMutation'

const LoginFormWithState = () => {
    const appState = useAppState()
    const navigate = useNavigate()
    const [login, { loading }] = useLoginMutation()
    return (
        <FormState
            isValidForm={isValidLoginForm}
            getFormErrors={getLoginFormErrors}
            initialState={{
                loginWasSuccessful: false,
                errorMessage: null,
            }}
            initialValues={{
                email: '',
                password: '',
            }}
        >
            {({
                state,
                setState,
                values,
                errors,
                checkValidation,
                handleChange,
            }) => {
                if (state.loginWasSuccessful) {
                    return (
                        <Alert
                            message="Login Successfull"
                            description="You will be redirected shortly."
                            type="success"
                            showIcon
                        />
                    )
                }
                return (
                    <LoginForm
                        isLoading={loading}
                        errorMessage={state.errorMessage}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onSubmit={async () => {
                            if (checkValidation()) {
                                try {
                                    const { data } = await login({ variables: { input: values } })
                                    setState({
                                        loginWasSuccessful: true,
                                        errorMessage: null,
                                    })
                                    await delay(2000)
                                    // setting the jwt token will re-render the App component
                                    const { accessToken, refreshToken, me } = data.loginWithPassword
                                    await appState.login(accessToken, refreshToken, me)
                                    navigate('/')
                                } catch (e) {
                                    setState({
                                        errorMessage: e.graphQLErrors[0].message,
                                    })
                                }
                            }
                        }}
                    />
                )
            }}
        </FormState>
    )
}

export default LoginFormWithState
