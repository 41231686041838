import { gql, useQuery } from '@apollo/client'

export const FIX_PAGE_ASSET_FRAGMENT = gql`
    fragment FixPageAsset on Asset {
        id
    }
`
export const FIX_PAGE_QUERY = gql`
    query fixPageQuery {
        assets {
            ...FixPageAsset
        }
        requestHedgeLogin {
            authorizationUri
            isAuthorized
        }
        appSettings {
            hedgeIsConnected
        }
    }
    ${FIX_PAGE_ASSET_FRAGMENT}
`

const useFetchHedgePageData = () => {
    const {
        data = {},
        loading,
        error,
        ...result
    } = useQuery(FIX_PAGE_QUERY)
    const {
        assets = {},
        requestHedgeLogin = {},
        appSettings = {},
    } = data
    return {
        error,
        assets,
        appSettings,
        requestHedgeLogin,
        isFetching: loading,
        ...result,
    }
}

export default useFetchHedgePageData
