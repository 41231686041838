import { useQuery, gql } from '@apollo/client'

export const PRICES_PAGE_QUERY = gql`
    query pricesPageQuery {
        assets {
            id
            suspended
            name
            code
            available
            currencies {
                code
                name
                symbol
            }
            units {
                code
                name
                milligram
            }
        }
    }
`

const useFetchPricesPageData = () => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(PRICES_PAGE_QUERY)
    const { assets = {} } = data

    return {
        error,
        isFetching: loading,
        assets,
    }
}

export default useFetchPricesPageData
