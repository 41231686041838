import React from 'react'
import {
    Col,
    Row,
    Form,
    Button,
} from 'antd'
import * as constants from '../../../util/constants'
import FormItem from '../../util/form/FormItem'
import UnitSelect from '../../util/form/UnitSelect'
import CurrencyValue from '../../util/CurrencyValue'
import InputNumber from '../../util/form/InputNumber'
import AssetSelect from '../../util/form/AssetSelect'
import CurrencySelect from '../../util/form/CurrencySelect'
import OrderSideButtons from '../orders/order-modal/OrderSideButtons'
import MarketDataContainer from '../orders/market-order-modal/MarketDataContainer'

const { SIDE_BUY } = constants

const CreateHedgeTradeForm = ({
    asset,
    units = [],
    assets = [],
    currencies = [],
    formState,
    onSubmit,
}) => {
    const {
        values,
        antdErrors,
        handleChange,
        getInputProps,
        getNativeInputProps,
    } = formState
    return (
        <Form layout="vertical">
            <FormItem>
                <OrderSideButtons
                    {...getNativeInputProps('side')}
                />
            </FormItem>
            <FormItem
                isRequired
                label="Quantity"
                error={antdErrors.get('quantity')}
            >
                <InputNumber
                    min={0}
                    step={1}
                    precision={0}
                    {...getInputProps('quantity')}
                    addonAfter={(
                        <UnitSelect
                            disabled
                            units={units}
                            {...getInputProps('unitCode')}
                        />
                    )}
                />
            </FormItem>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Currency"
                        error={antdErrors.get('currencyCode')}
                    >
                        <CurrencySelect
                            currencies={currencies}
                            {...getInputProps('currencyCode')}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <AssetSelect
                        isRequired
                        disabled
                        label="Asset"
                        {...getInputProps('assetId')}
                        error={antdErrors.get('assetId')}
                        assets={assets}
                        className="w-full"
                    />
                </Col>
            </Row>
            <MarketDataContainer
                side={values.side}
                assetCode={asset.code}
                unitCode={values.unitCode}
                currencyCode={values.currencyCode}
                onCompletedSpotPrice={(spotPrice) => {
                    handleChange('spotPrice', spotPrice / 100)
                }}
            />
            <FormItem
                label="Total Price"
            >
                <CurrencyValue
                    isBold
                    formatValue={false}
                    value={values.spotPrice * values.quantity}
                    currencyCode={values.currencyCode}
                />
            </FormItem>
            <Button
                type="primary"
                className="w-full"
                onClick={onSubmit}
                disabled={(
                    typeof values.quantity === 'undefined'
                    || values.quantity <= 0
                    || typeof values.spotPrice === 'undefined'
                    || values.spotPrice <= 0
                )}
            >
                {values.side === SIDE_BUY ? 'Buy' : 'Sell'}
            </Button>
        </Form>
    )
}
export default CreateHedgeTradeForm
