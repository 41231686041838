import React from 'react'
import { useParams } from 'react-router-dom'
import CustomerPageError from '../../layout/CustomerPageError'
import CustomerPage from '../../layout/CustomerPage'
import useFetchCustomerBalancePageData from './useFetchCustomerBalancePageData'
import CustomerBalancePage from './CustomerBalancePage'

const CustomerBalancePageWithState = () => {
    const params = useParams()
    const customerId = parseInt(params.customerId, 10)
    const {
        error,
        isFetching,
        customer,
    } = useFetchCustomerBalancePageData(customerId)

    if (typeof error !== 'undefined') {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem="balance"
            />
        )
    }

    return (
        <CustomerPage
            customerId={customerId}
            isLoading={isFetching}
            activeMenuItem="balance"
        >
            <CustomerBalancePage
                customer={customer}
            />
        </CustomerPage>
    )
}

export default CustomerBalancePageWithState
