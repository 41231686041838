import React from 'react'
import { milligramToUnitUsingOrderRounding } from '../../../../../util/units'
import PreferredUnits from '../../../../util/PreferredUnits'
import DeliveryLogItem from './DeliveryLogItem'

const DeliveryCreatedItem = ({
    assets,
    appEvent
}) => {
    const {
        customer, user, createdAt, payload
    } = appEvent
    return (
        <DeliveryLogItem
            isCreated
            createdAt={createdAt}
            user={user}
        >
            <div>
                Delivery of
                {' '}
                <strong>
                    <PreferredUnits>
                        {({ preferredUnitCode }) => (
                            `${milligramToUnitUsingOrderRounding(payload.quantity, preferredUnitCode)}${preferredUnitCode}`
                        )}
                    </PreferredUnits>
                    {' '}
                    {assets.find(({ id }) => id === payload.assetId).name}
                </strong> for
                {' '}
                <strong>{customer.name}</strong>
            </div>
        </DeliveryLogItem>
    )
}

export default DeliveryCreatedItem
