import React, { useState } from 'react'
import { notification } from 'antd'
import { useAsyncEffect } from '@wappla/react-hooks'
import { useApolloClient } from '@apollo/client'
import getAdjustedMarketData from '../../../api/getAdjustedMarketData'
import { whereId } from '../../util/index'
import * as constants from '../../../util/constants'
import CreateTradeModal from './CreateCustomerTradeModal'
import useCreateCustomerTradeQuery from './useCreateCustomerTradeQuery'
import useCreateCustomerTradeMutation from './useCreateCustomerTradeMutation'
import useCreateCustomerTradeFormState from './useCreateCustomerTradeFormState'

const {
    UNIT_CODE_KILOGRAM: KG,
} = constants

const CreateTradeModalWithState = ({
    date,
    assetId,
    customer,
    isOpen,
    closeModal,
    onCompleted,
}) => {
    const [pricing, setPricing] = useState({ buyPrice: 0, sellPrice: 0 })
    const apolloClient = useApolloClient()
    const {
        units,
        assets,
        currencies,
    } = useCreateCustomerTradeQuery()
    const [createCustomerTrade, { loading: isCreating }] = useCreateCustomerTradeMutation({
        onCompleted,
    })
    const formState = useCreateCustomerTradeFormState({
        date,
        assetId,
        customerId: customer.id,
        currencyCode: currencies[0].code,
        quantityUnitCode: KG,
        priceUnitCode: KG,
    })

    const { values } = formState
    useAsyncEffect(async () => {
        try {
            if (values.assetId) {
                const {
                    currencyCode,
                    priceUnitCode,
                } = values
                const assetCode = assets.find(whereId(values.assetId)).code
                const { data } = await getAdjustedMarketData(apolloClient, {
                    variables: {
                        assetCode,
                        currencyCode,
                        unitCode: priceUnitCode,
                    },
                })
                const { buyPrice, sellPrice } = data.adjustedMarketData
                setPricing({ buyPrice, sellPrice })
            }
        } catch (e) {
            notification.error({
                message: 'Could not fetch pricing',
                description: `Something went wrong. The following error occurred: ${e}`,
            })
        }
    }, [values.currencyCode, values.priceUnitCode, values.assetId])

    const handleCreateTrade = async () => {
        if (formState.validate()) {
            try {
                const input = formState.valuesToInput()
                await createCustomerTrade({
                    variables: {
                        input,
                    },
                })
                formState.resetForm()
                notification.success({
                    message: 'Trade created!',
                    description: 'Trade was successfully created!',
                })
            } catch (e) {
                notification.error({
                    message: 'Trade not created!',
                    description: `Something went wrong. The following error occurred: ${e}`,
                })
            }
        }
    }

    const handleCancelTrade = () => {
        formState.resetForm()
        closeModal()
    }

    return (
        <CreateTradeModal
            customer={customer}
            pricing={pricing}
            units={units}
            assets={assets}
            currencies={currencies}
            formState={formState}
            isOpen={isOpen}
            isCreating={isCreating}
            onCancel={handleCancelTrade}
            onConfirm={handleCreateTrade}
        />
    )
}

export default CreateTradeModalWithState
