import { gql } from '@apollo/client'

const ADMIN_USER_FRAGMENT = gql`
    fragment User on User {
        id
        createdAt
        firstName
        lastName
        email
        phone
        preferredCurrencyCode
        preferredUnitCode
        role {
            name
            id
        }
        customer {
            id
            name
        }
    }
`

export default ADMIN_USER_FRAGMENT
