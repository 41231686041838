import React from 'react'
import { SIDE_BUY } from '../../../util/constants'
import useUnfilledBuyOrdersQuery from './useUnfilledBuyOrdersQuery'
import UnfilledBuyCustomerOrdersTable from './UnfilledBuyCustomerOrdersTable'
import UnfilledBuyCustomerOrdersCalculationInfo from './UnfilledBuyCustomerOrdersCalculationInfo'

const calculateOrderQuantities = (orders, deliveries) => orders.map((order) => {
    const updatedOrder = { ...order }
    deliveries.some((delivery) => {
        if (order.id === delivery.orderId) {
            updatedOrder.outstandingQuantity -= delivery.quantity
            return true
        }
        return false
    })
    let originalOutstandingQuantity = null
    if (order.outstandingQuantity < order.quantity) {
        originalOutstandingQuantity = order.outstandingQuantity
    }
    return {
        ...updatedOrder,
        originalOutstandingQuantity,
    }
})

const UnfilledBuyCustomerOrdersTableWithData = ({
    customerId,
    assetId,
    formState,
}) => {
    const {
        loading,
        customerOrders,
    } = useUnfilledBuyOrdersQuery({
        variables: {
            first: 500,
            customerId,
            where: { assetId, side: SIDE_BUY, deliveryStatus: ['awaitingDelivery'] },
        },
    })
    const updatedCustomerOrders = calculateOrderQuantities(customerOrders, formState.values.orderPickups)
    const deliveryQuantity = formState.values.orderPickups.reduce((total, delivery) => total + delivery.quantity, 0)

    return (
        <>
            <UnfilledBuyCustomerOrdersTable
                orders={updatedCustomerOrders}
                isLoading={loading}
                formState={formState}
            />
            <UnfilledBuyCustomerOrdersCalculationInfo
                quantityAvailable={formState.values.quantity - deliveryQuantity}
                totalQuantity={formState.values.quantity}
                error={formState.antdErrors.get('orderPickups')}
            />
        </>
    )
}

export default UnfilledBuyCustomerOrdersTableWithData
