import React from 'react'
import { Select } from 'antd'
import useAppState from '../../hooks/useAppState'

const CurrencySelect = ({
    name,
    value,
    onChange,
    disabled,
    multiple,
    placeholder = 'Select a currency',
}) => {
    const { currencies } = useAppState()
    let selectProps = { value }
    if (multiple) {
        selectProps = {
            mode: 'multiple',
            defaultValue: value,
            filterOption: (input, option) => (
                option.props.children.toLowerCase().includes(input.toLowerCase())
            ),
        }
    }
    return (
        <Select
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            {...selectProps}
        >
            {currencies.map((currency) => (
                <Select.Option
                    key={currency.code}
                    value={currency.code}
                >
                    {currency.realSymbol} {currency.code}
                </Select.Option>
            ))}
        </Select>
    )
}

export default CurrencySelect
