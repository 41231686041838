import { fixFloatingPointError, milligramToUnitUsingOrderRounding } from './units'

// We use milligrams as source of truth
// This means the given quantity is always in milligrams

export const convertQuantityToGrams = (quantity) => quantity / 1000

export const convertQuantityToKilograms = (quantity) => quantity / 1000000

export const convertQuantityToOunces = (quantity) => quantity / 28349.5

export const convertQuantityToPounds = (quantity) => quantity / 453592

export const convertQuantityToMilligrams = (quantity, unitCode) => {
    let convertedQuantity
    switch (unitCode) {
        case 'mg':
            convertedQuantity = quantity
            break
        case 'g':
            convertedQuantity = quantity * 1000
            break
        case 'kg':
            convertedQuantity = quantity * 1000000
            break
        case 'oz':
            convertedQuantity = quantity / 0.000032151
            break
        case 'lb':
            convertedQuantity = quantity * 453592
            break
        default:
            throw new Error(`The given unit ${unitCode} cannot be converted!`)
    }
    return fixFloatingPointError(convertedQuantity)
}

export const convertQuantity = (quantity, newUnitCode) => (
    milligramToUnitUsingOrderRounding(quantity, newUnitCode)
)
