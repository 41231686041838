import { gql } from '@apollo/client'

const MARK_AS_UNPAID_MUTATION = gql`
    mutation markOrderUnpaid($input: OrderPaymentInput!) {
        markOrderUnpaid(input: $input) {
            order {
                id
                totalOutstandingPrice
            }
        }
    }
`

export default async function markOrderUnpaid(apolloClient, orderId, customerId, options) {
    const mutation = MARK_AS_UNPAID_MUTATION
    const variables = { input: { orderId, customerId } }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
