import { gql, useMutation } from '@apollo/client'

const CHOOSE_NEW_PASSWORD_MUTATION = gql`
    mutation choosePassword($input: ChoosePasswordInput!) {
        choosePassword(input: $input) {
            accessToken
            refreshToken
        }
    }
`

const useChoosePasswordMutation = (options) => (
    useMutation(CHOOSE_NEW_PASSWORD_MUTATION, options)
)

export default useChoosePasswordMutation
