import React from 'react'
import { Modal, Form, Input } from 'antd'
import FormItem from '../../util/form/FormItem'

const { TextArea } = Input

const EditOrderModal = ({
    formState,
    onUpdateOrder,
    onCloseModal,
    isVisible,
    isUpdating,
}) => {
    const {
        antdErrors,
        getNativeInputProps,
    } = formState
    return (
        <Modal
            title="Edit notes"
            visible={isVisible}
            width={400}
            onOk={onUpdateOrder}
            closable={!isUpdating}
            maskClosable={!isUpdating}
            confirmLoading={isUpdating}
            onCancel={onCloseModal}
            okText={isUpdating ? 'Updating...' : 'Update'}
        >
            <Form layout="vertical">
                <FormItem
                    label="Notes"
                    error={antdErrors.get('notes')}
                >
                    <TextArea
                        rows={4}
                        {...getNativeInputProps('notes')}
                    />
                </FormItem>
            </Form>
        </Modal>
    )
}

export default EditOrderModal
