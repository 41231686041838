import React from 'react'
import clsx from 'clsx'
import { InfoCircleOutlined } from '@ant-design/icons'

const ExtraInfoLabel = ({
    message,
    type = '',
}) => (
    <div
        className={
            clsx(
                'text-xs my-2',
                type === 'danger' && 'text-red-500',
                type === 'warning' && 'text-yellow-500',
            )
        }
    >
        <InfoCircleOutlined />
        <span> {message}</span>
    </div>
)

export default ExtraInfoLabel
