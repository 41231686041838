import React from 'react'
import { Link } from 'react-router-dom'
import {
    Affix,
    Button,
    PageHeader,
} from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import CustomerAssetForm from './CustomerAssetForm'
import CalculationInfoAlert from '../update-asset/PriceAdjustmentInfoAlert'

const UpdateCustomerAssetPage = ({
    customerId,
    currencies,
    units,
    routes,
    isSaving,
    formState,
    onSaveAsset,
}) => (
    <>
        <Affix>
            <PageHeader style={{ backgroundColor: 'white' }}>
                <div className="flex justify-between">
                    <Link to={routes.customerAssetSettings(customerId)}>
                        <LeftOutlined className="mr-4" />Back to assets
                    </Link>
                    <Button
                        type="primary"
                        onClick={onSaveAsset}
                        loading={isSaving}
                    >
                        Save asset
                    </Button>
                </div>
            </PageHeader>
        </Affix>
        <CustomerAssetForm
            formState={formState}
            currencies={currencies}
            units={units}
        />
        <CalculationInfoAlert />
    </>
)

export default UpdateCustomerAssetPage
