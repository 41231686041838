import { gql } from '@apollo/client'

const SUSPEND_ASSET_MUTATION = gql`
    mutation toggleSuspendAsset($id: Int!) {
        toggleSuspendAsset(id: $id) {
            asset {
                id
                suspended
            }
        }
    }
`

export default async function toggleSuspendAsset(apolloClient, id, options) {
    const mutation = SUSPEND_ASSET_MUTATION
    const variables = { id }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
