import React from 'react'
import { useParams } from 'react-router-dom'
import { removeEdgeFromCollectionWhereNodeId } from '../../../util'
import CustomerPageError from '../../layout/CustomerPageError'
import CustomerPaymentsPage from './CustomerPaymentsPage'
import useCustomerPaymentsPageQuery, { updateCustomerPaymentsQuery } from './useCustomerPaymentsPageQuery'

const activeMenuItem = 'payments'
const CustomerPaymentsPageWithState = () => {
    const params = useParams()
    const customerId = parseInt(params.customerId, 10)
    const {
        error,
        loading,
        hasMore,
        isFetchingMore,
        customer,
        customerPayments,
        handleFetchMore,
        queryToRefetch,
    } = useCustomerPaymentsPageQuery({
        variables: {
            customerId,
        },
    })

    if (typeof error !== 'undefined') {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem={activeMenuItem}
            />
        )
    }

    return (
        <CustomerPaymentsPage
            activeMenuItem={activeMenuItem}
            hasMore={hasMore}
            isFetching={loading}
            isFetchingMore={isFetchingMore}
            customer={customer}
            customerPayments={customerPayments}
            handleFetchMore={handleFetchMore}
            onDeleteComplete={(paymentId) => {
                updateCustomerPaymentsQuery({
                    path: 'customerPayments.edges',
                    value: removeEdgeFromCollectionWhereNodeId(paymentId),
                    variables: { customerId },
                })
            }}
            queryToRefetch={queryToRefetch}
        />
    )
}

export default CustomerPaymentsPageWithState
