import React from 'react'
import PageError from '../../layout/PageError'
import PageLoading from '../../layout/PageLoading'
import useModalState from '../../hooks/useModalState'
import CustomersPage from './CustomersPage'
import useFetchCustomersPageData from './useFetchCustomersPageData'
import CreateCustomerModalWithState from './CreateCustomerModalWithState'

const CustomersPageWithState = () => {
    const createCustomerModalState = useModalState()
    const {
        customers,
        loading,
        error,
        isFetchingMore,
        hasMore,
        handleFetchMore,
    } = useFetchCustomersPageData()

    if (loading) {
        return <PageLoading activeMenuItem="customers" />
    }
    if (error) {
        return <PageError />
    }

    return (
        <>
            <CustomersPage
                hasMore={hasMore}
                customers={customers}
                loading={loading}
                isFetchingMore={isFetchingMore}
                handleFetchMore={handleFetchMore}
                onCreateCustomerClick={() => {
                    createCustomerModalState.open()
                }}
            />
            {createCustomerModalState.isOpen && (
                <CreateCustomerModalWithState
                    onCreateCustomerComplete={() => createCustomerModalState.close()}
                    closeModal={() => createCustomerModalState.close()}
                    {...createCustomerModalState.initialState}
                />
            )}
        </>
    )
}

export default CustomersPageWithState
