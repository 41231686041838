import { useEffect } from 'react'

const POLL_FREQUENCY = 1000 * 3 // 3 sec

const usePolling = (start, stop, frequency = POLL_FREQUENCY) => {
    useEffect(() => {
        start(frequency)
        return () => stop()
    }, [start, stop])
}

export default usePolling
