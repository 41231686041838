import React from 'react'
import {
    Row,
    Col,
    Form,
    Button,
} from 'antd'
import { getRoundingForUnit } from '../../../util/units'
import * as constants from '../../../util/constants'
import FormItem from '../../util/form/FormItem'
import InputNumber from '../../util/form/InputNumber'
import UnitSelect from '../../util/form/UnitSelectWithCache'
import AssetSelect from '../../util/form/AssetSelect'
import CurrencySelect from '../../util/form/CurrencySelectWithCache'
import CustomerSearchSelect from '../../util/form/CustomerSearchSelect'
import OrderSideButtons from './order-modal/OrderSideButtons'
import CustomerMarketDataContainer from './order-modal/CustomerMarketDataContainer'

const { SIDE_BUY } = constants

const CreateOrderForm = ({
    formState,
    asset,
    units,
    assets,
    currencies,
    isFetching,
    customers,
    selectedCustomer,
    onSearchCustomers,
    onSubmit,
}) => {
    const {
        values,
        antdErrors,
        setValues,
        handleChange,
        getInputProps,
        getNativeInputProps,
    } = formState
    return (
        <Form layout="vertical">
            <FormItem>
                <OrderSideButtons
                    {...getNativeInputProps('side')}
                />
            </FormItem>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Customer"
                        error={antdErrors.customerId}
                    >
                        <CustomerSearchSelect
                            disabled={isFetching}
                            value={values.customerId}
                            customer={values.customer}
                            onSearch={onSearchCustomers}
                            onChange={(id, customer) => {
                                setValues({
                                    customerId: id,
                                    customer,
                                })
                            }}
                            className="w-full"
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <AssetSelect
                        isRequired
                        disabled
                        label="Asset"
                        {...getInputProps('assetId')}
                        error={antdErrors.get('assetId')}
                        assets={assets}
                        className="w-full"
                    />
                </Col>
            </Row>
            <CustomerMarketDataContainer
                customerId={values.customerId}
                side={values.side}
                assetCode={asset.code}
                unitCode={values.unitCode}
                currencyCode={values.currencyCode}
                onCompletedSpotPrice={(spotPrice) => {
                    handleChange('spotPrice', spotPrice / 100)
                }}
            />
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Quantity"
                        error={antdErrors.get('quantity')}
                    >
                        <InputNumber
                            min={0}
                            step={1}
                            precision={0}
                            disabled={isFetching}
                            {...getInputProps('quantity')}
                            addonAfter={(
                                <UnitSelect
                                    units={units}
                                    {...getInputProps('unitCode')}
                                />
                            )}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Price"
                        error={antdErrors.get('spotPrice')}
                    >
                        <InputNumber
                            {...getInputProps('spotPrice')}
                            min={0}
                            precision={getRoundingForUnit(values.unitCode)}
                            disabled={isFetching || typeof values.currencyCode === 'undefined' || typeof values.unitCode === 'undefined'}
                            addonAfter={(
                                <CurrencySelect
                                    currencies={currencies}
                                    {...getInputProps('currencyCode')}
                                />
                            )}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Button
                type="primary"
                className="w-full"
                onClick={onSubmit}
                disabled={(
                    isFetching
                    || typeof values.quantity === 'undefined'
                    || values.quantity <= 0
                    || typeof values.spotPrice === 'undefined'
                    || values.spotPrice <= 0
                )}
            >
                {values.side === SIDE_BUY ? 'Buy' : 'Sell'}
            </Button>
        </Form>
    )
}

export default CreateOrderForm
