import React from 'react'
import { unitTo } from '../../../../util/units'
import { UNIT_CODE_MILLIGRAM } from '../../../../util/constants'
import OrderInfo from './OrderInfo'
import DeliveriesTable from './DeliveriesTable'

const OrderDeliveriesForm = ({
    formState,
    order,
    onAdd,
    onRemove,
    deliveries,
    remainingQuantityToFill,
}) => {
    const { antdErrors } = formState
    const error = antdErrors.get('orderDeliveries')

    return (
        <>
            <OrderInfo order={order} />
            <DeliveriesTable
                order={order}
                deliveries={deliveries}
                remainingQuantityToFill={remainingQuantityToFill}
                onAdd={onAdd}
                onRemove={onRemove}
            />
            <>
                <h2 className="text-2xl">Remaining quantity to fill: {unitTo(remainingQuantityToFill, UNIT_CODE_MILLIGRAM, order.quantityUnitCode)} {order.quantityUnitCode}</h2>
                {error && <span className="text-red-500">{error}</span>}
            </>
        </>
    )
}

export default OrderDeliveriesForm
