import React from 'react'
import CustomerLogItem from './CustomerLogItem'

const CustomerDeletedItem = ({
    appEvent
}) => (
    <CustomerLogItem
        isDeleted
        createdAt={appEvent.createdAt}
        user={appEvent.user}
    >
        Customer <strong>{appEvent.payload.name}</strong> deleted.
    </CustomerLogItem>
)

export default CustomerDeletedItem
