import React from 'react'
import createStore from 'unistore'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider, ApolloProvider as ApolloHooksProvider } from '@apollo/client'
import { Provider as UnistoreProvider } from 'unistore/react'

import AppErrorBoundary from './AppErrorBoundary'

const unistore = createStore({
    openModals: {},
    openDrawers: {},
})

function AppProviders({
    apolloClient,
    children,
}) {
    return (
        <BrowserRouter>
            <UnistoreProvider store={unistore}>
                <ApolloProvider client={apolloClient}>
                    <ApolloHooksProvider client={apolloClient}>
                        <AppErrorBoundary>
                            {children}
                        </AppErrorBoundary>
                    </ApolloHooksProvider>
                </ApolloProvider>
            </UnistoreProvider>
        </BrowserRouter>
    )
}

export default AppProviders
