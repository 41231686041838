import { useQuery, gql } from '@apollo/client'
import { UNIT_FRAGMENT, CURRENCY_FRAGMENT } from '../../fragments/ConfigFragments'

export const CREATE_TRADE_QUERY = gql`
    query createTradeQuery {
        assets {
            id
            name
            code
        }
        units {
            ...Unit
        }
        currencies {
            ...Currency
        }
    }
    ${UNIT_FRAGMENT}
    ${CURRENCY_FRAGMENT}
`

const useCreateTradeQuery = () => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(CREATE_TRADE_QUERY)

    const {
        units = [],
        assets = [],
        currencies = [],
    } = data

    return ({
        error,
        isFetching: loading,
        units,
        assets,
        currencies,
    })
}

export default useCreateTradeQuery
