import { gql } from '@apollo/client'
import ADMIN_USER_FRAGMENT from '../components/pages/users/AdminUserFragment'

const CREATE_ADMIN_USER = gql`
    mutation createAdminUser($input: AdminUserInput!) {
        createAdminUser(input: $input) {
            user {
                ...User
            }
        }
    }
    ${ADMIN_USER_FRAGMENT}
`

export default async function createAdminUser(apolloClient, input, refetchQueries) {
    const mutation = CREATE_ADMIN_USER
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        refetchQueries,
    })
    return response
}
