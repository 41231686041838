import { gql, useMutation } from '@apollo/client'
import ME_FRAGMENT from '../../fragments/MeFragment'

const LOGIN_MUTATION = gql`
    mutation login($input: LoginWithPasswordInput!) {
        loginWithPassword(input: $input) {
            accessToken
            refreshToken
            me {
                ...Me
            }
        }
    }
    ${ME_FRAGMENT}
`

const useLoginMutation = (options) => (
    useMutation(LOGIN_MUTATION, options)
)

export default useLoginMutation
