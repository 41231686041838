import moment from 'moment'

const Date = ({
    value,
    format = 'DD/MM HH:mm',
}) => (
    moment(value).format(format)
)

export default Date
