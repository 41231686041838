import { createValidationErrorObject } from '../index'
import * as validation from './createPayment'

export const getType = createValidationErrorObject(
    validation.isValidType,
    'type',
    'Please select if you want to withdraw or deposit.'
)

export const getAmount = createValidationErrorObject(
    validation.isValidAmount,
    'amount',
    'Please fill in a valid amount.'
)

export const getCurrencyCode = createValidationErrorObject(
    validation.isValidCurrencyCode,
    'currencyCode',
    'Please select a currency.'
)

export const getCreatePaymentFormErrors = ({
    type,
    amount,
    currencyCode,
}) => ({
    ...getType(type),
    ...getAmount(amount),
    ...getCurrencyCode(currencyCode),
})
