import React, { useEffect } from 'react'
import moment from 'moment'
import { useParams, Navigate } from 'react-router-dom'
import * as routes from '../../../routes'
import * as constants from '../../../util/constants'
import { subscribeToEvent } from '../../../services/pusher/pusher'
import PageLoading from '../../layout/PageLoading'
import PageError from '../../layout/PageError'
import useModalState from '../../hooks/useModalState'
import useActiveRangeFilter, { TODAY } from '../../hooks/useActiveRangeFilter'
import ExportOrdersToFileModalWithState from './export-orders/ExportOrdersModalWithState'
import OrdersPage from './OrdersPage'
import useFilterState from './useOrdersFilterState'
import useOrdersPageQuery from './useOrdersPageQuery'
import CreateOrderModalWithState from './CreateOrderModalWithState'

const {
    PUSHER_ADMIN_CHANNEL_NAME: ADMIN_CHANNEL,
} = constants

const getAssetIds = (assets) => assets.map(({ id }) => id)
const doesAssetExists = (assetId, assetIds) => assetIds.includes(assetId)

const OrdersPageWithState = () => {
    const params = useParams()
    const assetId = parseInt(params.assetId, 10) || 1
    const activeMenuItem = `orders:${assetId}`
    const createOrderModalState = useModalState()
    const exportOrdersModalState = useModalState()
    const {
        filters,
        handleChangeFilter,
    } = useFilterState({
        assetId,
        from: moment().clone().startOf('day'),
        till: moment().clone().endOf('day'),
        dateFilter: TODAY,
    })
    useActiveRangeFilter(filters.dateFilter, (from, till) => (
        handleChangeFilter({ from, till })
    ))
    const {
        error,
        isFetching,
        isFetchingMoreOrders,
        assets,
        combinedOrders,
        hasMoreOrders,
        handleFetchMoreOrders,
        refetch,
    } = useOrdersPageQuery(filters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => subscribeToEvent(ADMIN_CHANNEL, 'newCustomerOrder', refetch), [])

    if (isFetching) {
        return <PageLoading activeMenuItem={activeMenuItem} />
    }
    const assetIds = getAssetIds(assets)
    const assetExists = doesAssetExists(assetId, assetIds)
    if (!assetExists) {
        const firstAssetId = assetIds[0]
        return <Navigate to={routes.assetOrders(firstAssetId)} />
    }
    if (typeof error !== 'undefined' || assetIds.length <= 0) {
        return <PageError activeMenuItem={activeMenuItem} />
    }

    return (
        <>
            <OrdersPage
                hasMore={hasMoreOrders}
                orders={combinedOrders}
                isFetchingMore={isFetchingMoreOrders}
                activeMenuItem={activeMenuItem}
                refetch={refetch}
                fetchMore={handleFetchMoreOrders}
                filters={filters}
                onExportOrders={() => exportOrdersModalState.open({ dateRange: [filters.from, filters.till] })}
                onCreateCustomerOrder={() => createOrderModalState.open()}
                onChangeDateFilter={(dateFilter) => handleChangeFilter({ dateFilter })}
                onChangeRangeFilter={(rangeFilter) => {
                    if (rangeFilter !== null && typeof rangeFilter !== 'undefined') {
                        handleChangeFilter({
                            from: rangeFilter[0],
                            till: rangeFilter[1],
                            dateFilter: null,
                        })
                    }
                }}
                onChangeSearchFilter={(orderId) => handleChangeFilter({ orderId })}
                onChangeCustomerFilter={(customerId, customer) => (
                    handleChangeFilter({ customerId, customer })
                )}
            />
            {exportOrdersModalState.isOpen && (
                <ExportOrdersToFileModalWithState
                    isOpen={exportOrdersModalState.isOpen}
                    onExportOrdersToFileComplete={exportOrdersModalState.close}
                    closeModal={exportOrdersModalState.close}
                    assetId={assetId}
                    {...exportOrdersModalState.initialState}
                />
            )}
            {createOrderModalState.isOpen && (
                <CreateOrderModalWithState
                    assetId={assetId}
                    isOpen={createOrderModalState.isOpen}
                    onCreateOrderCompleted={createOrderModalState.close}
                    closeModal={createOrderModalState.close}
                    {...createOrderModalState.initialState}
                />
            )}
        </>
    )
}

export default OrdersPageWithState
