import React from 'react'

const MarketDataPlaceholder = ({
    title,
    text,
    icon,
}) => (
    <div className="flex flex-col h-14 mb-4">
        <div className="flex flex-1 items-center">
            <div className="flex items-center justify-center">
                {icon}
            </div>
            <div className="text">
                <h5 className="mb-1">{title}</h5>
                <div>{text}</div>
            </div>
        </div>
    </div>
)

export default MarketDataPlaceholder
