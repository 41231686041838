import React from 'react'
import TradesSearchInput from './TradesSearchInput'
import CustomerTradesTableFilter from './CustomerOrdersTableFilter'
import { STATUS_FILTER_OPTIONS, PAYMENT_FILTER_OPTIONS } from './useFetchCustomerOrdersPageData'

const CustomerTradesTableHeader = ({
    filters,
    onSearchOrder,
    onResetOrderSearch,
    onResetTableFilter,
}) => {
    const {
        orderId,
        statusFilter,
        paymentFilter,
    } = filters

    const appliedFilters = []
    if (orderId !== null) {
        appliedFilters.push(`Ref. ${orderId}`)
    }
    if (statusFilter.length > 0 && statusFilter.length !== STATUS_FILTER_OPTIONS.length) {
        appliedFilters.push(`Transfer: ${statusFilter.map((status, index) => (
            `${status}${index !== statusFilter.length - 1 ? ', ' : ''}`
        ))}`)
    }
    if (paymentFilter.length > 0 && paymentFilter.length !== PAYMENT_FILTER_OPTIONS.length) {
        appliedFilters.push(`Payment: ${paymentFilter.map((paymentStatus, index) => (
            `${paymentStatus}${index !== paymentFilter.length - 1 ? ', ' : ''}`
        ))}`)
    }

    return (
        <div className="flex justify-between">
            <div className="flex mt-2 h-8 mb-4">
                <CustomerTradesTableFilter
                    filters={filters}
                    onResetTableFilter={onResetTableFilter}
                />
            </div>
            <div className="h-8 mb-4">
                <TradesSearchInput
                    onSearchOrder={onSearchOrder}
                    searchOrderRef={orderId}
                    onResetOrderSearch={onResetOrderSearch}
                />
            </div>
        </div>
    )
}

export default CustomerTradesTableHeader
