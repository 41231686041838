import React from 'react'
import AssetLogItem from './AssetLogItem'

const AssetSuspendedItem = ({
    appEvent
}) => {
    const { createdAt, user, payload } = appEvent
    const { suspended, name, code } = payload
    return (
        <AssetLogItem
            isCreated={!suspended}
            isDeleted={suspended}
            createdAt={createdAt}
            user={user}
        >
            <strong>{name} ({code})</strong> {suspended ? 'suspended' : 'available'}.
        </AssetLogItem>
    )
}

export default AssetSuspendedItem
