import { useQuery, gql } from '@apollo/client'
import { connectionToCollection, PAGE_INFO_FRAGMENT } from '../../../util/graphql'

export const QUERY = gql`
    query customerUnresolvedPickupQuery(
        $customerId: Int!,
        $after: String,
        $first: Int,
        $where: PickupWhereInput,
    ) {
        customerPickups(
            customerId: $customerId,
            after: $after,
            first: $first,
            where: $where,
        ) {
            edges {
                node {
                    id
                    quantity
                    unresolvedQuantity
                    createdAt
                    customer {
                        id
                        name
                    }
                    asset {
                        id
                        name
                    }
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const useAddPickupToOrderQuery = (
    customerId,
    assetId,
    first,
    after,
) => {
    const variables = {
        customerId,
        first,
        after,
        where: {
            assetId,
            unresolvedQuantityIsNot: 0,
        },
    }
    const {
        loading,
        data = {},
    } = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })
    let { customerPickups = [] } = data

    if (!loading && typeof data.customerPickups !== 'undefined') {
        customerPickups = connectionToCollection(data.customerPickups)
    }

    return {
        isFetching: loading,
        customerPickups,
    }
}

export default useAddPickupToOrderQuery
