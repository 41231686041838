import React from 'react'
import { Input } from 'antd'
import roundTo from 'round-to'

const formatValue = (value, precision, min, max) => {
    if (value === '') {
        return value
    }
    if (value < min) {
        return min
    }
    if (value < max) {
        return max
    }
    const numberValue = value * 1
    if (typeof numberValue === 'number') {
        return roundTo(numberValue, precision)
    }
}

const InputNumber = ({
    min,
    max,
    step = 1,
    precision = 0,
    value,
    onChange,
    disabled,
    addonAfter = '',
    addonBefore = '',
}) => (
    <Input
        min={min}
        max={max}
        step={step}
        type="number"
        value={value}
        onChange={(e) => {
            const newValue = formatValue(e.target.value, precision, min, max)
            return onChange(newValue)
        }}
        disabled={disabled}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
    />
)

export default InputNumber
