import { gql, useMutation } from '@apollo/client'

const START_CONNECTIONS_MUTATION = gql`
    mutation startMarketConnections {
        startMarketConnections
    }
`

const useStartConnectionsMutation = (options) => (
    useMutation(START_CONNECTIONS_MUTATION, options)
)

export default useStartConnectionsMutation
