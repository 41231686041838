import React from 'react'
import { Form, Col, Row } from 'antd'
import FormItem from '../../../../util/form/FormItem'
import CustomerSearchSelect from '../../../../util/form/CustomerSearchSelect'
import AssetSelect from '../../../../util/form/AssetSelect'
import UnitInputWithConverter from '../../../../util/form/UnitInputWithConverter'
import ExtraInfoLabel from '../../../../util/form/ExtraInfoLabel'
import CustomerOrdersTable from '../table/CustomerOrdersTable'
import CustomerOrdersTableWithData from '../table/CustomerOrdersTableWithData'

const CreateDeliveryForm = ({
    formState,
    assets,
    onFill,
    onUnFill,
}) => {
    const {
        values,
        setValues,
        antdErrors,
        getInputProps,
    } = formState
    const {
        customer,
        assetId,
    } = values

    return (
        <Form layout="vertical">
            <FormItem
                label="Customer"
                error={antdErrors.get('customer')}
            >
                <CustomerSearchSelect
                    className="w-full"
                    customer={customer !== null ? customer : undefined}
                    value={customer !== null ? customer.id : undefined}
                    onChange={(customerId, newCustomer) => {
                        let inputCustomer = null
                        if (typeof newCustomer !== 'undefined') {
                            inputCustomer = newCustomer
                        }
                        setValues({
                            customer: inputCustomer,
                            orderDeliveries: [],
                            quantity: 0,
                        })
                    }}
                />
            </FormItem>
            <Row gutter={16}>
                <Col span={12}>
                    <AssetSelect
                        {...getInputProps('assetId')}
                        className="w-full"
                        error={antdErrors.get('assetId')}
                        label="Asset"
                        assets={assets}
                        onChange={(newAssetId) => setValues({
                            assetId: newAssetId,
                            orderDeliveries: [],
                        })}
                    />
                </Col>
                <Col span={12}>
                    <FormItem
                        label="Quantity"
                        style={{ width: '100%' }}
                    >
                        <UnitInputWithConverter
                            {...getInputProps('quantity')}
                            error={antdErrors.get('quantity')}
                        />
                    </FormItem>
                </Col>
            </Row>
            {(() => {
                if (customer !== null && assetId !== null) {
                    return (
                        <>
                            <CustomerOrdersTableWithData
                                formValues={values}
                                onFill={onFill}
                                onUnFill={onUnFill}
                                error={antdErrors.get('orderDeliveries')}
                            />
                            <ExtraInfoLabel message="Changes made in this form will not be saved until the form is submitted." />
                        </>
                    )
                }
                return (
                    <>
                        <CustomerOrdersTable
                            orders={[]}
                        />
                        <ExtraInfoLabel message="Search for a customer and select an asset first to view the orders." />
                    </>
                )
            })()}
        </Form>
    )
}

export default CreateDeliveryForm
