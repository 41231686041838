import React from 'react'
import moment from 'moment'
import { Tag, Popover } from 'antd'
import * as constants from '../../../util/constants'
import { whereId } from '../../util'
import UnitValue from '../../util/UnitValue'
import CurrencyValue from '../../util/CurrencyValue'
import TradeLogItem from './items/trade/TradeLogItem'

const { SIDE_SELL } = constants

const LogTradeCreated = ({
    assets,
    appEvent,
}) => {
    const {
        user,
        payload,
        createdAt,
    } = appEvent
    const {
        type,
        date,
        side,
        assetId,
        quantity,
        quantityUnitCode,
        totalPrice,
        counterParty,
        currencyCode,
    } = payload
    const isSell = side === SIDE_SELL
    const color = isSell ? 'green' : 'blue'
    return (
        <TradeLogItem
            isCreated
            createdAt={createdAt}
            user={user}
        >
            <div>
                Trade created{' '}
                <Popover
                    placement="bottom"
                    content={(
                        <div>
                            <ul>
                                <li>{counterParty} - {type.toUpperCase()}</li>
                                <li>
                                    Date:{' '}
                                    {moment(date).format('DD/MM/YY HH:mm')}
                                </li>
                                <li>
                                    Asset:{' '}
                                    <strong>
                                        {assets.find(whereId(assetId)).name}
                                    </strong>
                                </li>
                                <li>
                                    Quantity:{' '}
                                    <UnitValue
                                        isBold
                                        value={quantity}
                                        unitCode={quantityUnitCode}
                                    />
                                </li>
                                <li>
                                    Price:{' '}
                                    <CurrencyValue
                                        isBold
                                        value={totalPrice}
                                        currencyCode={currencyCode}
                                    />
                                </li>
                            </ul>
                        </div>
                    )}
                >
                    <Tag color={color}>Data</Tag>
                </Popover>
            </div>
        </TradeLogItem>
    )
}

export default LogTradeCreated
