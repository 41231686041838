import React from 'react'
import {
    Col,
    Row,
    Form,
    Input,
    InputNumber,
    DatePicker,
} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { createRange } from '../../../util'
import {
    calculateStep,
    getRoundingForUnit,
} from '../../../util/units'
import * as constants from '../../../util/constants'
import FormItem from '../../util/form/FormItem'
import UnitSelect from '../../util/form/UnitSelect'
import AssetSelect from '../../util/form/AssetSelect'
import CurrencySelect from '../../util/form/CurrencySelect'
import CustomerSearchSelect from '../../util/form/CustomerSearchSelect'
import OrderSideButtons from '../orders/order-modal/OrderSideButtons'
import TypeSelect from './TypeSelect'

const { SIDE_BUY } = constants

const { TextArea } = Input

export const pricingIsWithinValidRange = (value, pricing, side) => {
    const currentPrice = side === SIDE_BUY ? pricing.buyPrice : pricing.sellPrice
    const { min, max } = createRange(currentPrice, 15)
    return min <= value && value <= max
}

const CreateTradeForm = ({
    pricing,
    units = [],
    assets = [],
    currencies = [],
    formState,
}) => {
    const {
        values,
        setValues,
        handleChange,
        getInputProps,
        getNativeInputProps,
        antdErrors,
    } = formState
    return (
        <Form layout="vertical">
            <FormItem>
                <OrderSideButtons
                    {...getNativeInputProps('side')}
                />
            </FormItem>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Date"
                        error={antdErrors.get('date')}
                    >
                        <DatePicker
                            format="DD/MM/YYYY HH:mm"
                            showTime={{ format: 'HH:mm' }}
                            className="w-full"
                            {...getInputProps('date')}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Type"
                        error={antdErrors.get('type')}
                    >
                        <TypeSelect
                            {...getInputProps('type')}
                            onChange={(value) => {
                                if (value !== TypeSelect.OTHER) {
                                    setValues({
                                        type: value,
                                        counterParty: null,
                                    })
                                } else {
                                    handleChange('type', value)
                                }
                            }}
                        />
                    </FormItem>
                </Col>
            </Row>
            {values.type === TypeSelect.OTHER && (
                <FormItem
                    label="Counterparty"
                    error={antdErrors.get('counterParty')}
                >
                    <Input
                        {...getNativeInputProps('counterParty')}
                    />
                </FormItem>
            )}
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        isRequired={values.type === TypeSelect.ORDER}
                        label="Customer"
                        error={antdErrors.get('customer')}
                    >
                        <CustomerSearchSelect
                            disabled={values.type === TypeSelect.HEDGE}
                            value={values.customer !== null ? values.customer.id : undefined}
                            customer={values.customer !== null ? values.customer : undefined}
                            onChange={(_, selectedCustomer = null) => {
                                handleChange('customer', selectedCustomer)
                            }}
                            className="w-full"
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <AssetSelect
                        isRequired
                        {...getInputProps('assetId')}
                        disabled
                        label="Asset"
                        error={antdErrors.get('assetId')}
                        assets={assets}
                        className="w-full"
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Quantity"
                        error={antdErrors.get('quantity')}
                    >
                        <InputNumber
                            {...getInputProps('quantity')}
                            decimalSeparator=","
                            min={0}
                            step={calculateStep(true, values.quantityUnitCode)}
                            precision={getRoundingForUnit(values.quantityUnitCode)}
                            addonAfter={(
                                <UnitSelect
                                    units={units}
                                    {...getInputProps('quantityUnitCode')}
                                />
                            )}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Price"
                        error={antdErrors.get('price')}
                        extra={values.price !== 0 && !pricingIsWithinValidRange(values.price * 100, pricing, values.side) && (
                            <div className="flex items-baseline text-yellow-400">
                                <InfoCircleOutlined className="text-sm mr-2" />
                                <span>Based on the current pricing, this value does not look correct</span>
                            </div>
                        )}
                    >
                        <InputNumber
                            {...getInputProps('price')}
                            decimalSeparator=","
                            min={0}
                            precision={2}
                            addonAfter={(
                                <div>
                                    <CurrencySelect
                                        currencies={currencies}
                                        {...getInputProps('currencyCode')}
                                    />
                                    /
                                    <UnitSelect
                                        units={units}
                                        {...getInputProps('priceUnitCode')}
                                    />
                                </div>
                            )}
                        />
                    </FormItem>
                </Col>

            </Row>

            <FormItem
                label="Notes"
                error={antdErrors.get('notes')}
            >
                <TextArea
                    rows={4}
                    {...getNativeInputProps('notes')}
                />
            </FormItem>
        </Form>
    )
}

export default CreateTradeForm
