import React from 'react'
import { getCurrencySymbol, formatCurrencyNotation } from '../../../../util/currency'

const CustomerMarketData = ({
    currencyCode,
    unitCode,
    side,
    marketData,
    adjustedMarketData,
    adjustedCustomerMarketData,
}) => {
    let key = 'buyPrice'
    if (side === 'SELL') {
        key = 'sellPrice'
    }
    const customerCanTradeAsset = adjustedCustomerMarketData !== null
    const spotPrice = customerCanTradeAsset
        ? adjustedCustomerMarketData[key]
        : adjustedMarketData[key]
    const hedgePrice = marketData[key]
    return (
        <div className="mb-4 text-center">
            <div className="font-medium text-md mb-2">
                CUSTOMER PRICE
            </div>
            <div className="flex justify-center mb-2 w-full">
                <span className="text-2xl font-medium mr-4">
                    {getCurrencySymbol(currencyCode)}
                </span>
                <h2 className="text-3xl font-black">
                    {formatCurrencyNotation(spotPrice, currencyCode)}
                </h2>
                <span className="text-2xl font-medium self-end ml-4">
                    {unitCode}
                </span>
            </div>
            <div className="text-blue-300 text-sm font-thin">
                <span>Hedge price {formatCurrencyNotation(hedgePrice, currencyCode)}/{unitCode}</span>
            </div>
        </div>
    )
}

export default CustomerMarketData
