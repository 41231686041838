import React from 'react'
import { Form, Input, Checkbox } from 'antd'
import FormItem from '../../util/form/FormItem'
import UnitSelect from '../../util/form/UnitSelect'
import CurrencySelect from '../../util/form/CurrencySelect'
import CustomerPriceAdjustmentsTable from './table/CustomerPriceAdjustmentsTable'

const CustomerAssetForm = ({
    formState,
    currencies,
    units,
}) => {
    const {
        getInputProps,
        getNativeInputProps,
        antdErrors,
        handleCurrencyChange,
        handleUnitChange,
        values,
    } = formState

    return (
        <Form layout="vertical">
            <FormItem
                label="Name"
                error={antdErrors.get('name')}
            >
                <Input
                    {...getInputProps('name')}
                    disabled
                />
            </FormItem>
            <FormItem
                label="Code"
                error={antdErrors.get('code')}
            >
                <Input
                    {...getInputProps('code')}
                    disabled
                />
            </FormItem>
            <FormItem
                label="Permissions"
                error={antdErrors.get('permissions')}
            >
                <Checkbox
                    {...getNativeInputProps('canSell')}
                    checked={values.canSell}
                    style={{ width: '100px' }}
                >
                    Sell
                </Checkbox>
                <Checkbox
                    {...getNativeInputProps('canBuy')}
                    checked={values.canBuy}
                    style={{ width: '100px' }}
                >
                    Buy
                </Checkbox>
            </FormItem>
            <FormItem
                label="Currencies"
                error={antdErrors.get('currencies')}
            >
                <CurrencySelect
                    {...getInputProps('currencies')}
                    multiple
                    currencies={currencies}
                    onChange={handleCurrencyChange}
                />
            </FormItem>
            <FormItem
                label="Units"
                error={antdErrors.get('units')}
            >
                <UnitSelect
                    {...getInputProps('units')}
                    multiple
                    units={units}
                    onChange={handleUnitChange}
                />
            </FormItem>
            <h2 className="text-2xl">Price Adjustments:</h2>
            <CustomerPriceAdjustmentsTable
                formState={formState}
            />
        </Form>
    )
}

export default CustomerAssetForm
