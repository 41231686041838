import React from 'react'
import {
    Button,
    Select,
    DatePicker,
} from 'antd'
import {
    CalendarOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons'
import {
    TODAY,
    YESTERDAY,
    WEEK,
    MONTH,
    YEAR,
    ALL,
} from '../../hooks/useActiveRangeFilter'

const { Option } = Select
const { RangePicker } = DatePicker

const OrdersTableDatepicker = ({
    filters,
    onChangeDateFilter,
    onChangeRangeFilter,
}) => {
    const { from, till, dateFilter } = filters
    const isShowingDatePicker = dateFilter !== null
    return (
        <>
            {isShowingDatePicker ? (
                <div className="flex">
                    <Select
                        value={dateFilter}
                        style={{ width: 150 }}
                        placeholder="Filter orders"
                        onChange={onChangeDateFilter}
                    >
                        <Option value={TODAY}>Today</Option>
                        <Option value={YESTERDAY}>Yesterday</Option>
                        <Option value={WEEK}>This week</Option>
                        <Option value={MONTH}>This month</Option>
                        <Option value={YEAR}>This year</Option>
                        <Option value={ALL}>All time</Option>
                    </Select>
                    <Button
                        icon={<CalendarOutlined />}
                        onClick={() => onChangeRangeFilter([from, till])}
                    />
                </div>
            ) : (
                <>
                    <RangePicker
                        value={[from, till]}
                        onChange={onChangeRangeFilter}
                    />
                    <Button
                        icon={<UnorderedListOutlined />}
                        onClick={() => onChangeDateFilter(TODAY)}
                    />
                </>
            )}
        </>
    )
}

export default OrdersTableDatepicker
