import React from 'react'
import { Button } from 'antd'
import CustomerPage from '../../layout/CustomerPage'
import CustomerTopBar from '../../layout/CustomerTopBar'
import usePermissions from '../../hooks/usePermisssions'
import CustomerPaymentsTable from './CustomerPaymentsTable'
import CreatePaymentModalWithState, { CreatePaymentModalState } from './CreatePaymentModalWithState'

const CustomerPaymentsPage = ({
    activeMenuItem,
    hasMore,
    isFetching,
    isFetchingMore,
    customer,
    customerPayments,
    handleFetchMore,
    onDeleteComplete,
    queryToRefetch,
}) => {
    const { isAdmin } = usePermissions()
    return (
        <CustomerPage
            customerId={customer.id}
            isLoading={isFetching}
            activeMenuItem={activeMenuItem}
        >
            <CustomerTopBar
                customerName={customer.name}
                rightChildren={
                    isAdmin && (
                        <CreatePaymentModalState>
                            {({ open }) => (
                                <Button
                                    type="primary"
                                    onClick={() => open()}
                                >
                                    Add payment
                                </Button>
                            )}
                        </CreatePaymentModalState>
                    )
                }
            />
            <CustomerPaymentsTable
                isLoading={isFetching}
                isFetchingMore={isFetchingMore}
                hasMore={hasMore}
                customerPayments={customerPayments}
                onFetchMore={handleFetchMore}
                onDeleteComplete={onDeleteComplete}
            />
            <CreatePaymentModalWithState
                customerId={customer.id}
                queryToRefetchAfterMutation={queryToRefetch}
            />
        </CustomerPage>
    )
}
export default CustomerPaymentsPage
