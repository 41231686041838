import React from 'react'
import {
    Tag,
    Table,
    Button,
} from 'antd'
import {
    FilePdfOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { SIDE_SELL, UNIT_CODE_OUNCE } from '../../../util/constants'
import CurrencyValue from '../../util/CurrencyValue'

const generateColumns = () => [{
    align: 'left',
    children: [
        {
            key: 'side',
            dataIndex: 'side',
            render: (side) => (
                <Tag
                    color={side === SIDE_SELL ? 'green' : 'blue'}
                >
                    {side}
                </Tag>
            ),
            align: 'center',
        }, {
            key: 'receit',
            dataIndex: 'receitUrl',
            align: 'right',
            render: (receitUrl, order) => receitUrl !== null && order.orderId !== null && (
                <Button
                    type="link"
                    icon={<FilePdfOutlined />}
                    size="large"
                    href={receitUrl}
                    target="_blank"
                    className="mb-2"
                />
            ),
        }],
}, {
    title: <h2 className="text-2xl">Customers</h2>,
    align: 'left',
    children: [{
        dataIndex: 'createdAt',
        title: 'Traded on',
        render: (createdAt) => createdAt !== null && moment(createdAt).format('DD/MM HH:mm'),
        align: 'left',
        width: 110,
    }, {
        dataIndex: 'reference',
        title: 'Ref.',
        render: (reference) => reference !== null && reference,
        align: 'left',
    }, {
        key: 'customer',
        title: 'Name',
        dataIndex: 'customer',
        render: (customer) => customer !== null && customer.name,
    }, {
        key: 'quantity',
        title: '',
        dataIndex: 'quantity',
        align: 'right',
        render: (quantity, order) => quantity !== null && (
            <strong>
                {order.quantityInUnit}
                {order.quantityUnitCode}
            </strong>
        ),
    }, {
        dataIndex: 'price',
        title: 'Price',
        render: (price, order) => price !== null && (
            <strong>
                <CurrencyValue
                    value={price}
                    currencyCode={order.currencyCode}
                />
                /
                {order.priceUnitCode}
            </strong>
        ),
        align: 'left',
    }, {
        dataIndex: 'quantityNet',
        title: '',
        render: (quantityNet, order) => (quantityNet !== null && order.impurity !== null)
            && (
                <div className="text-sm">
                    <strong>
                        {`${order.impurity.name} `}
                        ({order.quantityNetInUnit}
                        {order.quantityUnitCode})
                    </strong>
                </div>
            ),
        align: 'left',
    }, {
        dataIndex: 'totalPrice',
        title: 'Order Total',
        render: (totalPrice, order) => totalPrice !== null && (
            <CurrencyValue
                value={totalPrice}
                currencyCode={order.currencyCode}
            />
        ),
        align: 'right',
        width: 150,
    }],
}, {
    title: <h2 className="text-2xl">Hedge</h2>,
    align: 'left',
    className: 'border-l-1',
    children: [{
        dataIndex: 'marketOrderQuantityFilled',
        title: 'Fixed',
        align: 'left',
        className: 'border-l-1',
        render: (quantityFilled, order) => order.marketOrderQuantityFilled !== null && `${quantityFilled} ${UNIT_CODE_OUNCE}`,
        width: 75,
    }, {
        dataIndex: 'marketOrderPrice',
        title: 'Price',
        align: 'left',
        render: (price, order) => order.marketOrderPrice !== null && (
            <CurrencyValue
                value={price}
                currencyCode={order.currencyCode}
            />
        ),
    }, {
        dataIndex: 'marketOrderTotalPrice',
        title: 'Order Total',
        align: 'right',
        render: (totalPrice, order) => order.marketOrderTotalPrice !== null && (
            <CurrencyValue
                value={totalPrice}
                currencyCode={order.currencyCode}
            />
        ),
    }],
}, {
    className: 'border-l-1',
    children: [{
        key: 'profit',
        dataIndex: ['summary', 'profit'],
        title: 'Difference',
        className: 'border-l-1',
        render: (profit, order) => order.summary !== null && (
            order.price !== null && (
                <CurrencyValue
                    isBold
                    value={order.summary.priceDiff}
                    currencyCode={order.currencyCode}
                    displayCurrencyCode={`${order.currencyCode}/${order.priceUnitCode}`}
                />
            )
        ),
    }],
}]

const OrdersTable = ({
    orders,
    onExportOrders,
}) => (
    <Table
        rowKey="uid"
        size="middle"
        columns={(
            generateColumns({
                orders,
                onExportOrders,
            })
        )}
        dataSource={orders}
        pagination={false}
    />
)

export default OrdersTable
