import React from 'react'
import { Dropdown, Menu } from 'antd'
import {
    EditOutlined,
    SwapOutlined,
    CheckOutlined,
    FilePdfOutlined,
} from '@ant-design/icons'
import PaymentButtonWithState from './PaymentButtonWithState'
import { SIDE_BUY, SIDE_SELL, ORDER_FIX_STATUS_FAILED as FAILED } from '../../../util/constants'

export const ACTION_EDIT_ORDER = 'editOrder'
export const ACTION_ADD_PICKUP = 'addPickup'
export const ACTION_ADD_DELIVERY = 'addDelivery'
export const ACTION_CONFIRM_ORDER = 'confirmOrder'
export const ACTION_DOWNLOAD_RECEIT = 'downloadReceit'

const CustomerTradesTableActionDropdown = ({
    order,
    onEditOrder,
    onAddPickup,
    onAddDelivery,
    onConfirmOrder,
    children,
}) => {
    const handleSelect = (e) => {
        switch (e.key) {
            case ACTION_EDIT_ORDER:
                onEditOrder(order)
                break
            case ACTION_ADD_PICKUP:
                onAddPickup(order)
                break
            case ACTION_ADD_DELIVERY:
                onAddDelivery(order)
                break
            case ACTION_CONFIRM_ORDER:
                onConfirmOrder(order)
                break
            default:
                break
        }
    }
    return (
        <>
            <Dropdown
                overlay={(
                    <Menu
                        onClick={handleSelect}
                        className="border-1 border-gray-200"
                    >
                        {order.orderId !== null && (
                            <Menu.Item key={ACTION_EDIT_ORDER}>
                                <EditOutlined size="small" />
                                Edit notes
                            </Menu.Item>

                        )}
                        {order.side === SIDE_BUY && order.outstandingQuantity !== 0 && (
                            <Menu.Item key={ACTION_ADD_PICKUP}>
                                <SwapOutlined size="small" />
                                Add pickup
                            </Menu.Item>
                        )}
                        {order.side === SIDE_SELL && order.outstandingQuantity !== 0 && (
                            <Menu.Item key={ACTION_ADD_DELIVERY}>
                                <SwapOutlined size="small" />
                                Add delivery
                            </Menu.Item>
                        )}
                        {order.fixStatus === FAILED ? (
                            <Menu.Item key={ACTION_CONFIRM_ORDER}>
                                <CheckOutlined size="small" />
                                Confirm order
                            </Menu.Item>
                        ) : null}
                        <Menu.Item key="payment">
                            <PaymentButtonWithState
                                orderId={order.id}
                                totalOutstandingPrice={order.totalOutstandingPrice}
                                customerId={order.customer.id}
                            />
                        </Menu.Item>
                        <Menu.Item key={ACTION_DOWNLOAD_RECEIT}>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={order.receitUrl}
                            >
                                <FilePdfOutlined size="small" />
                                Get receipt
                            </a>
                        </Menu.Item>
                    </Menu>
                )}
                placement="bottomRight"
                trigger={['click']}
            >
                {children}
            </Dropdown>
        </>
    )
}

export default CustomerTradesTableActionDropdown
