import { gql } from '@apollo/client'
import { removeEdgeFromCollectionWhereNodeId } from '../util/index'
import { updateCustomerUsersQuery } from '../components/pages/customer-users/useFetchCustomerUsersTableData'

const DELETE_CUSTOMER_USER_MUTATION = gql`
    mutation deleteCustomerUser($id: Int!) {
        deleteCustomerUser(id: $id)
    }
`

export default async function deleteCustomerUser(apolloclient, customerUserId, customerId) {
    const mutation = DELETE_CUSTOMER_USER_MUTATION
    const variables = { id: customerUserId }
    const update = () => {
        updateCustomerUsersQuery({
            path: 'customerUsers.edges',
            value: removeEdgeFromCollectionWhereNodeId(customerUserId),
            variables: { customerId },
        })
    }
    const response = await apolloclient.mutate({
        mutation,
        variables,
        update,
    })
    return response
}
