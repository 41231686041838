import { createFormValidation } from 'use-form-state'
import useFormState from '../../../../hooks/useFormState'
import * as validation from '../../../../../validation/index'

const setInitialFormValues = (customer) => ({
    customer,
    assetId: null,
    quantity: 0,
    orderDeliveries: [],
})

export const deliveryValidation = createFormValidation([{
    path: 'customer',
    validate: (customer) => customer !== null && validation.isValue(customer.id),
    message: 'Please choose a valid customer.',
}, {
    path: 'assetId',
    validate: (assetId) => validation.isValue(assetId),
    message: 'Please choose a valid asset.',
}, {
    path: 'quantity',
    validate: (quantity, { orderDeliveries }) => {
        if (orderDeliveries.length > 0) {
            return true
        }
        return quantity > 0
    },
    message: 'Please provide a quantity higher than 0.',
}, {
    path: 'orderDeliveries',
    validate: (orderDeliveries, { quantity }) => {
        if (quantity === 0) {
            return orderDeliveries.length > 0
        }
        return true
    },
    message: 'Please select at least one order.',
}])

const toCreateDeliveryInput = ({
    customer,
    assetId,
    quantity,
    orderDeliveries,
}) => ({
    customerId: customer.id,
    assetId,
    quantity,
    orderDeliveries,
})

const useCreateDeliveryForm = (customer, options) => useFormState(
    setInitialFormValues(customer),
    {
        validation: deliveryValidation,
        valuesToInput: toCreateDeliveryInput,
        ...options,
    },
)

export default useCreateDeliveryForm
