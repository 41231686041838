import React from 'react'
import { EditOutlined } from '@ant-design/icons'
import HasPermissions from '../../../util/HasPermissions'

const AssetEditButton = ({
    asset,
    onClick,
}) => (
    <HasPermissions roles={[HasPermissions.ROLES.ADMIN]}>
        {(hasPermission) => (
            <EditOutlined
                onClick={() => hasPermission && onClick(asset)}
                style={{ cursor: !hasPermission && 'not-allowed' }}
            />
        )}
    </HasPermissions>
)

export default AssetEditButton
