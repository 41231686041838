import React from 'react'
import Loading from '../util/Loading'
import Main from './Main'
import Navbar from './Navbar'

const PageLoading = ({ activeMenuItem }) => (
    <>
        <Navbar activeMenuItem={activeMenuItem} />
        <Main>
            <Loading />
        </Main>
    </>
)

export default PageLoading
