import React from 'react'
import clsx from 'clsx'

const Tag = ({
    isInfo,
    isSuccess,
    isWarning,
    isAlert,
    className,
    children,
}) => (
    <span
        className={
            clsx(
                'text-white bg-primary font-regular text-sm p-1 rounded-md',
                className,
                isInfo && 'bg-blue-100',
                isSuccess && 'bg-green-100',
                isWarning && 'bg-yellow-100',
                isAlert && 'bg-red-100',
            )
        }
    >
        {children}
    </span>
)

export default Tag
