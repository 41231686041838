import React from 'react'
import clsx from 'clsx'

const TopBar = ({
    leftChildren,
    rightChildren,
    children,
    className,
}) => (
    <div
        className={
            clsx(
                'flex justify-between items-center mb-4',
                className,
            )
        }
    >
        <div>
            {leftChildren}
            {children}
        </div>
        <div>
            {rightChildren}
        </div>
    </div>
)

export default TopBar
