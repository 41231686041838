import * as storage from '../../../util/cache'

const getExportedTrades = async ({
    from,
    until,
    assetId,
}) => {
    const {
        REACT_APP_SERVER_URI: URI,
    } = window.env
    const url = `${URI}/exportTrades?from=${from}&until=${until}&assetId=${assetId}`
    const accessToken = await storage.getAccessToken()
    const response = await fetch(url, {
        method: 'GET',
        responseType: 'blob',
        headers: {
            authorization: accessToken,
        },
    })
    const file = new Blob([await response.blob()], { type: 'text/csv' })
    const fileUrl = URL.createObjectURL(file)
    window.open(fileUrl)
}

export default getExportedTrades
