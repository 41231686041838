import React from 'react'
import { gql } from '@apollo/client'
import {
    Tag,
    Table,
    Button,
    Popover,
} from 'antd'
import {
    MoreOutlined,
    CommentOutlined,
} from '@ant-design/icons'
import Date from '../../util/Date'
import UnitValue from '../../util/UnitValue'
import CurrencyValue from '../../util/CurrencyValue'
import * as constants from '../../../util/constants'
import TradePageTableActionDropdown from '../trades/TradePageTableActionDropdown'
import CustomerTradesTableDetails from './CustomerTradesTableDetails'

const { Column } = Table

const {
    SIDE_SELL,
    UNIT_CODE_OUNCE: OZ,
    UNIT_CODE_KILOGRAM: KG,
} = constants

const FRAGMENT = gql`
    fragment CustomerTradesPageTable on Trade {
        id
        createdAt
        updatedAt
        date
        side
        currencyCode
        quantity
        quantityUnitCode
        asset {
            id
            name
            code
        }
        assetCode
        type
        price
        priceUnitCode
        conversionRate
        totalPrice
        notes
        user {
            id
            fullName
            email
        }
    }
`

const CustomerTradesPageTable = ({
    loading,
    customerTrades,
    onUpdateTrade,
}) => (
    <Table
        size="small"
        rowKey="id"
        useFixedHeader
        loading={loading}
        dataSource={customerTrades}
        pagination={false}
        expandedRowRender={CustomerTradesTableDetails}
    >
        <Column
            key="date"
            dataIndex="date"
            align="right"
            render={(date) => date && (
                <Date value={date} format="DD/MM/YY HH:mm" />
            )}
            width={140}
        />
        <Column
            key="side"
            dataIndex="side"
            align="center"
            render={(side) => (
                <Tag
                    color={side === SIDE_SELL ? 'green' : 'blue'}
                >
                    {side}
                </Tag>
            )}
        />
        <Column
            key="asset"
            dataIndex="asset"
            title="Asset"
            align="left"
            render={(asset) => (
                <span>
                    {asset.name} - {asset.code}
                </span>
            )}
        />
        <Column
            key="quantity"
            dataIndex="quantity"
            title="Quantity"
            align="right"
            render={(quantity, { quantityUnitCode }) => {
                if (quantityUnitCode !== OZ) {
                    return (
                        <UnitValue
                            value={quantity}
                            unitCode={KG}
                        />
                    )
                }
                return (
                    <UnitValue
                        value={quantity}
                        unitCode={quantityUnitCode}
                    />
                )
            }}
        />
        <Column
            key="price"
            dataIndex="price"
            title="Price"
            align="right"
            render={(price, { currencyCode, priceUnitCode }) => {
                if (!price) {
                    return null
                }
                return (
                    <CurrencyValue
                        value={price}
                        displayCurrencyCode={`${currencyCode}/${priceUnitCode}`}
                    />
                )
            }}
        />
        <Column
            key="totalPrice"
            dataIndex="totalPrice"
            title="Total"
            align="right"
            render={(totalPrice, { currencyCode }) => {
                if (!totalPrice) {
                    return null
                }
                return (
                    <CurrencyValue
                        isBold
                        value={totalPrice}
                        currencyCode={currencyCode}
                    />
                )
            }}
        />
        <Column
            key="notes"
            dataIndex="notes"
            align="center"
            render={(notes) => (
                notes !== null && notes !== '' && (
                    <Popover
                        placement="topRight"
                        content={notes}
                    >
                        <Button
                            ghost
                            icon={<CommentOutlined />}
                            type="primary"
                            shape="circle"
                            className="border-none"
                        />
                    </Popover>
                )
            )}
        />
        <Column
            key="id"
            dataIndex="id"
            align="center"
            render={(_, trade) => (
                <TradePageTableActionDropdown
                    trade={trade}
                    onEdit={() => onUpdateTrade(trade)}
                >
                    <Button
                        ghost
                        icon={<MoreOutlined />}
                        type="primary"
                        shape="circle"
                        className="border-none"
                    />
                </TradePageTableActionDropdown>
            )}
        />
    </Table>
)

CustomerTradesPageTable.FRAGMENT = FRAGMENT

export default CustomerTradesPageTable
