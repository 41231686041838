import { useQuery, gql } from '@apollo/client'

const CUSTOMER_BALANCE_PAGE_QUERY = gql`
    query customerBalancePageQuery($customerId: Int!) {
        customer(id: $customerId) {
            id
            name
            hasTradeLimit
            fixedTradeLimit
            balance {
                total
                tradeLimit
                tradeMargin
                assetWallets {
                    value
                    quantity
                    quantityInCredit
                    quantityInDebt
                    asset {
                        id
                        name
                        code
                    }
                }
                moneyWallets {
                    value
                    sellValue
                    buyValue
                    currencyCode
                }
            }
        }
    }
`

const useFetchCustomerBalancePageData = (customerId) => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(CUSTOMER_BALANCE_PAGE_QUERY, {
        variables: {
            customerId,
        },
        fetchPolicy: 'network-only',
    })
    const { customer = {} } = data

    return {
        error,
        isFetching: loading,
        customer,
    }
}

export default useFetchCustomerBalancePageData
