import React from 'react'
import { Button } from 'antd'
import {
    EditOutlined,
    CloseOutlined,
} from '@ant-design/icons'

const TradeLimitsToggleButton = ({
    areTradeLimitsSet,
    onToggleEditTradeLimits,
}) => {
    if (areTradeLimitsSet) {
        return (
            <Button
                type="danger"
                icon={<CloseOutlined />}
                size="small"
                onClick={() => onToggleEditTradeLimits(false)}
            />
        )
    }
    return (
        <Button
            type="primary"
            icon={<EditOutlined />}
            size="small"
            onClick={() => onToggleEditTradeLimits(true)}
        />
    )
}

export default TradeLimitsToggleButton
