import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { connectionToCollection, PAGE_INFO_FRAGMENT, mergeQueryResults } from '../../../util/graphql'

export const ORDERS_PAGE_QUERY = gql`
    query ordersPageQuery(
            $from: DateTime,
            $until: DateTime,
            $assetId: Int!,
            $orderId: Int,
            $customerId: Int,
            $after: String,
            $first: Int,
        ) {
        combinedOrders(
            from: $from,
            until: $until,
            assetId: $assetId,
            orderId: $orderId,
            customerId: $customerId,
            after: $after,
            first: $first,
        ) {
            edges {
                node {
                    orderId
                    marketOrderId
                    createdAt
                    updatedAt
                    side
                    currencyCode
                    unitCode
                    uid
                    notes
                    quantity
                    quantityNet
                    quantityInUnit
                    quantityNetInUnit
                    quantityUnitCode
                    outstandingQuantity
                    quantityFixed
                    price
                    priceUnitCode
                    totalPrice
                    totalOutstandingPrice
                    fixStatus
                    reference
                    marketOrderQuantity
                    marketOrderQuantityFilled
                    marketOrderSpotPrice
                    marketOrderPrice
                    marketOrderTotalPrice
                    marketOrderStatus
                    status
                    summary {
                        priceDiff
                    }
                    asset {
                        name
                        code
                    }
                    customer {
                        id
                        name
                    }
                    receitUrl
                    impurity {
                        id
                        name
                    }
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
        assets {
            id
            name
            code
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const useOrdersPageQuery = ({
    from,
    till,
    orderId,
    assetId,
    customerId,
}) => {
    const [isFetchingMoreOrders, setIsFetchingMoreOrders] = useState(false)
    const variables = {
        assetId,
        from,
        until: till,
        ...typeof customerId !== 'undefined' && { customerId },
        ...orderId !== null && orderId !== '' && { orderId: parseInt(orderId, 10) },
    }

    const {
        error,
        loading,
        data = {},
        fetchMore,
        refetch,
        ...result
    } = useQuery(ORDERS_PAGE_QUERY, {
        variables,
    })
    const {
        assets = [],
    } = data

    let combinedOrders = []
    let hasMoreOrders = false
    if (!loading && typeof data.combinedOrders !== 'undefined') {
        combinedOrders = connectionToCollection(data.combinedOrders)
        hasMoreOrders = data.combinedOrders.pageInfo.hasNextPage
    }

    return {
        error,
        isFetching: loading,
        isFetchingMoreOrders,
        assets,
        combinedOrders,
        hasMoreOrders,
        handleFetchMoreOrders: () => {
            setIsFetchingMoreOrders(true)
            const refetchAfter = data.combinedOrders.pageInfo.endCursor
            fetchMore({
                variables: { after: refetchAfter },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    setIsFetchingMoreOrders(false)
                    return mergeQueryResults(previousResult, fetchMoreResult, 'combinedOrders')
                },
            })
        },
        refetch,
        ...result,
    }
}

export default useOrdersPageQuery
