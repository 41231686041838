import React from 'react'
import PreferredCurrencyValue from '../../util/PreferredCurrencyValue'

const BalanceCard = ({
    balance,
    hasTradeLimit,
    fixedTradeLimit,
}) => {
    const {
        total,
        tradeLimit,
        tradeMargin,
    } = balance

    let limitText = 'None'
    let totalLimitText = 'None'
    if (hasTradeLimit) {
        if (tradeMargin !== null) {
            limitText = `${tradeMargin}%`
            totalLimitText = tradeLimit
        }
        if (fixedTradeLimit !== null) {
            limitText = 'Fixed'
            totalLimitText = fixedTradeLimit
        }
    }

    return (
        <div className="py-4 px-6 mr-4 border-1 rounded-lg min-w-[400px]">
            <h2 className="font-black mb-4 text-center text-2xl">
                <PreferredCurrencyValue
                    isNegative={total < 0}
                    isPositive={total > 0}
                    value={Math.abs(total)}
                />
            </h2>
            <div className="flex">
                {hasTradeLimit
                    ? (
                        <>
                            <div className="flex-1">
                                <div>Trade limit</div>
                                <h5>
                                    {limitText}
                                </h5>
                            </div>
                            <div className="flex-1">
                                <div>Total limit</div>
                                <h5>
                                    <PreferredCurrencyValue
                                        value={totalLimitText}
                                    />
                                </h5>
                            </div>
                        </>
                    )
                    : (
                        <>
                            <div className="flex-1">
                                <div>{limitText}</div>
                            </div>
                            <div className="flex-1">
                                <div>{totalLimitText}</div>
                            </div>
                        </>
                    )}
            </div>
        </div>
    )
}

export default BalanceCard
