import { createFormValidation } from 'use-form-state'
import {
    calculatePriceAdjustmentValuesForForm,
    calculatePriceAdjustmentValuesForDb,
    calculateImpurityValuesForForm,
    calculateImpurityValuesForDb,
    getCorrectedCurrencyCodeForDb,
} from '../../../util/currency'
import useFormState from '../../hooks/useFormState'
import * as validation from '../../../validation/index'
import { INITIAL_ADJUSTMENT_VALUES } from './table/PriceAdjustmentsTable'

export const toCode = ({ code }) => code

export const priceAdjustmentsToForm = (priceAdjustments) => priceAdjustments.map(
    (priceAdjustment) => ({
        ...priceAdjustment,
        ...calculatePriceAdjustmentValuesForForm(priceAdjustment),
    }),
)

export const impuritiesToForm = (impurities) => impurities.map(
    (impurity) => ({
        ...impurity,
        ...calculateImpurityValuesForForm(impurity),
    }),
)

export const priceAdjustmentsToDb = (priceAdjustments) => priceAdjustments.map(
    (inputPriceAdjustment) => {
        const {
            __typename, code, priceAdjustment, ...otherProps
        } = inputPriceAdjustment
        return ({
            ...otherProps,
            ...calculatePriceAdjustmentValuesForDb(inputPriceAdjustment),
            currencyCode: getCorrectedCurrencyCodeForDb(code),
        })
    },
)

export const impuritiesToDb = (impurities) => impurities.map(
    (impurity) => {
        const { __typename, code, ...otherProps } = impurity
        return ({
            ...otherProps,
            ...calculateImpurityValuesForDb(impurity),
            currencyCode: getCorrectedCurrencyCodeForDb(code),
        })
    },
)

export const computePriceAdjustmentsFromValues = (values) => {
    const { units, currencies, priceAdjustments } = values
    const computedPriceAdjustments = []
    currencies.forEach((currencyCode) => {
        units.forEach((unitCode) => {
            const computedAdjustment = priceAdjustments.find((adjustment) => (
                adjustment.unitCode === unitCode
                && adjustment.currencyCode === currencyCode
            ))
            computedPriceAdjustments.push({
                ...INITIAL_ADJUSTMENT_VALUES,
                ...computedAdjustment,
                code: `${currencyCode} / ${unitCode}`,
                currencyCode,
                unitCode,
            })
        })
    })
    return computedPriceAdjustments
}

const assetToFormValues = (asset) => {
    const {
        currencies = [], units = [], priceAdjustments = [], impurities = [],
    } = asset
    return {
        name: asset.name,
        code: asset.code,
        currencies: currencies.map(toCode),
        units: units.map(toCode),
        priceAdjustments: priceAdjustmentsToForm(priceAdjustments),
        impurities: impurities !== null ? impuritiesToForm(impurities) : null,
    }
}

export const assetFormvalidation = createFormValidation([{
    path: 'name',
    validate: (name) => validation.isValue(name),
    message: 'Please fill in a name',
}, {
    path: 'code',
    validate: (code) => validation.isValue(code),
    message: 'Please fill in a code',
}, {
    path: 'currencies',
    validate: (currencies) => validation.isLongerThan(0)(currencies),
    message: 'Please select at least one currency',
}, {
    path: 'units',
    validate: (units) => validation.isLongerThan(0)(units),
    message: 'Please select at least one unit',
}])

const formValuesToInput = ({
    name,
    code,
    priceAdjustments,
    impurities,
}) => ({
    name,
    code,
    priceAdjustments: priceAdjustmentsToDb(priceAdjustments),
    impurities: (impurities && impurities.length > 0) ? impuritiesToDb(impurities) : [],
})

const useUpdateAssetForm = (asset, options) => {
    const formState = useFormState(
        assetToFormValues(asset),
        {
            validation: assetFormvalidation,
            valuesToInput: formValuesToInput,
            ...options,
        },
    )

    const handleCurrencyChange = (newCurrencies) => {
        const newPriceAdjustments = computePriceAdjustmentsFromValues({
            units: formState.values.units,
            currencies: newCurrencies,
            priceAdjustments: formState.values.priceAdjustments,
        })
        formState.setValues({ currencies: newCurrencies, priceAdjustments: newPriceAdjustments })
    }

    const handleUnitChange = (newUnits) => {
        const newPriceAdjustments = computePriceAdjustmentsFromValues({
            units: newUnits,
            currencies: formState.values.currencies,
            priceAdjustments: formState.values.priceAdjustments,
        })
        formState.setValues({ units: newUnits, priceAdjustments: newPriceAdjustments })
    }

    return {
        ...formState,
        handleCurrencyChange,
        handleUnitChange,
    }
}

export default useUpdateAssetForm
