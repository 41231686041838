import React from 'react'
import AppSettingsLogItem from './AppSettingsLogItem'

const AssetUpdatedItem = ({
    appEvent,
}) => {
    const { createdAt, user } = appEvent

    return (
        <AppSettingsLogItem
            createdAt={createdAt}
            user={user}
        >
            App settings updated.
        </AppSettingsLogItem>
    )
}

export default AssetUpdatedItem
