import React from 'react'
import {
    Button,
} from 'antd'
import {
    DownloadOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
} from '@ant-design/icons'
import usePermissions from '../../hooks/usePermisssions'
import TradePageBlotter from './TradePageBlotter'
import TradePageDateSelect from './TradePageDateSelect'

const TradePageTopBar = ({
    loading,
    trades,
    tradeSummary,
    selectedMonth,
    selectedYear,
    isTableExpanded,
    onToggleTableExpand,
    onExportTrades,
    onCreateTrade,
    onCreateHedgeTrade,
    onChangeMonth,
    onChangeYear,
    onCloseMonth,
    onFixOpenQuantity,
}) => {
    const { isAdmin } = usePermissions()
    return (
        <div className="max-w-container my-0 mx-auto px-6 mb-8">
            <div className="flex justify-between">
                <TradePageBlotter
                    tradeSummary={tradeSummary}
                    loading={loading}
                    onFixOpenQuantity={onFixOpenQuantity}
                />
                <div className="flex flex-col justify-end items-end">
                    <div className="flex justify-end flex-wrap-reverse">

                        <Button
                            type="primary"
                            icon={isTableExpanded ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                            onClick={onToggleTableExpand}
                            ghost
                            className="mb-6"
                        >
                            {isTableExpanded ? 'Collapse' : 'Expand'} table
                        </Button>
                        {isAdmin && (
                            <>
                                <Button
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                    disabled={loading}
                                    onClick={onExportTrades}
                                    ghost
                                    className="ml-4 mb-6"
                                >
                                    Export
                                </Button>
                                <Button
                                    type="primary"
                                    disabled={loading || tradeSummary.open === 0}
                                    onClick={onCloseMonth}
                                    className="ml-4 mb-6"
                                    ghost
                                >
                                    Close month
                                </Button>
                                <Button
                                    type="primary"
                                    disabled={loading}
                                    className="ml-4 mb-6"
                                    onClick={onCreateHedgeTrade}
                                >
                                    Create Hedge trade
                                </Button>
                                <Button
                                    type="primary"
                                    disabled={loading}
                                    className="ml-4 mb-6"
                                    onClick={onCreateTrade}
                                >
                                    Create trade
                                </Button>
                            </>
                        )}
                    </div>
                    <TradePageDateSelect
                        selectedMonth={selectedMonth}
                        onChangeMonth={onChangeMonth}
                        selectedYear={selectedYear}
                        onChangeYear={onChangeYear}
                    />
                </div>
            </div>
        </div>
    )
}

export default TradePageTopBar
