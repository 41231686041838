import { createValidationErrorObject } from '../index'
import * as validation from './login'

const getEmailError = createValidationErrorObject(
    validation.isValidEmail,
    'email',
    'Please fill in a valid email.'
)

const getPasswordError = createValidationErrorObject(
    validation.isValidPassword,
    'password',
    'Please fill in a valid password.'
)


export const getLoginFormErrors = ({
    email,
    password,
}) => ({
    ...getEmailError(email),
    ...getPasswordError(password),
})
