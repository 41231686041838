import React from 'react'
import { Button } from 'antd'
import { DownloadOutlined, } from '@ant-design/icons'
import CustomerSearchSelect from '../../util/form/CustomerSearchSelect'
import usePermissions from '../../hooks/usePermisssions'
import TradesSearchInput from '../customer-orders/TradesSearchInput'
import OrdersTableDatepicker from './OrdersTableDatepicker'

const OrdersPageTopBar = ({
    orders,
    filters,
    onExportOrders,
    onCreateCustomerOrder,
    onChangeDateFilter,
    onChangeRangeFilter,
    onChangeSearchFilter,
    onChangeCustomerFilter,
}) => {
    const { isAdmin } = usePermissions()
    const {
        orderId,
        customer,
        customerId,
    } = filters
    if (isAdmin) {
        return (
            <div className="flex justify-between mb-4">
                <div className="flex justify-center">
                    <div className="mr-2">
                        <OrdersTableDatepicker
                            filters={filters}
                            onChangeDateFilter={onChangeDateFilter}
                            onChangeRangeFilter={onChangeRangeFilter}
                        />
                    </div>
                    <div className="mr-2 w-96">
                        <CustomerSearchSelect
                            value={filters.customer ? customerId : undefined}
                            customer={customer || undefined}
                            onChange={onChangeCustomerFilter}
                            className="w-full"
                        />
                    </div>
                    <TradesSearchInput
                        searchOrderRef={orderId}
                        onSearchOrder={onChangeSearchFilter}
                        onResetOrderSearch={() => {}}
                    />
                </div>
                <div className="flex">
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={onExportOrders}
                        ghost
                        className="mx-2"
                    >
                        Export
                    </Button>
                    <Button
                        className="w-full"
                        type="primary"
                        onClick={onCreateCustomerOrder}
                    >
                        New customer order
                    </Button>
                </div>
            </div>
        )
    }
    return null
}

export default OrdersPageTopBar
