import React from 'react'
import {
    Row, Col, Button, Alert,
} from 'antd'
import {
    StopOutlined,
    PoweroffOutlined,
    PlayCircleOutlined,
} from '@ant-design/icons'
import HasPermissions from '../../util/HasPermissions'
import SettingsPage from '../SettingsPage'

const HedgePage = ({
    isLoggingOut,
    hedgeIsConnected,
    hedgeIsAuthorized,
    isStoppingConnections,
    isStartingConnections,
    onStopConnections,
    onStartConnections,
    onHedgeLogout,
    onAuthorizeSaxoBank,
}) => (
    <SettingsPage activeMenuItem="fix">
        <Row>
            <Col md={12} sm={24}>
                <h2 className="text-2xl">Hedge connections</h2>
                <HasPermissions roles={[HasPermissions.ROLES.ADMIN]}>
                    {(hasPermission) => {
                        if (!hasPermission) {
                            return (
                                <Alert
                                    message="Unauthorized"
                                    description="You are unauthorized to perform actions on this page"
                                    type="error"
                                    showIcon
                                />
                            )
                        }
                        if (hedgeIsAuthorized) {
                            return (
                                <>
                                    <div className="mb-6">
                                        {
                                            hedgeIsConnected ? (
                                                <>
                                                    <p className="mb-4">The hedge connections are currently up and running.</p>
                                                    <Button
                                                        type="primary"
                                                        disabled={isStoppingConnections}
                                                        loading={isStoppingConnections}
                                                        onClick={onStopConnections}
                                                        icon={<StopOutlined />}
                                                    >
                                                        Stop hedge connections
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <p className="">The hedge connections are closed so trading will not be possible.</p>
                                                    <Button
                                                        type="primary"
                                                        disabled={isStartingConnections}
                                                        loading={isStartingConnections}
                                                        onClick={onStartConnections}
                                                        icon={<PlayCircleOutlined />}
                                                    >
                                                        Start hedge connections
                                                    </Button>
                                                </>
                                            )
                                        }
                                    </div>
                                    <Alert
                                        message="Authorized"
                                        description={(
                                            <>
                                                <p>You are authorized to use the Hedge trading provider.</p>
                                                {!hedgeIsConnected && (
                                                    <Button
                                                        disabled={isLoggingOut}
                                                        loading={isLoggingOut}
                                                        icon={<PoweroffOutlined />}
                                                        onClick={onHedgeLogout}
                                                    >
                                                        Logout
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                        type="success"
                                        showIcon
                                    />
                                </>
                            )
                        }
                        return (
                            <>
                                <p>Please click the button below to authorize the app to use the hedge provider.</p>
                                <Button
                                    type="primary"
                                    onClick={onAuthorizeSaxoBank}
                                >
                                    Authorize Saxo Bank provider
                                </Button>
                            </>
                        )
                    }}
                </HasPermissions>
            </Col>
        </Row>
    </SettingsPage>
)

export default HedgePage
