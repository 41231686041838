import React from 'react'
import CustomerPage from '../../layout/CustomerPage'
import CustomerTopBar from '../../layout/CustomerTopBar'
import InfiniteScroll from '../../util/InfiniteScroll'
import CustomerTradesPageTable from './CustomerTradesPageTable'
import CustomerTradesPageTobBar from './CustomerTradesPageTobBar'

const CustomerTradesPage = ({
    loading,
    assets,
    customer,
    customerTrades,
    activeMenuItem,
    selectedMonth,
    selectedYear,
    selectedAssetId,
    onChangeMonth,
    onChangeYear,
    onChangeAsset,
    isFetchingMore,
    hasMore,
    handleFetchMore,
    onCreateTrade,
    onUpdateTrade,
}) => (
    <>
        <CustomerPage
            customerId={customer.id}
            activeMenuItem={activeMenuItem}
            isLoading={loading}
        >
            <CustomerTopBar
                customerName={customer.name}
            />
            <CustomerTradesPageTobBar
                loading={loading}
                assets={assets}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                selectedAssetId={selectedAssetId}
                onChangeMonth={onChangeMonth}
                onChangeYear={onChangeYear}
                onChangeAsset={onChangeAsset}
                onCreateTrade={onCreateTrade}
                className="mb-8"
            />
            <InfiniteScroll
                isFetchingMore={isFetchingMore}
                hasMore={hasMore}
                onFetchMore={handleFetchMore}
            >
                <CustomerTradesPageTable
                    loading={loading}
                    customerTrades={customerTrades}
                    onUpdateTrade={onUpdateTrade}
                />
            </InfiniteScroll>
        </CustomerPage>
    </>
)

export default CustomerTradesPage
