import React from 'react'
import { Modal } from 'antd'
import UnitValue from '../../util/UnitValue'

const CloseTradeMonthModal = ({
    isOpen,
    isTranferring,
    open,
    onCancel,
    onConfirm,
}) => (
    <Modal
        title="Close month"
        visible={isOpen}
        closable={!isTranferring}
        maskClosable={!isTranferring}
        confirmLoading={isTranferring}
        onCancel={onCancel}
        onOk={onConfirm}
    >
        <p>
            Are you sure you want to transfer the open quantity of{' '}
            <UnitValue
                isBold
                value={open}
                unitCode="kg"
                isPositive={open > 0}
                isNegative={open < 0}
            />
            {' '}to the next month?
        </p>
        <p>Transferring this quantity will result in closing this month.</p>
    </Modal>
)

export default CloseTradeMonthModal
