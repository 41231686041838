import { gql } from '@apollo/client'
import CUSTOMER_ASSET_FRAGMENT from '../components/fragments/CustomerAssetFragment'

const CREATE_CUSTOMER_ASSET = gql`
    mutation createCustomerAsset($customerId: Int!, $assetId: Int!) {
        createCustomerAsset(customerId: $customerId, assetId: $assetId) {
            customerAsset {
                ...CustomerAsset
            }
        }
    }
    ${CUSTOMER_ASSET_FRAGMENT}
`

export default async function createCustomerAsset(apolloClient, customerId, assetId, options) {
    const mutation = CREATE_CUSTOMER_ASSET
    const variables = {
        customerId,
        assetId,
    }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
