export const STATUS_DELIVERED = 'DELIVERED'
export const STATUS_PARTIALY_DELIVERED = 'PARTIALY_DELIVERED'
export const STATUS_AWAITING_DELIVERY = 'AWAITING_DELIVERY'
export const STATUS_PICKED_UP = 'PICKED_UP'
export const STATUS_AWAITING_PICKUP = 'AWAITING_PICKUP'

export const DEPOSIT = 'DEPOSIT'
export const WITHDRAW = 'WITHDRAW'

export const SIDE_BUY = 'BUY'
export const SIDE_SELL = 'SELL'
export const SIDES = [
    SIDE_BUY,
    SIDE_SELL,
]

export const UNIT_CODE_MILLIGRAM = 'mg'
export const UNIT_CODE_GRAM = 'g'
export const UNIT_CODE_OUNCE = 'oz'
export const UNIT_CODE_KILOGRAM = 'kg'

export const GRAM_ROUND = 2
export const OUNCE_ROUND = 2
export const KILOGRAM_ROUND = 5

export const TRADE_LIMIT_TYPE_FIXED = 'fixed'
export const TRADE_LIMIT_TYPE_MARGIN = 'margin'
export const TRADE_LIMIT_TYPES = [
    TRADE_LIMIT_TYPE_FIXED,
    TRADE_LIMIT_TYPE_MARGIN,
]

export const MIN_TRADE_LIMIT_MARGIN = 1
export const MAX_TRADE_LIMIT_MARGIN = 100

export const PRICE_ADJUSTMENT_ACCURACY = 100000
export const IMPURITY_ACCURACY = 100000

export const PUSHER_ADMIN_CHANNEL_IDENTIFIER = 'adminChannel'
export const PUSHER_ADMIN_CHANNEL_NAME = 'private-adminChannel'
export const PUSHER_CUSTOMER_CHANNEL_IDENTIFIER = 'customerChannel'
export const PUSHER_CUSTOMER_CHANNEL_NAME = 'private-customerChannel-' // Followed by customerId

export const TRADE_TYPE_OTHER = 'OTHER'
export const TRADE_TYPE_HEDGE = 'HEDGE'
export const TRADE_TYPE_ORDER = 'ORDER'

export const ORDER_FIX_STATUS_UNKNOWN = 'UNKNOWN'
export const ORDER_FIX_STATUS_UNFIXED = 'UNFIXED'
export const ORDER_FIX_STATUS_FIXING = 'FIXING'
export const ORDER_FIX_STATUS_FIXED = 'FIXED'
export const ORDER_FIX_STATUS_FAILED = 'FAILED'
export const ORDER_FIX_STATUSES = [
    ORDER_FIX_STATUS_UNKNOWN,
    ORDER_FIX_STATUS_UNFIXED,
    ORDER_FIX_STATUS_FIXING,
    ORDER_FIX_STATUS_FIXED,
    ORDER_FIX_STATUS_FAILED,
]
