import React from 'react'
import { getCurrencySymbol, formatCurrencyNotation } from '../../../../util/currency'

const MarketData = ({
    spotPrice,
    unitCode,
    currencyCode,
}) => (
    <div className="mb-4 text-center">
        <div className="font-medium text-sm mb-2">
            CURRENT HEDGE PRICE
        </div>
        <div className="flex justify-center w-full mb-2">
            <span className="font-medium text-lg mr-4">
                {getCurrencySymbol(currencyCode)}
            </span>
            <h2 className="text-xl font-black">
                {formatCurrencyNotation(spotPrice, currencyCode)}
            </h2>
            <span className="font-medium text-lg ml-4 self-end">
                {unitCode}
            </span>
        </div>
    </div>
)

export default MarketData
