import React from 'react'
import { Timeline } from 'antd'
import InfiniteScroll from '../../util/InfiniteScroll'
import LogTimelineItem from './LogTimelineItem'

const LogTimeline = ({
    assets,
    appEvents = [],
    isLoading,
    hasMore,
    isFetchingMore,
    onFetchMore,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        hasLoader={false}
        hasMore={hasMore}
        onFetchMore={onFetchMore}
    >
        <Timeline
            pending={isFetchingMore}
        >
            {appEvents.map((appEvent) => (
                <LogTimelineItem
                    key={appEvent.id}
                    assets={assets}
                    appEvent={appEvent}
                />
            ))}
        </Timeline>
    </InfiniteScroll>
)

export default LogTimeline
