import React from 'react'

const OrderInfo = ({ order }) => (
    <>
        <h4 className="text-lg">Order:</h4>
        <div className="flex justify-between">
            <div>
                <p className="mb-0 font-bold">User:</p>
                <p>{order.user.fullName}</p>
            </div>
            <div>
                <p className="mb-0 font-bold">Ref.:</p>
                <p>{order.reference}</p>
            </div>
            <div>
                <p className="mb-0 font-bold">Asset:</p>
                <p>{order.impurity === null ? order.asset.name : `${order.asset.name} ${order.impurity.name}`}</p>
            </div>
            <div>
                <p className="mb-0 font-bold">Quantity to fill:</p>
                <p>{`${order.outstandingQuantityInUnit} ${order.unitCode}`}</p>
            </div>
        </div>
    </>
)

export default OrderInfo
