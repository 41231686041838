import React from 'react'
import { StopOutlined } from '@ant-design/icons'
import TimelineItem from '../timeline/TimelineItem'

const HedgeConnectionsStoppedItem = ({
    appEvent,
}) => {
    const {
        user,
        createdAt,
    } = appEvent
    return (
        <TimelineItem
            dot={(
                <StopOutlined
                    style={{
                        fontSize: '18px',
                    }}
                />
            )}
            createdAt={createdAt}
            user={user}
        >
            <div>
                Hedge connections stopped
            </div>
        </TimelineItem>
    )
}

export default HedgeConnectionsStoppedItem
