import { useQuery, gql } from '@apollo/client'
import CUSTOMER_ASSET_FRAGMENT from '../../fragments/CustomerAssetFragment'

export const UPDATE_CUSTOMER_ASSET_PAGE_QUERY = gql`
    query updateCustomerAssetPageQuery($customerAssetId: Int!) {
        customerAsset(customerAssetId: $customerAssetId) {
            ...CustomerAsset
        }
    }
    ${CUSTOMER_ASSET_FRAGMENT}
`

const useFetchUpdateCustomerAssetPageData = (customerAssetId) => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(UPDATE_CUSTOMER_ASSET_PAGE_QUERY, {
        variables: {
            customerAssetId,
        },
    })

    const { customerAsset = {} } = data
    const { currencies = [], units = [] } = customerAsset

    return ({
        error,
        isFetching: loading,
        customerAsset,
        currencies,
        units,
    })
}

export default useFetchUpdateCustomerAssetPageData
