import { gql, useMutation } from '@apollo/client'

const DELETE_PAYMENT_MUTATION = gql`
    mutation deletePayment($id: Int!) {
        deletePayment(id: $id)
    }
`

const useDeletePaymentMutation = (options) => (
    useMutation(DELETE_PAYMENT_MUTATION, options)
)

export default useDeletePaymentMutation
