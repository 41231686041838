import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
    PAGE_INFO_FRAGMENT,
    mergeQueryResults,
    createQueryUpdater,
    connectionToCollection,
} from '../../../util/graphql'
import ADMIN_USER_FRAGMENT from './AdminUserFragment'

export const ADMIN_USERS_QUERY = gql`
    query adminUsers($after: String, $first: Int) {
        adminUsers(
            after: $after,
            first: $first,
            orderBy: {
                createdAt: DESC
            }
        ) {
            edges {
                node {
                    ...User
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${ADMIN_USER_FRAGMENT}
`

export const updateAdminUsersQuery = createQueryUpdater(ADMIN_USERS_QUERY)

const useFetchUsersPageData = (options) => {
    const [isFetchingMore, setIsFetchingMore] = useState(false)
    const {
        loading,
        data = {},
        fetchMore,
        ...result
    } = useQuery(ADMIN_USERS_QUERY, {
        ...options,
    })
    let adminUsers = []
    let hasMore = false
    if (!loading && typeof data.adminUsers !== 'undefined') {
        adminUsers = connectionToCollection(data.adminUsers)
        hasMore = data.adminUsers.pageInfo.hasNextPage
    }

    return {
        hasMore,
        isFetching: loading,
        isFetchingMore,
        adminUsers,
        handleFetchMore: () => {
            setIsFetchingMore(true)
            const refetchAfter = data.adminUsers.pageInfo.endCursor
            fetchMore({
                variables: { after: refetchAfter },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    setIsFetchingMore(false)
                    return mergeQueryResults(previousResult, fetchMoreResult, 'adminUsers')
                },
            })
        },
        ...result,
    }
}

export default useFetchUsersPageData
