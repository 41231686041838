import React from 'react'
import { Popconfirm } from 'antd'
import {
    EyeOutlined,
    EyeInvisibleOutlined,
} from '@ant-design/icons'
import HasPermissions from '../../../util/HasPermissions'

const getPopconfirmMessage = (available) => {
    if (available) {
        return 'Are you sure you want to make this asset unavailable?'
    }
    return 'Are you sure you want to make this asset available?'
}

const AssetAvailabilityButton = ({
    available,
    onClick,
}) => (
    <HasPermissions roles={[HasPermissions.ROLES.ADMIN]}>
        {(hasPermission) => {
            if (!hasPermission) {
                if (available) {
                    return <EyeOutlined style={{ cursor: 'not-allowed' }} />
                }
                return (
                    <EyeInvisibleOutlined style={{ cursor: 'not-allowed' }} />
                )
            }
            return (
                <Popconfirm
                    title={getPopconfirmMessage(available)}
                    onConfirm={onClick}
                    okText="Yes"
                    cancelText="No"
                >
                    {(() => {
                        if (available) {
                            return <EyeOutlined />
                        }
                        return (
                            <EyeInvisibleOutlined />
                        )
                    })()}
                </Popconfirm>
            )
        }}
    </HasPermissions>
)

export default AssetAvailabilityButton
