import React from 'react'
import ScrollSentinel from './ScrollSentinel'
import Loading from './Loading'

const InfiniteScroll = ({
    isFetchingMore,
    hasMore,
    onFetchMore,
    hasLoader = true,
    children,
}) => (
    <div className="relative">
        {children}
        {(hasLoader && isFetchingMore) && (
            <div className="my-4 mx-auto">
                <Loading />
            </div>
        )}
        {(!isFetchingMore && hasMore) && (
            <ScrollSentinel onIntersect={onFetchMore} />
        )}
    </div>
)

export default InfiniteScroll
