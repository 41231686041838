import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { connectionToCollection, PAGE_INFO_FRAGMENT, mergeQueryResults } from '../../../util/graphql'

export const TRANSFERS_PAGE_QUERY = gql`
    query transfers($after: String, $first: Int) {
        deliveries(after: $after, first: $first) {
            edges {
                node {
                    id
                    quantity
                    createdAt
                    customer {
                        id
                        name
                    }
                    asset {
                        id
                        name
                    }
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
        pickups(after: $after, first: $first) {
            edges {
                node {
                    id
                    quantity
                    createdAt
                    customer {
                        id
                        name
                    }
                    asset {
                        id
                        name
                    }
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
        assets {
            id
            name
            code
        }
    }
    ${PAGE_INFO_FRAGMENT}
`

const useFetchTransfersPageData = (first, after) => {
    const [isFetchingMorePickups, setIsFetchingMorePickups] = useState(false)
    const [isFetchingMoreDeliveries, setIsFetchingMoreDeliveries] = useState(false)

    const variables = {
        first,
        after,
    }
    const {
        error,
        loading,
        data = {},
        fetchMore,
    } = useQuery(TRANSFERS_PAGE_QUERY, {
        variables,
    })
    const { assets = [] } = data
    let { deliveries = [], pickups = [] } = data

    let hasMorePickups = false
    let hasMoreDeliveries = false
    if (!loading && (typeof data.pickups !== 'undefined' || typeof data.deliveries !== 'undefined')) {
        pickups = connectionToCollection(data.pickups)
        deliveries = connectionToCollection(data.deliveries)
        hasMorePickups = data.pickups.pageInfo.hasNextPage
        hasMoreDeliveries = data.deliveries.pageInfo.hasNextPage
    }

    return {
        error,
        isFetching: loading,
        pickups,
        deliveries,
        assets,
        isFetchingMorePickups,
        isFetchingMoreDeliveries,
        hasMorePickups,
        hasMoreDeliveries,
        handleFetchMorePickups: () => {
            setIsFetchingMorePickups(true)
            const refetchAfter = pickups.pageInfo.endCursor
            fetchMore({
                variables: { after: refetchAfter },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    setIsFetchingMorePickups(false)
                    return mergeQueryResults(previousResult, fetchMoreResult, 'pickups')
                },
            })
        },
        handleFetchMoreDeliveries: () => {
            setIsFetchingMoreDeliveries(true)
            const refetchAfter = deliveries.pageInfo.endCursor
            fetchMore({
                variables: { after: refetchAfter },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    setIsFetchingMoreDeliveries(false)
                    return mergeQueryResults(previousResult, fetchMoreResult, 'deliveries')
                },
            })
        },
    }
}

export default useFetchTransfersPageData
