import { gql, useMutation } from '@apollo/client'

const UPDATE_ORDER_MUTATION = gql`
    mutation updateOrderMutation($id: Int!, $input: UpdateOrderInput!) {
        updateOrder(id: $id, input: $input) {
            order {
                id
            }
        }
    }
`

const useUpdateOrderMutation = (options) => (
    useMutation(UPDATE_ORDER_MUTATION, options)
)

export default useUpdateOrderMutation
