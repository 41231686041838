import React from 'react'
import Main from '../../layout/Main'
import Navbar from '../../layout/Navbar'
import PageError from '../../layout/PageError'
import PageLoading from '../../layout/PageLoading'
import useModalState from '../../hooks/useModalState'
import CreateDeliveryModalWithState from './deliveries/modals/CreateDeliveryModalWithState'
import TransfersPage from './TransfersPage'
import CreatePickupModalWithState from './CreatePickupModalWithState'
import useFetchTransferPageData, { TRANSFERS_PAGE_QUERY } from './useFetchTransfersPageData'

const TransfersPageWithState = () => {
    const createPickupModalState = useModalState()
    const createDeliveryModalState = useModalState()
    const {
        error,
        isFetching,
        pickups,
        deliveries,
        assets,
        isFetchingMorePickups,
        isFetchingMoreDeliveries,
        hasMorePickups,
        hasMoreDeliveries,
        handleFetchMorePickups,
        handleFetchMoreDeliveries,
    } = useFetchTransferPageData()

    if (typeof error !== 'undefined') {
        return <PageError activeMenuItem="deliveries" />
    }
    if (isFetching) {
        return <PageLoading activeMenuItem="deliveries" />
    }

    return (
        <>
            <Navbar activeMenuItem="deliveries" />
            <Main>
                <TransfersPage
                    pickups={pickups}
                    deliveries={deliveries}
                    isFetchingMorePickups={isFetchingMorePickups}
                    isFetchingMoreDeliveries={isFetchingMoreDeliveries}
                    hasMorePickups={hasMorePickups}
                    hasMoreDeliveries={hasMoreDeliveries}
                    handleFetchMorePickups={handleFetchMorePickups}
                    handleFetchMoreDeliveries={handleFetchMoreDeliveries}
                    onCreatePickup={createPickupModalState.open}
                    onCreateDelivery={createDeliveryModalState.open}
                />
                {createPickupModalState.isOpen && (
                    <CreatePickupModalWithState
                        isVisible={createPickupModalState.isOpen}
                        onCreatePickupComplete={createPickupModalState.close}
                        closeModal={createPickupModalState.close}
                        refetchQuery={TRANSFERS_PAGE_QUERY}
                        assets={assets}
                        {...createPickupModalState.initialState}
                    />
                )}
                {createDeliveryModalState.isOpen && (
                    <CreateDeliveryModalWithState
                        onCreateDeliveryComplete={createDeliveryModalState.close}
                        closeModal={createDeliveryModalState.close}
                        refetchQuery={TRANSFERS_PAGE_QUERY}
                        assets={assets}
                        {...createDeliveryModalState.initialState}
                    />
                )}
            </Main>
        </>
    )
}

export default TransfersPageWithState
