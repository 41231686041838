import React from 'react'
import { useApolloClient } from '@apollo/client'
import { notification } from 'antd'
import resendAdminUserMail from '../../../api/resendAdminUserMail'
import useModalState from '../../hooks/useModalState'
import useDrawerState from '../../hooks/useDrawerState'
import SettingsPageError from '../../layout/SettingsPageError'
import UsersPage from './UsersPage'
import useFetchUsersPageData from './useFetchUsersPageData'
import CreateAdminUserModalWithState from './CreateAdminUserModalWithState'
import UpdateAdminUserDrawerWithState from './UpdateAdminUserDrawerWithState'

const UsersPageWithState = () => {
    const createAdminUserModalState = useModalState()
    const updateAdminUserDrawerState = useDrawerState()

    const apolloClient = useApolloClient()
    const {
        error,
        isFetching,
        adminUsers,
        hasMore,
        isFetchingMore,
        handleFetchMore,
    } = useFetchUsersPageData()

    if (error) {
        return (
            <SettingsPageError
                activeMenuItem="users"
            />
        )
    }

    return (
        <>
            <UsersPage
                hasMore={hasMore}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                adminUsers={adminUsers}
                handleFetchMore={handleFetchMore}
                onCreateAdminUser={() => createAdminUserModalState.open()}
                onUpdateAdminUser={(user) => updateAdminUserDrawerState.open({ user })}
                onResendEmailInvitation={async (user) => {
                    if (user) {
                        try {
                            await resendAdminUserMail(apolloClient, user.id)
                            notification.success({
                                message: 'Email sent!',
                                description: `A new email invitation was sent to ${user.firstName}`,
                            })
                        } catch (e) {
                            notification.error({
                                message: 'Email not sent!',
                                description: `Something went wrong. The following error occured: ${e}`,
                            })
                        }
                    }
                }}
            />
            {createAdminUserModalState.isOpen && (
                <CreateAdminUserModalWithState
                    closeModal={createAdminUserModalState.close}
                    {...createAdminUserModalState.initialState}
                />
            )}
            {updateAdminUserDrawerState.isOpen && (
                <UpdateAdminUserDrawerWithState
                    closeDrawer={updateAdminUserDrawerState.close}
                    {...updateAdminUserDrawerState.initialState}
                />
            )}
        </>
    )
}

export default UsersPageWithState
