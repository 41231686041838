import React from 'react'
import clsx from 'clsx'
import { Button } from 'antd'
import AssetSelect from '../../util/form/AssetSelect'
import usePermissions from '../../hooks/usePermisssions'
import TradePageDateSelect from '../trades/TradePageDateSelect'

const CustomerTradesPageTopBar = ({
    loading,
    assets,
    selectedMonth,
    selectedYear,
    selectedAssetId,
    onChangeMonth,
    onChangeYear,
    onChangeAsset,
    onCreateTrade,
    className,
}) => {
    const { isAdmin } = usePermissions()
    return (
        <div>
            <div className="flex justify-end mb-6">
                {isAdmin && (
                    <Button
                        type="primary"
                        onClick={onCreateTrade}
                    >
                        Create trade
                    </Button>
                )}
            </div>
            <div className={clsx('flex justify-end', className)}>
                <AssetSelect
                    allowClear
                    disabled={loading}
                    assets={assets}
                    value={selectedAssetId}
                    onChange={onChangeAsset}
                    placeholder="Filter on asset"
                    style={{ width: 200 }}
                />
                <TradePageDateSelect
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    onChangeMonth={onChangeMonth}
                    onChangeYear={onChangeYear}
                    className="ml-4"
                />
            </div>
        </div>
    )
}

export default CustomerTradesPageTopBar
