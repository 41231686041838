import { gql, useMutation } from '@apollo/client'
import CustomerTradesPageTable from './CustomerTradesPageTable'

const MUTATION = gql`
    mutation createCustomerTradeMutation($input: CreateCustomerTradeInput!) {
        createCustomerTrade(input: $input) {
            trade {
                ...CustomerTradesPageTable
            }
        }
    }
    ${CustomerTradesPageTable.FRAGMENT}
`

const useCreateCustomerTradeMutation = (options) => (
    useMutation(MUTATION, options)
)

export default useCreateCustomerTradeMutation
