import React from 'react'
import { Tag, Popover } from 'antd'
import { ROUNDINGS } from '../../../update-asset/table/PriceAdjustmentsTable'
import CurrencyValue from '../../../../util/CurrencyValue'
import AssetLogItem from './AssetLogItem'

const AssetUpdatedItem = ({
    appEvent,
}) => {
    const { createdAt, user, payload } = appEvent
    const { name, code, priceAdjustments } = payload
    return (
        <AssetLogItem
            createdAt={createdAt}
            user={user}
        >
            <div>
                <strong>{name} ({code})</strong>
                {' '}
                updated.
                {' '}
                <Popover
                    trigger="click"
                    content={(
                        <ul>
                            <li>Name: <strong>{name}</strong></li>
                            <li>Code: <strong>{code}</strong></li>
                            {priceAdjustments.map(({
                                unitCode,
                                currencyCode,
                                discount,
                                premium,
                                step,
                                rounding,
                            }) => (
                                <li key={`${currencyCode}/${unitCode}`}>
                                    {currencyCode}/{unitCode}
                                    <ul>
                                        <li>
                                            Discount:
                                            {' '}
                                            <strong>
                                                <CurrencyValue value={discount} />
                                            </strong>
                                        </li>
                                        <li>
                                            Premium:
                                            {' '}
                                            <strong>
                                                <CurrencyValue value={premium} />
                                            </strong>
                                        </li>
                                        <li>
                                            Step:
                                            {' '}
                                            <strong>
                                                <CurrencyValue value={step} />
                                            </strong>
                                        </li>
                                        <li>
                                            Rounding:
                                            {' '}
                                            <strong>
                                                {ROUNDINGS.find(({ value }) => value === `${rounding}`).label}
                                            </strong>
                                        </li>
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    )}
                >
                    <Tag>Data</Tag>
                </Popover>
            </div>
        </AssetLogItem>
    )
}

export default AssetUpdatedItem
