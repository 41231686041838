import React from 'react'
import { Button } from 'antd'
import { STATUS_FILTER_OPTIONS, PAYMENT_FILTER_OPTIONS } from './useFetchCustomerOrdersPageData'

const CustomerOrdersTableFilter = ({
    filters,
    onResetTableFilter,
}) => {
    const {
        statusFilter,
        paymentFilter,
    } = filters

    const appliedFilters = []
    if (filters.orderId !== null) {
        appliedFilters.push(`Ref. ${filters.orderId}`)
    }
    if (statusFilter.length > 0 && statusFilter.length !== STATUS_FILTER_OPTIONS.length) {
        appliedFilters.push(`Transfer: ${statusFilter.map((status, index) => (
            `${status}${index !== statusFilter.length - 1 ? ', ' : ''}`
        ))}`)
    }
    if (paymentFilter.length > 0 && paymentFilter.length !== PAYMENT_FILTER_OPTIONS.length) {
        appliedFilters.push(`Payment: ${paymentFilter.map((paymentStatus, index) => (
            `${paymentStatus}${index !== paymentFilter.length - 1 ? ', ' : ''}`
        ))}`)
    }

    if (appliedFilters.length <= 0) {
        return (
            <div className="flex items-center">
                <h3 className="text-xl">Applied Filters:</h3>
                <span className="ml-3">None</span>
            </div>
        )
    }

    return (
        <div className="flex items-center">
            <h3 className="text-xl">Applied Filters:</h3>
            {appliedFilters.map((filter) => (
                <div
                    key={filter}
                    className="ml-3"
                >
                    {filter}
                </div>
            ))}
            <div className="ml-3">
                <Button
                    size="small"
                    onClick={onResetTableFilter}
                >
                    Reset
                </Button>
            </div>
        </div>
    )
}

export default CustomerOrdersTableFilter
