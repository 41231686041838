import { gql } from '@apollo/client'

const ASSET_FRAGMENT = gql`
    fragment Asset on Asset {
        id
        name
        suspended
        code
        currencies {
            code
            name
            symbol
        }
        units {
            code
            name
            milligram
        }
    }
`

export default ASSET_FRAGMENT
