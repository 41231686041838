import React from 'react'
import { Form, Input } from 'antd'
import FormItem from '../../util/form/FormItem'
import UnitSelectWithCache from '../../util/form/UnitSelectWithCache'
import CurrencySelectWithCache from '../../util/form/CurrencySelectWithCache'
import ImpuritiesTable from './table/ImpuritiesTable'
import ImpurityInfoAlert from './ImpurityInfoAlert'
import PriceAdjustmentsTable from './table/PriceAdjustmentsTable'
import PriceAdjustmentInfoAlert from './PriceAdjustmentInfoAlert'

const AssetForm = ({
    formState,
}) => {
    const {
        values,
        getInputProps,
        getNativeInputProps,
        antdErrors,
        handleCurrencyChange,
        handleUnitChange,
    } = formState

    const showImpurities = window.env.REACT_APP_SHOW_IMPURITIES

    return (
        <Form layout="vertical">
            <FormItem
                label="Name"
                error={antdErrors.get('name')}
            >
                <Input
                    {...getNativeInputProps('name')}
                />
            </FormItem>
            <FormItem
                label="Code"
                error={antdErrors.get('code')}
            >
                <Input
                    {...getNativeInputProps('code')}
                />
            </FormItem>

            <FormItem
                label="Currencies"
                error={antdErrors.get('currencies')}
            >
                <CurrencySelectWithCache
                    {...getInputProps('currencies')}
                    onChange={handleCurrencyChange}
                    multiple
                />
            </FormItem>
            <FormItem
                label="Units"
                error={antdErrors.get('units')}
            >
                <UnitSelectWithCache
                    {...getInputProps('units')}
                    onChange={handleUnitChange}
                    multiple
                />
            </FormItem>
            <h2 className="text-2xl">Price Adjustments:</h2>
            <PriceAdjustmentsTable
                formState={formState}
            />
            <PriceAdjustmentInfoAlert />
            {showImpurities && values.impurities !== null && (
                <>
                    <h2 className="mt-6">Impurities:</h2>
                    <ImpuritiesTable
                        formState={formState}
                    />
                    <ImpurityInfoAlert />
                </>
            )}
        </Form>
    )
}

export default AssetForm
