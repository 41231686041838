import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
    Table,
    Button,
    Popover,
} from 'antd'
import {
    MoreOutlined,
    CommentOutlined,
} from '@ant-design/icons'
import * as routes from '../../../routes'
import CurrencyValue from '../../util/CurrencyValue'
import UnitValue from '../../util/UnitValue'
import * as constants from '../../../util/constants'
import TradePageTableActionDropdown from './TradePageTableActionDropdown'

const { Column, ColumnGroup } = Table
const {
    SIDE_SELL,
    UNIT_CODE_OUNCE: OZ,
    UNIT_CODE_KILOGRAM: KG,
    TRADE_TYPE_OTHER,
    TRADE_TYPE_ORDER,
    TRADE_TYPE_HEDGE,
} = constants

const toSide = ({
    id,
    date,
    side,
    currencyCode,
    quantity,
    quantityUnitCode,
    type,
    counterParty,
    price,
    priceUnitCode,
    conversionRate,
    totalPrice,
    ...rest
}) => {
    if (side === SIDE_SELL) {
        return {
            id,
            type,
            date,
            sellDate: date,
            sellCurrencyCode: currencyCode,
            sellQuantity: quantity,
            sellQuantityUnitCode: quantityUnitCode,
            sellCounterParty: counterParty,
            sellType: type,
            sellPrice: price,
            sellPriceUnitCode: priceUnitCode,
            sellConversionRate: conversionRate,
            sellTotalPrice: totalPrice,
            ...rest,
        }
    }
    return {
        id,
        type,
        date,
        buyDate: date,
        buyCurrencyCode: currencyCode,
        buyQuantity: quantity,
        buyQuantityUnitCode: quantityUnitCode,
        buyCounterParty: counterParty,
        buyType: type,
        buyPrice: price,
        buyPriceUnitCode: priceUnitCode,
        buyConversionRate: conversionRate,
        buyTotalPrice: totalPrice,
        ...rest,
    }
}

const ColumnGroupTitle = ({
    title,
    subTitle,
}) => (
    <div className="flex items-center ">
        <h1 className="text-xl mr-8">{title}</h1>
        <div>{subTitle}</div>
    </div>
)

const TradePageTable = ({
    trades,
    loading,
    onDeleteTrade,
    onUpdateTrade,
}) => {
    const tradesBySide = trades.map(toSide)
    return (
        <Table
            size="small"
            rowKey="id"
            useFixedHeader
            loading={loading}
            dataSource={tradesBySide}
            pagination={false}
        >
            <Column
                key="date"
                dataIndex="date"
                align="right"
                render={(date) => date && moment(date).format('DD/MM/YY HH:mm')}
                className="border-r-1"
                width={140}
            />
            <ColumnGroup
                align="left"
                className="border-r-1"
                title={(
                    <ColumnGroupTitle
                        title="Buy"
                        subTitle="Value Trading buys from…"
                    />
                )}
            >
                <Column
                    key="buyCounterParty"
                    dataIndex="buyCounterParty"
                    title="Counter party"
                    render={(counterParty, { buyType, customer }) => {
                        if (buyType === TRADE_TYPE_OTHER) {
                            return counterParty
                        }
                        if (buyType === TRADE_TYPE_ORDER) {
                            return (
                                <span className="mr-3">
                                    <Link className="text-blue-400 underline hover:text-blue-400" to={routes.customerTrades(customer.id)}>
                                        {counterParty}
                                    </Link>
                                </span>
                            )
                        }
                        if (buyType === TRADE_TYPE_HEDGE) {
                            return (
                                <span className="mr-3">
                                    {counterParty}
                                </span>
                            )
                        }
                        return null
                    }}
                />
                <Column
                    key="buyQuantity"
                    dataIndex="buyQuantity"
                    title="Quantity"
                    align="right"
                    render={(buyQuantity, { buyQuantityUnitCode }) => {
                        if (!buyQuantity) {
                            return null
                        }
                        if (buyQuantityUnitCode !== OZ) {
                            return (
                                <UnitValue
                                    value={buyQuantity}
                                    unitCode={KG}
                                />
                            )
                        }
                        return (
                            <UnitValue
                                value={buyQuantity}
                                unitCode={buyQuantityUnitCode}
                            />
                        )
                    }}
                />
                <Column
                    key="buyPrice"
                    dataIndex="buyPrice"
                    title="Price"
                    align="right"
                    render={(buyPrice, { buyCurrencyCode, buyPriceUnitCode }) => {
                        if (!buyPrice) {
                            return null
                        }
                        return (
                            <CurrencyValue
                                isBold
                                value={buyPrice}
                                displayCurrencyCode={`${buyCurrencyCode}/${buyPriceUnitCode}`}
                            />
                        )
                    }}
                />
                <Column
                    key="buyTotalPrice"
                    dataIndex="buyTotalPrice"
                    title="Total"
                    align="right"
                    className="border-r-1"
                    render={(buyTotalPrice, { buyCurrencyCode }) => {
                        if (!buyTotalPrice) {
                            return null
                        }
                        return (
                            <CurrencyValue
                                value={buyTotalPrice}
                                currencyCode={buyCurrencyCode}
                            />
                        )
                    }}
                />
            </ColumnGroup>
            <ColumnGroup
                align="left"
                title={(
                    <ColumnGroupTitle
                        title="Sell"
                        subTitle="Value Trading sells to…"
                    />
                )}
            >
                <Column
                    key="sellCounterParty"
                    dataIndex="sellCounterParty"
                    title="Counter party"
                    render={(counterParty, { sellType, customer }) => {
                        if (sellType === TRADE_TYPE_OTHER) {
                            return counterParty
                        }
                        if (sellType === TRADE_TYPE_ORDER) {
                            return (
                                <span className="mr-3">
                                    <Link className="text-blue-400 underline hover:text-blue-400" to={routes.customerTrades(customer.id)}>
                                        {counterParty}
                                    </Link>
                                </span>
                            )
                        }
                        if (sellType === TRADE_TYPE_HEDGE) {
                            return (
                                <span className="mr-3 text-orange-400">
                                    {counterParty}
                                </span>
                            )
                        }
                        return null
                    }}
                />
                <Column
                    key="sellQuantity"
                    dataIndex="sellQuantity"
                    title="Quantity"
                    align="right"
                    render={(sellQuantity, { sellQuantityUnitCode }) => {
                        if (!sellQuantity) {
                            return null
                        }
                        if (sellQuantityUnitCode !== OZ) {
                            return (
                                <UnitValue
                                    value={sellQuantity}
                                    unitCode={KG}
                                />
                            )
                        }
                        return (
                            <UnitValue
                                value={sellQuantity}
                                unitCode={sellQuantityUnitCode}
                                displayUnitCode={sellQuantityUnitCode}
                            />
                        )
                    }}
                />
                <Column
                    key="sellPrice"
                    dataIndex="sellPrice"
                    title="Price"
                    align="right"
                    render={(sellPrice, { sellCurrencyCode, sellPriceUnitCode }) => {
                        if (!sellPrice) {
                            return null
                        }
                        return (
                            <CurrencyValue
                                isBold
                                value={sellPrice}
                                displayCurrencyCode={`${sellCurrencyCode}/${sellPriceUnitCode}`}
                            />
                        )
                    }}
                />
                <Column
                    key="sellTotalPrice"
                    dataIndex="sellTotalPrice"
                    title="Total"
                    align="right"
                    render={(sellTotalPrice, { sellCurrencyCode }) => {
                        if (!sellTotalPrice) {
                            return null
                        }
                        return (
                            <CurrencyValue
                                value={sellTotalPrice}
                                currencyCode={sellCurrencyCode}
                            />
                        )
                    }}
                />
            </ColumnGroup>
            <Column
                key="id"
                dataIndex="id"
                align="left"
                className="border-l-1"
                render={(_, trade) => (
                    trade.notes !== null && trade.notes !== '' && (
                        <Popover
                            placement="topRight"
                            content={trade.notes}
                        >
                            <Button
                                ghost
                                icon={<CommentOutlined />}
                                type="primary"
                                shape="circle"
                                className="border-none"
                            />
                        </Popover>
                    )
                )}
            />
            <Column
                key="id"
                dataIndex="id"
                render={(_, trade) => (
                    <TradePageTableActionDropdown
                        trade={trade}
                        onEdit={() => onUpdateTrade(trade)}
                        onDelete={() => onDeleteTrade(trade.id)}
                    >
                        <Button
                            ghost
                            icon={<MoreOutlined />}
                            type="primary"
                            shape="circle"
                            className="border-none"
                        />
                    </TradePageTableActionDropdown>
                )}
            />
        </Table>
    )
}

export default TradePageTable
