import { useQuery, gql } from '@apollo/client'
import { ASSETS_PAGE_FRAGMENT } from '../assets/useFetchAssetsPageData'

export const UPDATE_ASSET_PAGE_QUERY = gql`
    query updateAssetPageQuery($assetId: Int!) {
        asset(id: $assetId) {
            ...AssetPageAsset
        }
    }
    ${ASSETS_PAGE_FRAGMENT}
`

const useFetchUpdateAssetPageData = (assetId) => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(UPDATE_ASSET_PAGE_QUERY, {
        variables: {
            assetId,
        },
    })

    const { asset = {} } = data

    return ({
        error,
        isFetching: loading,
        asset,
    })
}

export default useFetchUpdateAssetPageData
