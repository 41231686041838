import React, { useState } from 'react'
import { notification } from 'antd'
import ExportTradesModal from './ExportTradesModal'
import getExportedTrades from './getExportedTrades'
import useExportTradesFormState from './useExportTradesFormState'

const ExportTradesModalWithState = ({
    assetId,
    from,
    until,
    isOpen,
    closeModal,
    onCompleted,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const formState = useExportTradesFormState({
        dateRange: [from, until],
    })

    const handleExportTrades = async () => {
        if (formState.validate()) {
            try {
                setIsLoading(true)
                const { dateRange } = formState.values
                await getExportedTrades({
                    assetId,
                    from: dateRange[0],
                    until: dateRange[1],
                })
                onCompleted()
            } catch (e) {
                notification.error({
                    message: 'Trades not exported!',
                    description: `Something went wrong. The following error occured: ${e}`,
                })
            } finally {
                setIsLoading(true)
            }
        }
    }
    return (
        <ExportTradesModal
            isOpen={isOpen}
            isLoading={isLoading}
            formState={formState}
            onCancel={closeModal}
            onExportTrades={handleExportTrades}
        />
    )
}

export default ExportTradesModalWithState
