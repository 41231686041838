import React from 'react'
import Main from '../../layout/Main'
import Navbar from '../../layout/Navbar'
import AssetCardWithState from './assetcard/AssetCardWithState'

const PricesPage = ({
    assets,
    activeMenuItem,
}) => (
    <>
        <Navbar activeMenuItem={activeMenuItem} />
        <Main>
            <div className="flex flex-col items-center">
                {assets.map((asset) => (
                    <AssetCardWithState
                        key={asset.id}
                        isCustomerPage
                        asset={asset}
                    />
                ))}
            </div>
        </Main>
    </>
)

export default PricesPage
