import React, { useState, useEffect } from 'react'
import { notification } from 'antd'
import { useParams } from 'react-router-dom'
import roundTo from 'round-to'
import { useAsyncEffect } from '@wappla/react-hooks'
import moment from 'moment'
import { subscribeToEvent } from '../../../services/pusher/pusher'
import * as constants from '../../../util/constants'
import { milligramToUnit } from '../../../util/units'
import PageError from '../../layout/PageError'
import useModalState from '../../hooks/useModalState'
import { whereId } from '../../util/index'
import * as cache from '../../../util/cache'
import {
    THIS_MONTH,
    LAST_MONTH,
    NEXT_MONTH,
} from './TradePageDateSelect'
import TradePage from './TradePage'
import useTradeDateState from './useTradeDateState'
import useTradePageQuery from './useTradePageQuery'
import useDeleteTradeMutation from './useDeleteTradeMutation'
import CreateTradeModalWithState from './CreateTradeModalWithState'
import UpdateTradeModalWithState from './UpdateTradeModalWithState'
import ExportTradesModalWithState from './ExportTradesModalWithState'
import CloseTradeMonthModalWithState from './CloseTradeMonthModalWithState'
import CreateHedgeTradeModalWithState from './CreateHedgeTradeModalWithState'

const {
    SIDE_SELL,
    SIDE_BUY,
    UNIT_CODE_OUNCE,
    PUSHER_ADMIN_CHANNEL_NAME: ADMIN_CHANNEL,
} = constants

function TradePageWithState() {
    const params = useParams()
    const assetId = parseInt(params.assetId, 10) || 1
    const activeMenuItem = `trades:${assetId}`
    const [modalIsOpen, setModalOpen] = useState()
    const [isTableExpanded, setIsTableExpanded] = useState(false)
    const updateModalState = useModalState()
    const exportTradesModalState = useModalState()
    const transferTradeModalState = useModalState()
    const createHedgeTradeModalOpenState = useModalState()

    const {
        fromDate,
        untilDate,
        selectedMonth,
        selectedYear,
        setSelectedMonth,
        setSelectedYear,
    } = useTradeDateState()

    useAsyncEffect(async () => {
        const isExpanded = await cache.isTableExpanded()
        setIsTableExpanded(!!isExpanded)
    }, [])

    const {
        error,
        trades,
        tradeSummary,
        loading,
        refetch,
        isFetchingMore,
        hasMore,
        handleFetchMore,
    } = useTradePageQuery({
        variables: {
            assetId,
            from: fromDate,
            until: untilDate,
            orderBy: { date: 'DESC' },
        },
    })
    const [deleteTrade] = useDeleteTradeMutation({
        onCompleted: refetch,
    })

    useEffect(() => subscribeToEvent(ADMIN_CHANNEL, 'newTrade', refetch), [])

    if (error) {
        return <PageError />
    }
    const handleDeleteTrade = async (tradeId) => {
        try {
            await deleteTrade({
                variables: {
                    id: tradeId,
                },
            })
            notification.success({
                message: 'Trade deleted!',
                description: 'Trade was successfully deleted!',
            })
        } catch (e) {
            notification.error({
                message: 'Trade not deleted!',
                description: `Something went wrong. The following error occurred: ${e}`,
            })
        }
    }
    return (
        <>
            <TradePage
                hasMore={hasMore}
                activeMenuItem={activeMenuItem}
                assetId={assetId}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                trades={trades}
                loading={loading}
                tradeSummary={tradeSummary}
                handleFetchMore={handleFetchMore}
                isFetchingMore={isFetchingMore}
                isTableExpanded={isTableExpanded}
                onExportTrades={() => exportTradesModalState.open({
                    from: moment.utc(fromDate),
                    until: moment.utc(untilDate),
                })}
                onToggleTableExpand={() => {
                    setIsTableExpanded((prevState) => !prevState)
                    cache.toggleIsTableExpanded()
                }}
                onFixOpenQuantity={() => {
                    const { open } = tradeSummary
                    createHedgeTradeModalOpenState.open({
                        side: open > 0 ? SIDE_SELL : SIDE_BUY,
                        quantity: roundTo.up(milligramToUnit(Math.abs(open), UNIT_CODE_OUNCE), 0),
                    })
                }}
                onChangeMonth={(month) => {
                    setSelectedMonth(month)
                    if (month === LAST_MONTH || month === THIS_MONTH || month === NEXT_MONTH) {
                        setSelectedYear(moment())
                    }
                }}
                onCloseMonth={() => transferTradeModalState.open({
                    open: tradeSummary.open,
                    date: moment.utc(fromDate),
                })}
                onChangeYear={setSelectedYear}
                onCreateTrade={() => setModalOpen(true)}
                onCreateHedgeTrade={() => createHedgeTradeModalOpenState.open()}
                onDeleteTrade={handleDeleteTrade}
                onUpdateTrade={(trade) => (
                    updateModalState.open({ trade: trades.find(whereId(trade.id)) })
                )}
            />
            {modalIsOpen && (
                <CreateTradeModalWithState
                    date={moment().seconds(0)}
                    assetId={assetId}
                    isOpen={modalIsOpen}
                    onCreateTradeCompleted={() => setModalOpen(false)}
                    closeModal={() => setModalOpen(false)}
                />
            )}
            {createHedgeTradeModalOpenState.isOpen && (
                <CreateHedgeTradeModalWithState
                    assetId={assetId}
                    isOpen={createHedgeTradeModalOpenState.isOpen}
                    onCreateTradeCompleted={() => createHedgeTradeModalOpenState.close()}
                    closeModal={createHedgeTradeModalOpenState.close}
                    {...createHedgeTradeModalOpenState.initialState}
                />
            )}
            {updateModalState.isOpen && (
                <UpdateTradeModalWithState
                    isOpen={updateModalState.isOpen}
                    onUpdateTradeCompleted={() => {
                        updateModalState.close()
                        refetch()
                    }}
                    closeModal={updateModalState.close}
                    {...updateModalState.initialState}
                />
            )}
            {transferTradeModalState.isOpen && (
                <CloseTradeMonthModalWithState
                    assetId={assetId}
                    isOpen={transferTradeModalState.isOpen}
                    onCompleted={() => {
                        transferTradeModalState.close()
                        refetch()
                    }}
                    closeModal={transferTradeModalState.close}
                    {...transferTradeModalState.initialState}
                />
            )}
            {exportTradesModalState.isOpen && (
                <ExportTradesModalWithState
                    assetId={assetId}
                    isOpen={exportTradesModalState.isOpen}
                    closeModal={exportTradesModalState.close}
                    onCompleted={exportTradesModalState.close}
                    {...exportTradesModalState.initialState}
                />
            )}
        </>
    )
}

export default TradePageWithState
