import React from 'react'
import MainCentered from '../../layout/MainCentered'
import LoginFormWrapper from './LoginFormWrapper'
import LoginFormWithState from './LoginFormWithState'

const LoginPage = () => (
    <MainCentered>
        <LoginFormWrapper title="Login">
            <LoginFormWithState />
        </LoginFormWrapper>
    </MainCentered>
)

export default LoginPage
