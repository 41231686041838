import React from 'react'
import {
    Tag,
    Table,
    Button,
} from 'antd'
import {
    MoreOutlined,
    DownloadOutlined,
    MinusCircleTwoTone,
    CheckCircleTwoTone,
} from '@ant-design/icons'
import moment from 'moment'
import InfiniteScroll from '../../util/InfiniteScroll'
import CurrencyValue from '../../util/CurrencyValue'
import * as constants from '../../../util/constants'
import { getCurrencySymbol } from '../../../util/currency'
import FilterCheckboxGroup from './FilterCheckboxGroup'
import CustomerOrdersTableDetails from './CustomerOrdersTableDetails'
import CustomerTradesTableActionDropdown from './CustomerOrdersTableActionDropdown'
import { PAYMENT_FILTER_OPTIONS, STATUS_FILTER_OPTIONS } from './useFetchCustomerOrdersPageData'

const {
    SIDE_SELL,
    ORDER_FIX_STATUS_FIXED: FIXED,
    ORDER_FIX_STATUS_FIXING: FIXING,
} = constants

const getColumns = ({
    filters,
    onEditOrder,
    onAddPickup,
    onAddDelivery,
    onExportOrders,
    onConfirmOrder,
    onApplyStatusFilter,
    onApplyPaymentFilter,
}) => [{
    title: (
        <Button
            type="primary"
            icon={<DownloadOutlined />}
            size="small"
            onClick={onExportOrders}
            ghost
        />
    ),
    key: 'side',
    dataIndex: 'side',
    render: (side) => (
        <Tag
            color={side === SIDE_SELL ? 'green' : 'blue'}
        >
            {side}
        </Tag>
    ),
    align: 'center',
}, {
    dataIndex: 'reference',
    title: 'Ref.',
    render: (reference) => reference !== null && reference,
    align: 'left',
}, {
    dataIndex: 'createdAt',
    title: 'Traded on',
    render: (createdAt) => createdAt !== null && moment(createdAt).format('DD/MM'),
    align: 'left',
}, {
    title: 'Asset',
    key: 'asset',
    dataIndex: 'asset',
    render: (asset, order) => (
        order.impurity === null ? (
            `${asset.name} - ${asset.code}`
        ) : (
            `${asset.name} ${order.impurity.name}`
        )
    ),
    align: 'center',
}, {
    key: 'quantity',
    title: 'Transfer',
    dataIndex: 'quantity',
    filterDropdown: () => (
        <FilterCheckboxGroup
            value={filters.statusFilter}
            options={STATUS_FILTER_OPTIONS}
            onApplyFilter={onApplyStatusFilter}
        />
    ),
    render: (quantity, {
        quantityInUnit, outstandingQuantityInUnit, quantityUnitCode,
    }) => quantity !== null && (
        <>
            {quantityInUnit - outstandingQuantityInUnit}
            /
            {quantityInUnit}
            {quantityUnitCode}
        </>
    ),
}, {
    dataIndex: 'price',
    title: 'Price',
    render: (price, order) => price !== null && (
        <>
            <CurrencyValue
                value={price}
                currencyCode={order.currencyCode}
                displayCurrencyCode={getCurrencySymbol(order.currencyCode)}
            />
            /
            {order.priceUnitCode}
        </>
    ),
    align: 'left',
}, {
    dataIndex: 'totalPrice',
    title: 'Order Total',
    render: (totalPrice, order) => totalPrice !== null && (
        <CurrencyValue
            isBold
            value={totalPrice}
            currencyCode={order.currencyCode}
            displayCurrencyCode={getCurrencySymbol(order.currencyCode)}
        />
    ),
}, {
    title: 'Status',
    dataIndex: 'fixStatus',
    render: (fixStatus) => {
        const getColor = () => {
            switch (fixStatus) {
                case FIXED:
                    return 'green'
                case FIXING:
                    return 'orange'
                default:
                    return 'red'
            }
        }
        return (
            <Tag
                color={getColor()}
            >
                {fixStatus}
            </Tag>
        )
    }
}, {
    title: 'Payment',
    dataIndex: 'totalOutstandingPrice',
    filterDropdown: () => (
        <FilterCheckboxGroup
            value={filters.paymentFilter}
            options={PAYMENT_FILTER_OPTIONS}
            onApplyFilter={onApplyPaymentFilter}
        />
    ),
    render: (totalOutstandingPrice) => {
        const paymentIsResolved = totalOutstandingPrice <= 0
        if (paymentIsResolved) {
            return <CheckCircleTwoTone style={{ fontSize: 18 }} twoToneColor="green" />
        }
        return <MinusCircleTwoTone style={{ fontSize: 18 }} twoToneColor="red" />
    },
    align: 'center',
}, {
    title: 'Action',
    dataIndex: 'uid',
    render: (uid, order) => (
        <CustomerTradesTableActionDropdown
            order={order}
            onEditOrder={onEditOrder}
            onAddPickup={onAddPickup}
            onAddDelivery={onAddDelivery}
            onConfirmOrder={onConfirmOrder}
        >
            <Button
                ghost
                icon={<MoreOutlined />}
                type="primary"
                shape="circle"
                className="ant-btn-no-border"
            />
        </CustomerTradesTableActionDropdown>
    ),
    align: 'center',
}]

const CustomerOrdersTable = ({
    loading,
    filters,
    customerOrders,
    isFetchingMore,
    hasMore,
    onEditOrder,
    onFetchMore,
    onAddPickup,
    onAddDelivery,
    onExportOrders,
    onConfirmOrder,
    onApplyStatusFilter,
    onApplyPaymentFilter,
}) => (
    <InfiniteScroll
        isFetchingMore={isFetchingMore}
        hasMore={hasMore}
        onFetchMore={onFetchMore}
    >
        <Table
            rowKey="uid"
            columns={getColumns({
                filters,
                onEditOrder,
                onAddPickup,
                onAddDelivery,
                onExportOrders,
                onConfirmOrder,
                onApplyStatusFilter,
                onApplyPaymentFilter,
            })}
            dataSource={customerOrders}
            pagination={false}
            loading={loading}
            expandedRowRender={CustomerOrdersTableDetails}
        />
    </InfiniteScroll>
)

export default CustomerOrdersTable
