import React from 'react'
import { Popconfirm, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import useDeletePaymentMutation from './useDeletePaymentMutation'

const CustomerPaymentsTableDeleteButton = ({
    paymentId,
    disabled,
    onDeleteComplete,
}) => {
    const [deletePayment, { loading }] = useDeletePaymentMutation()
    return (
        <Popconfirm
            title="Are you sure you want to delete this payment?"
            onConfirm={async () => {
                await deletePayment({
                    variables: { id: paymentId },
                    update: () => onDeleteComplete(paymentId),
                })
            }}
            okText="Yes"
            cancelText="No"
            disabled={disabled}
        >
            <Button
                disabled={disabled}
                ghost
                loading={loading}
                icon={<DeleteOutlined />}
                type="danger"
                shape="circle"
                size="small"
                className="border-none"
            />
        </Popconfirm>
    )
}

export default CustomerPaymentsTableDeleteButton
