import { createFormValidation } from 'use-form-state'
import useFormState from '../../hooks/useFormState'
import * as validation from '../../../validation'

const INITIAL_VALUES = {
    orderPickups: [],
}

export const orderPickupsValidation = createFormValidation([{
    path: 'orderPickups',
    validate: (orderPickups) => validation.isLongerThan(0)(orderPickups),
    message: 'Please select at least one pickup',
}])

const useCreateOrderPickupsForm = (values, options) => useFormState(
    {
        ...INITIAL_VALUES,
        ...values,
    },
    {
        validation: orderPickupsValidation,
        ...options,
    },
)

export default useCreateOrderPickupsForm
