import moment from 'moment'
import { createFormValidation } from 'use-form-state'
import * as formValidation from '../../../validation'
import useFormState from '../../hooks/useFormState'
import { fixFloatingPointError } from '../../../util/units'

const validation = createFormValidation([{
    path: 'date',
    validate: formValidation.isValue,
    message: 'Please fill in a date.',
}, {
    path: 'assetId',
    validate: formValidation.isValue,
    message: 'Please select an asset.',
}, {
    path: 'price',
    validate: (value) => formValidation.isValue(value) && formValidation.isGreaterThan(0)(value),
    message: 'Price must be greater than 0.',
}, {
    path: 'quantity',
    validate: (value) => formValidation.isValue(value) && formValidation.isGreaterThan(0)(value),
    message: 'Quantity must be greater than 0.',
}])

const valuesToInput = ({
    date,
    side,
    currencyCode,
    quantity,
    quantityUnitCode,
    notes,
    price,
    priceUnitCode,
    assetId,
}) => ({
    date,
    side,
    currencyCode,
    quantity,
    quantityUnitCode,
    notes,
    price: fixFloatingPointError(price * 100),
    priceUnitCode,
    assetId,
})

const useUpdateCustomerTradeForm = (initialValues, options) => (
    useFormState(
        {
            ...initialValues,
            notes: null,
            assetId: initialValues.asset.id,
            date: moment(initialValues.date),
            price: initialValues.price / 100,
        },
        {
            validation,
            valuesToInput,
            ...options,
        }
    )
)

export default useUpdateCustomerTradeForm
