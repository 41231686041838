import React from 'react'
import { useParams } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { notification } from 'antd'
import resendCustomerUserMail from '../../../api/resendCustomerUserMail'
import PageError from '../../layout/PageError'
import useModalState from '../../hooks/useModalState'
import useDrawerState from '../../hooks/useDrawerState'
import CustomerPage from '../../layout/CustomerPage'
import useFetchCustomerUsersTableData from './useFetchCustomerUsersTableData'
import CustomerUsersPage from './CustomerUsersPage'
import CreateCustomerUserModalWithState from './CreateCustomerUserModalWithState'
import UpdateCustomerUserDrawerWithState from './UpdateCustomerUserDrawerWithState'

const CustomerUsersPageWithState = () => {
    const params = useParams()
    const createCustomerUserModalState = useModalState()
    const updateCustomerUserDrawerState = useDrawerState()
    const apolloClient = useApolloClient()
    const customerId = parseInt(params.customerId, 10)
    const {
        refetch,
        error,
        hasMore,
        isFetching,
        isFetchingMore,
        customer,
        customerUsers,
        handleFetchMore,
    } = useFetchCustomerUsersTableData(customerId)

    if (error) {
        return (
            <PageError
                activeMenuItem="customers"
                error={error}
            />
        )
    }

    return (
        <CustomerPage
            customerId={customer.id}
            isLoading={isFetching}
            activeMenuItem="users"
        >
            <CustomerUsersPage
                hasMore={hasMore}
                isFetchingMore={isFetchingMore}
                customer={customer}
                customerUsers={customerUsers}
                handleFetchMore={handleFetchMore}
                onDeleteComplete={() => refetch()}
                onCreateCustomerUser={() => { createCustomerUserModalState.open() }}
                onUpdateCustomerUser={(user) => updateCustomerUserDrawerState.open({ user })}
                onResendEmailInvitation={async (user) => {
                    if (user) {
                        try {
                            await resendCustomerUserMail(apolloClient, user.id)
                            notification.success({
                                message: 'Email sent!',
                                description: `A new email invitation was sent to ${user.firstName}`,
                            })
                        } catch (e) {
                            notification.error({
                                message: 'Email not sent!',
                                description: `Something went wrong. The following error occured: ${e}`,
                            })
                        }
                    }
                }}
            />
            {createCustomerUserModalState.isOpen && (
                <CreateCustomerUserModalWithState
                    customerId={customerId}
                    closeModal={createCustomerUserModalState.close}
                    {...createCustomerUserModalState.initialState}
                />
            )}
            {updateCustomerUserDrawerState.isOpen && (
                <UpdateCustomerUserDrawerWithState
                    customerId={customerId}
                    closeDrawer={updateCustomerUserDrawerState.close}
                    {...updateCustomerUserDrawerState.initialState}
                />
            )}
        </CustomerPage>
    )
}

export default CustomerUsersPageWithState
