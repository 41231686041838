import { gql } from '@apollo/client'

const CREATE_ORDER_DELIVERIES = gql`
    mutation createOrderDeliveries($input: OrderDeliveriesInput!) {
        createOrderDeliveries(input: $input) {
            orderDeliveries {
                id
            }
        }
    }
`

export default async function createOrderDeliveries(apolloClient, input, options) {
    const mutation = CREATE_ORDER_DELIVERIES
    const variables = { input }

    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })

    return response
}
