import React from 'react'
import { Modal } from 'antd'
import AddPickupToOrderForm from './AddPickupToOrderForm'

const AddPickupToOrderModal = ({
    formState,
    order,
    pickups,
    remainingQuantityToFill,
    isVisible,
    isSubmitting,
    onSubmit,
    onCancel,
    onAdd,
    onRemove,
}) => (
    <Modal
        title="Add pickup to order"
        visible={isVisible}
        width={750}
        onOk={onSubmit}
        closable={!isSubmitting}
        maskClosable={!isSubmitting}
        confirmLoading={isSubmitting}
        onCancel={onCancel}
    >
        <AddPickupToOrderForm
            formState={formState}
            order={order}
            pickups={pickups}
            remainingQuantityToFill={remainingQuantityToFill}
            onAdd={onAdd}
            onRemove={onRemove}
        />
    </Modal>
)

export default AddPickupToOrderModal
