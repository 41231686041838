import { gql } from '@apollo/client'

const MARK_AS_PAID_MUTATION = gql`
    mutation markOrderPaid($input: OrderPaymentInput!) {
        markOrderPaid(input: $input) {
            order {
                id
                totalOutstandingPrice
            }
        }
    }
`

export default async function markOrderPaid(apolloClient, orderId, customerId, options) {
    const mutation = MARK_AS_PAID_MUTATION
    const variables = { input: { orderId, customerId } }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
