import { gql } from '@apollo/client'

const TOGGLE_AVAILABLE_ASSET_MUTATION = gql`
    mutation toggleAvailableAsset($id: Int!) {
        toggleAvailableAsset(id: $id) {
            asset {
                id
                available
            }
        }
    }
`

export default async function toggleAvailableAsset(apolloClient, id, options) {
    const mutation = TOGGLE_AVAILABLE_ASSET_MUTATION
    const variables = { id }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
