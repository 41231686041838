import * as validation from '../index'

export const isValidType = (type) => (
    validation.isValue(type)
)

export const isValidAmount = (amount) => (
    validation.isValue(amount) &&
    validation.isNumber(amount) &&
    validation.isGreaterThan(0)(amount) &&
    validation.isLessOrEqualThan(20000000)(amount)
)

export const isValidCurrencyCode = (currencyCode) => (
    validation.isValue(currencyCode)
)

export const isValidCreatePaymentForm = (values) => (
    isValidType(values.type) &&
    isValidAmount(values.amount) &&
    isValidCurrencyCode(values.currencyCode)
)
