import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import MarketDataPlaceholderContainer from './MarketDataPlaceholderContainer'

const MarketDataLoading = () => (
    <MarketDataPlaceholderContainer>
        <LoadingOutlined />
    </MarketDataPlaceholderContainer>
)

export default MarketDataLoading
