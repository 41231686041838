import { gql } from '@apollo/client'
import CUSTOMER_FRAGMENT from '../components/fragments/CustomerFragment'

const UPDATE_CUSTOMER_MUTATION = gql`
    mutation updateCustomer($id: Int!, $input: CustomerInput!) {
        updateCustomer(id: $id, input: $input) {
            customer {
                ...Customer
            }
        }
    }
    ${CUSTOMER_FRAGMENT}
`

export default async function updateCustomer(apolloClient, id, input) {
    const mutation = UPDATE_CUSTOMER_MUTATION
    const variables = { id, input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
    })
    return response
}
