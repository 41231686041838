import React from 'react'
import { Alert } from 'antd'

const ImpurityInfoAlert = () => (
    <Alert
        showIcon
        className="mt-4 bg-blue-50 border-blue-300"
        message="Customer price calculation"
        description={(
            <>
                <div>
                    Customer <strong>sells</strong> to VT: Hedge bid price * percentage - discount = Sell price
                </div>
                <div>
                    Customer <strong>buys</strong> from VT: Hedge offer price * percentage + premium = Buy price
                </div>
            </>
        )}
    />
)

export default ImpurityInfoAlert
