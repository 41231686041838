import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { connectionToCollection, PAGE_INFO_FRAGMENT, mergeQueryResults } from '../../../util/graphql'
import CUSTOMER_FRAGMENT from '../../fragments/CustomerFragment'

export const CUSTOMER_TRANSFERS_PAGE_QUERY = gql`
    query customerDeliveriesPageQuery($afterPickups: String, $afterDeliveries: String, $first: Int, $customerId: Int!) {
        customer(id: $customerId) {
            ...Customer
        }
        customerDeliveries(after: $afterDeliveries, first: $first, customerId: $customerId) {
            edges {
                node {
                    id
                    quantity
                    createdAt
                    customer {
                        id
                        name
                    }
                    asset {
                        id
                        name
                    }
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
        customerPickups(after: $afterPickups, first: $first, customerId: $customerId) {
            edges {
                node {
                    id
                    quantity
                    createdAt
                    customer {
                        id
                        name
                    }
                    asset {
                        id
                        name
                    }
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
        assets {
            id
            name
            code
        }
    }
    ${CUSTOMER_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`

const useFetchCustomerTransfersPageData = (customerId, first, after) => {
    const [isFetchingMorePickups, setIsFetchingMorePickups] = useState(false)
    const [isFetchingMoreDeliveries, setIsFetchingMoreDeliveries] = useState(false)

    const variables = {
        customerId,
        first,
        after,
    }
    const {
        error,
        loading,
        data = {},
        fetchMore,
    } = useQuery(CUSTOMER_TRANSFERS_PAGE_QUERY, {
        variables,
    })
    const { customer, assets = [] } = data
    let { customerPickups = [], customerDeliveries = [] } = data

    let hasMorePickups = false
    let hasMoreDeliveries = false
    if (!loading && (typeof data.customerPickups !== 'undefined' || typeof data.customerDeliveries !== 'undefined')) {
        customerPickups = connectionToCollection(data.customerPickups)
        customerDeliveries = connectionToCollection(data.customerDeliveries)
        hasMorePickups = data.customerPickups.pageInfo.hasNextPage
        hasMoreDeliveries = data.customerDeliveries.pageInfo.hasNextPage
    }

    return {
        error,
        isFetching: loading,
        customer,
        customerPickups,
        customerDeliveries,
        assets,
        isFetchingMorePickups,
        isFetchingMoreDeliveries,
        hasMorePickups,
        hasMoreDeliveries,
        handleFetchMorePickups: () => {
            setIsFetchingMorePickups(true)
            const refetchAfter = customerPickups.pageInfo.endCursor
            fetchMore({
                variables: { after: refetchAfter },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    setIsFetchingMorePickups(false)
                    return mergeQueryResults(previousResult, fetchMoreResult, 'customerPickups')
                },
            })
        },
        handleFetchMoreDeliveries: () => {
            setIsFetchingMoreDeliveries(true)
            const refetchAfter = customerDeliveries.pageInfo.endCursor
            fetchMore({
                variables: { after: refetchAfter },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    setIsFetchingMoreDeliveries(false)
                    return mergeQueryResults(previousResult, fetchMoreResult, 'customerDeliveries')
                },
            })
        },
    }
}

export default useFetchCustomerTransfersPageData
