import { createValidationErrorObject } from '../index'
import * as validation from './choosePassword'

const getPasswordError = createValidationErrorObject(
    validation.isValidPassword,
    'password',
    'Please fill in a password.'
)

const getPasswordLengthError = createValidationErrorObject(
    validation.isPasswordLongEnough,
    'password',
    'Password should be at least 6 characters long.'
)

const getConfirmPasswordError = createValidationErrorObject(
    validation.isValidConfirmPassword,
    'confirmPassword',
    'Please fill in the same password.'
)

export const getChoosePasswordFormErrors = ({
    password,
    confirmPassword
}) => ({
    ...getPasswordError(password),
    ...getPasswordLengthError(password),
    ...getConfirmPasswordError(password, confirmPassword),
})
