import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { notification } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import * as routes from '../../../routes'
import CustomerPage from '../../layout/CustomerPage'
import CustomerPageError from '../../layout/CustomerPageError'
import { removeFromCollectionWhereId } from '../../../util/index'
import createCustomerAsset from '../../../api/createCustomerAsset'
import deleteCustomerAsset from '../../../api/deleteCustomerAsset'
import toggleSuspendCustomerAsset from '../../../api/toggleSuspendCustomerAsset'
import CustomerAssetsPage from './CustomerAssetsPage'
import useFetchCustomerAssetsPageData, { updateCustomerAssetsPageData } from './useFetchCustomerAssetsPageData'

const ACTIVE_PAGE = 'assets'

const CustomerAssetsPageWithState = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [isCreatingCustomerAsset, setIsCreatingCustomerAsset] = useState(false)
    const [isDeletingCustomerAsset, setIsDeletingCustomerAsset] = useState(false)
    const customerId = parseInt(params.customerId, 10)
    const apolloclient = useApolloClient()
    const {
        error,
        isFetching,
        assets,
        customer,
        customerAssets,
        refetchQueries,
    } = useFetchCustomerAssetsPageData(customerId)
    if (typeof error !== 'undefined') {
        return (
            <CustomerPageError
                customerId={customerId}
                activeMenuItem={ACTIVE_PAGE}
            />
        )
    }

    return (
        <CustomerPage
            customerId={customer.id}
            isLoading={isFetching}
            activeMenuItem={ACTIVE_PAGE}
        >
            <CustomerAssetsPage
                customer={customer}
                assets={assets}
                customerAssets={customerAssets}
                isCreatingCustomerAsset={isCreatingCustomerAsset}
                onCreateCustomerAsset={async (assetId) => {
                    setIsCreatingCustomerAsset(true)
                    try {
                        const { data } = await createCustomerAsset(apolloclient, customerId, assetId, { refetchQueries })
                        notification.success({
                            message: 'Asset Created!',
                            description: `Asset ${data.createCustomerAsset.customerAsset.asset.name} successfully created`,
                        })
                        setIsCreatingCustomerAsset(false)
                    } catch (e) {
                        notification.error({
                            message: 'Something went wrong',
                            description: `The following error occurred: ${e}`,
                        })
                        setIsCreatingCustomerAsset(false)
                    }
                }}
                onToggleSuspendCustomerAsset={async (asset) => {
                    try {
                        const { data } = await toggleSuspendCustomerAsset(apolloclient, asset.id, {
                            optimisticResponse: {
                                __typename: 'Mutation',
                                toggleSuspendCustomerAsset: {
                                    __typename: 'CustomerAssetPayload',
                                    customerAsset: {
                                        __typename: 'CustomerAsset',
                                        ...asset,
                                        suspended: !asset.suspended,
                                    },
                                },
                            },
                        })
                        let successMessage = 'Asset was successfully suspended'
                        if (data.toggleSuspendCustomerAsset.customerAsset.suspended === false) {
                            successMessage = 'Asset was successfully made available again'
                        }
                        notification.success({
                            message: 'Asset Updated!',
                            description: successMessage,
                        })
                    } catch (e) {
                        notification.error({
                            message: 'Something went wrong',
                            description: `The following error occurred: ${e}`,
                        })
                    }
                }}
                onEditCustomerAsset={(asset) => {
                    navigate(`/customer/${customerId}/settings/assets/${asset.id}`)
                }}
                isDeletingCustomerAsset={isDeletingCustomerAsset}
                onDeleteCustomerAsset={async (asset) => {
                    setIsDeletingCustomerAsset(true)
                    try {
                        await deleteCustomerAsset(apolloclient, asset)
                        updateCustomerAssetsPageData({
                            path: 'customerAssets',
                            value: removeFromCollectionWhereId(asset.id),
                            variables: { customerId },
                        })
                        notification.success({
                            message: 'Asset Deleted!',
                            description: 'The asset was successfully deleted',
                        })
                        setIsDeletingCustomerAsset(false)
                    } catch (e) {
                        notification.error({
                            message: 'Something went wrong',
                            description: `The following error occurred: ${e}`,
                        })
                        setIsDeletingCustomerAsset(false)
                    }
                }}
            />
        </CustomerPage>
    )
}

export default CustomerAssetsPageWithState
