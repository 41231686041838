import React from 'react'
import moment from 'moment'
import { Table, Button } from 'antd'
import { gql } from '@apollo/client'
import TableActions from '../../util/TableActions'
import CurrencyValue from '../../util/CurrencyValue'
import PreferredUnits from '../../util/PreferredUnits'
import { convertQuantity } from '../../../util/converters'

const tableHeight = 240

const FRAGMENT = gql`
    fragment UnfilledBuyCustomerOrdersTable on Order {
        id
        createdAt,
        reference
        quantity
        outstandingQuantity
        price
        priceUnitCode
        currencyCode
    }
`

const UnfilledBuyCustomerOrdersTable = ({
    isLoading,
    orders,
    formState,
}) => (
    <PreferredUnits>
        {({ preferredUnitCode }) => (
            <div className="customersTable">
                <Table
                    rowKey="id"
                    size="small"
                    className="h-60"
                    scroll={{ y: tableHeight }}
                    loading={isLoading}
                    pagination={false}
                    columns={[{
                        key: 'createdAt',
                        title: 'Date',
                        dataIndex: 'createdAt',
                        render: (createdAt) => moment(createdAt).format('DD/MM HH:mm'),
                        width: 110,
                    }, {
                        key: 'reference',
                        title: 'Ref.',
                        dataIndex: 'reference',
                        width: 50,
                    }, {
                        key: 'outstandingQuantity',
                        title: 'Outstanding',
                        dataIndex: 'outstandingQuantity',
                        width: 250,
                        render: (outstandingQuantity, order) => (
                            <>
                                <strong>{convertQuantity(outstandingQuantity, preferredUnitCode)}</strong>
                                {' / '}
                                {convertQuantity(order.quantity, preferredUnitCode)}
                                {preferredUnitCode}
                            </>
                        ),
                    }, {
                        key: 'price',
                        title: 'Price',
                        dataIndex: 'price',
                        render: (price, record) => (
                            <>
                                <CurrencyValue
                                    value={price}
                                    currencyCode={record.currencyCode}
                                />
                                /
                                {record.priceUnitCode}
                            </>
                        ),
                    }, {
                        key: 'action',
                        align: 'right',
                        width: 110,
                        render: (record) => {
                            const {
                                quantity,
                                outstandingQuantity,
                                originalOutstandingQuantity,
                            } = record
                            const recordIsFilled = outstandingQuantity === quantity
                            const recordIsPartiallyDeliveredAndFilled = originalOutstandingQuantity !== null && originalOutstandingQuantity === outstandingQuantity
    
                            return (
                                <TableActions>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={() => formState.handleFill(record)}
                                        disabled={outstandingQuantity === 0 || formState.values.quantity === 0}
                                    >
                                        Fill
                                    </Button>
                                    <Button
                                        type="secondary"
                                        size="small"
                                        onClick={() => formState.handleUnFill(record)}
                                        disabled={recordIsFilled || recordIsPartiallyDeliveredAndFilled}
                                    >
                                        Unfill
                                    </Button>
                                </TableActions>
                            )
                        },
                    }]}
                    dataSource={orders}
                    rowClassName={({ quantity, outstandingQuantity }) => {
                        if (outstandingQuantity === 0) {
                            return 'text-green-400'
                        } if (outstandingQuantity < quantity && outstandingQuantity !== 0) {
                            return 'text-yellow-400'
                        }
                        return ''
                    }}
                />
            </div>
        )}
    </PreferredUnits>
)

UnfilledBuyCustomerOrdersTable.FRAGMENT = FRAGMENT

export default UnfilledBuyCustomerOrdersTable
