import React from 'react'
import { Modal } from 'antd'
import UpdateTradeForm from './CreateTradeForm'

const UpdateTradeModal = ({
    pricing,
    units,
    assets,
    currencies,
    formState,
    isOpen,
    isUpdating,
    onCancel,
    onConfirm,
}) => (
    <Modal
        title="Update trade"
        width={750}
        visible={isOpen}
        closable={!isUpdating}
        maskClosable={!isUpdating}
        confirmLoading={isUpdating}
        onCancel={onCancel}
        onOk={onConfirm}
    >
        <UpdateTradeForm
            pricing={pricing}
            units={units}
            assets={assets}
            currencies={currencies}
            formState={formState}
        />
    </Modal>
)

export default UpdateTradeModal
