import React from 'react'
import { gql } from '@apollo/client'
import { Select } from 'antd'
import FormItem from './FormItem'

const { Option } = Select

const FRAGMENT = gql`
    fragment AssetSelect on Asset {
        id
        name
        code
    }
`

const AssetSelect = ({
    isRequired,
    disabled = false,
    assets,
    error,
    label,
    ...props
}) => (
    <FormItem
        isRequired={isRequired}
        label={label}
        error={error}
    >
        <Select
            disabled={disabled}
            placeholder="Select an asset"
            {...props}
        >
            {assets.map((option) => (
                <Option
                    key={option.id}
                    value={option.id}
                >
                    {option.name} - {option.code}
                </Option>
            ))}
        </Select>
    </FormItem>
)

AssetSelect.FRAGMENT = FRAGMENT

export default AssetSelect
