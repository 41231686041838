import React from 'react'
import CustomerTopBar from '../../layout/CustomerTopBar'
import CustomerAssetsList from './list/CustomerAssetsList'
import CustomerAssetCreateButton from './list/CustomerAssetCreateButton'

const CustomerAssetsPage = ({
    customer,
    assets,
    customerAssets,
    isCreatingCustomerAsset,
    onCreateCustomerAsset,
    onToggleSuspendCustomerAsset,
    onEditCustomerAsset,
    isDeletingCustomerAsset,
    onDeleteCustomerAsset,
}) => (
    <>
        <CustomerTopBar
            customerName={customer.name}
            rightChildren={(
                <CustomerAssetCreateButton
                    disabled={assets.length === customerAssets.length}
                    assets={assets.filter(({ id }) => !customerAssets.find(({ asset }) => asset.id === id))}
                    isCreatingCustomerAsset={isCreatingCustomerAsset}
                    onCreateCustomerAsset={onCreateCustomerAsset}
                />
            )}
        />
        <CustomerAssetsList
            customerAssets={customerAssets}
            onToggleSuspendCustomerAsset={onToggleSuspendCustomerAsset}
            onEditCustomerAsset={onEditCustomerAsset}
            isDeletingCustomerAsset={isDeletingCustomerAsset}
            onDeleteCustomerAsset={onDeleteCustomerAsset}
        />
    </>
)

export default CustomerAssetsPage
